import { CarEvent } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/constants';

export const getCarEvents = async (filter: any, size = 50, page = 0) => {
  try {
    return (
      await axios.get(`${BASE_URL}/carevent`, {
        params: {
          filter,
          size,
          page,
        },
      })
    ).data.data as { count: number; carEvents: CarEvent[] };
  } catch (err) {
    console.error('Failed to get car event');
    console.error(err);
    throw err;
  }
};

export const updateCarEvent = async (
  carEventId: string,
  carEventData: CarEvent
) => {
  try {
    return (await axios.put(`${BASE_URL}/carevent/${carEventId}`, carEventData))
      .data;
  } catch (err) {
    console.error('Failed to put car event');
    console.error(err);
    throw err;
  }
};

export const createCarEvent = async (
  carEventData: CarEvent
): Promise<CarEvent> => {
  try {
    return (await axios.post(`${BASE_URL}/carevent`, carEventData)).data
      .carEvent;
  } catch (err) {
    console.error('Failed to create car event');
    console.error(err);
    throw err;
  }
};
