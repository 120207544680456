import { Route, Redirect } from 'react-router-dom';
import { TOKEN } from '../utils/constants';

interface props {
  component: any;
  allowed: Array<string>;
  [x: string]: any;
}

export const PrivateRoute = ({
  component: Component,
  allowed,
  ...rest
}: props) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = localStorage.getItem(TOKEN);
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export const PrivateAuthRoute = ({
  component: Component,
  allowed,
  ...rest
}: props) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = localStorage.getItem(TOKEN);
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};
