import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Car, CarEvent } from '../../../types/api';
import {
  CAR_EVENT_CASCO,
  CAR_EVENT_INSURANCE,
  CAR_EVENT_ITP,
  CAR_EVENT_VINIETA,
} from '../../../utils/constants';
import {
  MCModal,
  MCParagraph,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { useLeftNavigatorOwnerProps } from '../../hooks';
import { useAppCreateCarEventMutation } from '../../hooks/mutations/useAppCreateCarEventMutation';
import { useAppUpdateCarEventMutation } from '../../hooks/mutations/useAppUpdateCarEventMutation';
import { useAppCarEventsQuery } from '../../hooks/queries/useAppCarEventsQuery';
import { useAppCarsQuery } from '../../hooks/queries/useAppCarsQuery';
import styles from './CarEvents.module.css';
import { HistoryCarEvent } from './HistoryCarEvent';
import { LatestCarEvent } from './LatestCarEvent';
import { ModalCarEvent } from './ModalCarEvent';

export const CarEvents = () => {
  const props = useLeftNavigatorOwnerProps();

  const { data: cars, isLoading } = useAppCarsQuery();
  const [selectedCarId, setSelectedCarId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CarEvent>({
    type: 'RCA',
  });

  const { mutateAsync: updateCarEvent } = useAppUpdateCarEventMutation();
  const { mutateAsync: createCarEvent } = useAppCreateCarEventMutation();

  const toOption = useCallback((car: Car) => {
    return {
      name: `${car?.make} ${car?.model} ${car?.year} - ${car.registrationNumber}`,
      value: car._id || '',
    };
  }, []);

  useEffect(() => {
    if (!isLoading && cars?.[0]?._id) {
      setSelectedCarId(cars[0]._id);
    }
  }, [cars, isLoading, toOption]);

  const carSelectOptions = useMemo(() => {
    return (cars || []).map(toOption);
  }, [cars, toOption]);

  const { data: carEvents, isLoading: isLoadingCarEvents } =
    useAppCarEventsQuery({
      car: selectedCarId,
    });

  let rca: CarEvent | undefined = undefined;
  let casco: CarEvent | undefined = undefined;
  let itp: CarEvent | undefined = undefined;
  let vinieta: CarEvent | undefined = undefined;
  let endedEvents: Array<CarEvent> = [];
  carEvents
    ?.sort((a: CarEvent, b: CarEvent) => {
      // @ts-ignore
      return b.dateEnd - a.dateEnd;
    })
    .forEach((e) => {
      if (e.dateEnd && e.dateEnd >= moment().unix()) {
        if (rca === undefined && e.type === CAR_EVENT_INSURANCE) {
          rca = e;
          return;
        }
        if (casco === undefined && e.type === CAR_EVENT_CASCO) {
          casco = e;
          return;
        }
        if (itp === undefined && e.type === CAR_EVENT_ITP) {
          itp = e;
          return;
        }
        if (vinieta === undefined && e.type === CAR_EVENT_VINIETA) {
          vinieta = e;
          return;
        }
      }
      endedEvents.push(e);
    });

  const onNewInsurance = (event: CarEvent) => {
    setShowModal(true);
    setSelectedEvent(event);
  };

  const onEditInsurance = (event: CarEvent) => {
    setShowModal(true);
    setSelectedEvent(event);
  };

  return (
    <MCTwoPaneLayout title="Asigurari Rovinieta ITP" {...props}>
      {!!selectedCarId && (
        <div className={styles.container}>
          <MCSpacing />
          <MCSpacing />
          <div className={styles.centeredHalfWidth}>
            <InputSelect
              options={carSelectOptions}
              value={selectedCarId}
              onValueChange={setSelectedCarId}
              label="Masina"
              bold
            />
          </div>
          <MCSpacing />

          {isLoadingCarEvents && isLoading ? (
            <LoadingOutlined className="h1 p-5" />
          ) : (
            <div className={styles.containerBlocks}>
              <LatestCarEvent
                type={'RCA'}
                carEvent={rca}
                onEdit={onEditInsurance}
                onNew={onNewInsurance}
              />
              <LatestCarEvent
                type={'CASCO'}
                carEvent={casco}
                onEdit={onEditInsurance}
                onNew={onNewInsurance}
              />
              <LatestCarEvent
                type={'ITP'}
                carEvent={itp}
                onEdit={onEditInsurance}
                onNew={onNewInsurance}
              />
              <LatestCarEvent
                type={'VINIETA'}
                carEvent={vinieta}
                onEdit={onEditInsurance}
                onNew={onNewInsurance}
              />
            </div>
          )}

          <MCSpacing />
          <MCSpacing />
          <MCSpacing />

          {isLoadingCarEvents && isLoading ? (
            <LoadingOutlined className="h1 p-5" />
          ) : (
            <>
              {endedEvents.length > 0 && (
                <>
                  <MCParagraph fontSize={30} bold>
                    Istoric
                  </MCParagraph>
                  <MCSpacing />
                  {endedEvents.map((e, index) => {
                    return (
                      <div style={{ width: '100%' }} id={`${index}`}>
                        <MCSpacing />
                        <HistoryCarEvent
                          carEvent={e}
                          onEdit={onEditInsurance}
                        />
                        <MCSpacing />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      )}

      <MCModal
        topMargin={250}
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalCarEvent
          carEvent={selectedEvent}
          onSave={(e) => {
            if (e._id) {
              updateCarEvent({ carEventId: e._id, carEventData: e });
            } else {
              createCarEvent({
                ...e,
                car: selectedCarId,
                isNotificationSent: false,
              });
            }
            setShowModal(false);
          }}
          onDelete={(e) => {
            setShowModal(false);
          }}
        />
      </MCModal>
    </MCTwoPaneLayout>
  );
};
