import {
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  DRIVER_LOADED,
  AUTH_ERROR,
  DRIVER_UPDATE,
} from '../types';
import { Dispatch } from 'redux';
import {
  IS_PROD_ENV,
  PlatformType,
  PLATFORM_TYPE,
  TOKEN,
} from '../../utils/constants';
import { toast } from 'react-toastify';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';
import {
  getDriver,
  loginDriver,
  registerDriver,
  updateDriver,
  verifDriverPhone,
} from '../../api/drivers';
import { Driver } from '../../types/api';
import { getAnalytics } from 'firebase/analytics';
import { initTeamSettings } from '../../api/teamSettings';

const IS_OWNER_PLATFORM = PLATFORM_TYPE === PlatformType.Owner;
const firebaseConfig = (() => {
  if (IS_OWNER_PLATFORM) {
    return IS_PROD_ENV
      ? require('../../firebase/prod-owner-firebaseConfig.json')
      : require('../../firebase/dev-owner-firebaseConfig.json');
  }

  return IS_PROD_ENV
    ? require('../../firebase/prod-firebaseConfig.json')
    : require('../../firebase/dev-firebaseConfig.json');
})();

const FIREBASE_REGISTER_REDIRECT_URL = (() => {
  if (IS_OWNER_PLATFORM) {
    return IS_PROD_ENV
      ? 'https://proprietar.masinilacheie.ro'
      : 'https://stage-proprietar.masinilacheie.ro';
  }

  return IS_PROD_ENV
    ? 'https://app.masinilacheie.ro/onboarding'
    : 'https://stage.masinilacheie.ro/onboarding';
})();

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// eslint-disable-next-line
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);

setPersistence(firebaseAuth, browserLocalPersistence);

// Load User
export const getDriverAction = () => async (dispatch: Dispatch) => {
  if (localStorage.authToken) {
    try {
      const driver = await getDriver();

      dispatch({
        type: DRIVER_LOADED,
        payload: driver,
      });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  }
};

// Register Driver
export const registerDriverAction =
  (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
      const user = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password!
      );

      const actionCodeSettings = {
        url: FIREBASE_REGISTER_REDIRECT_URL,
      };

      await sendEmailVerification(user.user, actionCodeSettings);

      const idToken = await user.user.getIdToken();

      await registerDriver(idToken);
      toast('Cont creat cu succes');
      dispatch(
        // @ts-ignore
        loginDriverAction(email, password, PLATFORM_TYPE === PlatformType.Owner)
      );
    } catch (err) {
      // @ts-ignore
      if (err?.code === 'auth/email-already-in-use') {
        toast.error('Email deja folosit');
      } else {
        toast.error('Eroare la inregistrare');
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login Driver
export const loginDriverAction =
  (email: string, password: string, shouldInitTeamSettings = false) =>
  async (dispatch: Dispatch) => {
    try {
      const driver = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password!
      );

      const idToken = await driver.user.getIdToken();

      const payload = await loginDriver(idToken);

      localStorage.setItem(TOKEN, payload.token);
      if (shouldInitTeamSettings) {
        initTeamSettings(payload);
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload,
      });

      // @ts-ignore
      dispatch(getDriverAction());
    } catch (err) {
      // @ts-ignore
      if (err?.code === 'auth/wrong-password') {
        toast.error('Combinatie email/parola gresita');
      } else {
        toast.error('Erroare la login');
      }
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Login Driver
export const loginOwnerAdmin =
  (token: string) => async (dispatch: Dispatch) => {
    try {
      localStorage.setItem(TOKEN, token);
      await initTeamSettings({ token });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token },
      });

      console.log();

      // @ts-ignore
      await dispatch(getDriverAction());
    } catch (err) {
      // @ts-ignore
      if (err?.code === 'auth/wrong-password') {
        toast.error('Combinatie email/parola gresita');
      } else {
        toast.error('Erroare la login');
      }
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Update Driver
export const updateDriverAction =
  (driver: Driver) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: DRIVER_UPDATE,
        payload: driver,
      });

      updateDriver(driver._id!, driver);
    } catch (err) {}
  };

// Logout / Clear profile
export const logout = () => (dispatch: Dispatch) => {
  localStorage.removeItem(TOKEN);
  firebaseAuth.signOut();

  dispatch({ type: LOGOUT });
};

export const resendEmailVerification = async () => {
  try {
    if (firebaseAuth.currentUser) {
      const actionCodeSettings = {
        url: 'https://app.masinilacheie.ro/onboarding',
      };

      await sendEmailVerification(firebaseAuth.currentUser, actionCodeSettings);
      toast.success('Email retrimis');
    } else {
      toast.error('User not found');
    }
  } catch (err) {
    toast.error('Incercati mai tarziu');
  }
};

export const isEmailVerified = () => {
  try {
    if (firebaseAuth.currentUser) {
      return firebaseAuth.currentUser.emailVerified;
    } else {
      return false;
    }
  } catch (err) {}
};

export const resetPasswordAction =
  (email: string) => async (dispatch: Dispatch) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      toast.success('Email de resetare trimis!');
    } catch (err) {
      toast.error('Email gresit!');
    }
  };

export const sendPhoneVerification = async (
  driverId: string,
  phone: string
) => {
  await verifDriverPhone(driverId, phone);
};
