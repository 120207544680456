import { LoadingOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Car } from '../../../types/api';
import {
  CAR_ACTIVE,
  CAR_AUTOMATIC,
  CAR_COMING_SOON,
  CAR_DEACTIVATED,
  CAR_MANUAL,
} from '../../../utils/constants';
import {
  MCParagraph,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { useAppUpdateCarMutation } from '../../hooks/mutations/useAppUpdateCarMutation';
import { useAppCarsQuery } from '../../hooks/queries';
import styles from './Cars.module.css';

export const Categories = () => {
  const props = useLeftNavigatorOwnerProps();

  const { data: cars, isLoading } = useAppCarsQuery({
    types: ['category'],
  });
  const [selectedCarId, setSelectedCarId] = useState('');
  const [selectedCar, setSelectedCar] = useState<Car>();
  const [isModified, setIsModified] = useState(false);
  const { trackEvent } = useOwnerAnalytics();

  const { mutateAsync: updateCar } = useAppUpdateCarMutation();

  const toOption = useCallback((car: Car) => {
    return {
      name: car.categoryDetails.name,
      value: car._id || '',
    };
  }, []);

  const hasData = useRef(false);
  useEffect(() => {
    if (hasData.current) {
      return;
    }

    if (!isLoading && cars?.[0]?._id) {
      hasData.current = true;
      setSelectedCarId(cars[0]._id);
    }
  }, [cars, isLoading, toOption]);

  useEffect(() => {
    if (selectedCarId !== '' && cars?.length) {
      const found = cars.find((x) => x._id === selectedCarId);
      if (found) {
        setSelectedCar(found);
      }
    }
  }, [selectedCarId, cars]);

  const carSelectOptions = useMemo(() => {
    return (cars || []).map(toOption);
  }, [cars, toOption]);

  return (
    <MCTwoPaneLayout title={'Categorii'} {...props}>
      <MCSpacing />
      <MCSpacing />
      <MCSpacing />
      <div className={styles.contentCenter}>
        <div className={styles.halfWidth}>
          <InputSelect
            options={carSelectOptions}
            value={selectedCarId}
            onValueChange={(id) => {
              const found = carSelectOptions.find((x) => x.value === id);
              trackEvent('Categories_CategoriesCombo_Select', {
                carId: id,
                name: found?.name || '',
              });
              setSelectedCarId(id);
            }}
            label={'Categoria'}
            bold
          />
        </div>

        <div className={styles.halfWidth}>
          <MCSpacing />
          <MCSpacing />
          <MCSpacing />

          {
            <>
              {selectedCar && (
                <>
                  <MCParagraph fontSize={20} bold>
                    Nume categorie
                  </MCParagraph>
                  <MCSpacing />
                  <InputField
                    value={selectedCar.categoryDetails.name}
                    placeholder={''}
                    onChange={(name) => {
                      trackEvent('Categories_CategoryNameInput_Type', {
                        carId: selectedCarId,
                        name,
                      });
                      setSelectedCar({
                        ...selectedCar,
                        categoryDetails: {
                          ...selectedCar.categoryDetails,
                          name,
                        },
                      });
                      setIsModified(true);
                    }}
                  />
                  <MCSpacing />
                  <MCSpacing />
                  <MCSpacing />
                </>
              )}
            </>
          }

          <>
            <MCParagraph fontSize={20} bold>
              Status Categorie
            </MCParagraph>
            <MCSpacing />
            {isLoading && <LoadingOutlined className="h1 p-5" />}
            {selectedCar && (
              <>
                <InputSelect
                  options={[
                    { name: 'Activa', value: CAR_ACTIVE },
                    { name: 'In curand', value: CAR_COMING_SOON },
                    { name: 'Dezactivata', value: CAR_DEACTIVATED },
                  ]}
                  value={selectedCar?.status}
                  onValueChange={(status) => {
                    trackEvent('Categories_StatusCombo_Select', {
                      carId: selectedCarId,
                      status,
                    });
                    setSelectedCar({ ...selectedCar, status: status });
                    setIsModified(true);
                  }}
                  label="Status"
                  bold
                />
                <MCSpacing />
                <MCSpacing />
              </>
            )}
          </>

          <MCParagraph fontSize={20} bold>
            Motor si date tehnice
          </MCParagraph>
          <MCSpacing />
          {isLoading && <LoadingOutlined className="h1 p-5" />}
          {selectedCar && (
            <>
              <InputField
                label={'An de fabricatie'}
                value={`${selectedCar?.categoryDetails.year}`}
                placeholder={''}
                onChange={(year) => {
                  trackEvent('Categories_YearInput_Type', {
                    carId: selectedCarId,
                    year,
                  });
                  setSelectedCar({
                    ...selectedCar,
                    categoryDetails: {
                      ...selectedCar.categoryDetails,
                      year,
                    },
                  });

                  setIsModified(true);
                }}
              />
              <MCSpacing />
              <InputSelect
                marginLeft={30}
                options={[
                  { name: 'Manuala', value: CAR_MANUAL },
                  { name: 'Automata', value: CAR_AUTOMATIC },
                ]}
                value={selectedCar.engine.transmission}
                onValueChange={(trans) => {
                  trackEvent('Categories_TransmissionCombo_Select', {
                    carId: selectedCarId,
                    transmission: trans,
                  });
                  setSelectedCar({
                    ...selectedCar,
                    engine: {
                      ...selectedCar?.engine,
                      transmission: trans,
                    },
                  });
                  setIsModified(true);
                }}
                label="Transmisie"
                bold
              />
              <MCSpacing />
              <InputSelect
                marginLeft={40}
                options={[
                  { name: '2 locuri', value: '2' },
                  { name: '4 locuri', value: '4' },
                  { name: '5 locuri', value: '5' },
                  { name: '6 locuri', value: '6' },
                  { name: '6 + 1 locuri', value: '7' },
                  { name: '7 + 1 locuri', value: '8' },
                  { name: '8 + 1 locuri', value: '9' },
                ]}
                value={`${selectedCar.seats}`}
                onValueChange={(seats) => {
                  trackEvent('Categories_SeatsCombo_Select', {
                    carId: selectedCarId,
                    seats,
                  });
                  setSelectedCar({
                    ...selectedCar,
                    seats: parseInt(seats),
                  });
                  setIsModified(true);
                }}
                label="Locuri"
                bold
              />
              <MCSpacing />
            </>
          )}
          <MCSpacing />
          <MCSpacing />
          <div className={styles.saveBtn}>
            <ActionButton
              label={'Salveaza'}
              disabled={!isModified}
              onClick={() => {
                trackEvent('Categories_Save_Click', { carId: selectedCarId });
                if (selectedCar) {
                  updateCar({ carId: selectedCarId, carData: selectedCar });
                  setIsModified(false);
                }
              }}
            />
          </div>
          <MCSpacing />
        </div>
      </div>
    </MCTwoPaneLayout>
  );
};
