import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../redux/actions/driverAction';
import { ReduxState } from '../../../types/redux';
import logo from '../../../assets/driver/navbar/logo-navbar.svg';
import menu from '../../../assets/driver/navbar/burger-menu.svg';
import menuExit from '../../../assets/driver/navbar/menu-exit-button.svg';

import './NavBarDriver.style.css';
import { HOME_URL } from '../../../utils/constants';
import { useAppWindowSizeInfo } from '../../../hooks';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';

export const NavbarDriver = () => {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const { isMobile } = useAppWindowSizeInfo();
  const { trackEvent } = useDriverAnalytics();

  const renderElement = (name: any, redirect: any, onClick: () => void) => {
    const isExternal = redirect.includes('https');
    return (
      <Link
        className="nav-item"
        style={{
          textDecoration: 'none',
        }}
        to={isExternal ? { pathname: redirect } : redirect}
        target={isExternal ? '_blank' : '_self'}
        onClick={() => {
          onClick();
          setIsActive(false);
        }}
      >
        {name}
      </Link>
    );
  };

  const renderLogout = () => {
    return (
      <div className="nav-item">
        <Link
          to="/login"
          onClick={() => {
            dispatch(logout());
          }}
          style={{ color: '#F54952' }}
        >
          Deconectare
        </Link>
      </div>
    );
  };

  const renderListCar = () => {
    return (
      <div className="nav-item">
        <Link
          to={{
            pathname: `${HOME_URL}/list-your-car/`,
          }}
          target="_blank"
          style={{ color: '#F54952' }}
        >
          Listează-ți mașina
        </Link>
      </div>
    );
  };

  const renderNavItems = (isMobile: boolean) => {
    if (driver.isAuthenticated) {
      return (
        <div className="nav-items">
          {renderElement('Catalog', 'https://masinilacheie.ro/catalog', () =>
            trackEvent('Navbar_Catalog_Click')
          )}
          {renderElement('Inchirieri', '/reservations', () =>
            trackEvent('Navbar_Rentals_Click')
          )}
          {renderElement('Profil', '/profile', () =>
            trackEvent('Navbar_Profile_Click')
          )}
          {renderLogout()}
        </div>
      );
    } else {
      return (
        <div className="nav-items">
          {renderElement('Catalog', 'https://masinilacheie.ro/catalog', () =>
            trackEvent('Navbar_Catalog_Click')
          )}
          {renderElement('Autentificare', '/login', () =>
            trackEvent('Navbar_Login_Click')
          )}
          {renderElement('Înregistrare', '/register', () =>
            trackEvent('Navbar_Register_Click')
          )}
          {renderListCar()}
        </div>
      );
    }
  };

  const renderMobile = () => {
    return (
      <div
        className="menu-icon"
        onClick={() => {
          trackEvent('Navbar_Burger_Click', { open: !isActive });
          setIsActive(!isActive);
        }}
      >
        <img
          src={isActive ? menuExit : menu}
          width="30"
          height="30"
          alt="burger menu"
        />
      </div>
    );
  };

  return (
    <div className="nav-container">
      <div className="n-container">
        <a
          className="nav-logo"
          href={HOME_URL}
          onClick={() => trackEvent('Navbar_Logo_Click')}
        >
          <img src={logo} width="40" height="40" alt="logo" />
        </a>

        {isMobile && renderMobile()}
        <div className={isActive ? 'nav-menu active' : 'nav-menu'}>
          {renderNavItems(isMobile)}
        </div>
      </div>
    </div>
  );
};
