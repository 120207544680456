import moment from 'moment';

export interface MonthDay {
  dateLabel: string;
  isSelected: boolean;
  isSelectable: boolean;
  date: Date;
}

const isDateSelectable = (
  indexDate: moment.Moment,
  {
    minimumDate,
    maximumDate,
    disabledIntervals,
  }: {
    minimumDate?: Date;
    maximumDate?: Date;
    disabledIntervals?: Array<{ begin: moment.Moment; end: moment.Moment }>;
  }
): boolean => {
  if (minimumDate && indexDate.diff(minimumDate, 'days') < 0) {
    return false;
  }
  if (maximumDate && indexDate.diff(maximumDate, 'days') >= 0) {
    return false;
  }
  if (disabledIntervals) {
    const foundInterval = disabledIntervals.find(({ begin, end }) => {
      return moment(indexDate).isBetween(begin, end, 'days', '[]');
    });
    if (foundInterval) {
      return false;
    }
  }

  return true;
};

export const computeMonthDays = (
  firstDayMonthCurrent: Date,
  minimumDate?: Date,
  maximumDate?: Date,
  disabledIntervals?: Array<{ begin: Date; end: Date }>,
  isDisabledDate?: (date: moment.Moment) => boolean
) => {
  const firstDayMonth = firstDayInMonth(
    firstDayMonthCurrent.getMonth(),
    firstDayMonthCurrent.getFullYear()
  );
  const lastDayMonth = daysInMonth(
    firstDayMonthCurrent.getMonth(),
    firstDayMonthCurrent.getFullYear()
  );

  const monthDays: Array<Array<MonthDay>> = [];
  const indexDate = new Date(firstDayMonth.getTime());
  while (indexDate.getDay() !== 1) {
    indexDate.setDate(indexDate.getDate() - 1);
  }

  const disabledIntervalsMoment = disabledIntervals?.map(({ begin, end }) => ({
    begin: moment(begin),
    end: moment(end),
  }));

  for (let i = 0; i < 6; i++) {
    if (indexDate.getTime() <= lastDayMonth.getTime()) {
      monthDays.push([]);
      for (let j = 0; j < 7; j++) {
        const dateString =
          indexDate.getTime() <= lastDayMonth.getTime() &&
          indexDate.getTime() >= firstDayMonth.getTime()
            ? indexDate.getDate().toString()
            : '';

        const isSelectable =
          isDisabledDate && isDisabledDate(moment(indexDate))
            ? false
            : isDateSelectable(moment(indexDate), {
                minimumDate,
                maximumDate,
                disabledIntervals: disabledIntervalsMoment,
              });

        monthDays[i].push({
          dateLabel: dateString,
          isSelected: false,
          isSelectable,
          date: new Date(indexDate.getTime()),
        });
        indexDate.setDate(indexDate.getDate() + 1);
      }
    }
  }

  return monthDays;
};

const daysInMonth = (month: number, year: number) => {
  if (month === 11) {
    year += 1;
    month = 0;
  } else {
    month += 1;
  }
  return new Date(year, month, 0);
};

const firstDayInMonth = (month: number, year: number) => {
  return new Date(year, month, 1);
};
