import * as React from 'react';
import styles from './MCParagraph.module.css';

export interface MCParagraphProps {
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  bold?: boolean;
  underline?: boolean;
  fontSize?: number;
  lineHeight?: number;
  color?: string;
  textAlign?: string;
  cursor?: string;
}

export function MCParagraph({
  onClick,
  children,
  bold = false,
  underline = false,
  fontSize = 14,
  lineHeight = 1.2,
  color = undefined,
  textAlign = undefined,
  cursor = 'auto',
}: MCParagraphProps) {
  return (
    <span
      /*
      // @ts-ignore */
      style={{ fontSize, lineHeight, color, textAlign, cursor }}
      onClick={onClick}
      className={`${styles.paragraph} ${bold ? styles.bold : ''} ${
        underline ? styles.underline : ''
      }`}
    >
      {children}
    </span>
  );
}
