export interface AuthDetails {
  _id?: string;
  email: string;
  uid: string;
  role?: string;
}

export interface AuthCredentials {
  emaiL: string;
  password: string;
}

export interface LoginRes {
  token: string;
}

export interface RentalOffer {
  days: number;
  priceIntegral: number;
  distance: number;
}

export interface CarSettings {
  monthIncrease: number[];
  guarantees: Guarantees;
  minDaysRental: number;
  distancePerDay: number;
  extraHundredKmFee: number;
  euExitActive: boolean;
  euExitFee: number;
  euExitGuarantee: number;
  rentalOffers: Array<RentalOffer>;
}

export interface Guarantees {
  integral: GuaranteesSettings;
  reduced: GuaranteesSettings;
  minimal: GuaranteesSettings;
}

export type GuaranteeType = keyof Guarantees;

export interface GuaranteesSettings {
  active: boolean;
  guarantee: number;
  assurancePricePerDay: number;
}

export interface CarLocation {
  location: any;
  isDeliveryActive?: boolean;
  deliveryExtraFee?: number;
}

export interface Car {
  _id: string;
  owner: string;
  location: Array<CarLocation>;
  baseUrl: string;
  reservations: Array<{
    dateBegin: number;
    dateEnd: number;
  }>;
  conditions?: {
    age?: number;
    driverLicenseAge?: number;
    areas?: string;
    refuel?: string;
    hygiene?: string;
    deposit?: string;
  };
  make: string;
  model: string;
  carType: string;
  carClass: string;
  isPremium: boolean;
  isSuv: boolean;
  scores: number[];
  fuel: string;
  year: number;
  seats: number;
  engine: {
    power: number;
    torque: number;
    displacement: number;
    tractionType: string;
    transmission: string;
    range?: number;
  };
  images: Array<string>;
  status: string;
  registrationNumber: string;
  settings: CarSettings;
  teamSettings: TeamSettings;
  type: 'car' | 'category';
  categoryDetails: {
    year: string; // "2015-2018"
    name: string;
    cars: Array<{
      make: string;
      model: string;
      quantity: number;
      images: string[];
    }>;
  };
}

export enum VoucherDiscountType {
  Value = 'value',
  Percentage = 'percentage',
}

export interface Voucher {
  code: string;
  dateBegin: number;
  dateEnd: number;
  discountType: VoucherDiscountType;
  discountValue: number;
}

export interface CarOffer {
  distancePerDay: number;
  totalDistance: number;
  pricePerDay: number;
  numberOfDays: number;
  totalRent: number;
  destinationCharges: number;
  deposit: number;
  totalCost: number;
}

export interface Location {
  _id?: string;
  name: string;
  isAreaLocation: boolean;
  type: string;
  parent?: Array<string>;
  lat: number;
  lng: number;
  fullAdress?: string;
}

export enum ReservationType {
  Driver = 'driver',
  Owner = 'owner',
  Service = 'service',
  Personal = 'personal',
}

export enum PaymentMethod {
  Cash = 'cash',
  Card = 'card',
  CardAndCash = 'card_cash',
}

export enum CheckoutPaymentMethod {
  FullOnline = 'FullOnline',
  Advance20 = 'Advance20',
  Owner = 'Owner',
}

export interface ExtraOptions {
  euExit?: boolean;
  extraHundredKm?: number;
}

export interface Reservation {
  _id: string;
  owner?: any;
  user?: Driver | string;
  car: any;
  location?: Location | string;
  locationDetails?: string;
  dateBegin: number;
  dateEnd: number;
  days: number;
  bufferDateBegin: number;
  bufferDateEnd: number;
  status?: string;
  refusedReason?: string;
  stripeSessionId?: string;
  stripeSession?: any;
  reservationType?: ReservationType;
  name?: string;
  phoneNumber?: string;
  email?: string;
  paymentMethod?: PaymentMethod;
  checkoutPaymentMethod?: CheckoutPaymentMethod;
  isOurReservation?: boolean;
  companyName?: string;
  companyCUI?: string;
  reservationId: string;
  carDetails?: {
    makeAndModel?: string;
    registrationNumber?: string;
    _id?: string;
  };
  extraOptions?: {
    euExit?: boolean;
    extraHundredKm?: number;
    extraDeliveryFee?: number;
  };
  taxes?: Array<{
    name: string;
    value: number;
    totalValue: number;
    quantity: number;
  }>;
  options?: Array<{
    name: string;
    value: number;
    totalValue: number;
    quantity: number;
  }>;
  pricing: {
    basePrice: number;
    totalPrice: number;
    pricePerDay: number;
    taxesPrice: number;
    optionsPrice: number;
    discount: number;
    customerPaidAmount: number;
    customerPrice: number;
    customerLeftToPayAmount: number;
    marketplaceRevenue: number;
    supplierRevenue: number;
    supplierInvoiceValue: number;
    deposit?: number;
  };
}

export interface TeamSettings {
  notifications: {
    emailNotifications: boolean;
    phoneNotifications: boolean;
  };
  timeBetweenRentals: number;
  schedule: {
    extraScheduleTax: number;
    isExtraScheduleTax: boolean;
    schedule: Array<Array<number>>;
    scheduleDays: Array<boolean>;
  };
  paymentMethod: {
    payAtOffice: boolean;
    officePaymentType: string;
    officeDepositPaymentType: string;
  };
  promoteCars?: boolean;
  disabledCheckoutMethods?: CheckoutPaymentMethod[];
}

export interface Seller {
  _id?: string;
  uid?: string;
  email?: string;
  role?: string;
  phone?: string;
  name?: string;
  companyName?: string;
  image?: string;
  stripeAccountId?: string;
  stripeSeller?: any;
  stripeSession?: any;
  marketplaceFeePercent?: number;
  isVerified?: boolean;
  phoneNumber?: string;
  teamSettings: TeamSettings;
  showPromoteCars?: boolean;
}

export enum OnboardingStage {
  None = 0,
  VerifyEmail = 1,
  EmailVerified = 2,
  VerifyPhone = 3,
  PhoneVerified = 4,
  DriverDetails = 5,
  DriverVerified = 6,
}

export interface Driver {
  _id?: string;
  uid?: string;
  email?: string;
  role?: string;
  dateBirth?: number;
  dateDriversLicense?: number;
  firstName?: string;
  lastName?: string;
  cnp?: string;
  address?: string;
  onboarding?: {
    stage: OnboardingStage;
    phone?: string;
    phoneCode?: string;
    verificationStatus?: string;
    verificationUrl?: string;
    verificationId?: string;
    verification?: any;
    isVerified?: boolean;
    isPhoneVerified?: boolean;
    isEmailVerified?: boolean;
  };
}

export enum TripIntervalType {
  Past = 'past',
  Present = 'present',
  Future = 'future',
}

export interface CreateUnavailabilityDto {
  car: string;
  dateBegin: number;
  dateEnd: number;
  name: string;
  phoneNumber: string;
  locationDetails: string;
  reservationType: ReservationType;
}

export interface EditUnavailabilityDto
  extends Omit<
      CreateUnavailabilityDto,
      'name' | 'phoneNumber' | 'locationDetails' | 'reservationType'
    >,
    Partial<
      Pick<CreateUnavailabilityDto, 'name' | 'phoneNumber' | 'locationDetails'>
    > {
  _id: string;
  price?: number;
  advance?: number;
  deposit?: number;
  paymentMethod?: PaymentMethod;
}

export interface UpdateOwnerDto {
  ownerId: string;
  owner: Seller;
}

export interface SummaryData {
  title: string;
  tooltip: string;
  value: number;
  isIncrease: boolean;
  increaseValue: number;
  suffix: string;
}

export interface InfoPerCar {
  totalIncome: string;
  isIncomeIncrease: boolean;
  incomeIncreaseValue: string;
  incomeSymbol?: string;
}

export interface SelectedCarsInfoReport {
  model: string;
  registrationNumber: string;
  data: InfoPerCar[];
}

export interface OwnerReport {
  summary: SummaryData[];
  valueDates: Array<number>;
  selectedCars: SelectedCarsInfoReport[];
}

export interface InvoiceItem {
  name?: string;
  productDescription?: string;
  isDiscount?: boolean;
  measuringUnitName?: string;
  currency?: string;
  quantity?: number;
  price?: number;
  isTaxIncluded?: boolean;
  taxName?: string;
  taxPercentage?: number;
}

export interface Invoice {
  _id?: string;
  reservation?: string;
  owner?: string;
  driver?: string;
  seriesName?: string;
  seriesNumber?: string;
  issueDate?: number;
  dueDate?: number;
  paymentUrl?: string;
  totalAmount?: number;
  isPaid?: boolean;
  seller?: {
    companyVatCode?: string;
    name?: string;
    email?: string;
    adress?: string;
    city?: string;
    country?: string;
  };
  client?: {
    companyVatCode?: string;
    name?: string;
    email?: string;
    adress?: string;
    city?: string;
    country?: string;
  };
  items?: Array<InvoiceItem>;
}

export interface CarEvent {
  _id?: string;
  car?: string;
  type: string;
  dateStart?: number;
  dateEnd?: number;
  daysTotal?: number;
  dateNotif?: number;
  isNotificationSent?: boolean;
  cost?: number;
  issuer?: string;
}
