import { LoadingOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCars, updateCar } from '../../../api/cars';
import {
  createLocation,
  deleteLocation,
  getLocations,
  updateLocation,
} from '../../../api/locations';
import { Car, CarLocation, Location } from '../../../types/api';
import { ReduxState } from '../../../types/redux';
import {
  MCParagraph,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { MCButton } from '../../../_shared/components/MCButton';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { DeliveryLocation } from './DeliveryLocation';
import { FixLocation } from './FixLocation';
import styles from './Locations.module.css';

export const Locations = () => {
  const props = useLeftNavigatorOwnerProps();
  const ownerId = useSelector(
    (x: ReduxState) => x.userReducer.owner?._id || ''
  );

  const { trackEvent } = useOwnerAnalytics();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [cars, setCars] = useState<Array<Car>>([]);
  const [fixedLocations, setFixedLocations] = useState<Array<Location>>([]);
  const [deliveryZones, setDeliveryZones] = useState<Array<CarLocation>>([]);
  const [allAreaLocations, setAllAreaLocations] = useState<Array<Location>>([]);
  const [didUpdateLocations, setDidUpdateLocations] = useState<boolean>(false);

  useEffect(() => {
    getAsyncLocations();
  }, []);

  useEffect(() => {
    if (didUpdateLocations) {
      // do something
      setDidUpdateLocations(false);
    }
  }, [didUpdateLocations]);

  const getAsyncCarsOwner = useCallback(async () => {
    if (ownerId && ownerId.length > 0) {
      setIsLoading(true);
      const carsOwner = await getCars({ owner: ownerId });

      setCars(carsOwner);

      if (carsOwner.length > 0) {
        setFixedLocations(
          carsOwner[0].location
            .filter((loc: CarLocation) => {
              return loc.location.isAreaLocation === false;
            })
            .map((carLoc) => carLoc.location)
        );
        setDeliveryZones(
          carsOwner[0].location.filter((loc: CarLocation) => {
            return loc.location.isAreaLocation === true;
          })
        );
      }
      setIsLoading(false);
    }
  }, [ownerId]);

  useEffect(() => {
    getAsyncCarsOwner();
  }, [getAsyncCarsOwner]);

  const getAsyncLocations = async () => {
    const areaLocations = await getLocations({ isAreaLocation: true });
    setAllAreaLocations(areaLocations.locations);
  };

  const onSaveLocations = async () => {
    trackEvent('Locations_Save_Click');
    setIsModified(false);
    let promises: any[] = [];

    // update locations
    fixedLocations.forEach((loc: Location) => {
      if (loc._id) {
        const promise = updateLocation(loc._id, loc);
        promises.push(promise);
      } else {
        const promise = createLocation(loc);
        promises.push(promise);
      }
    });

    const locations = await Promise.all(promises);

    // keep only location id
    const carLocations = [
      ...locations.map((loc: Location): CarLocation => {
        return {
          deliveryExtraFee: 0,
          isDeliveryActive: false,
          location: loc._id,
        };
      }),
      ...deliveryZones.map((loc: CarLocation): CarLocation => {
        return {
          ...loc,
          location: loc.location._id,
        };
      }),
    ];

    // update all cars
    // TODO: create one request for all cars
    cars.forEach((car: Car) => {
      updateCar(car._id, { ...car, location: carLocations });
    });
  };

  return (
    <MCTwoPaneLayout title="Locatii" {...props}>
      <MCSpacing />
      <MCSpacing />

      <MCParagraph fontSize={24} bold>
        Puncte fixe primiri - predari (sediu)
      </MCParagraph>

      <MCSpacing />
      {isLoading ? (
        <LoadingOutlined className="h1 m-5" />
      ) : (
        <>
          {fixedLocations.length > 0 &&
            fixedLocations.map((loc: Location, index) => {
              return (
                <>
                  <FixLocation
                    location={loc}
                    key={loc._id}
                    allLocations={allAreaLocations}
                    setLocation={(loc) => {
                      setIsModified(true);
                      fixedLocations[index] = loc;
                      setFixedLocations([...fixedLocations]);
                    }}
                    onDelete={() => {
                      setIsModified(true);
                      deleteLocation(fixedLocations[index]._id!);
                      fixedLocations.splice(index, 1);
                      setFixedLocations([...fixedLocations]);
                      onSaveLocations();
                    }}
                  />
                  <MCSpacing />
                </>
              );
            })}

          <div style={{ width: 200 }}>
            <MCButton
              label={'Adauga Locatie Fixa'}
              onClick={() => {
                trackEvent('Locations_AddFixedLocation_Click');
                const loc: Location = {
                  isAreaLocation: false,
                  name: '',
                  type: 'CITY',
                  parent: [allAreaLocations[0]._id!],
                  lat: allAreaLocations[0].lat,
                  lng: allAreaLocations[0].lng,
                };
                setFixedLocations([...fixedLocations, loc]);
              }}
            />
          </div>
        </>
      )}

      <MCSpacing />
      <MCSpacing />
      <MCSpacing />

      <MCParagraph fontSize={24} bold>
        Zone Livrare
      </MCParagraph>

      <MCSpacing />

      {isLoading ? (
        <LoadingOutlined className="h1 m-5" />
      ) : (
        <>
          {deliveryZones.length > 0 &&
            deliveryZones.map((loc: CarLocation, index) => {
              return (
                <>
                  <DeliveryLocation
                    carLocation={loc}
                    allLocations={allAreaLocations}
                    key={index}
                    setLocation={(loc) => {
                      setIsModified(true);
                      deliveryZones[index] = loc;
                      setDeliveryZones([...deliveryZones]);
                    }}
                    onDelete={(loc) => {
                      setIsModified(true);
                      deliveryZones.splice(index, 1);
                      setDeliveryZones([...deliveryZones]);
                    }}
                  />
                  <MCSpacing />
                </>
              );
            })}

          <div style={{ width: 200 }}>
            <MCButton
              label={'Adauga Zona Livrare'}
              onClick={() => {
                trackEvent('Locations_AddDeliveryZone_Click');

                const loc: CarLocation = {
                  isDeliveryActive: true,
                  deliveryExtraFee: 10000,
                  location: allAreaLocations[0],
                };
                setDeliveryZones([...deliveryZones, loc]);
                setIsModified(true);
              }}
            />
          </div>
        </>
      )}

      <MCSpacing />
      <MCSpacing />

      <div className={styles.save}>
        <ActionButton
          label={'Salveaza'}
          disabled={!isModified}
          onClick={onSaveLocations}
        />
      </div>
    </MCTwoPaneLayout>
  );
};
