import classNames from 'classnames';
import moment from 'moment';
import { useMemo } from 'react';
import { pngUser, svgPhone, svgWrench } from '../../../assets';
import { Reservation, ReservationType } from '../../../types/api';
import { BACKGROUND_COLOR_AGENDA } from '../../../utils/constants';
import styles from './AgendaReservation.module.css';
import receptionArrow from '../../../assets/owner/receptionDeliveries/receptionArrow.png';
import deliveryArrow from '../../../assets/owner/receptionDeliveries/deliveryArrow.png';

const COLOR_START = '#60C72E';
const COLOR_END = '#FF0000';

interface AgendaReservationProps {
  gridWidth: number;
  gridHeight: number;
  carIdx: number;
  rowHeight: number;
  firstDay: Date;
  lastDay: Date;
  reservation: Reservation;
  onClick: () => void;
}

export function AgendaReservation({
  gridWidth,
  gridHeight,
  rowHeight,
  carIdx,
  reservation,
  firstDay,
  lastDay,
  onClick,
}: AgendaReservationProps) {
  const totalRows = gridHeight / rowHeight;
  const top = rowHeight * carIdx;
  const bottom = rowHeight * (totalRows - 1 - carIdx);

  const firstDayUnix = moment(firstDay).unix();
  const lastDayUnix = moment(lastDay).unix();
  const totalSeconds = lastDayUnix - firstDayUnix;
  const durationBegin = reservation.dateBegin - firstDayUnix;
  const durationEnd = lastDayUnix - reservation.dateEnd;
  const left =
    durationBegin <= 0 ? 0 : (durationBegin / totalSeconds) * gridWidth;
  const right = durationEnd <= 0 ? 0 : (durationEnd / totalSeconds) * gridWidth;

  const rows = useMemo(() => {
    if (reservation.reservationType === ReservationType.Service) {
      return [{ img: svgWrench, text: 'Service' }];
    }

    if (reservation.reservationType === ReservationType.Personal) {
      return [{ img: pngUser, text: 'Personal' }];
    }

    return [
      { img: pngUser, text: reservation.name || '' },
      { img: svgPhone, text: reservation.phoneNumber || '' },
    ];
  }, [reservation.name, reservation.phoneNumber, reservation.reservationType]);

  const rowsWithDates = useMemo(() => {
    const filteredRows = rows.filter((x) => !!x.text);

    filteredRows.push({
      img: deliveryArrow,
      text: `${moment
        .unix(reservation.dateBegin)
        .format('DD/MM/YYYY Or\\a HH:mm')}`,
    });

    filteredRows.push({
      img: receptionArrow,
      text: `${moment
        .unix(reservation.dateEnd)
        .format('DD/MM/YYYY Or\\a HH:mm')}`,
    });

    return filteredRows;
  }, [rows]);

  return (
    <div
      style={{
        top,
        bottom,
        left,
        right,
      }}
      className={styles.container}
    >
      <div
        className={classNames(styles.content, {
          [styles.contentRoundedLeft]: left !== 0,
          [styles.contentRoundedRight]: right !== 0,
        })}
        onClick={onClick}
      >
        <div
          className={styles.edge}
          style={{
            backgroundColor:
              left === 0
                ? BACKGROUND_COLOR_AGENDA[
                    reservation.reservationType || ReservationType.Driver
                  ]
                : COLOR_START,
          }}
          onClick={() => {}}
        />
        <div
          className={styles.middle}
          style={{
            backgroundColor:
              BACKGROUND_COLOR_AGENDA[
                reservation.reservationType || ReservationType.Driver
              ],
          }}
        >
          <div className={styles.middleOverlay}>
            {rowsWithDates.map((row, index) => {
              return (
                <div key={index} className={styles.infoRow}>
                  <img src={row.img} className={styles.image} alt="img" />
                  <span className={styles.infoText}>{row.text}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            backgroundColor:
              right === 0
                ? BACKGROUND_COLOR_AGENDA[
                    reservation.reservationType || ReservationType.Driver
                  ]
                : COLOR_END,
          }}
          className={styles.edge}
        />
      </div>
    </div>
  );
}
