import './Legal.style.css';

export const Gdpr = () => {
  return (
    <div className="legal-container">
      <div className="legal-container-width">
        <p className="legal-container-text header">
          Politica de Confidențialitate (GDPR)
        </p>

        <p className="legal-container-text">
          În 25 mai 2018 a devenit aplicabil Regulamentul European 2016/679
          privind protecția persoanelor fizice referitor la prelucrarea datelor
          cu caracter personal și libera circulație a acestor date
          (,,Regulamentul” sau ,,GDPR”). Scopul principal al acestuia este
          creșterea nivelului de protecție a datelor personale și crearea unui
          climat de încredere care să permită fiecărei persoane controlul asupra
          propriilor date. Având în vedere aceste aspecte, se impune să vă
          informăm cu privire la prelucrarea datelor dumneavoastră cu caracter
          personal, la modalitățile prin care protejăm datele dumneavoastră
          personale, precum și cu privire la drepturile dumneavoastră, în
          conformitate cu prevederile GDPR.
        </p>

        <p className="legal-container-text sub-header">Descriere</p>
        <p className="legal-container-text">
          Politica noastră de prelucrare a datelor cu caracter personal descrie
          modalitatea în care vom colecta și procesa datele cu caracter personal
          ale clienților, ale partenerilor de afaceri, ale colaboratorilor și
          furnizorilor, potențialilor clienți, potențialilor angajați,
          potențialilor colaboratori și se aplică tuturor datelor cu caracter
          personal colectate pe Website, precum și tuturor datelor cu caracter
          personal pe care le colectăm prin intermediul e-mailului sau prin
          orice altă cale de comunicare prin intermediul căreia se vor procesa
          aceste date (“Datele personale”).
        </p>
        <p className="legal-container-text">
          În general, intenția noastră este de a colecta numai Datele personale
          oferite voluntar de către fiecare persoană vizată, astfel încât să
          putem furniza serviciile noastre la cel mai inalt nivel, desfășura
          relațiile contractuale si promova serviciile noastre.
        </p>
        <p className="legal-container-text">
          De asemenea, putem colecta informații cu privire la preferințele
          dumneavoastră prin utilizarea Websiteului, atunci când solicitați
          informații de la noi sau ne angajăm serviciile sau ca rezultat al
          relației pe care o aveți cu noi sau cu unul sau mai mulți dintre
          clienții noștri.
        </p>

        <p className="legal-container-text sub-header">A. Datele prelucrate</p>
        <p className="legal-container-text">
          Datele personale înseamnă orice informații privind o persoană fizică
          identificată sau identificabilă („persoana vizată”); o persoană fizică
          identificabilă este o persoană care poate fi identificată, direct sau
          indirect, în special prin referire la un element de identificare, cum
          ar fi un nume, un număr de identificare, date de localizare, un
          identificator online, sau la unul sau mai multe elemente specifice,
          proprii identității sale fizice, fiziologice, genetice, psihice,
          economice, culturale sau sociale.
        </p>
        <p className="legal-container-text">
          Cu privire la Datele personale pe care noi le putem prelucra, vă
          informăm că temeiul juridic pe care ne putem baza este:
        </p>
        <p className="legal-container-text">
          - prelucrarea este necesară realizarea unei relații contractuale,
          respectiv pentru furnizarea serviciilor solicitate de către
          dumneavoastră
        </p>
        <p className="legal-container-text">
          - obligația legală care ne revine pentru a prelucra astfel de date
        </p>
        <p className="legal-container-text">
          - prelucrarea este necesară pentru constatarea, exercitarea sau
          apărarea unui drept în instanță
        </p>
        <p className="legal-container-text">- consimțământul dumneavoastră.</p>

        <p className="legal-container-text sub-header">B. Principii GDPR</p>
        <p className="legal-container-text">
          Ori de câte ori vom prelucra Date personale vom ține cont de
          următoarele principii:
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            LEGALITATE, ECHITATE ȘI TRANSPARENȚĂ
          </span>{' '}
          – Datele dumneavoastra personale vor fi prelucrate ”în mod legal,
          echitabil și transparent”;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            LIMITĂRI LEGATE DE SCOP
          </span>{' '}
          – Datele dumneavoastra personale vor fi colectate în scopuri bine
          determinate, explicite și legitime, iar prelucrările ulterioare ale
          datelor respective nu se vor abate de la aceste scopuri;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            EXACTITATEA INFORMAȚIILOR
          </span>{' '}
          – vom lua toate măsurile pentru a asigura validitatea Datelor
          personale, iar cele dovedite inexacte trebuie actualizate fără
          întârziere sau șterse.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            REDUCEREA LA MINIMUM A DATELOR
          </span>{' '}
          – orice colectare de Date Personale va fi foarte bine analizată
          înainte de solicitarea efectivă a acestora, prelucrand doar Datele
          Personale care vor fi relevante și strict limitate la ceea ce este
          absolut necesar pentru scopurile în care sunt prelucrate;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">LIMITAREA STOCĂRII</span>{' '}
          – Datele personale trebuie păstrate exclusiv pentru perioada pentru
          care sunt necesare prelucrării asumate. Perioadele mai lungi de
          stocare reprezintă excepții asociate cu activități publice de
          arhivare, cercetare sau statistică, care se pot desfășura în anumite
          condiții;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            INTEGRITATE ȘI CONFIDENȚIALITATE
          </span>{' '}
          – prelucrarea Datelor personale trebuie făcută în condiții de
          siguranța, care să includă ”protecția împotriva prelucrării
          neautorizate sau ilegale și împotriva pierderii, a distrugerii sau a
          deteriorării accidentale, prin luarea de măsuri tehnice sau
          organizatorice corespunzătoare”.
        </p>

        <p className="legal-container-text sub-header">C. Drepturi</p>
        <p className="legal-container-text">
          Noi, MasiniLaCheie.ro, vă asigurăm respectarea drepturilor conferite
          persoanelor vizate în temeiul GDPR, printre care:
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">DREPTUL DE ACCES</span> –
          – vă permite obținerea detaliilor relevante prelucrării Datelor
          personale;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL LA RECTIFICARE
          </span>{' '}
          – vă permite rectificarea sau completarea Datelor dumneavoastra
          personale, dacă acestea au fost incorect sau incomplet prelucrate;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL LA RESTRICȚIONAREA DATELOR PRELUCRATE
          </span>{' '}
          – vă permite obținerea restricționării procesării Datelor
          dumneavoastră personale în anumite cazuri (de exemplu, atunci când
          contestați exactitatea Datelor dumneavoastra personale, pentru o
          perioadă care să ne permită să verificăm o astfel de exactitate);
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL DE A FI UITAT
          </span>{' '}
          – vă permite ștergerea Datelor dumneavoastra personale în anumite
          cazuri (de exemplu, dacă datele nu mai sunt necesare în legătură cu
          scopurile pentru care au fost colectate) și, în cazul în care Datele
          personale au fost făcute publice, transmiterea informațiilor
          referitoare la solicitarea de ștergere către alți operatori;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL DE A OBIECTA
          </span>{' '}
          – vă permite să vă opuneți prelucrării în continuare a Datelor
          dumneavoastra personale în condițiile și limitele stabilite de lege;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL LA PORTABILITATEA DATELOR
          </span>{' '}
          – vă permite să primiți Datele Personale referitoare la dumneavoastra
          pe care ni le-ați furnizat, într-un format structurat, utilizat în
          format electronic sau să transmiteți aceste date altui operator de
          date;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL DE RETRAGERE A CONSIMȚĂMÂNTULUI
          </span>{' '}
          – vă permite să retrageți oricând un consimțământ dat în vederea
          opririi unei prelucrări a Datelor personale care se bazează pe acel
          consimțământ. Retragerea nu va afecta legalitatea prelucrării pe baza
          consimțământului acordat înainte de retragere;
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            DREPTUL DE A DEPUNE O PLÂNGERE LA O AUTORITATE DE SUPRAVEGHERE
          </span>{' '}
          – dreptul de a înainta o plângere către Autoritate Națională de
          Supraveghere a Protecției Datelor din România, în cazul în care
          considerați că prelucrarea Datelor dumneavoastra personale încalcă
          legile aplicabile.
        </p>

        <p className="legal-container-text sub-header">
          D. Scopurile și Prelucrarea Datelor cu Caracter Personal În Baza
          Consimțământului Obținut
        </p>
        <p className="legal-container-text">
          Vom prelucra Datele dumneavoastra personale în mod legal, echitabil și
          transparent; scopurile în care urmează să folosim aceste date fiind
          bine determinate, mai exact în vederea pregătirii si oferirii
          serviciilor pe care le solicitați de la noi și/sau în desfășurarea
          relațiilor contractuale.
        </p>
        <p className="legal-container-text">
          Printre altele, Datele personale vor fi prelucrate în vederea
          comunicării prin e-mail, corespondență, telefonic, video sau prin
          orice alt mijloc de comunicare prin care se vor transmite răspunsurile
          la solicitările dumneavoastra, materialele pregătite în cadrul
          activității, precum și orice alt răspuns sau solicitare din cadrul
          colaborării sau potențialei colaborări cu MasiniLaCheie.ro. În acest
          caz prelucrarea datelor cu caracter personal va avea la bază interesul
          legitim în vederea oferirii serviciilor noastre în funcție de relația
          contractuală.
        </p>
        <p className="legal-container-text">
          Putem prelucra Datele dumneavoastra personale și în contextul
          furnizării serviciilor bazate pe obligații legale sau a obligațiilor
          impuse de dispozițiile legale, în acest caz obligația legală
          reprezintă temeiul prelucrării Datelor personale.
        </p>
        <p className="legal-container-text">
          Noi, MasiniLaCheie.ro, vă solicităm furnizarea Datelor personale,
          exclusiv în scopurile sus-menționate, în vederea desfășurării
          activității noastre curente și în vederea prestării serviciilor
          noastre.
        </p>
        <p className="legal-container-text">
          În toate cazurile, Datele personale sunt furnizate direct de
          dumneavoastra ca urmare a solicitării noastre și a exprimării
          consimțământului dumneavoastra, în vederea îndeplinirii scopurilor
          sus-menționate.
        </p>
        <p className="legal-container-text">
          Ne angajăm să protejăm confidențialitatea Datelor dumneavoastra
          personale. Dacă aveți întrebări sau comentarii cu privire la
          administrarea Datelor dumneavoastră personale, precum și în vederea
          exercitării drepturilor, vă rugăm să ne contactați la{' '}
          <a href="mailto:contact@masinilacheie.ro">contact@masinilacheie.ro</a>
          .
        </p>

        <p className="legal-container-text sub-header">
          E. Dezvăluirea datelor cu caracter personal
        </p>
        <p className="legal-container-text">
          Ca regulă, nu partajăm Date personale cu terțe părți, cu excepția
          cazului în care acest lucru este necesar pentru nevoile noastre
          legitime profesionale și operaționale, precum și în interesul
          dumneavoastră, pentru a vă îndeplini cerințele și/sau după cum este
          cerut sau permis de legi sau standardele profesionale, în contextul
          furnizării serviciilor pe care le-ați solicitat, ori acest aspect este
          necesar pentru realizarea obiectului contractului încheiat între părți
        </p>
        <p className="legal-container-text bold">
          Durata stocării datelor cu caracter personal
        </p>
        <p className="legal-container-text">
          Datele dumneavoastra personale vor fi stocate pentru cel putin 1 an,
          dar nu mai putin de perioada impusa de obligațiile legale care ne
          revin. În ipoteza în care Datele personale nu sunt stocate în baza
          unei relații contractuale, aceste date vor fi păstrate, pe o perioada
          determinata, atât timp cât este necesar pentru atingerea scopului
          prelucrării acestora, ori atât timp cât prestăm servicii care sunt in
          sfera dumneavoastra de interes, în vederea informării cu privire la
          acestea.
        </p>
        <p className="legal-container-text">
          Cu toate acestea, vom înceta să prelucrăm oricare dintre Datele
          dumneavoastră personale, atâta timp cât nu mai sunt necesare pentru
          scopurile stabilite sau când vă retrageți consimțământul, acesta fiind
          temeiul juridic al prelucrării și (i) nu există motive legitime
          imperioase pentru să continuarea unei prelucrare care să prevaleze
          intereselor, drepturilor și libertăților dumneavoastră sau (ii) dacă
          Datele personale nu sunt necesare pentru stabilirea, exercitarea sau
          apărarea unui drept în instanță.
        </p>
      </div>
    </div>
  );
};
