import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../routing/PrivateRoute';
import { ADMIN } from '../utils/constants';
import { LoginPage } from '../_shared/Auth/Auth';
import { CarsPage } from '../_shared/pages/Cars/Cars';
import { NavbarAdmin } from './components/NavbarAdmin';
import { DashboardPage } from './pages/Dashboard';
import { LocationPage } from './pages/Locations/Location';
import { LocationsPage } from './pages/Locations/Locations';
import { SellerPage } from './pages/Sellers/Seller';
import { SellersPage } from './pages/Sellers/Sellers';
import { UserPage } from './pages/Users/User';
import { UsersPage } from './pages/Users/Users';
import { CarPage } from '../_shared/pages/Cars/Car';
import { NotFoundPage } from '../_shared/pages/NotFound';
import Footer from '../_shared/components/Footer/Footer';

export const AdminRouting = () => {
  return (
    <BrowserRouter>
      <div>
        <NavbarAdmin />
        <Switch>
          <Route exact={true} path="/login" component={LoginPage} />
          <PrivateRoute
            exact={true}
            path="/"
            allowed={[ADMIN]}
            component={DashboardPage}
          />
          <PrivateRoute
            exact={true}
            path="/sellers"
            allowed={[ADMIN]}
            component={SellersPage}
          />
          <PrivateRoute
            exact={true}
            path="/seller/:id"
            allowed={[ADMIN]}
            component={SellerPage}
          />
          <PrivateRoute
            exact={true}
            path="/users"
            allowed={[ADMIN]}
            component={UsersPage}
          />
          <PrivateRoute
            exact={true}
            path="/user/:id"
            allowed={[ADMIN]}
            component={UserPage}
          />
          <PrivateRoute
            exact={true}
            path="/locations"
            allowed={[ADMIN]}
            component={LocationsPage}
          />
          <PrivateRoute
            exact={true}
            path="/location/:id"
            allowed={[ADMIN]}
            component={LocationPage}
          />
          <PrivateRoute
            exact={true}
            path="/cars"
            allowed={[ADMIN]}
            component={CarsPage}
          />
          <PrivateRoute
            exact={true}
            path="/car/:id"
            allowed={[ADMIN]}
            component={CarPage}
          />
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
};
