import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSellers } from '../../../api/sellers';
import { Table } from '../../../_shared/components/Table';
import { Seller } from '../../../types/api';

export const SellersPage = () => {
  const [sellers, setSellers] = useState<Array<Seller>>([]);

  const history = useHistory();

  useEffect(() => {
    const asyncGetSellers = async () => {
      const sellers = await getSellers({});
      setSellers(sellers);
    };

    asyncGetSellers();
  }, []);

  return (
    <div
      className="d-flex justify-content-center row-hl  col-sm-6 col-md-8"
      style={{ marginTop: 60, marginBottom: 10 }}
    >
      <div>
        <h2>Sellers</h2>
      </div>
      <div style={{ marginTop: 50 }}>
        <Table
          keys={['name']}
          data={sellers}
          onEdit={(id: string) => {
            history.push(`/seller/${id}`);
          }}
        />
      </div>
    </div>
  );
};
