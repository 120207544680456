import styles from './Settings.module.css';
import emailIcon from '../../../assets/owner/settings/email.svg';
import phoneIcon from '../../../assets/owner/settings/phone.svg';
import {
  MCDivider,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { useCallback, useEffect, useState } from 'react';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { PaymentMethod, Seller } from '../../../types/api';
import { CustomHourPicker } from '../../../_shared/components/InputFormDate/CustomHourPicker';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { useOwnerQuery } from '../../hooks/queries/useOwnerQuery';
import { LoadingOutlined } from '@ant-design/icons';
import { MCCheckbox } from '../../../_shared/components/MCCheckbox';
import { MCTooltip } from '../../../_shared/components/MCTooltip';
import React from 'react';
import moment from 'moment';
import { useAppUpdateTeamSettingsMutation } from '../../hooks/mutations/useAppUpdateTeamSettingsMutation';

const weekDays = [
  'Luni',
  'Marti',
  'Miercuri',
  'Joi',
  'Vineri',
  'Sambata',
  'Duminica',
];

const TIME_BETWEEN_RENTALS = [
  { name: '1h', value: '3600' },
  { name: '2h', value: '7200' },
  { name: '3h', value: '10800' },
  { name: '4h', value: '14400' },
  { name: '5h', value: '18000' },
  { name: '6h', value: '21600' },
];

const PAYMENT_METHOD = [
  { name: 'card', value: PaymentMethod.Card },
  { name: 'cash', value: PaymentMethod.Cash },
  { name: 'card si cash', value: PaymentMethod.CardAndCash },
];

export const Settings = () => {
  const { data: owner } = useOwnerQuery();

  if (!owner) {
    return <LoadingOutlined />;
  }
  return <SettingsImpl owner={owner} />;
};

export const SettingsImpl = ({ owner }: { owner: Seller }) => {
  const props = useLeftNavigatorOwnerProps();

  const { trackEvent } = useOwnerAnalytics();

  const [isModified, setIsModified] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState<boolean>(
    owner?.teamSettings?.notifications?.emailNotifications ?? true,
  );
  const [phoneNotifications, setPhoneNotifications] = useState<boolean>(
    owner?.teamSettings?.notifications?.phoneNotifications ?? true,
  );
  const [timeBetweenRentals, setTimeBetweenRentals] = useState(
    owner?.teamSettings?.timeBetweenRentals ?? 3600,
  );
  const [isExtraScheduleTax, setIsExtraScheduleTax] = useState(
    owner?.teamSettings?.schedule?.isExtraScheduleTax ?? false,
  );
  const [extraScheduleTax, setExtraScheduleTax] = useState(
    owner?.teamSettings?.schedule?.extraScheduleTax ?? 0,
  );
  const [payAtOffice, setPayAtOffice] = useState(
    owner?.teamSettings?.paymentMethod?.payAtOffice ?? true,
  );
  const [officePaymentType, setOfficePaymentType] = useState(
    owner?.teamSettings?.paymentMethod?.officePaymentType ?? PaymentMethod.Cash,
  );
  const [officeDepositPaymentType, setOfficeDepositPaymentType] = useState(
    owner?.teamSettings?.paymentMethod?.officeDepositPaymentType ??
      PaymentMethod.Cash,
  );
  const [promoteCars, setPromoteCars] = useState(
    owner?.teamSettings?.promoteCars ?? false,
  );
  const [schedule, setSchedule] = useState([
    [540, 1080],
    [540, 1080],
    [540, 1080],
    [540, 1080],
    [540, 1080],
    [540, 1080],
    [540, 1080],
  ]);
  const [scheduleDays, setScheduleDays] = useState([
    true,
    true,
    true,
    true,
    true,
    false,
    false,
  ]);

  useEffect(() => {
    if (owner.teamSettings?.schedule?.schedule?.length) {
      setSchedule(owner.teamSettings?.schedule?.schedule);
    }

    if (owner.teamSettings?.schedule?.scheduleDays?.length) {
      setScheduleDays(owner.teamSettings?.schedule?.scheduleDays);
    }
  }, [owner]);

  const { mutateAsync: updateTeamSettings } =
    useAppUpdateTeamSettingsMutation();

  const renderNotificationSettings = useCallback(() => {
    return (
      <div>
        <p className={styles.sectionText}>Condiții de utilizare:</p>
        <a
          href={'https://masinilacheie.ro/partner_terms_and_conditions/'}
          className="pr-1 text-sm font-light text-primary-black no-underline"
        >
          Condiții de utilizare a platformei
        </a>
        <MCSpacing />
        <p className={styles.sectionText}>Optiuni de notificare</p>
        <MCSpacing />
        <div className={styles.notificationLineContainer}>
          <div className={styles.notificationLineInnerContainer}>
            <img className={styles.notificationIcon} src={emailIcon} />
            <p className={styles.normalText}>{owner?.email}</p>
          </div>
          <MCCheckbox
            value={emailNotifications}
            onChange={(e) => {
              setIsModified(true);
              setEmailNotifications(e);
            }}
            children={undefined}
          />
        </div>
        <MCSpacing />
        <div className={styles.notificationLineContainer}>
          <div className={styles.notificationLineInnerContainer}>
            <img className={styles.notificationIcon} src={phoneIcon} />
            <p className={styles.normalText}>{owner?.phoneNumber}</p>
          </div>
          <MCCheckbox
            value={phoneNotifications}
            onChange={(e) => {
              setIsModified(true);
              setPhoneNotifications(e);
            }}
            children={undefined}
          />
        </div>
      </div>
    );
  }, [
    owner?.email,
    owner?.phoneNumber,
    emailNotifications,
    phoneNotifications,
  ]);

  const renderTimeBetweenRentals = useCallback(() => {
    return (
      <div className={styles.timeBetweenContainer}>
        <p className={styles.sectionText}>Timp minim intre inchirieri</p>
        <div style={{ width: 110 }}>
          <InputSelect
            value={timeBetweenRentals.toString()}
            onValueChange={(e) => {
              trackEvent('Settings_TimeBetweenRentals_Selected', {
                selected: `${parseInt(e) / 3600}h`,
              });
              setTimeBetweenRentals(parseInt(e));
              setIsModified(true);
            }}
            options={TIME_BETWEEN_RENTALS}
          />
        </div>
      </div>
    );
  }, [timeBetweenRentals, trackEvent]);

  const renderPaymentTypes = useCallback(() => {
    return (
      <div>
        <a
          className={styles.sectionText}
          href="https://masinilacheie.ro/partner_terms_and_conditions/"
          target="_blank"
        >
          Modalitati de plata prin platforma Masinilacheie.ro
        </a>
        <MCSpacing />
        <div className={styles.paymentLineContainer}>
          <p className={styles.normalText}>Plata online</p>
          <MCCheckbox value={true} onChange={(e) => {}} children={undefined} />
        </div>
        <MCSpacing />
        <div className={styles.paymentLineContainer}>
          <p className={styles.normalText}>Plata la sediu</p>
          <MCCheckbox
            value={payAtOffice}
            onChange={(e) => {
              trackEvent('Settings_PaymentOfficeEnabled_Checked', {
                checked: e,
              });
              setIsModified(true);
              setPayAtOffice(e);
            }}
            children={undefined}
          />
        </div>
        <MCSpacing />

        {payAtOffice && (
          <>
            <div className={styles.paymentLineContainer2}>
              <p className={styles.sectionText}>Modalitati de plata la sediu</p>
              <div style={{ width: 160 }}>
                <InputSelect
                  value={officePaymentType}
                  onValueChange={(e) => {
                    trackEvent('Settings_PaymentOffice_Selected', {
                      selected: e,
                    });
                    setOfficePaymentType(e);
                    setIsModified(true);
                  }}
                  options={PAYMENT_METHOD}
                />
              </div>
            </div>
            <MCSpacing />
          </>
        )}

        <div className={styles.paymentLineContainer2}>
          <p className={styles.sectionText}>Modalitati de plata depozit</p>
          <div style={{ width: 160 }}>
            <InputSelect
              value={officeDepositPaymentType}
              onValueChange={(e) => {
                trackEvent('Settings_PaymentDeposit_Selected', {
                  selected: e,
                });
                setOfficeDepositPaymentType(e);
                setIsModified(true);
              }}
              options={PAYMENT_METHOD}
            />
          </div>
        </div>
      </div>
    );
  }, [payAtOffice, officePaymentType, officeDepositPaymentType, trackEvent]);

  const renderWeekDaySchedule = useCallback(
    (index: number) => {
      return (
        <div className={styles.scheduleLineContainer}>
          <div className={styles.scheduleLineDayContainer}>
            <p className={styles.normalText}>{weekDays[index]}</p>

            <MCCheckbox
              value={scheduleDays[index]}
              onChange={(e) => {
                trackEvent('Settings_ScheduleDay_Checked', {
                  day: index,
                  checked: e,
                });
                setIsModified(true);
                scheduleDays[index] = e;
                setScheduleDays([...scheduleDays]);
              }}
              children={undefined}
            />
          </div>
          <CustomHourPicker
            minutes={schedule[index][0]}
            onMinutesChange={(e) => {
              trackEvent('Settings_StartingHour_Selected', {
                hour: moment().startOf('day').add(e, 'minutes').format('HH:mm'),
                day: index,
              });
              setIsModified(true);
              schedule[index][0] = e;
              setSchedule([...schedule]);
            }}
            showHourLabel={false}
          />
          <p className={styles.normalText}>{'pana la'}</p>
          <CustomHourPicker
            minutes={schedule[index][1]}
            onMinutesChange={(e) => {
              trackEvent('Settings_EndingHour_Selected', {
                hour: moment().startOf('day').add(e, 'minutes').format('HH:mm'),
                day: index,
              });
              setIsModified(true);
              schedule[index][1] = e;
              setSchedule([...schedule]);
            }}
            showHourLabel={false}
          />
        </div>
      );
    },
    [scheduleDays, schedule, trackEvent],
  );

  const renderSchedule = () => {
    return (
      <div>
        <p className={styles.sectionText}>Program primiri - predari</p>
        {new Array(7).fill(0).map((_, index) => {
          return (
            <React.Fragment key={index}>
              <MCSpacing />
              {renderWeekDaySchedule(index)}
            </React.Fragment>
          );
        })}
        <MCSpacing />

        <div className={styles.scheduleLineContainer}>
          <p className={styles.normalText}>
            {
              'Prin activarea acestei optiuni sunteti de acord cu primirea-predarea masinii inafara programului'
            }
          </p>
          <MCTooltip
            children={
              <div className={styles.modalContainer}>
                <h5 className={styles.headerText}>Taxa Extra Program</h5>
                <p className={styles.tooltipText}>
                  Prin activarea acestei optiuni calendarul dumneavoastra o sa
                  fie deschis 24/7. Atunci cand un client va alege sa preia /
                  predea masina inafara intervalului stabilit la sectiunea
                  "Program primiri - predari", se va aplica aceasta taxa.
                </p>
                <p className={styles.tooltipText}>
                  Taxa de extra program se aplica asupra fiecarui eveniment
                  inafara programului stabilit. Valoare recomandata 50-150 Ron.
                </p>
                <p className={styles.tooltipText}>
                  Aceasta taxa nu este comisionata.
                </p>
              </div>
            }
          />
          <div style={{ width: 16 }} />
          <MCCheckbox
            value={isExtraScheduleTax}
            onChange={(e) => {
              trackEvent('Settings_ExtraScheduleTaxEnabled_Checked', {
                checked: e,
              });
              setIsModified(true);
              setIsExtraScheduleTax(e);
              if (e === false) {
                setExtraScheduleTax(0);
              } else {
                setExtraScheduleTax(5000);
              }
            }}
            children={undefined}
          />
        </div>
        <MCSpacing />
        <div className={styles.scheduleLineContainer}>
          <p className={styles.normalText}>{'Valoare taxa extra program'}</p>
          <div className={styles.extraScheduleContainer}>
            <InputField
              type={'number'}
              placeholder="Suma"
              value={
                extraScheduleTax ? (extraScheduleTax / 100).toString() : ''
              }
              onChange={(e) => {
                trackEvent('Settings_ExtraScheduleInput_Type', {
                  amount: parseInt(e),
                });
                setIsModified(true);
                setExtraScheduleTax(parseInt(e) * 100);
              }}
            />

            <p className={styles.normalText} style={{ marginLeft: 16 }}>
              Ron
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <MCTwoPaneLayout title="Settings" {...props}>
      <div className={styles.mainContainer}>
        <div className={styles.centeredContainer}>
          <div className={styles.nameContainer}>
            <p className={styles.headerText}>{owner?.name}</p>
          </div>
          <MCSpacing />

          <MCSpacing />
          {renderNotificationSettings()}
          <MCSpacing />

          <MCDivider />

          <MCSpacing />
          {renderTimeBetweenRentals()}
          <MCSpacing />

          <MCDivider />

          <MCSpacing />
          {renderSchedule()}
          <MCSpacing />

          <MCDivider />

          <MCSpacing />
          {renderPaymentTypes()}
          <MCSpacing />
          <MCSpacing />

          {owner.showPromoteCars && (
            <>
              <p className={styles.sectionText}>
                Promovare in lista pe pagina de catalog cu comision crescut
                (15%)
              </p>
              <MCSpacing />
              <div className={styles.paymentLineContainer}>
                <p className={styles.normalText}>Activeaza</p>
                <MCCheckbox
                  value={promoteCars}
                  onChange={(e) => {
                    setPromoteCars(e);
                    setIsModified(true);
                  }}
                  children={undefined}
                />
              </div>
            </>
          )}
          <MCSpacing />
          <MCSpacing />

          <ActionButton
            label={'Salveaza'}
            disabled={!isModified}
            onClick={() => {
              trackEvent('Settings_Save_Click');

              setIsModified(false);

              const settings = {
                notifications: {
                  emailNotifications,
                  phoneNotifications,
                },
                timeBetweenRentals: timeBetweenRentals,
                schedule: {
                  extraScheduleTax,
                  isExtraScheduleTax,
                  schedule,
                  scheduleDays,
                },
                paymentMethod: {
                  payAtOffice,
                  officePaymentType,
                  officeDepositPaymentType,
                },
                promoteCars,
              };

              updateTeamSettings(settings);
            }}
          />
        </div>
      </div>
    </MCTwoPaneLayout>
  );
};
