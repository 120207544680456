import moment from 'moment';
import { useQuery } from 'react-query';
import { getCars } from '../../api/cars';
import { getReservationsForCar } from '../../api/reservations';
import { Reservation } from '../../types/api';
import { computeNumberOfDaysForRental } from '../../utils/mathUtil';

export function useAppAvailableCarsQuery(
  {
    dateBegin,
    dateEnd,
    locationId,
    transmissionType,
    countryExit,
    owner,
    ownerAvailability,
  }: {
    dateBegin?: Date;
    dateEnd?: Date;
    locationId?: string;
    transmissionType?: string;
    countryExit?: string;
    owner?: string;
    ownerAvailability?: boolean[];
  },
  enabled: boolean = true
) {
  return useQuery(
    [
      'available-cars',
      dateBegin,
      dateEnd,
      locationId,
      transmissionType,
      countryExit,
    ],
    async () => {
      if (!dateBegin || !dateEnd) {
        return {
          cars: await getCars({
            location: locationId,
            transmission: transmissionType,
            countryExit: countryExit,
            status: ['active'],
            owner,
          }),
        };
      }

      const cars = await getCars({
        dateBegin: moment(dateBegin).endOf('day').unix(),
        dateEnd: moment(dateEnd).startOf('day').unix(),
        location: locationId,
        transmission: transmissionType,
        countryExit: countryExit,
        status: ['active'],
        owner,
        ownerAvailability,
        minDays: computeNumberOfDaysForRental(
          moment(dateBegin).unix(),
          moment(dateEnd).unix()
        ),
      });
      if (cars.length === 0) {
        return { cars };
      }

      const carId = cars
        .filter((car) => car.type !== 'category')
        .map((x) => x._id);

      if (carId.length === 0) {
        return { cars };
      }

      const [reservationsStarting, reservationsEnding] = await Promise.all([
        getReservationsForCar({
          carId,
          beginDate: moment(dateBegin).startOf('day').unix(),
          endDate: moment(dateBegin).endOf('day').unix(),
        }),
        getReservationsForCar({
          carId,
          beginDate: moment(dateEnd).startOf('day').unix(),
          endDate: moment(dateEnd).endOf('day').unix(),
        }),
      ]);

      const beginDayCarReservations: Record<string, Reservation> =
        reservationsStarting
          .sort((a, b) => b.dateBegin - a.dateBegin)
          .reduce((prev, x) => {
            prev[x.car._id || ''] = x;
            return prev;
          }, {} as Record<string, Reservation>);

      const endDayCarReservations: Record<string, Reservation> =
        reservationsEnding
          .sort((a, b) => a.dateBegin - b.dateBegin)
          .reduce((prev, x) => {
            prev[x.car._id || ''] = x;
            return prev;
          }, {} as Record<string, Reservation>);

      return { cars, beginDayCarReservations, endDayCarReservations };
    },
    {
      staleTime: 60000,
      keepPreviousData: true,
      enabled,
    }
  );
}
