import { useQuery } from 'react-query';
import { getCars } from '../../api/cars';
import { Car } from '../../types/api';

export function useAppCarByFilterQuery(filter: Partial<Car>) {
  return useQuery(['car', filter], async () => {
    const cars = await getCars(filter);
    return cars.length < 1 ? undefined : cars[0];
  });
}
