import React from 'react';
import styles from './MCCard.module.css';

interface MCCardProps {
  children?: React.ReactNode | React.ReactNode[];
  style?: any;
  hover?: boolean;
}

export function MCCard({ children, style, hover = false }: MCCardProps) {
  return (
    <div
      style={{ ...style }}
      className={hover ? styles.containerHover : styles.container}
    >
      {children}
    </div>
  );
}
