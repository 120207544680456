import { useEffect, useState, useCallback } from 'react';
import { svgSearch } from '../../../assets';
import { useAppOwnerReservationsSearchQuery } from '../../../hooks';
import { Reservation } from '../../../types/api';
import { MCSpacing, MCTwoPaneLayout } from '../../../_shared/components';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { MCPagination } from '../../../_shared/components/MCPagination';
import { RentalHistoryTable } from '../../components/RentalHistoryTable';
import { ReservationTooltip } from '../../components/ReservationTooltip';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import styles from './RentalHistory.module.css';

export function RentalHistory() {
  const props = useLeftNavigatorOwnerProps();
  const [search, setSearch] = useState('');

  const actualSearch = search.length <= 2 ? '' : search;
  const size = 20;

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [actualSearch]);

  const { data: reservations } = useAppOwnerReservationsSearchQuery(
    { search: actualSearch },
    {
      size,
      page,
    }
  );

  const [clickedReservation, setClickedReservation] = useState<Reservation>();
  const [marginTop, setMarginTop] = useState(0);

  const onClearSelection = useCallback(
    () => setClickedReservation(undefined),
    [setClickedReservation]
  );

  const onClickReservation = useCallback(
    (reservation: Reservation) => {
      trackEvent('RentalHistory_RentalCard_Click', {
        carId: reservation.car?._id,
        reservationId: reservation.reservationId,
        reservationMongoId: reservation._id,
      });
      setClickedReservation(reservation);
      const index = (reservations?.reservations || []).indexOf(reservation);
      setMarginTop((index === -1 ? 0 : index) * 96);
    },
    [reservations, setClickedReservation, setMarginTop]
  );

  const { trackEvent } = useOwnerAnalytics();

  return (
    <MCTwoPaneLayout title="Istoric inchirieri" {...props}>
      <ReservationTooltip
        reservation={clickedReservation}
        onClearSelection={onClearSelection}
        marginTop={marginTop}
      />
      <MCSpacing />
      <MCSpacing />
      <div className={styles.searchContainer}>
        <InputField
          value={search}
          onChange={(s) => {
            trackEvent('RentalHistory_Search_Type', { search: s });
            setSearch(s);
          }}
          placeholder="Cautare dupa client, id sau masina"
          icon={svgSearch}
        />
      </div>
      <MCSpacing />
      <MCSpacing />
      {reservations && (
        <MCPagination
          currentPage={page}
          setCurrentPage={(page) => {
            trackEvent('RentalHistory_PageControl_Click', { page });
            setPage(page);
          }}
          perPage={size}
          total={reservations.count}
        />
      )}
      <MCSpacing />
      <MCSpacing />
      {reservations && (
        <RentalHistoryTable
          reservations={reservations.reservations}
          onClickReservation={onClickReservation}
        />
      )}
    </MCTwoPaneLayout>
  );
}
