import { toast } from 'react-toastify';
import { AuthDetails, Driver, LoginRes } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL, PlatformType, PLATFORM_TYPE } from '../utils/constants';

const IS_OWNER_PLATFORM = PLATFORM_TYPE === PlatformType.Owner;

const ENDPOINT = IS_OWNER_PLATFORM ? 'owner' : 'driver';

export const getDriver = async (): Promise<AuthDetails> => {
  try {
    const response = (await axios.get(`${BASE_URL}/${ENDPOINT}/me`)).data;

    return IS_OWNER_PLATFORM ? response : response.data;
  } catch (err) {
    console.error('Failed to login user');
    console.error(err);
    throw err;
  }
};

export const loginDriver = async (
  firebaseToken?: string
): Promise<LoginRes> => {
  try {
    const res = (
      await axios.post(`${BASE_URL}/${ENDPOINT}/login`, undefined, {
        headers: {
          authorization: 'Bearer ' + firebaseToken,
        },
      })
    ).data;

    return res;
  } catch (err) {
    console.error('Failed to login user');
    console.error(err);
    throw err;
  }
};

export const registerDriver = async (firebaseToken?: string) => {
  try {
    const res = (
      await axios.post(`${BASE_URL}/${ENDPOINT}/register`, undefined, {
        headers: {
          authorization: 'Bearer ' + firebaseToken,
        },
      })
    ).data;

    return res;
  } catch (err) {
    console.error('Failed to register user');
    console.error(err);
    throw err;
  }
};

export const getDrivers = async (filter: any) => {
  try {
    return (
      await axios.get(`${BASE_URL}/driver`, {
        params: {
          filter,
        },
      })
    ).data.found;
  } catch (err) {
    console.error('Failed to get driver');
    console.error(err);
    throw err;
  }
};

export const createDriver = async (driverData: Driver) => {
  try {
    return (await axios.post(`${BASE_URL}/driver`, driverData)).data;
  } catch (err) {
    toast.error('Numar de telefon invalid');
    console.error('Failed to create driver');
    console.error(err);
    throw err;
  }
};

export const updateDriver = async (driverId: string, driverData: Driver) => {
  try {
    return await (
      await axios.put(`${BASE_URL}/driver/${driverId}`, driverData)
    ).data;
  } catch (err) {
    console.error('Failed to update driver');
    console.error(err);
    throw err;
  }
};

export const verifDriverPhone = async (driverId: string, phone: string) => {
  try {
    return await (
      await axios.put(`${BASE_URL}/driver/${driverId}/phone`, {
        onboarding: { phone },
      })
    ).data;
  } catch (err) {
    toast.error('Numar de telefon invalid');
    console.error(err);
  }
};

export const checkDriverPhoneCode = async (
  driverId: string,
  code: string
): Promise<Driver | undefined> => {
  try {
    return await (
      await axios.put(`${BASE_URL}/driver/${driverId}/phone/${code}`)
    ).data.data;
  } catch (err) {
    console.error(err);
  }
  return undefined;
};

export const updateDriverGovIdImg = async (
  driverId: string,
  govIdImg: File
) => {
  try {
    const formData = new FormData();
    formData.append('image', govIdImg);
    return await axios.put(
      `${BASE_URL}/driver/${driverId}/governmentid`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } catch (err) {
    console.error('Failed to update driver');
    console.error(err);
    throw err;
  }
};

export const updateDriverLicenseImg = async (
  driverId: string,
  driversLicenseImg: File
) => {
  try {
    const formData = new FormData();
    formData.append('image', driversLicenseImg);
    return await axios.put(
      `${BASE_URL}/driver/${driverId}/driverslicense`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } catch (err) {
    console.error('Failed to update driver');
    console.error(err);
    throw err;
  }
};

export const deleteDriver = async (userId: string) => {
  try {
    return await axios.delete(`${BASE_URL}/driver/${userId}`);
  } catch (err) {
    console.error('Failed to delete driver');
    console.error(err);
    throw err;
  }
};
