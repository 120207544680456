import React from 'react';
import styles from './MCRow.module.css';

interface MCRowProps {
  children: React.ReactNode | React.ReactNodeArray;
  flexDirection?: 'row' | 'column';
  margins?: boolean;
  alignItems?: 'center' | 'flex-start';
  style?: Record<string, any>;
}

export function MCRow({
  children,
  flexDirection = 'row',
  margins = true,
  alignItems,
  style = {},
}: MCRowProps) {
  const margin = margins === false ? 0 : flexDirection !== 'row' ? 0 : 16;

  return (
    <div
      style={{
        flexDirection,
        marginTop: margin,
        marginBottom: margin,
        alignItems,
        ...style,
      }}
      className={styles.container}
    >
      {children}
    </div>
  );
}
