import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../assets/logo-outline-color.png';
import { getDriverAction } from '../../../redux/actions/driverAction';
import { ReduxState } from '../../../types/redux';
import { OnboardingEmailStage } from './OnboardingEmailStage';
import { OnboardingPhone } from './OnboardingPhone';
import { OnboardingProgress } from './OnboardingProgress';
import { OnboardingVeriff } from './OnboardingDriver';
import './Onboarding.style.css';
import { useHistory } from 'react-router-dom';
import { OnboardingStage } from '../../../types/api';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';

export const Onboarding = () => {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  const history = useHistory();

  useEffect(() => {
    getDriverAction();
  }, []);

  const { trackEvent } = useDriverAnalytics();

  const stage =
    driver?.driver?.onboarding?.stage || OnboardingStage.VerifyEmail;
  //const stage = OnboardingStage.Address as never as OnboardingStage;

  return (
    <div className="onboarding-container">
      <div className="onboarding-width-container">
        <div className="onboarding-logo-container">
          <img className="onboarding-logo" src={logo} alt="onboarding-logo" />
          <p className="onboarding-logo-label">masinilacheie.ro</p>
        </div>
        <OnboardingProgress />

        {[OnboardingStage.VerifyEmail, OnboardingStage.EmailVerified].includes(
          stage
        ) && <OnboardingEmailStage stage={stage} />}

        {[OnboardingStage.VerifyPhone, OnboardingStage.PhoneVerified].includes(
          stage
        ) && <OnboardingPhone stage={stage} />}

        {[
          OnboardingStage.DriverDetails,
          OnboardingStage.DriverVerified,
        ].includes(stage) && <OnboardingVeriff stage={stage} />}

        {stage !== OnboardingStage.DriverVerified && (
          <p
            className="onboarding-skip-label"
            onClick={() => {
              trackEvent('Onboarding_SkipEmail_Click');
              history.push('/catalog');
            }}
          >
            Omit acest past pentru moment, doresc sa rasfoiesc catalogul
          </p>
        )}
      </div>
    </div>
  );
};
