import classNames from 'classnames';
import moment from 'moment';
import { svgCalendarCancel } from '../../../assets';
import { InputFormDate } from '../InputFormDate/InputFormDate';
import { MCDivider } from '../MCDivider';
import { MCSvgImage } from '../MCSvgImage';
import { LinkText } from '../Typography';
import styles from './MCDateForm.module.css';

const minimumDate = new Date();
minimumDate.setDate(minimumDate.getDate());
minimumDate.setHours(0, 0, 0, 0);
const maximumDate = new Date();
maximumDate.setMonth(maximumDate.getMonth() + 12);

const initialStartDate = new Date(minimumDate.getTime());
initialStartDate.setDate(minimumDate.getDate());
const initialEndDate = new Date(minimumDate.getTime());
initialEndDate.setDate(minimumDate.getDate() + 5);

export interface MCDateFormProps {
  interval: {
    dateBegin?: Date;
    dateEnd?: Date;
    minutesBegin: number;
    minutesEnd: number;
  };
  onDateBeginChanged: (dateBegin: Date) => void;
  onEndDateChanged: (dateEnd: Date) => void;
  onBeginMinutesChanged: (minutes: number) => void;
  onEndMinutesChanged: (minutes: number) => void;
  disabledBeginIntervals?: Array<{ begin: Date; end: Date }>;
  disabledEndIntervals?: Array<{ begin: Date; end: Date }>;
  minDate?: Date;
  maxDate?: Date;
  minMinutesBegin?: number;
  maxMinutesEnd?: number;
  onClear?: () => void;
  compact?: boolean;
  disableHourPicker?: boolean;
}

export function MCDateForm({
  interval,
  onDateBeginChanged,
  onEndDateChanged,
  onBeginMinutesChanged,
  onEndMinutesChanged,
  disabledBeginIntervals,
  disabledEndIntervals,
  minDate,
  maxDate,
  minMinutesBegin,
  maxMinutesEnd,
  onClear,
  compact,
  disableHourPicker = false,
}: MCDateFormProps) {
  const { dateBegin, dateEnd } = interval;
  const startDate = dateBegin || initialStartDate;

  const startPlusOne = moment(startDate).add(1, 'days').toDate();

  const isClearVisible = !!onClear;

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerCompact]: compact,
      })}
    >
      <div className={styles.inputDateContainer}>
        <InputFormDate
          minimumDate={minDate ?? minimumDate}
          maximumDate={maxDate ?? maximumDate}
          label={'De la'}
          selectedDate={dateBegin}
          onDateSelected={onDateBeginChanged}
          minutes={interval.minutesBegin}
          onMinutesChanged={onBeginMinutesChanged}
          minMinutes={minMinutesBegin}
          disabledIntervals={disabledBeginIntervals}
          disableHourPicker={disableHourPicker}
        />
      </div>
      {compact ? <MCDivider /> : <div className="search-form-input-divider" />}
      <div className={styles.inputDateContainer}>
        <InputFormDate
          minimumDate={minDate ?? startPlusOne}
          maximumDate={maxDate ?? maximumDate}
          label={'Pana la'}
          onDateSelected={onEndDateChanged}
          selectedDate={dateEnd}
          minutes={interval.minutesEnd}
          onMinutesChanged={onEndMinutesChanged}
          maxMinutes={maxMinutesEnd}
          disabledIntervals={disabledEndIntervals}
          disableHourPicker={disableHourPicker}
        />
      </div>

      {isClearVisible && (
        <div className={styles.clearButton} onClick={onClear}>
          <MCSvgImage image={svgCalendarCancel} />
          <LinkText>anulare date</LinkText>
        </div>
      )}
    </div>
  );
}
