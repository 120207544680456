import { ProfileData } from './ProfileData';
import { LeftNavigator } from '../../components/LeftNavigator';
import { ProfileWhyCollect } from './ProfileWhyCollect';
import './Profile.style.css';

export const Profile = () => {
  return (
    <div className="profile-container">
      <div className="profile-inner-container">
        <LeftNavigator />
        <div className="profile-main-container">
          <ProfileData />
          <ProfileWhyCollect />
        </div>
      </div>
    </div>
  );
};
