import { useHistory } from 'react-router-dom';
import { svgBackButton } from '../../../assets';
import { MCTitle } from '../Typography';
import styles from './MCHeader.module.css';

interface MCHeaderProps {
  title: string;
  onBack?: () => void;
}

export function MCHeader({ title, onBack }: MCHeaderProps) {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img
        className={styles.buttonImg}
        src={svgBackButton}
        alt="back-button"
        onClick={() => {
          onBack?.();
          (history as any).goBack();
        }}
      />
      <MCTitle>{title}</MCTitle>
    </div>
  );
}
