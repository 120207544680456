import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/actions/driverAction';
import { MCTwoPaneLayout } from '../../components';

export const CarsPage = () => {
  const dispatch = useDispatch();

  return (
    <MCTwoPaneLayout
      title="Cars"
      options={[]}
      onLogout={() => dispatch(logout())}
    />
  );
};
