import classNames from 'classnames';
import moment from 'moment';
import { Car, Reservation } from '../../../types/api';
import { computeNumberOfDaysForRental } from '../../../utils/mathUtil';
import { MCParagraph, MCSpacing } from '../../../_shared/components';
import styles from './HistoryRentalCard.module.css';
import receptionArrow from '../../../assets/owner/receptionDeliveries/receptionArrow.png';
import deliveryArrow from '../../../assets/owner/receptionDeliveries/deliveryArrow.png';

interface HistoryRentalCardProps {
  reservation: Reservation;
  onClickReservation: (reservation: Reservation) => void;
}

export function HistoryRentalCard({
  onClickReservation,
  reservation,
}: HistoryRentalCardProps) {
  return (
    <div
      className={styles.container}
      onClick={() => onClickReservation(reservation)}
    >
      <div className={classNames(styles.column, styles.width25)}>
        <div>
          <img className={styles.image} src={deliveryArrow} />
          <MCParagraph>
            {moment
              .unix(reservation.dateBegin)
              .format('DD MMM YYYY Or\\a HH:mm')}
          </MCParagraph>
        </div>
        <MCSpacing />

        <div>
          <img className={styles.image} src={receptionArrow} />
          <MCParagraph>
            {moment.unix(reservation.dateEnd).format('DD MMM YYYY Or\\a HH:mm')}
          </MCParagraph>
        </div>
      </div>
      {(reservation.car as Car).type === 'car' && (
        <div className={styles.column}>
          <MCParagraph>
            {(reservation.car as Car).make} {(reservation.car as Car).model}
          </MCParagraph>
          <MCSpacing />
          <MCParagraph>
            {(reservation.car as Car).registrationNumber}
          </MCParagraph>
        </div>
      )}
      {(reservation.car as Car).type === 'category' && (
        <div className={styles.column}>
          <MCParagraph>
            {(reservation.car as Car).categoryDetails.name}
          </MCParagraph>
        </div>
      )}
      <div className={styles.column}>
        <MCParagraph bold>
          {(reservation.pricing.customerPrice || 0) / 100} Ron
        </MCParagraph>
        <MCSpacing />
        <MCParagraph>
          {computeNumberOfDaysForRental(
            reservation.dateBegin,
            reservation.dateEnd
          )}{' '}
          zile
        </MCParagraph>
      </div>
      <div className={styles.column}>
        <MCParagraph bold>{reservation.name}</MCParagraph>
        <MCSpacing />
        <MCParagraph>{reservation.phoneNumber}</MCParagraph>
      </div>
      <div className={classNames(styles.column, styles.width15)}>
        <MCParagraph bold fontSize={18}>
          {reservation.reservationId}
        </MCParagraph>
      </div>
    </div>
  );
}
