import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getCars } from '../../../api/cars';
import { ReduxState } from '../../../types/redux';

export function useAppCarsQuery(
  {
    types,
  }: {
    types: Array<'car' | 'category'>;
  } = { types: ['car'] }
) {
  const id = useSelector((x: ReduxState) => x.userReducer.driver?._id || '');

  return useQuery(
    ['car-list', id, types],
    async () => {
      return getCars({ owner: id, type: types });
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!id,
    }
  );
}
