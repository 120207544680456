import { useState } from 'react';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { InputField } from '../../../_shared/components/InputField/InputField';
import PhoneInput from 'react-phone-number-input';
import verifiedIcon from '../../../assets/onboarding/verified.svg';
import './OnboardingPhone.style.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../types/redux';
import {
  sendPhoneVerification,
  updateDriverAction,
} from '../../../redux/actions/driverAction';
import { toast } from 'react-toastify';
import { checkDriverPhoneCode } from '../../../api/drivers';
import 'react-phone-number-input/style.css';
import { OnboardingStage } from '../../../types/api';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';

interface Props {
  stage: number;
}

export const OnboardingPhone = ({ stage }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [canSendCode, setCanSendCode] = useState(true);

  const driver = useSelector((state: ReduxState) => state.userReducer);
  const dispatch = useDispatch();

  const { trackEvent } = useDriverAnalytics();

  const isPhoneNumberValid = () => {
    return true;
  };

  const sendVerificationPhone = () => {
    setPhoneCode('');
    if (isPhoneNumberValid() && canSendCode) {
      sendPhoneVerification(driver.driver?._id!, phoneNumber);
      setIsCodeSent(true);

      // can resend code only in 5s
      setCanSendCode(false);
      setTimeout(() => {
        setCanSendCode(true);
      }, 5000);
      toast.success('Cod de validare trimis');
    } else {
      toast.error('Numarul de telefon este invalid');
    }
  };

  const validatePhoneCode = async () => {
    trackEvent('Onboarding_ValidateCode_Click');
    const updatedDriver = await checkDriverPhoneCode(
      driver.driver?._id!,
      phoneCode
    );
    if (updatedDriver?.onboarding?.isPhoneVerified) {
      dispatch(updateDriverAction(updatedDriver));
    } else {
      setPhoneCode('');
      toast.error('Codul introdus este invalid');
    }
  };

  if (stage === OnboardingStage.PhoneVerified) {
    return (
      <div className="onboarding-phone-container">
        <p className="onboarding-phone-header">
          Felicitari! Numarul tau de telefon a fost validat
        </p>
        <img src={verifiedIcon} alt="Verificare" />
        <div style={{ height: 16 }} />
        <ActionButton
          label="Pasul urmator"
          onClick={() => {
            trackEvent('Onboarding_NextStep_Click', { stepNumber: 2 });
            dispatch(
              updateDriverAction({
                ...driver.driver,
                // @ts-ignore
                onboarding: {
                  ...driver?.driver?.onboarding,
                  stage: OnboardingStage.DriverDetails,
                  phone: phoneNumber,
                },
              })
            );
          }}
        />
      </div>
    );
  }

  return (
    <div className="onboarding-phone-container">
      {isCodeSent ? (
        <>
          <div className="onboarding-phone-edit-container">
            <p className="onboarding-phone-edit-label ">Numar de telefon:</p>
            <p className="onboarding-phone-edit-label ">{phoneNumber}</p>
            <p
              className="onboarding-phone-edit-label underline"
              onClick={() => {
                setIsCodeSent(false);
                setPhoneNumber('');
              }}
            >
              Editeaza
            </p>
          </div>
          <div className="onboarding-phone-validate-container">
            <InputField
              placeholder="xxxxxx"
              isCenter={true}
              value={phoneCode}
              onChange={(value) => {
                trackEvent('Onboarding_CodeInput_Type', {
                  charCount: value.length,
                });
                setPhoneCode(value);
              }}
            />
            <div className="onboarding-phone-validate-action">
              <ActionButton label="Valideaza" onClick={validatePhoneCode} />
            </div>
            <p
              className={`onboarding-phone-resend-label ${
                canSendCode ? '' : 'disabled'
              }`}
              onClick={() => {
                trackEvent('Onboarding_ResendCode_Click');
                sendVerificationPhone();
              }}
            >
              Retrimite cod verificare
            </p>
          </div>
        </>
      ) : (
        <>
          <p className="onboarding-phone-header">Introdu numarul de telefon:</p>
          <PhoneInput
            initialValueFormat="national"
            placeholder="0770 777 777"
            defaultCountry="RO"
            value={phoneNumber}
            onChange={(value) => {
              trackEvent('Onboarding_PhoneNumberInput_Type', {
                charCount: ('' + value).length,
              });
              // @ts-ignore
              setPhoneNumber(value);
            }}
          />
          <div style={{ height: 48 }} />
          <ActionButton
            label="Trimite codul"
            onClick={() => {
              trackEvent('Onboarding_SendCode_Click');
              sendVerificationPhone();
            }}
          />
        </>
      )}
    </div>
  );
};
