import * as React from 'react';
import styles from './MCHeadline.module.css';

export interface MCHeadlineProps {
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export function MCHeadline({ children }: MCHeadlineProps) {
  return <span className={styles.headline}>{children}</span>;
}
