import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/driverAction';
import { ReduxState } from '../../types/redux';

export const NavbarAdmin = () => {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  const dispatch = useDispatch();

  const renderElement = (name: any, redirect: any) => {
    return (
      <li className="nav-item" style={{ marginLeft: 8 }}>
        <a className="nav-link" href={redirect}>
          {name}
        </a>
      </li>
    );
  };

  const renderLogout = () => {
    return (
      <li className="nav-item" style={{ marginLeft: 8 }}>
        <a
          className="nav-link"
          href={'/login'}
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </a>
      </li>
    );
  };

  const renderNavItems = () => {
    return driver.isAuthenticated ? (
      <ul className="navbar-nav">
        {renderElement('Sellers', '/sellers')}
        {renderElement('Users', '/users')}
        {renderElement('Locations', '/locations')}
        {renderElement('Cars', '/cars')}
        {renderElement('Rentals', '/reservations')}
        {renderLogout()}
      </ul>
    ) : (
      <ul></ul>
    );
  };

  return (
    <div className="navbar navbar-expand-sm navbar-light bg-dark navbar-dark fixed-top">
      <div className="container col-sm-10 offset-sm-1 col-md-8 offset-md-2">
        <a className="navbar-brand" href="/">
          {`Admin Masini la Cheie `}
        </a>
        <div>{renderNavItems()}</div>
      </div>
    </div>
  );
};
