import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import whatsapp from '../../../assets/whatsapp.png';
import messenger from '../../../assets/messenger.png';
import linkedin from '../../../assets/linkedin.png';
import instagram from '../../../assets/instagram.png';
import facebook from '../../../assets/facebook.png';
import blackLogoFooter from '../../../assets/black-logo-footer.png';
import heart from '../../../assets/heart.png';

import './Footer.style.css';

import { LIST_CAR_LINK } from '../../../utils/constants';
import { useDriverAnalytics } from '../../../_driver/hooks/useDriverAnalytics';

function Footer() {
  const { trackEvent } = useDriverAnalytics();

  const socialRender = useCallback(
    (socialLogo: any, name: string, socialLink: string) => {
      return (
        <Link
          to={{ pathname: socialLink }}
          target="_blank"
          onClick={() =>
            trackEvent('Footer_SocialItem_Click', { name, url: socialLink })
          }
        >
          <img src={socialLogo} alt="logo" className="socials-logo" />
        </Link>
      );
    },
    [trackEvent]
  );

  const footerRoutes = useCallback(
    (
      content: string,
      contentType: string,
      route: string,
      onClick?: () => void
    ) => {
      const clickHandler = onClick
        ? onClick
        : () =>
            trackEvent('Footer_FooterItem_Click', {
              name: content,
              url: route,
            });

      const isExternal = route.includes('http') || route.includes('mailto');

      return (
        <Link
          className={contentType}
          to={isExternal ? { pathname: route } : route}
          target={isExternal ? '_blank' : '_self'}
          onClick={() => clickHandler()}
        >
          {content}
        </Link>
      );
    },
    [trackEvent]
  );

  return (
    <div className="footer-container">
      <div className="footer-routes">
        <div>
          {footerRoutes(
            'Despre masinilacheie.ro',
            'footer-route left',
            '/',
            () => trackEvent('Footer_AboutUs_Click')
          )}
          {footerRoutes('Cum funcționează', 'footer-route left', '/', () =>
            trackEvent('Footer_HowItWorks_Click')
          )}
          {footerRoutes('Întrebări frecvente', 'footer-route left', '/', () =>
            trackEvent('Footer_FAQ_Click')
          )}
          {footerRoutes(
            'Contact',
            'footer-route bold left',
            'mailto:contact@masinilacheie.ro',
            () => trackEvent('Footer_Contact_Click')
          )}
        </div>
        <div className="divider" />
        <div>
          {footerRoutes(
            'Termeni și Condiții',
            'footer-route',
            '/terms_and_conditions',
            () => trackEvent('Footer_TermsAndConditions_Click')
          )}
          {footerRoutes(
            'Politică de confidențialitate',
            'footer-route',
            '/privacy_policy',
            () => trackEvent('Footer_PrivacyPolicy_Click')
          )}
          {footerRoutes('GDPR', 'footer-route', '/gdpr', () =>
            trackEvent('Footer_GDPR_Click')
          )}
        </div>
        <div className="divider" />

        <div>
          {footerRoutes(
            'Autentificare',
            'footer-route right purple',
            '/login',
            () => trackEvent('Footer_Login_Click')
          )}
          {footerRoutes(
            'Înregistrare',
            'footer-route right purple',
            '/register',
            () => trackEvent('Footer_Register_Click')
          )}
          {footerRoutes(
            'Catalog',
            'footer-route right purple',
            'https://masinilacheie.ro/catalog',
            () => trackEvent('Footer_Catalog_Click')
          )}
          {footerRoutes(
            'Listează-ți mașina',
            'footer-route right red',
            LIST_CAR_LINK,
            () => trackEvent('Footer_ListCar_Click')
          )}
        </div>
      </div>
      <div className="socials-section">
        <div className="socials-container">
          {socialRender(whatsapp, 'whatsapp', 'https://wa.me/40723473933')}
          {socialRender(
            linkedin,
            'linkedin',
            'https://www.linkedin.com/in/masini-la-cheie-contact-672652239/'
          )}
          {socialRender(messenger, 'messenger', 'https://m.me/107579835198008')}
          {socialRender(
            instagram,
            'instagram',
            'https://www.instagram.com/masinilacheie.ro'
          )}
          {socialRender(
            facebook,
            'facebook',
            'https://www.facebook.com/Masini-La-Cheie-107579835198008'
          )}
        </div>
      </div>
      <div className="crafted-section">
        <img src={blackLogoFooter} width="50" height="32" alt="logo" />
        <div className="crafted-with">
          Crafted with
          <img
            src={heart}
            width="18.95"
            height="18"
            alt="logo"
            style={{ marginLeft: '6.53px' }}
          />
        </div>
      </div>
      <div className="all-rights">
        masinilacheie.ro © all rights reserved 2024
      </div>
    </div>
  );
}

export default Footer;
