import moment from 'moment';
import { useMemo } from 'react';
import { svgCheck } from '../../../assets';
import { useAppComputeOffer } from '../../../hooks/util/useAppComputeOffer';
import { useCurrencyFormatter } from '../../../hooks/util/useCurrencyFormatter';
import { Car, Guarantees } from '../../../types/api';
import { computeNumberOfDaysForRental } from '../../../utils/mathUtil';
import { OptionCard } from '../OptionCard';

interface CheckoutGuaranteeOptionsProps {
  dateBegin: Date;
  dateEnd: Date;
  car: Car;
  selected: keyof Guarantees;
  onSelectedChange: (selected: keyof Guarantees) => void;
}

export function CheckoutGuaranteeOptions({
  dateBegin,
  dateEnd,
  car,
  selected,
  onSelectedChange,
}: CheckoutGuaranteeOptionsProps) {
  const days = computeNumberOfDaysForRental(
    moment(dateBegin).unix(),
    moment(dateEnd).unix()
  );
  const { formatCurrency } = useCurrencyFormatter();

  const { pricePerDay: pricePerDayIntegral } = useAppComputeOffer({
    dateBegin: dateBegin.getTime() / 1000,
    dateEnd: dateEnd.getTime() / 1000,
    car,
    guaranteeType: 'integral',
  });
  const { pricePerDay: pricePerDayReduced } = useAppComputeOffer({
    dateBegin: dateBegin.getTime() / 1000,
    dateEnd: dateEnd.getTime() / 1000,
    car,
    guaranteeType: 'reduced',
  });
  const { pricePerDay: pricePerDayMinimal } = useAppComputeOffer({
    dateBegin: dateBegin.getTime() / 1000,
    dateEnd: dateEnd.getTime() / 1000,
    car,
    guaranteeType: 'minimal',
  });

  const options = useMemo(() => {
    const options = [
      {
        title: 'Fara extra acoperire',
        description: `${formatCurrency(
          pricePerDayIntegral,
          2
        )} X ${days} zile si ${formatCurrency(
          car.settings.guarantees.integral.guarantee
        )} garantie`,
        available: car.settings.guarantees.integral.active,
        isSelected: selected === 'integral',
        onClick: () => onSelectedChange('integral'),
      },
      {
        title: 'Asigurare limitata',
        description: `${formatCurrency(
          pricePerDayReduced,
          2
        )} X ${days} zile si ${formatCurrency(
          car.settings.guarantees.reduced.guarantee
        )} garantie`,
        available: car.settings.guarantees.reduced.active,
        isSelected: selected === 'reduced',
        onClick: () => onSelectedChange('reduced'),
      },
      {
        title: 'Asigurare full',
        description: `${formatCurrency(
          pricePerDayMinimal,
          2
        )} X ${days} zile si ${formatCurrency(
          car.settings.guarantees.minimal.guarantee
        )} garantie`,
        available: car.settings.guarantees.minimal.active,
        isSelected: selected === 'minimal',
        onClick: () => onSelectedChange('minimal'),
      },
    ];

    const result = options.filter((x) => x.available);

    return result;
  }, [
    car.settings.guarantees.integral.active,
    car.settings.guarantees.integral.guarantee,
    car.settings.guarantees.minimal.active,
    car.settings.guarantees.minimal.guarantee,
    car.settings.guarantees.reduced.active,
    car.settings.guarantees.reduced.guarantee,
    days,
    formatCurrency,
    onSelectedChange,
    pricePerDayIntegral,
    pricePerDayMinimal,
    pricePerDayReduced,
    selected,
  ]);

  return (
    <div>
      {options.length > 1 && (
        <div>
          {options.map((option) => {
            return <OptionCard key={option.title} {...option} />;
          })}
        </div>
      )}
      {options.length === 1 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 18,
            fontWeight: 'bold',
            color: '#280659',
          }}
        >
          <div style={{ marginRight: 10 }}>Asigurare full</div>
          <div>
            <img src={svgCheck} alt="selected" />
          </div>
        </div>
      )}
    </div>
  );
}
