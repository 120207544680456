import React from 'react';
import { RentalOffer, CarSettings } from '../../../types/api';
import { MONTHS_INCREASE, MONTHS_SHORT } from '../../../utils/constants';
import {
  MCCell,
  MCHeadline,
  MCParagraph,
  MCRow,
  MCSpacing,
} from '../../../_shared/components';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { MCButton } from '../../../_shared/components/MCButton';
import { getRecommendedDiscount } from '../../../_shared/util';
import { BasePrices } from '../../components/BasePrices';
import { OfferCard } from '../../components/OfferCard';
import { useOwnerAnalytics } from '../../hooks';
import styles from './Prices.module.css';

export interface PriceSettingsProps {
  carId: string;
  basePrices: CarSettings;
  setBasePrices: (basePrices: CarSettings) => void;
  extraScheduleFee: number;
  onExtraScheduleFeeChange: (amount: number) => void;
}

export function PriceSettings({
  carId,
  basePrices,
  setBasePrices,
  extraScheduleFee,
  onExtraScheduleFeeChange,
}: PriceSettingsProps) {
  const { trackEvent } = useOwnerAnalytics();
  return (
    <>
      <BasePrices
        carId={carId}
        basePrices={basePrices}
        onBasePricesChange={setBasePrices}
        extraScheduleFee={extraScheduleFee}
        onExtraScheduleFeeChange={onExtraScheduleFeeChange}
      />

      <MCSpacing />
      <MCSpacing />

      <MCHeadline>Pret in functie de numarul de zile</MCHeadline>
      <MCSpacing />
      {!!basePrices.rentalOffers.length && (
        <div className={styles.header}>
          <MCRow>
            <MCCell flex={1.35}>
              <MCParagraph bold>Valabile de la minim</MCParagraph>
            </MCCell>
            <MCCell>
              <MCParagraph bold>Pret pe zi</MCParagraph>
            </MCCell>
            <MCCell flex={1.5}>
              <MCParagraph bold>Discount (in procente)</MCParagraph>
            </MCCell>
            <MCCell>
              <MCParagraph bold>Distanta</MCParagraph>
            </MCCell>
            <div style={{ width: 100 }} />
          </MCRow>
        </div>
      )}
      {basePrices.rentalOffers.map((offer, offerIdx, offerArr) => {
        const setOffer = (newOffer: RentalOffer) => {
          const arr = [...offerArr];
          arr[offerIdx] = { ...newOffer };
          setBasePrices({
            ...basePrices,
            rentalOffers: arr,
          });
        };

        const deleteOffer = () => {
          trackEvent('Prices_OfferDelete_Click', { carId, offerIdx });
          setBasePrices({
            ...basePrices,
            rentalOffers: basePrices.rentalOffers.filter(
              (_, idx) => idx !== offerIdx
            ),
          });
        };

        let minDaysRental = 1;
        if (offerIdx >= 1) {
          minDaysRental = Math.max(
            minDaysRental,
            basePrices.rentalOffers[offerIdx - 1].days + 1
          );
        }

        let maxDaysOfRental = 30;
        if (offerIdx + 1 < basePrices.rentalOffers.length) {
          maxDaysOfRental = Math.min(
            maxDaysOfRental,
            basePrices.rentalOffers[offerIdx + 1].days - 1
          );
        }

        return (
          <React.Fragment key={offerIdx}>
            <OfferCard
              key={offerIdx}
              baseIntegralPrice={
                basePrices.rentalOffers?.[0]?.priceIntegral || 0
              }
              unremovable={offerIdx === 0}
              daysOfRental={offer.days}
              minDaysOfRental={minDaysRental}
              maxDaysOfRental={maxDaysOfRental}
              onChangeDaysOfRental={(days) => {
                trackEvent('Prices_OfferDays_Selected', {
                  carId,
                  offerIdx,
                  amount: days,
                });
                setOffer({ ...offer, days });
              }}
              integralPrice={offer.priceIntegral}
              onIntegralPriceChange={(priceIntegral) => {
                trackEvent('Prices_OfferPricePerDay_Type', {
                  carId,
                  offerIdx,
                  amount: priceIntegral,
                });
                setOffer({ ...offer, priceIntegral });
              }}
              onDeleteOffer={deleteOffer}
              kmPerDay={offer.distance}
              onKmPerDayCHange={(distance) => {
                trackEvent('Prices_OfferKmPerDay_Type', {
                  carId,
                  offerIdx,
                  amount: distance,
                });
                setOffer({ ...offer, distance });
              }}
            />
            <MCSpacing />
          </React.Fragment>
        );
      })}

      <div className={styles.addButtonContainer}>
        <MCButton
          disabled={
            !!basePrices.rentalOffers.length &&
            (basePrices.rentalOffers[basePrices.rentalOffers.length - 1]
              .days === 30 ||
              basePrices.rentalOffers.length === 7)
          }
          onClick={() => {
            trackEvent('Prices_AddOffer_Click');
            const days =
              Math.max(
                !basePrices.rentalOffers.length
                  ? 1
                  : basePrices.rentalOffers[basePrices.rentalOffers.length - 1]
                      .days,
                basePrices.minDaysRental
              ) + 1;
            const recommendedDiscount = getRecommendedDiscount(days);

            function computeDiscountPrice(basePrice: number) {
              return (
                basePrice - Math.round((basePrice * recommendedDiscount) / 100)
              );
            }

            const newOffer = {
              days,
              priceIntegral: computeDiscountPrice(
                basePrices.rentalOffers?.[0]?.priceIntegral || 0
              ),
              priceReduced: computeDiscountPrice(
                basePrices.rentalOffers?.[0]?.priceIntegral || 0
              ),
              priceMinimal: computeDiscountPrice(
                basePrices.rentalOffers?.[0]?.priceIntegral || 0
              ),
              distance: 1000,
            };

            setBasePrices({
              ...basePrices,
              rentalOffers: [...basePrices.rentalOffers, newOffer],
            });
          }}
          label="Adauga oferta"
        />
      </div>

      <MCSpacing />
      <MCSpacing />

      <MCHeadline>Setari de preturi in functie de sezon</MCHeadline>
      <div>
        <MCRow>
          <MCCell />
          {MONTHS_SHORT.map((name, index) => (
            <MCCell key={name}>
              <div>
                <MCCell justifyContent="center">
                  <MCParagraph>{name}</MCParagraph>
                </MCCell>
                <MCSpacing amount={3} />
                <InputSelect
                  onValueChange={(increase) => {
                    trackEvent('Prices_MonthIncrease_Select', {
                      monthIdx: index,
                      carId,
                      selected: `${increase}%`,
                    });
                    const monthIncrease = [...basePrices.monthIncrease];
                    monthIncrease[index] = Number(increase);
                    setBasePrices({ ...basePrices, monthIncrease });
                  }}
                  value={`${basePrices.monthIncrease[index]}`}
                  options={MONTHS_INCREASE}
                  marginLeft={0}
                  paddingLeft={4}
                  innerPaddingLeft={20}
                />
              </div>
            </MCCell>
          ))}
        </MCRow>
        {basePrices.rentalOffers.map((offer, offerIndex) => {
          const nextOffer =
            offerIndex + 1 < basePrices.rentalOffers.length
              ? basePrices.rentalOffers[offerIndex + 1]
              : null;
          const label = !nextOffer
            ? `${offer.days}+ zile`
            : offerIndex === 0
            ? `${offer.days} zile`
            : `${offer.days}${
                nextOffer.days - offer.days > 1 ? `-${nextOffer.days}` : ''
              } zile`;

          return (
            <div
              key={offerIndex}
              style={{
                borderBottom: nextOffer ? '1px solid #422680' : undefined,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: 50,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  borderRight: '1px solid #422680',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <MCParagraph>{label}</MCParagraph>
                </div>
              </div>
              {basePrices.monthIncrease.map((percentage, index) => {
                const increase = (offer.priceIntegral * percentage) / 100;
                const value = Math.round(
                  (offer.priceIntegral + increase) / 100
                );
                return (
                  <div
                    key={index}
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRight:
                        index + 1 !== MONTHS_SHORT.length
                          ? '1px solid #422680'
                          : undefined,
                    }}
                  >
                    <MCParagraph>{value}</MCParagraph>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <MCSpacing />
    </>
  );
}
