import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { pngStripe } from '../../../assets';
import { useAppWindowSizeInfo } from '../../../hooks';
import { CheckoutPaymentMethod } from '../../../types/api';
import { DAY_S } from '../../../utils/constants';
import {
  MCCheckbox,
  MCCheckboxAcceptTermsAndConds,
  MCHeadline,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import {
  RentOfferPrices,
  RentOfferPricesProps,
} from '../../pages/Car/RentOfferPrices';
import { OptionCard } from '../OptionCard';
import styles from './CheckoutPayment.module.css';

interface CheckoutPaymentProps {
  beginsInExtraSchedule?: boolean;
  dateBegin: Date;
  dateEnd: Date;
  total: number;
  offer: RentOfferPricesProps['offer'];
  disableButton?: boolean;
  onSubmit: (paymentMethod: CheckoutPaymentMethod) => void;
  extraDeliveryFee?: number;
  extraScheduleCost?: number;
  disabledCheckoutMethods?: CheckoutPaymentMethod[];
}

export function CheckoutPayment({
  beginsInExtraSchedule,
  total,
  dateBegin,
  dateEnd,
  onSubmit,
  offer,
  disableButton,
  extraDeliveryFee = 0,
  extraScheduleCost = 0,
  disabledCheckoutMethods = [],
}: CheckoutPaymentProps) {
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [hasAge, setHasAge] = useState(false);

  const options = useMemo(() => {
    const from = moment(dateBegin);

    // 24h window
    const isPaymentAtOwnerAvailable =
      from.unix() - moment().unix() <= 1 * DAY_S;

    const options = [
      {
        type: CheckoutPaymentMethod.FullOnline,
        title: 'Suma integrala - cu cardul',
        amount: total,
        description: 'Platesti toata suma si esti gata de drum, fara griji',
        available: false,
      },
      {
        type: CheckoutPaymentMethod.Advance20,
        title: 'Platesc 20% avans acum',
        amount: total * 0.2,
        description: `Platesti acum ${((total * 0.2) / 100).toFixed(
          2
        )} Ron si restul cand pleci la drum`,
        available: true,
      },
      {
        type: CheckoutPaymentMethod.Owner,
        title: 'Plata la proprietar',
        amount: total,
        description: 'Platesti toata suma si esti gata de drum, fara griji',
        available:
          isPaymentAtOwnerAvailable &&
          !(
            beginsInExtraSchedule || extraDeliveryFee > 0
          ),
      },
    ] as Array<{
      type: CheckoutPaymentMethod;
      title: string;
      amount: number;
      description: string;
      available?: boolean;
    }>;

    return options.filter(
      (x) => x.available && !disabledCheckoutMethods.includes(x.type)
    );
  }, [
    beginsInExtraSchedule,
    dateBegin,
    disabledCheckoutMethods,
    extraDeliveryFee,
    extraScheduleCost,
    total,
  ]);

  const [selectedOption, setSelectedOption] = useState<CheckoutPaymentMethod>(
    options?.[0]?.type
  );

  useEffect(() => {
    if (!options.find((x) => x.type === selectedOption) && options?.[0]?.type) {
      setSelectedOption(options?.[0]?.type);
    }
  }, [options, selectedOption, setSelectedOption]);

  const { isMobile } = useAppWindowSizeInfo();

  const { trackEvent, withEvent } = useDriverAnalytics();

  return (
    <div>
      <div>
        {options.map((option) => {
          return (
            <OptionCard
              key={option.type}
              description={option.description}
              title={option.title}
              isSelected={option.type === selectedOption}
              price={option.amount}
              onClick={() => {
                trackEvent(
                  option.type === CheckoutPaymentMethod.FullOnline
                    ? 'Checkout_Payment100_Click'
                    : option.type === CheckoutPaymentMethod.Advance20
                    ? 'Checkout_Payment20_Click'
                    : 'Checkout_PaymentOffice_Click'
                );

                setSelectedOption(option.type);
              }}
            />
          );
        })}
      </div>
      {isMobile && (
        <>
          <MCSpacing />
          <div className={styles.paymentDetailsCard}>
            <MCParagraph fontSize={18} bold>
              Detalii tarifare
            </MCParagraph>
            <MCSpacing />
            <RentOfferPrices offer={offer} />
          </div>
        </>
      )}
      <MCSpacing />
      <MCSpacing />
      <MCCheckboxAcceptTermsAndConds
        value={isAcceptedTerms}
        onChange={withEvent('Checkout_AcceptTermsCheckbox_Click')(
          setIsAcceptedTerms
        )}
      />
      <MCSpacing />
      <MCCheckbox
        value={hasAge}
        onChange={withEvent('Checkout_AcceptYearConditionsCheckbox_Click')(
          setHasAge
        )}
      >
        <MCParagraph fontSize={18}>
          Am 21 de ani impliniti si permisul de cel putin 1 an
        </MCParagraph>
      </MCCheckbox>
      <MCSpacing />
      <div className={styles.paymentSafety}>
        <MCHeadline>
          {selectedOption === CheckoutPaymentMethod.Owner
            ? 'Platesc la receptie'
            : 'Platesc in siguranta online'}
        </MCHeadline>
        {selectedOption !== CheckoutPaymentMethod.Owner && (
          <>
            <MCSpacing />
            <img src={pngStripe} className={styles.stripeImg} alt="stripe" />
          </>
        )}
        <MCSpacing />
        <div className={styles.actionButtonContainer}>
          <ActionButton
            disabled={
              disableButton ||
              options.length === 0 ||
              !hasAge ||
              !isAcceptedTerms
            }
            label="Rezerva acum"
            onClick={() => onSubmit(selectedOption)}
          />
        </div>
      </div>
    </div>
  );
}
