import { Link, useLocation } from 'react-router-dom';
import {
  ArrowLeftRight,
  CalendarPlus,
  CalendarX2,
  Car,
  FileText,
  Folders,
  History,
  LineChart,
  LogOut,
  Map,
  Settings,
  ShieldCheck,
  SquareGanttChart,
  Tags,
} from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';

const navItems = [
  {
    name: 'Prezentare generală',
    path: '/overview',
    icon: <SquareGanttChart className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Istoric închirieri',
    path: '/rental-history',
    icon: <History className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Primiri - Predări',
    path: '/receptions-deliveries',
    icon: <ArrowLeftRight className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Prețuri',
    path: '/prices',
    icon: <Tags className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Mașini',
    path: '/cars',
    icon: <Car className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Categorii',
    path: '/categories',
    icon: <Folders className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Disponibilitate mașini',
    path: '/availability',
    icon: <CalendarPlus className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Indisponibilitate mașini',
    path: '/unavailability',
    icon: <CalendarX2 className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Facturi',
    path: '/invoices',
    icon: <FileText className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Locații',
    path: '/locations',
    icon: <Map className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Asigurări',
    path: '/insurance',
    icon: <ShieldCheck className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Rapoarte',
    path: '/reports',
    icon: <LineChart className="h-7 w-7 text-foreground" />,
  },
  {
    name: 'Setări',
    path: '/newsettings',
    icon: <Settings className="h-7 w-7 text-foreground" />,
  },
];

export function Sidebar() {
  const location = useLocation();
  console.log(location);

  return (
    <nav className="fixed z-50 flex h-screen w-16 flex-col items-center justify-between border-r border-border p-6 xl:w-72 xl:items-start overflow-scroll">
      <div className="flex flex-col items-center gap-6 xl:items-start">
        <Link to="/" className="flex gap-3">
          <LogOut className="h-7 w-7 text-foreground" />
          <span className="hidden text-lg xl:inline-block">
            Mașini la cheie
          </span>
        </Link>

        {navItems.map((item) => (
          <TooltipProvider delayDuration={500} key={item.name}>
            <Tooltip>
              <TooltipTrigger>
                <Link
                  key={item.name}
                  to={item.path}
                  className={`after:transition-width relative flex items-center gap-3 p-1 duration-200 after:absolute after:bottom-0 after:right-0 after:block after:h-0.5 after:w-0 after:bg-primary after:transition-all after:content-[''] hover:text-primary hover:after:w-full ${
                    location.pathname === item.path && 'text-primary'
                  }`}
                >
                  {item.icon}
                  <span className="hidden text-lg xl:inline-block text-foreground">
                    {item.name}
                  </span>
                </Link>
              </TooltipTrigger>

              <TooltipContent side="right" className="text-base xl:hidden">
                {item.name}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>

      <TooltipProvider delayDuration={500}>
        <Tooltip>
          <TooltipTrigger>
            <Link to="/" className="flex gap-3">
              <LogOut className="h-7 w-7 text-foreground" />
              <span className="hidden text-lg xl:inline-block">Logout</span>
            </Link>
          </TooltipTrigger>

          <TooltipContent
            side="right"
            className="text-base xl:hidden"
          ></TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </nav>
  );
}
