import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  svgInvoicesArrowIcon,
  svgInvoicesCalendarIcon,
  svgInvoicesClientsIcon,
  svgInvoicesDownloadIcon,
  svgInvoicesMoneyIcon,
} from '../../../assets';
import { useAppInvoicesQuery } from '../../../hooks/queries/useAppInvoicesQuery';
import { useAppReservationsQuery } from '../../../hooks/queries/useAppReservationsQuery';
import { ReduxState } from '../../../types/redux';
import { CONFIRMED, StatusColorType } from '../../../utils/constants';
import { computeNumberOfDaysForRental } from '../../../utils/mathUtil';
import {
  MCParagraph,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { MCStatusDot } from '../../../_shared/components/MCStatusDot';
import { useLeftNavigatorOwnerProps } from '../../hooks';
import styles from './Invoices.module.css';

export const Invoices = () => {
  const dateMoment = moment();
  const props = useLeftNavigatorOwnerProps();

  const ownerId = useSelector(
    (x: ReduxState) => x.userReducer.owner?._id || ''
  );

  const { data: reservations, isLoading } = useAppReservationsQuery({
    owner: ownerId,
    status: CONFIRMED,
    dateBegin: moment().startOf('month').unix(),
    dateEnd: moment().endOf('month').unix(),
  });

  const { data: invoices, isLoading: isLoadingInvoices } = useAppInvoicesQuery({
    owner: ownerId,
  });

  let totalRevenue = 0;
  let rentDays = 0;
  let clients = 0;
  let comision = 0;

  reservations?.forEach((res) => {
    if (res.isOurReservation && res.checkoutPaymentMethod === 'Owner') {
      totalRevenue += res.pricing.totalPrice! / 100;
      rentDays += computeNumberOfDaysForRental(res.dateBegin, res.dateEnd);
      clients += 1;
      comision += Math.max(res.pricing.marketplaceRevenue, 0)! / 100;
    }
  });

  return (
    <MCTwoPaneLayout title="Facturi" {...props}>
      <MCSpacing />
      <MCSpacing />
      <MCSpacing />
      <div className={styles.mainContainer}>
        <MCParagraph bold fontSize={24}>
          {dateMoment.format('MMM, YYYY')}
        </MCParagraph>

        {isLoading && <LoadingOutlined className="h1 p-5" />}
        {!isLoading && (
          <>
            <MCSpacing />
            <div className={styles.statsContainer}>
              <div className={styles.statContainer}>
                <div>
                  <MCParagraph>Total incasari la sediu</MCParagraph>
                  <img
                    className={styles.imgContainer}
                    src={svgInvoicesMoneyIcon}
                  />
                </div>
                <MCSpacing />
                <MCParagraph fontSize={25}>{totalRevenue} Ron</MCParagraph>
              </div>
              <div className={styles.statContainer}>
                <div>
                  <MCParagraph>Zile inchiriate</MCParagraph>
                  <img
                    className={styles.imgContainer}
                    src={svgInvoicesCalendarIcon}
                  />
                </div>
                <MCSpacing />
                <MCParagraph fontSize={25}>{rentDays}</MCParagraph>
              </div>
              <div className={styles.statContainer}>
                <div>
                  <MCParagraph>Clienti la sediu</MCParagraph>
                  <img
                    className={styles.imgContainer}
                    src={svgInvoicesClientsIcon}
                  />
                </div>
                <MCSpacing />
                <MCParagraph fontSize={25}>{clients}</MCParagraph>
              </div>
              <div className={styles.statContainer}>
                <MCParagraph>Comision</MCParagraph>
                <MCSpacing />
                <MCParagraph fontSize={25}>{comision} Ron</MCParagraph>
              </div>
            </div>

            <MCSpacing />
            <MCSpacing />
            <MCParagraph bold>
              Inchirieri clienti din platforma cu plata la sediu:
            </MCParagraph>

            {reservations
              ?.filter(
                (e) => e.isOurReservation && e.checkoutPaymentMethod === 'Owner'
              )
              .map((reservation, index) => {
                return (
                  <div className={styles.rowContainer} key={index}>
                    <MCParagraph>{index + 1}.</MCParagraph>
                    <MCParagraph bold>
                      Venit: {reservation.pricing.totalPrice! / 100} Ron
                    </MCParagraph>
                    <MCParagraph bold>
                      Comision: {reservation.pricing.marketplaceRevenue! / 100}{' '}
                      Ron
                    </MCParagraph>
                    <MCParagraph>
                      Interval:{' '}
                      {moment
                        .unix(reservation.dateBegin)
                        .format('DD MMM HH:mm')}
                    </MCParagraph>
                    <img src={svgInvoicesArrowIcon} />
                    <MCParagraph>
                      {moment.unix(reservation.dateEnd).format('DD MMM HH:mm')}
                    </MCParagraph>
                    <MCParagraph>
                      {reservation.carDetails?.makeAndModel}{' '}
                      {reservation.carDetails?.registrationNumber}
                    </MCParagraph>
                    <MCParagraph>Id: {reservation.reservationId}</MCParagraph>
                  </div>
                );
              })}
          </>
        )}
      </div>
      <MCSpacing />
      <MCSpacing />
      <MCParagraph bold fontSize={20}>
        Facturi din lunile anterioare:
      </MCParagraph>
      {isLoadingInvoices && <LoadingOutlined className="h1 p-5" />}
      {invoices?.map((invoice, index) => {
        return (
          <div className={styles.rowContainerMonth} key={index}>
            <MCParagraph>{index + 1}.</MCParagraph>
            <MCParagraph>
              Factura: {invoice.seriesName} {invoice.seriesNumber}
            </MCParagraph>
            <MCParagraph>
              Emitere:{' '}
              {invoice?.issueDate
                ? moment.unix(invoice?.issueDate).format('DD/MM/YYYY')
                : ''}
            </MCParagraph>
            <MCParagraph>
              Scadenta:{' '}
              {invoice?.dueDate
                ? moment.unix(invoice?.dueDate).format('DD/MM/YYYY')
                : ''}
            </MCParagraph>
            <div className={styles.widthContainer}></div>

            <div className={styles.invoiceStatusContainer}>
              <MCStatusDot
                marginRight={12}
                statusColor={
                  invoice.isPaid
                    ? StatusColorType.STATUS_COLOR_GREEN
                    : StatusColorType.STATUS_COLOR_ORANGE
                }
              />
              <MCParagraph>
                {invoice.isPaid ? 'Platita' : 'In Asteptare'}
              </MCParagraph>
            </div>

            <MCParagraph
              cursor="pointer"
              underline
              onClick={() => {
                console.log('eeeee');
              }}
            >
              Descarca:
              <img
                className={styles.imgContainer}
                src={svgInvoicesDownloadIcon}
              />
            </MCParagraph>
          </div>
        );
      })}
    </MCTwoPaneLayout>
  );
};
