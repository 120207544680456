import moment from 'moment';
import {
  AppTimeFormReducerState,
  useAppDisabledIntervals,
} from '../../../hooks';
import { Car, Location, Reservation } from '../../../types/api';
import { InputFormDate } from '../../../_shared/components/InputFormDate/InputFormDate';
import { InputFormLocation } from '../../../_shared/components/InputFormLocation/InputFormLocation';
import { useCarAvailabilityHourIntervals } from '../../hooks';
import './SearchFormCarPage.style.css';

const minimumDate = moment().startOf('day').toDate();
const maximumDate = new Date();
maximumDate.setMonth(maximumDate.getMonth() + 4);

export interface SearchFormCarPageProps {
  interval: AppTimeFormReducerState;
  carDetails: Car;
  initialLocationId: string;
  onLocationChange: (location: Location) => void;
  onDateBeginChanged: (dateBegin: Date) => void;
  onDateEndChanged: (dateEnd: Date) => void;
  onMinutesBeginChanged: (minutes: number) => void;
  onMinutesEndChanged: (minutes: number) => void;
  minDaysRental?: number;
  reservations: Array<Reservation>;
}

export const SearchFormCarPage = ({
  interval,
  carDetails,
  initialLocationId,
  onDateBeginChanged,
  onDateEndChanged,
  onMinutesBeginChanged,
  onMinutesEndChanged,
  onLocationChange,
  minDaysRental = 1,
  reservations,
}: SearchFormCarPageProps) => {
  const { disabledBeginIntervals, disabledEndIntervals } =
    useAppDisabledIntervals(reservations, interval);
  const availability = useCarAvailabilityHourIntervals({
    car: carDetails,
    interval,
  });

  const minLastDate = moment(interval.dateBegin || minimumDate)
    .add(minDaysRental, 'days')
    .toDate();

  return (
    <div className="search-form-car-container">
      <div className="search-form-car-input-section first">
        <InputFormLocation
          availableLocations={carDetails?.location.map((carLoc) => {
            return carLoc.location;
          })}
          onLocationChange={onLocationChange}
          locationId={initialLocationId}
        />
      </div>

      <div className="search-form-car-input-divider" />
      <div className="search-form-car-input-section">
        <InputFormDate
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          label={'De la'}
          onDateSelected={onDateBeginChanged}
          onMinutesChanged={onMinutesBeginChanged}
          minutes={interval.minutesBegin}
          selectedDate={interval.dateBegin}
          minMinutes={Math.max(
            availability?.pickup?.min || 0,
            interval.minMinutesBegin
          )}
          maxMinutes={availability?.pickup?.max}
          disabledIntervals={disabledBeginIntervals}
        />
      </div>
      <div className="search-form-car-input-divider" />
      <div className="search-form-car-input-section last">
        <InputFormDate
          minimumDate={minLastDate}
          maximumDate={maximumDate}
          label={'Pana la'}
          onDateSelected={onDateEndChanged}
          onMinutesChanged={onMinutesEndChanged}
          minutes={interval.minutesEnd}
          selectedDate={interval.dateEnd}
          minMinutes={availability?.retrieval?.min}
          maxMinutes={availability?.retrieval?.max}
          disabledIntervals={disabledEndIntervals}
        />
      </div>
    </div>
  );
};
