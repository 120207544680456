import classNames from 'classnames';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import defaultProfileImg from '../../../assets/profile/default-profile-image.svg';
import { Separator } from '../../../_shared/components/Separator';
import { MCSpacing } from '../MCSpacing';
import styles from './MCGenericLeftNavigator.module.css';

export interface MCGenericLeftNavigatorProps {
  options: Array<{
    text: string;
    route?: string;
    onClick?: () => void;
    isRed?: boolean;
    image?: any;
    icon?: {
      regular: any;
      selected: any;
    };
    disabled?: boolean;
    onEvent?: () => void;
  }>;
  onLogout?: () => void;
}

export const MCGenericLeftNavigator = ({
  options,
  onLogout,
}: MCGenericLeftNavigatorProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const selectedOption = useMemo(
    () => options.find((x) => x.route === pathname),
    [options, pathname]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {!!selectedOption?.image && (
          <>
            <img
              className={styles.profileImage}
              src={selectedOption?.image ?? defaultProfileImg}
              alt="profile"
            />
            <Separator />
          </>
        )}
        {options.map((option) => {
          const isSelected = selectedOption === option;
          return (
            <div
              key={option.text}
              className={classNames(styles.labelContainer, {
                [styles.disabled]: !!option.disabled,
              })}
              onClick={
                option.disabled
                  ? undefined
                  : () => {
                      option.onEvent?.();
                      if (option.onClick) option.onClick();

                      if (option.route) history.push(option.route);
                    }
              }
            >
              {option.icon && (
                <img
                  className={styles.labelIcon}
                  src={isSelected ? option.icon.selected : option.icon.regular}
                  alt="icon"
                />
              )}
              <p
                key={option.text}
                className={classNames({
                  [styles.red]: option.isRed,
                  [styles.label]: !option.isRed,
                  [styles.selected]: isSelected,
                  [styles.disabled]: option.disabled,
                })}
              >
                {option.text}
              </p>
            </div>
          );
        })}
      </div>

      {!!onLogout && (
        <>
          <MCSpacing />
          <div className={styles.logOutContainer}>
            <p className={styles.label} onClick={onLogout}>
              Log out
            </p>
          </div>
        </>
      )}
    </div>
  );
};
