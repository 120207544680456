import moment from 'moment';
import { useMemo } from 'react';
import {
  MCHeadline,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import styles from './AgendaHeader.module.css';

const MONTH_NAMES: Record<number, string> = {
  0: 'Ianuarie',
  1: 'Februarie',
  2: 'Martie',
  3: 'Aprilie',
  4: 'Mai',
  5: 'Iunie',
  6: 'Iulie',
  7: 'August',
  8: 'Septembrie',
  9: 'Octombrie',
  10: 'Noiembrie',
  11: 'Decembrie',
};

const DAY_IN_WEEK: Record<number, string> = {
  0: 'Dum',
  1: 'Lun',
  2: 'Mar',
  3: 'Mie',
  4: 'Joi',
  5: 'Vin',
  6: 'Sâm',
};

interface AgendaHeaderProps {
  startDate: Date;
  leftWidth: number;
  rightWidth: number;
}

export function AgendaHeader({
  startDate,
  leftWidth,
  rightWidth,
}: AgendaHeaderProps) {
  const days = useMemo(() => {
    const result: Array<{ dayNo: number; dayName: string }> = [];
    for (let i = 0; i < 14; i++) {
      const momentDate = moment(startDate).add(i, 'days').toDate();
      result.push({
        dayName: DAY_IN_WEEK[momentDate.getDay()],
        dayNo: momentDate.getDate(),
      });
    }
    return result;
  }, [startDate]);

  return (
    <div className={styles.container}>
      <div className={styles.headerLeft} style={{ width: leftWidth }}>
        <MCHeadline>{MONTH_NAMES[startDate.getMonth()]}</MCHeadline>
      </div>
      <div className={styles.headerRight} style={{ width: rightWidth }}>
        {days.map(({ dayName, dayNo }) => {
          return (
            <div
              key={dayNo}
              style={{ width: rightWidth / days.length }}
              className={styles.dateCell}
            >
              <MCParagraph bold>{dayName}</MCParagraph>
              <MCSpacing />
              <MCParagraph bold>{dayNo}</MCParagraph>
            </div>
          );
        })}
      </div>
    </div>
  );
}
