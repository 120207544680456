import { useCallback, useMemo } from 'react';
import { CarLocation, Location } from '../../../types/api';
import {
  MCCheckbox,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { MCTooltip } from '../../../_shared/components/MCTooltip';
import { useOwnerAnalytics } from '../../hooks';
import styles from './DeliveryLocation.module.css';

interface props {
  carLocation: CarLocation;
  allLocations: Array<Location>;
  setLocation: (_: CarLocation) => void;
  onDelete: (_: CarLocation) => void;
}

export const DeliveryLocation = ({
  carLocation,
  allLocations,
  setLocation,
  onDelete,
}: props) => {
  const { trackEvent } = useOwnerAnalytics();
  const toOption = useCallback((location: Location) => {
    return {
      name: location.name,
      value: location._id,
    };
  }, []);

  const locationSelectOptions = useMemo(() => {
    return [...(allLocations || []).map(toOption)];
  }, [allLocations, toOption]);

  return (
    <div className={styles.container}>
      <div style={{ width: 428, display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 396 }}>
          <InputSelect
            label="Locatie"
            value={carLocation.location._id}
            options={locationSelectOptions}
            onValueChange={(e) => {
              trackEvent('Locations_DeliveryZoneLocationCombo_Select', {
                name: allLocations.find((loc) => loc._id === e)?.name || '',
              });
              setLocation({
                ...carLocation,
                location: allLocations.find((loc) => loc._id === e),
              });
            }}
          />
        </div>
        <div style={{ marginLeft: 16, marginTop: 2 }}>
          <MCTooltip
            children={
              <div className={styles.modalContainer}>
                <h5 className={styles.headerText}>Zona Livrare</h5>
                <p className={styles.tooltipText}>
                  Masina va fi livrata in aceasta zona.
                </p>
                <p className={styles.tooltipText}>
                  Daca zona selectata este Aeroport atunci masina va fi livrata
                  in zona aeroportului intr-o parcare specifica.
                </p>
                <p className={styles.tooltipText}>
                  Daca zona selectata este Oras atunci masina va fi livrata in
                  oras oriunde doreste clientul.
                </p>
              </div>
            }
          />
        </div>
      </div>

      <div className={styles.inputContainer}>
        <div className={styles.checkboxContainer}>
          <MCParagraph>
            Taxa suplimentara preluare client din aceasta locatie
          </MCParagraph>
          <MCCheckbox
            value={carLocation.isDeliveryActive!}
            onChange={(val) => {
              trackEvent('Locations_DeliveryZoneRetrievalTaxCheckbox_Check', {
                checked: val,
              });
              setLocation({
                ...carLocation,
                isDeliveryActive: val,
                deliveryExtraFee: 0,
              });
            }}
          />
        </div>

        <MCSpacing />

        <div className={styles.sumContainer}>
          <MCParagraph>Suma</MCParagraph>
          <div style={{ width: 80, marginLeft: 8, marginRight: 8 }}>
            <InputField
              value={`${carLocation.deliveryExtraFee! / 100}`}
              onChange={(e) => {
                const sum = e.length === 0 ? 0 : parseInt(e);
                trackEvent('Locations_DeliveryZoneRetrievalTaxInput_Type', {
                  value: sum,
                });
                setLocation({
                  ...carLocation,
                  deliveryExtraFee: sum * 100,
                  isDeliveryActive: sum !== 0,
                });
              }}
            />
          </div>
          <MCParagraph>Ron</MCParagraph>
          <div style={{ marginLeft: 8, marginTop: -4 }}>
            <MCTooltip
              children={
                <div className={styles.modalContainer}>
                  <h5 className={styles.headerText}>Taxa livrare</h5>
                  <p className={styles.tooltipText}>
                    Aceasta taxa se aplica pentru preluarea / preadarea masinii
                    din aceasta locatie.
                  </p>
                  <p className={styles.tooltipText}>
                    Aceasta taxa se percepe individual pentru fiecare eveniment
                    de preluare / predare. Exemplu: Daca un client doreste sa
                    preia si sa predea masina in aceasta locatie va platii de 2
                    ori aceasta taxa.
                  </p>
                  <p className={styles.tooltipText}>
                    Intervalul de pret sugerat pentru aceasta taxa este de
                    50-200 Ron.
                  </p>
                </div>
              }
            />
          </div>
        </div>

        <div className={styles.alignRight}>
          <MCParagraph
            underline
            cursor="pointer"
            color="red"
            onClick={() => {
              trackEvent('Locations_DeleteDeliveryZone_Click');
              onDelete(carLocation);
            }}
          >
            Sterge zona livrare
          </MCParagraph>
        </div>
      </div>
    </div>
  );
};
