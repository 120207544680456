import './InputFormLocation.style.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getLocations } from '../../../api/locations';
import { LoadingOutlined } from '@ant-design/icons';
import { Location } from '../../../types/api';
import { LocationOption } from '../../../_shared/components/LocationOption/LocationOption';
import { useOnClickOutside } from '../../../utils/useOnClickOutside';

interface Props {
  availableLocations?: Array<Location>;
  onLocationChange: (location: Location) => void;
  locationId?: string;
}

export const InputFormLocation = ({
  availableLocations,
  onLocationChange,
  locationId,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);
  const [locations, setLocations] = useState<Array<Location>>([]);

  const selectedLocation = useMemo(() => {
    return locations!.find((x) => x._id === locationId);
  }, [locations, locationId]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsModalOpen(false));

  useEffect(() => {
    if (locations.length === 0) {
      if (availableLocations?.length) {
        setLocations(availableLocations);
      } else {
        getLocationsArray();
      }
    }
  }, [availableLocations]);

  const getLocationsArray = async () => {
    setIsLocationsLoading(true);
    const response = await getLocations({ isAreaLocation: true });
    setLocations(response.locations);
    setIsLocationsLoading(false);
  };

  const renderListOfLocations = () => {
    return locations.map((location: Location) => {
      return (
        <div
          key={location._id}
          style={{ paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }}
          onClick={() => {
            onLocationChange(location);
            setIsModalOpen(false);
          }}
        >
          <LocationOption location={location} />
        </div>
      );
    });
  };

  return (
    <div className="search-form-location-container">
      <p className="search-form-location-text">Locatie</p>
      <div
        className="search-form-location-inner-container"
        onClick={() => {
          setIsModalOpen(!isModalOpen);
          if (!locations.length) {
            getLocationsArray();
          }
        }}
      >
        <LocationOption location={selectedLocation} isDropDown={true} />
      </div>
      <div className="search-form-location-modal-outer-container">
        {isModalOpen && (
          // @ts-ignore
          <div ref={ref} className="search-form-location-modal-container">
            {isLocationsLoading ? (
              <LoadingOutlined className="h1" />
            ) : (
              <div>{renderListOfLocations()}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
