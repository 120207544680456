import { useMutation, useQueryClient } from 'react-query';
import { updateReservation } from '../../../api/reservations';
import { EditUnavailabilityDto } from '../../../types/api';

export function useAppEditUnavailabilityMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (unavailabilityDetails: EditUnavailabilityDto) => {
      return updateReservation(unavailabilityDetails);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('car-reservations');
        queryClient.invalidateQueries('available-cars');
        queryClient.invalidateQueries('owner-reservations');
        queryClient.invalidateQueries('owner-reservations-events');
        queryClient.invalidateQueries('owner-reservations-search');
      },
    }
  );
}
