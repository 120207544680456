interface props {
  label: string;
  placeholder: string;
  value?: string;
  onChange: (_: string) => void;
}

export const InputField = ({ label, placeholder, value, onChange }: props) => {
  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text">{label}</span>
      </div>
      <input
        className="form-control"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default InputField;
