import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppAvailableCarsQuery, useAppTimeForm } from '../../../hooks';
import { ReduxState } from '../../../types/redux';
import {
  MCDateForm,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { OwnerCarCard } from './OwnerCarCard';
import { computeNumberOfDaysForRental } from '../../../utils/mathUtil';

export const Availability = () => {
  const owner = useSelector((x: ReduxState) => x.userReducer.driver?._id || '');

  const props = useLeftNavigatorOwnerProps();
  const {
    interval,
    finalInterval,
    clearInterval,
    setDateEnd,
    setDateBegin,
    setMinutesBegin,
    setMinutesEnd,
  } = useAppTimeForm({});

  const noDaysForRental = useMemo(() => {
    if (!finalInterval.dateBegin || !finalInterval.dateEnd) {
      return 0;
    }

    return computeNumberOfDaysForRental(
      moment(finalInterval.dateBegin).unix(),
      moment(finalInterval.dateEnd).unix()
    );
  }, [finalInterval.dateBegin, finalInterval.dateEnd]);

  const { data } = useAppAvailableCarsQuery({ ...finalInterval, owner: owner });
  const cars = data?.cars;
  const beginDayReservations = data?.beginDayCarReservations;
  const endDayReservations = data?.endDayCarReservations;

  const { trackEvent } = useOwnerAnalytics();

  return (
    <MCTwoPaneLayout title="Disponibilitate mașini" {...props}>
      <MCSpacing />
      <MCSpacing />

      <MCDateForm
        interval={interval}
        onDateBeginChanged={(date) => {
          trackEvent('Availability_StartingDate_Selected', {
            date: moment(date).format('DD/MM/YYYY'),
          });
          setDateBegin(date);
        }}
        onEndDateChanged={(date) => {
          trackEvent('Availability_EndingDate_Selected', {
            date: moment(date).format('DD/MM/YYYY'),
          });
          setDateEnd(date);
        }}
        onBeginMinutesChanged={(mins) => {
          trackEvent('Availability_StartingHour_Selected', {
            hour: moment().startOf('day').add(mins).format('HH:mm'),
          });
          setMinutesBegin(mins);
        }}
        onEndMinutesChanged={(mins) => {
          trackEvent('Availability_EndingHour_Selected', {
            hour: moment().startOf('day').add(mins).format('HH:mm'),
          });
          setMinutesEnd(mins);
        }}
        onClear={() => {
          trackEvent('Availability_CancelDates_Selected');
          clearInterval();
        }}
      />

      <MCSpacing />
      <MCSpacing />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {cars?.map((car) => {
          return (
            <OwnerCarCard
              key={car._id}
              carDetails={car}
              noDaysForRental={noDaysForRental}
              beginDayReservation={beginDayReservations?.[car._id]}
              endDayReservation={endDayReservations?.[car._id]}
            />
          );
        })}
      </div>
    </MCTwoPaneLayout>
  );
};
