import { Location } from '../../../types/api';
import cityIcon from '../../../assets/locationOption/city-icon.svg';
import airportIcon from '../../../assets/locationOption/airport-icon.svg';
import dropDownArrow from '../../../assets/drop-down-arrow.svg';

import './LocationOption.style.css';

interface Props {
  location?: Location;
  isDropDown?: boolean;
  isVertical?: boolean;
  priceLabel?: string;
}

export const LocationOption = ({
  location,
  isDropDown,
  isVertical = false,
  priceLabel,
}: Props) => {
  const containerClassName = `location-option-container ${
    isVertical ? 'vertical' : ''
  }`;

  if (!location) {
    return (
      <div className={containerClassName}>
        <p className="search-form-location-text-placeholder">
          Oras, sector, aeroport
        </p>
        {isDropDown && <img src={dropDownArrow} alt="dropdown" />}
      </div>
    );
  }
  return (
    <div className={containerClassName}>
      <div className="location-option-icon">
        <img
          src={location.type === 'AIRPORT' ? airportIcon : cityIcon}
          alt="dropdown"
        />
      </div>
      <p className="location-option-text">{location.name}</p>
      {isDropDown && <img src={dropDownArrow} alt="dropdown" />}
      {priceLabel && (
        <p className="location-option-price-label">
          Livrare:{' '}
          <span className="location-option-price-label purple">
            {priceLabel}
          </span>
        </p>
      )}
    </div>
  );
};
