import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { PrivateAuthRoute } from '../routing/PrivateRoute';
import { OWNER, SHOW_OWNER_SWITCH, TOKEN } from '../utils/constants';
import { NotFoundPage } from '../_shared/pages/NotFound';
import { LoginPage } from '../_shared/Auth/Auth';
import { ReceptionsDeliveries } from './pages/ReceptionsDeliveries';
import { Availability } from './pages/Availability';
import { Unavailability } from './pages/Unavailability';
import { TermsAndConditions } from '../_shared/pages/Legal/TermsAndConditions';
import { PrivacyPolicy } from '../_shared/pages/Legal/PrivacyPolicy';
import { Gdpr } from '../_shared/pages/Legal/Gdpr';
import { Overview } from './pages/Overview';
import { Settings } from './pages/Settings/Settings';
import { NewSettings } from './pages/Settings/NewSettings';
import { RentalHistory } from './pages/RentalHistory';
import { Prices } from './pages/Prices';
import { Reports } from './pages/Reports/Reports';
import { Cars, Categories } from './pages/Cars';
import { Invoices } from './pages/Invoices';
import { CarEvents } from './pages/CarEvents';
import { useOwnerAnalytics } from './hooks';
import { useCallback, useEffect, useState } from 'react';
import { Locations } from './pages/Locations';
import { InputSelect } from '../_shared/components/InputSelect/InputSelect';
import { InputField } from '../_shared/components/InputField/InputField';
import React from 'react';
import { ActionButton } from '../_shared/components/ActionButton/ActionButton';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOwnerEmails, switchOwner } from '../api/sellers';
import { useDispatch } from 'react-redux';
import { loginOwnerAdmin } from '../redux/actions/driverAction';
import { MCSpacing } from '../_shared/components';

function RouteListener() {
  const { trackEvent } = useOwnerAnalytics();
  const location = useLocation();

  const url = location.pathname + location.search;

  useEffect(() => {
    trackEvent('Global_RouteSwitch', { url });
  }, [trackEvent, url]);

  return null;
}

const Router = () => {
  return (
    <BrowserRouter>
      <div>
        <RouteListener />
        <Switch>
          <Route exact={true} path={['/login', '/']}>
            <LoginPage isLogin={true} />
          </Route>
          <Route exact={true} path="/register">
            <LoginPage isLogin={false} />
          </Route>
          <PrivateAuthRoute
            exact={true}
            path="/overview"
            allowed={[OWNER]}
            component={Overview}
          />
          <PrivateAuthRoute
            exact={true}
            path="/rental-history"
            allowed={[OWNER]}
            component={RentalHistory}
          />
          <PrivateAuthRoute
            exact={true}
            path="/receptions-deliveries"
            allowed={[OWNER]}
            component={ReceptionsDeliveries}
          />
          <PrivateAuthRoute
            exact={true}
            path="/prices"
            allowed={[OWNER]}
            component={Prices}
          />
          <PrivateAuthRoute
            exact={true}
            path="/cars"
            allowed={[OWNER]}
            component={Cars}
          />
          <PrivateAuthRoute
            exact={true}
            path="/categories"
            allowed={[OWNER]}
            component={Categories}
          />
          <PrivateAuthRoute
            exact={true}
            path="/availability"
            allowed={[OWNER]}
            component={Availability}
          />
          <PrivateAuthRoute
            exact={true}
            path="/unavailability"
            allowed={[OWNER]}
            component={Unavailability}
          />
          <PrivateAuthRoute
            exact={true}
            path="/invoices"
            allowed={[OWNER]}
            component={Invoices}
          />
          <PrivateAuthRoute
            exact={true}
            path="/insurance"
            allowed={[OWNER]}
            component={CarEvents}
          />
          <PrivateAuthRoute
            exact={true}
            path="/reports"
            allowed={[OWNER]}
            component={Reports}
          />
          <PrivateAuthRoute
            exact={true}
            path="/locations"
            allowed={[OWNER]}
            component={Locations}
          />
          <PrivateAuthRoute
            exact={true}
            path="/settings"
            allowed={[OWNER]}
            component={Settings}
          />
          <PrivateAuthRoute
            exact={true}
            path="/newsettings"
            allowed={[OWNER]}
            component={NewSettings}
          />
          <Route
            exact={true}
            path="/terms_and_conditions"
            component={TermsAndConditions}
          />
          <Route
            exact={true}
            path="/privacy_policy"
            component={PrivacyPolicy}
          />
          <Route exact={true} path="/gdpr" component={Gdpr} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

const ADMIN_TOKEN_STORAGE_KEY = 'ADMIN_TOKEN_STORAGE_KEY';

const AdminSwitcher = ({ onToggle }: { onToggle: () => void }) => {
  const [password, setPassword] = React.useState(
    localStorage.getItem('ADMIN_TOKEN_STORAGE_KEY') || '',
  );
  const [userId, setUserId] = React.useState<string>(() => {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      return '';
    }

    const [, identityBase64] = token.split('.');

    const identity = atob(identityBase64);
    const id = JSON.parse(identity)?._id || '';

    return id as string;
  });
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const { data } = useQuery(
    ['owner-with-emails', password],
    async () => {
      const ownerWithEmails = await getOwnerEmails({ token: password });

      const result = ownerWithEmails.sellers.map((x) => ({
        value: x._id,
        email: x.email,
        name: `${x.name} - ${x.email}`,
      }));

      if (result?.[0].value) {
        setUserId((userId) => (userId ? userId : result?.[0].value));
      }

      return result;
    },
    { retry: false },
  );

  const email = data?.find((x) => x.value === userId)?.email || '';

  const { mutate } = useMutation(async () => {
    try {
      if (!email) {
        return;
      }

      const result = await switchOwner({ email, token: password });

      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey?.[0] !== 'owner-with-emails';
        },
      });
      await dispatch(loginOwnerAdmin(result.token));
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 500,
          alignSelf: 'center',
          padding: '30px 0px',
        }}
      >
        <InputField
          isBold
          labelFontSize={16}
          value={password}
          onChange={(x) => {
            setPassword(x);
            localStorage.setItem(ADMIN_TOKEN_STORAGE_KEY, x);
          }}
          placeholder="Parola"
          type="password"
        />
        <MCSpacing amount={10} />
        <InputSelect
          value={userId}
          options={data || []}
          onValueChange={setUserId}
        />

        <MCSpacing amount={10} />
        <ActionButton label="switch" onClick={mutate} />
      </div>
    </div>
  );
};

export const OwnerRouting = () => {
  const [toggle, setToggle] = useState(false);

  const onToggle = useCallback(() => setToggle((x) => !x), [setToggle]);

  return (
    <>
      {SHOW_OWNER_SWITCH && <AdminSwitcher onToggle={onToggle} />}
      {!toggle && <Router key="1" />}
      {toggle && <Router key="2" />}
    </>
  );
};
