import { useMutation, useQueryClient } from 'react-query';
import { updateCar } from '../../../api/cars';
import { Car } from '../../../types/api';

export function useAppUpdateCarMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ carId, carData }: { carId: string; carData: Car }) => {
      return updateCar(carId, carData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('car-list');
      },
    }
  );
}
