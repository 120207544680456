import { useQuery } from 'react-query';
import { searchReservations } from '../../api/reservations';

export function useAppOwnerReservationsSearchQuery(
  {
    search,
  }: {
    search: string;
  },
  { size, page }: { size?: number; page?: number } = {}
) {
  return useQuery(
    ['owner-reservations-search', { search, size, page }],
    async () => {
      return await searchReservations(search, size, page);
    },
    {
      keepPreviousData: true,
    }
  );
}
