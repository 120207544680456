import classNames from 'classnames';
import { svgCheck } from '../../../assets';
import { useCurrencyFormatter } from '../../../hooks/util/useCurrencyFormatter';
import { MCParagraph } from '../../../_shared/components';
import styles from './OptionCard.module.css';

interface OptionCardProps {
  isSelected?: boolean;
  onClick: () => void;
  title: string;
  description: string;
  price?: number;
}

export function OptionCard({
  isSelected,
  description,
  price,
  title,
  onClick,
}: OptionCardProps) {
  const { formatCurrency } = useCurrencyFormatter();
  return (
    <div
      className={classNames(styles.cardContainer, {
        [styles.cardContainerSelected]: isSelected,
      })}
      onClick={onClick}
    >
      <div>
        <MCParagraph bold>{title}</MCParagraph>
      </div>
      <div>
        <MCParagraph>{description}</MCParagraph>
      </div>
      {price && (
        <div className={styles.cardOptionPrice}>
          <MCParagraph bold>{formatCurrency(price, 2)}</MCParagraph>
        </div>
      )}
      {isSelected && (
        <img src={svgCheck} className={styles.cardOptionTick} alt="selected" />
      )}
    </div>
  );
}
