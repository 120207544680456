import moment from 'moment';
import { useCallback, useState } from 'react';

const minimumDate = new Date();
minimumDate.setHours(0, 0, 0, 0);
const maximumDate = new Date();
maximumDate.setMonth(maximumDate.getMonth() + 3);

const dateBegin = new Date(minimumDate.getTime());
dateBegin.setDate(minimumDate.getDate());
const dateEnd = new Date(minimumDate.getTime());
dateEnd.setDate(minimumDate.getDate() + 5);

function getInitialState({ from, to }: { from?: number; to?: number }): {
  dateBegin?: Date;
  dateEnd?: Date;
} {
  if (from && to) {
    return {
      dateBegin: moment(from).startOf('day').toDate(),
      dateEnd: moment(to).endOf('day').toDate(),
    };
  }

  if (from) {
    return {
      dateBegin: moment(from).startOf('day').toDate(),
      dateEnd: moment(from).startOf('day').add(5, 'days').toDate(),
    };
  }

  return {};
}

export function useAppTimeInterval({
  from,
  to,
}: {
  from?: number;
  to?: number;
}) {
  const [interval, setInterval] = useState(
    getInitialState({
      from: from ? from * 1000 : undefined,
      to: to ? to * 1000 : undefined,
    })
  );

  const setDateBegin = useCallback(
    (dateBegin: Date) => {
      setInterval((x) => ({ ...x, dateBegin }));
    },
    [setInterval]
  );

  const setDateEnd = useCallback(
    (dateEnd: Date) => {
      setInterval((x) => ({ ...x, dateEnd }));
    },
    [setInterval]
  );

  const clearInterval = useCallback(() => {
    setInterval({});
  }, [setInterval]);

  return {
    interval,
    setDateBegin,
    setDateEnd,
    clearInterval,
  };
}
