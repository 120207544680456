import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary:
          'bg-gradient-to-r from-[#422680]/80 to-primary text-primary-foreground hover:bg-primary',
        secondary:
          'bg-gradient-to-r from-[#222222]/80 to-secondary text-secondary-foreground hover:bg-secondary',
        outline:
          'border border-secondary bg-background hover:bg-[#D6D6D6]/20 hover:text-secondary',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        ghost: 'bg-card text-muted cursor-not-allowed',
        link: 'text-secondary underline-offset-2 underline',
        icon: 'rounded-full border bg-transparent hover:shadow-md',
      },
      variantSize: {
        default: 'h-12 text-base px-4',
        sm: 'h-10 text-sm px-3',
        lg: 'h-12 w-full text-base px-8',
      },
    },
    defaultVariants: {
      variant: 'primary',
      variantSize: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, variantSize, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, variantSize, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
