import React from 'react';
import styles from './MCListItem.module.css';
import classNames from 'classnames';
import { MCSvgImage } from '../MCSvgImage';

interface MCListItemProps {
  image?: any;
  imageSize?: number;
  addLeftMargin?: boolean;
  centerTextOnMobile?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  marginBottom?: number;
  marginTop?: number;
}

export function MCListItem({
  image,
  children,
  addLeftMargin = false,
  centerTextOnMobile = false,
  imageSize = 23,
  marginBottom = 16,
  marginTop = 0,
}: MCListItemProps) {
  return (
    <div style={{ marginBottom, marginTop }} className={styles.container}>
      {image && (
        <MCSvgImage
          image={image}
          addLeftMargin={addLeftMargin}
          size={imageSize}
        />
      )}
      <div
        className={classNames(styles.textContainer, {
          [styles.centerTextOnMobile]: centerTextOnMobile,
        })}
      >
        {children}
      </div>
    </div>
  );
}
