import * as React from 'react';

interface MCSpacingProps {
  vertical?: boolean;
  full?: boolean;
  amount?: number;
}

export function MCSpacing({
  vertical = true,
  full = false,
  amount = 16,
}: MCSpacingProps) {
  return (
    <div
      style={{
        flex: full ? 1 : undefined,
        height: vertical ? amount : 0,
        width: !vertical ? amount : 0,
      }}
    />
  );
}
