import classNames from 'classnames';
import { useMemo } from 'react';
import { PlatformType, PLATFORM_TYPE } from '../../utils/constants';
import './Auth.style.css';
import { AuthForm } from './AuthForm';

interface Props {
  isLogin: boolean;
}

const LOGIN_IMAGE_CONTENT_CLASS =
  PLATFORM_TYPE === PlatformType.Owner
    ? 'login-image-owner'
    : 'login-image-driver';

export const LoginPage = ({ isLogin }: Props) => {
  const redirectRoute = useMemo(() => {
    if (PlatformType.Owner === PLATFORM_TYPE) {
      return '/receptions-deliveries';
    }

    return isLogin ? '/catalog' : '/onboarding';
  }, [isLogin]);

  return (
    <div className="login-container">
      <div className="login-inner-container">
        <div className="login-image-container">
          <img
            className={classNames('login-image', LOGIN_IMAGE_CONTENT_CLASS)}
            alt="login"
          />
          <div className="login-image-inner-container">
            <img className="login-image-logo" alt="logo" />
            <p className="login-big-text">Iti uram</p>
            <p className="login-big-text bold">Bun venit!</p>
            <p className="login-small-text">
              probabil cea mai buna platforma de unde iti poti inchiria masina
              dorita
            </p>
          </div>
        </div>
        <div className="login-details-container">
          <AuthForm
            isLogin={isLogin}
            redirectRouteIfAuthenticated={redirectRoute}
          />
        </div>
      </div>
    </div>
  );
};
