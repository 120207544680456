import React, { useRef } from 'react';
import { useOnClickOutside } from '../../../utils/useOnClickOutside';
import styles from './MCModal.module.css';

interface MCModalProps {
  visible?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
  disableClickOutside?: boolean;
  topMargin?: number;
}

export function MCModal({
  children,
  visible = false,
  onClose = () => {},
  disableClickOutside = false,
  topMargin = 0,
}: MCModalProps) {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose, disableClickOutside);

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.overlay} />
        <div className={styles.content}>
          <div ref={ref}>
            <div>
              <div className={styles.innerContent} style={{ top: topMargin }}>
                {children}
                <div className={styles.closeButton} onClick={onClose}>
                  &times;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
