import React from 'react';
import styles from './MCCell.module.css';

interface MCCellProps {
  children?: React.ReactNode | React.ReactNodeArray;
  flex?: number;
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
}

export function MCCell({
  children,
  flex = 1,
  alignItems = 'center',
  justifyContent = 'flex-start',
}: MCCellProps) {
  return (
    <div
      style={{ flex, alignItems, justifyContent }}
      className={styles.container}
    >
      {children}
    </div>
  );
}
