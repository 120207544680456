import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSellers, updateSeller } from '../../../api/sellers';
import { Layout } from '../../../_shared/components/Layout';
import { Seller } from '../../../types/api';

export const SellerPage = () => {
  // @ts-ignore
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [sellerData, setSellerData] = useState<Seller | undefined>(undefined);

  useEffect(() => {
    const getAsyncSeller = async () => {
      const seller = (await getSellers({ _id: id }))[0];
      setSellerData(seller);
    };
    getAsyncSeller();
  }, [isEditing, id]);

  return (
    <Layout>
      {isEditing ? (
        <SellerEdit sellerData={sellerData!} setIsEditing={setIsEditing} />
      ) : (
        <SellerView sellerData={sellerData!} setIsEditing={setIsEditing} />
      )}
    </Layout>
  );
};

const SellerView = ({ sellerData, setIsEditing }: any) => {
  return (
    <div className="card shadow" style={{ borderRadius: 8 }}>
      <div className="card-body">
        <h4>{sellerData?.name}</h4>
        <p style={{ marginTop: 25 }}>Email: {sellerData?.auth.email}</p>
        <p>Role: {sellerData?.auth.role}</p>
        <p>Location: {sellerData?.location.name}</p>
        <p>Images:</p>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

const SellerEdit = ({ sellerData, setIsEditing }: any) => {
  const [name, setName] = useState(sellerData.name);

  const onSave = async () => {
    try {
      await updateSeller(sellerData._id, { ...sellerData, name });
    } catch (err) {
      console.error(err);
    }

    setIsEditing(false);
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="card shadow" style={{ borderRadius: 8 }}>
      <div className="card-body">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Name:</span>
          </div>
          <input
            className="form-control"
            type="text"
            placeholder={sellerData.name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <p>Images:</p>
        <button className="btn btn-outline-success" onClick={onSave}>
          Save
        </button>
        <button
          className="btn btn-outline-danger"
          onClick={onCancel}
          style={{ marginLeft: 10 }}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};
