export const Layout = (props: any) => {
  return (
    <div
      className="container col-md-8 col-lg-6"
      style={{ marginTop: 50, marginBottom: 10 }}
    >
      {props.children}
    </div>
  );
};
