import moment from 'moment';
import { CarEvent } from '../../../types/api';
import { MCParagraph, MCSpacing } from '../../../_shared/components';
import { MCButton } from '../../../_shared/components/MCButton';
import { MCProgress } from '../../../_shared/components/MCProgressBar';
import styles from './LatestCarEvent.module.css';

interface props {
  type: string;
  carEvent?: any;
  onEdit: (carEvent: CarEvent) => void;
  onNew: (carEvent: CarEvent) => void;
}

export const LatestCarEvent = ({ type, carEvent, onEdit, onNew }: props) => {
  const daysUntilEnd = carEvent?.dateEnd
    ? moment.unix(carEvent.dateEnd).diff(moment(), 'days')
    : 1;
  const daysSinceStart = carEvent?.dateStart
    ? moment().diff(moment.unix(carEvent.dateStart), 'days')
    : 1;
  const daysTotal =
    carEvent?.dateStart && carEvent?.dateEnd
      ? moment
          .unix(carEvent.dateEnd)
          .diff(moment.unix(carEvent.dateStart), 'days')
      : 1;
  const percentage = Math.min(daysSinceStart / daysTotal, 1);
  const isOverNotification = carEvent?.dateNotif
    ? moment().unix() > moment.unix(carEvent.dateNotif).unix()
    : false;

  return (
    <div className={styles.block}>
      <MCParagraph fontSize={18} bold>
        {type}
      </MCParagraph>
      {carEvent ? (
        <div className={styles.columnContainer}>
          <>
            <MCSpacing />
            {carEvent.issuer && (
              <MCParagraph>Asigurat de: {carEvent.issuer}</MCParagraph>
            )}
            {carEvent.cost && (
              <MCParagraph>Cost: {carEvent.cost / 100} Ron</MCParagraph>
            )}
            {carEvent.dateNotif && (
              <MCParagraph
                color={isOverNotification ? 'red' : ''}
                bold={isOverNotification}
              >
                Notificare:{' '}
                {moment.unix(carEvent.dateNotif).format('DD/MM/YYYY')}
              </MCParagraph>
            )}
          </>

          <>
            <MCSpacing />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <MCParagraph>
                {moment.unix(carEvent.dateStart).format('DD/MM/YYYY')}
              </MCParagraph>
              <MCParagraph
                color={isOverNotification ? 'red' : ''}
                bold={isOverNotification}
              >
                {moment.unix(carEvent.dateEnd).format('DD/MM/YYYY')}
              </MCParagraph>
            </div>

            <MCProgress percent={percentage * 100} />

            <div style={{ alignSelf: 'center' }}>
              <MCParagraph
                color={isOverNotification ? 'red' : ''}
                bold={isOverNotification}
              >
                Mai sunt {daysUntilEnd} zile
              </MCParagraph>
            </div>
          </>
        </div>
      ) : (
        <>
          <MCSpacing />
          <MCParagraph fontSize={18}> Nu ai adaugat {type}</MCParagraph>
        </>
      )}

      <MCSpacing />
      <MCSpacing />

      <div className={styles.buttonContainer}>
        {carEvent === undefined && (
          <MCButton
            label={`Adauga ${type}`}
            onClick={() => {
              onNew({ type });
            }}
          />
        )}
        {carEvent && (
          <MCButton
            label={`Reinoieste ${type}`}
            onClick={() => {
              onNew({ type });
            }}
          />
        )}
        {carEvent && (
          <MCButton
            label={`Editeaza ${type}`}
            onClick={() => {
              onEdit(carEvent);
            }}
          />
        )}
      </div>
    </div>
  );
};
