import { IS_PROD_ENV } from '../../utils/constants';
import { DummyAnalytics } from '../../_driver/hooks/DummyAnalytics';
import { GenericAnalytics } from '../../_driver/hooks/GenericAnalytics';

const driverAnalytics = IS_PROD_ENV
  ? new GenericAnalytics<EventMap>()
  : new DummyAnalytics<EventMap>();

export function useOwnerAnalytics() {
  return driverAnalytics;
}

type EventMap = {
  Global_RouteSwitch: {
    url: string;
  };
  Global_Intercom_Show: undefined;
  Global_Intercom_Hide: undefined;

  AuthForm_EmailInput_Type: { email: string };
  AuthForm_PasswordInput_Type: { chars: number };
  AuthForm_ForgotPass_Click: undefined;
  AuthForm_AcceptTermsAndConditions_Click: { checked: boolean };
  AuthForm_Login_Click: undefined;
  AuthForm_Register_Click: undefined;
  AuthForm_GotoRegister_Click: undefined;
  AuthForm_GotoLogin_Click: undefined;

  LeftMenu_Overview_Click: undefined;
  LeftMenu_RentalHistory_Click: undefined;
  LeftMenu_ReceptionsDeliveries_Click: undefined;
  LeftMenu_Prices_Click: undefined;
  LeftMenu_Cars_Click: undefined;
  LeftMenu_Categories_Click: undefined;
  LeftMenu_Availability_Click: undefined;
  LeftMenu_Unavailability_Click: undefined;
  LeftMenu_Invoices_Click: undefined;
  LeftMenu_Locations_Click: undefined;
  LeftMenu_Assurance_Click: undefined;
  LeftMenu_Reports_Click: undefined;
  LeftMenu_Settings_Click: undefined;
  LeftMenu_Logout_Click: undefined;

  Overview_Previous_Click: undefined;
  Overview_Next_Click: undefined;
  Overview_Car_Click: { carId: string; carMake: string; carModel: string };
  Overview_Rental_Click: { reservationId: string; reservationMongoId: string };

  Tooltip_Delete_Click: undefined;
  Tooltip_Edit_Click: undefined;
  Tooltip_Close_Click: undefined;
  Tooltip_NameInput_Type: { charCount: number };
  Tooltip_PhoneInput_Type: { charCount: number };
  Tooltip_Car_Selected: { carId: string; carMake: string; carModel: string };
  Tooltip_StartingDate_Selected: { date: string };
  Tooltip_StartingHour_Selected: { hour: string };
  Tooltip_EndingDate_Selected: { date: string };
  Tooltip_EndingHour_Selected: { hour: string };
  Tooltip_Deposit_Type: { amount: number };
  Tooltip_PaymentType_Selected: { paymentType: string };
  Tooltip_PricePerDay_Type: { amount: number };
  Tooltip_TotalPrice_Type: { amount: number };
  Tooltip_Advance_Type: { amount: number };
  Tooltip_Save_Click: undefined;
  Tooltip_CancelEdit_Click: undefined;

  RentalHistory_Search_Type: { search: string };
  RentalHistory_RentalCard_Click: {
    reservationId: string;
    reservationMongoId: string;
    carId: string;
  };
  RentalHistory_PageControl_Click: { page: number };

  ReceptionDeliveries_CardClick_Click: {
    reservationMongoId: string;
    reservationId: string;
    carId: string;
    index: number;
  };

  Prices_Tab_Click: { tabName: string };
  Prices_Car_Selected: { carId: string };
  Prices_Deposit_Type: { carId: string; amount: number };
  Prices_Extra100km_Type: { carId: string; amount: number };
  Prices_ExtraScheduleFee_Type: { carId: string; amount: number };
  Prices_ExitEuCheck_Click: { carId: string; checked: boolean };
  Prices_ExitEuFee_Type: { carId: string; amount: number };
  Prices_ExitEuDeposit_Type: { carId: string; amount: number };
  Prices_OfferDays_Selected: {
    carId: string;
    amount: number;
    offerIdx: number;
  };
  Prices_OfferPricePerDay_Type: {
    carId: string;
    amount: number;
    offerIdx: number;
  };
  Prices_OfferPricePerDayDiscount_Selected: {
    amount: string;
  };
  Prices_OfferKmPerDay_Type: {
    carId: string;
    amount: number;
    offerIdx: number;
  };
  Prices_OfferDelete_Click: { carId: string; offerIdx: number };
  Prices_MonthIncrease_Select: {
    carId: string;
    monthIdx: number;
    selected: string;
  };
  Prices_AddOffer_Click: undefined;
  Prices_GuaranteeActive_Check: { checked: boolean; name: string };
  Prices_GuaranteeValue_Type: { amount: number; name: string };
  Prices_InsuranceValue_Type: { amount: number; name: string };
  Prices_Save_Click: { carId: string };

  Cars_CarsCombo_Select: { carId: string; name: string };
  Cars_CarMakeInput_Type: { carId: string; make: string };
  Cars_CarModelInput_Type: { carId: string; model: string };
  Cars_StatusCombo_Select: { carId: string; status: string };
  Cars_RegistrationNumber_Select: { carId: string; number: string };
  Cars_YearInput_Type: { carId: string; year: string };
  Cars_PowerInput_Type: { carId: string; power: string };
  Cars_TorqueInput_Type: { carId: string; torque: string };
  Cars_DisplacementInput_Type: { carId: string; displacement: string };
  Cars_AutonomyInput_Type: { carId: string; range: string };
  Cars_TransmissionSelect_Selected: { carId: string; selected: string };
  Cars_CarTypeSelect_Selected: { carId: string; selected: string };
  Cars_TractionSelect_Selected: { carId: string; selected: string };
  Cars_SeatsSelect_Selected: { carId: string; selected: string };
  Cars_FuelSelect_Selected: { carId: string; selected: string };
  Cars_Save_Click: { carId: string };

  Categories_CategoriesCombo_Select: { carId: string; name: string };
  Categories_CategoryNameInput_Type: { carId: string; name: string };
  Categories_StatusCombo_Select: { carId: string; status: string };
  Categories_YearInput_Type: { carId: string; year: string };
  Categories_TransmissionCombo_Select: { carId: string; transmission: string };
  Categories_SeatsCombo_Select: { carId: string; seats: string };
  Categories_Save_Click: { carId: string };

  Availability_StartingDate_Selected: { date: string };
  Availability_StartingHour_Selected: { hour: string };
  Availability_EndingDate_Selected: { date: string };
  Availability_EndingHour_Selected: { hour: string };
  Availability_CancelDates_Selected: undefined;

  Unavailability_Car_Selected: {
    carId: string;
    carMake: string;
    carModel: string;
  };
  Unavailability_StartingDate_Selected: { date: string };
  Unavailability_StartingHour_Selected: { hour: string };
  Unavailability_EndingDate_Selected: { date: string };
  Unavailability_EndingHour_Selected: { hour: string };
  Unavailability_CancelDates_Selected: undefined;
  Unavailability_Reason_Selected: { selected: string };
  Unavailability_NameInput_Type: { charCount: number };
  Unavailability_PhoneInput_Type: { charCount: number };
  Unavailability_LocationInput_Type: { charCount: number };
  Unavailability_Submit_Click: undefined;

  Locations_NeighbourhoodInput_Type: { name: string };
  Locations_LocationCombo_Select: { name: string };
  Locations_FullAddressInput_Type: { name: string };
  Locations_MapPin_Pick: { lat: number; lng: number };
  Locations_DeleteLocation_Click: undefined;
  Locations_AddFixedLocation_Click: undefined;
  Locations_DeliveryZoneLocationCombo_Select: { name: string };
  Locations_DeliveryZoneRetrievalTaxCheckbox_Check: {
    checked: boolean;
  };
  Locations_DeliveryZoneRetrievalTaxInput_Type: {
    value: number;
  };
  Locations_DeleteDeliveryZone_Click: undefined;
  Locations_AddDeliveryZone_Click: undefined;
  Locations_Save_Click: undefined;

  Reports_DaysDropdown_Selected: { days: string };

  Settings_TimeBetweenRentals_Selected: { selected: string };
  Settings_ScheduleDay_Checked: { day: number; checked: boolean };
  Settings_StartingHour_Selected: { hour: string; day: number };
  Settings_EndingHour_Selected: { hour: string; day: number };
  Settings_ExtraScheduleTaxEnabled_Checked: { checked: boolean };
  Settings_ExtraScheduleInput_Type: { amount: number };
  // it seems this is always true at the moment
  // Settings_PaymentOnlineEnabled_Checked: { checked: boolean };
  Settings_PaymentOfficeEnabled_Checked: { checked: boolean };
  Settings_PaymentOffice_Selected: { selected: string };
  Settings_PaymentDeposit_Selected: { selected: string };
  Settings_Save_Click: undefined;
};
