import { useMemo } from 'react';
import {
  DAYS_TO_RENT,
  MAX_DISCOUNT,
  MIN_DISCOUNT,
} from '../../../utils/constants';
import {
  MCCard,
  MCCell,
  MCParagraph,
  MCRow,
  MCSpacing,
} from '../../../_shared/components';
import {
  InputNumericField,
  InputPriceField,
} from '../../../_shared/components/InputField/InputField';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { computeDiscount, getRecommendedDiscount } from '../../../_shared/util';
import { useOwnerAnalytics } from '../../hooks';
import styles from './OfferCard.module.css';

const DISCOUNTS = new Array(MAX_DISCOUNT)
  .fill(0)
  .map((_, index) => index + MIN_DISCOUNT)
  .map((x) => ({ value: `${x}`, name: `${x}%` }))
  .reverse();

interface OfferCardProps {
  baseIntegralPrice: number;
  daysOfRental: number;
  minDaysOfRental: number;
  maxDaysOfRental: number;
  onChangeDaysOfRental: (days: number) => void;
  integralPrice: number;
  onIntegralPriceChange: (discount: number) => void;
  kmPerDay: number;
  onKmPerDayCHange: (kmPerDay: number) => void;
  onDeleteOffer: () => void;
  unremovable: boolean;
}

export function OfferCard({
  baseIntegralPrice,
  daysOfRental,
  minDaysOfRental,
  maxDaysOfRental,
  onChangeDaysOfRental,
  integralPrice,
  onIntegralPriceChange,
  kmPerDay,
  onKmPerDayCHange,
  onDeleteOffer,
  unremovable = false,
}: OfferCardProps) {
  const { trackEvent } = useOwnerAnalytics();
  const daysOptions = useMemo(
    () =>
      DAYS_TO_RENT.filter((x) => {
        const daysNum = parseInt(x.value);
        return minDaysOfRental <= daysNum && daysNum <= maxDaysOfRental;
      }),
    [maxDaysOfRental, minDaysOfRental]
  );

  return (
    <div className={styles.container}>
      <MCCard>
        <MCRow margins={false}>
          <MCCell alignItems="flex-start" flex={1.35}>
            <MCRow alignItems="center">
              <MCParagraph>Incepand cu</MCParagraph>
              <MCSpacing vertical={false} />
              <div className={styles.numericInput}>
                <InputSelect
                  options={daysOptions}
                  value={`${daysOfRental}`}
                  onValueChange={(x) => onChangeDaysOfRental(Number(x))}
                />
              </div>
              <MCSpacing vertical={false} />
              <MCParagraph>zile</MCParagraph>
            </MCRow>
          </MCCell>
          <MCCell alignItems="flex-start">
            <MCRow alignItems="center">
              <div className={styles.numericInput}>
                <InputPriceField
                  value={integralPrice}
                  onChange={(newPrice) => {
                    const discountAmount = baseIntegralPrice - newPrice;

                    onIntegralPriceChange(baseIntegralPrice - discountAmount);
                  }}
                />
              </div>
              <MCSpacing vertical={false} />
              <MCParagraph>Ron / Zi</MCParagraph>
            </MCRow>
          </MCCell>
          <MCCell alignItems="flex-start" flex={1.5}>
            {unremovable && (
              <MCRow alignItems="center">
                <div style={{ opacity: 0.5 }}>
                  <MCParagraph>0% discount</MCParagraph>
                </div>
              </MCRow>
            )}
            {!unremovable && (
              <MCRow alignItems="center">
                <div className={styles.numericInput}>
                  <InputSelect
                    options={DISCOUNTS}
                    value={`${computeDiscount(
                      baseIntegralPrice,
                      integralPrice
                    )}`}
                    onValueChange={(x) => {
                      trackEvent('Prices_OfferPricePerDayDiscount_Selected', {
                        amount: `${x}%`,
                      });
                      const discount = Number(x);
                      const newPrice =
                        baseIntegralPrice -
                        (baseIntegralPrice * discount) / 100;
                      onIntegralPriceChange(newPrice);
                    }}
                  />
                </div>
                <MCSpacing vertical={false} />
                <MCParagraph>
                  recomandat {getRecommendedDiscount(daysOfRental)}%
                </MCParagraph>
              </MCRow>
            )}
          </MCCell>
          <MCCell>
            <div className={styles.numericInput}>
              <InputNumericField value={kmPerDay} onChange={onKmPerDayCHange} />
            </div>
            <MCSpacing vertical={false} />
            Km / Zi
          </MCCell>
          <div className={styles.deleteButton}>
            {!unremovable && (
              <MCParagraph underline onClick={onDeleteOffer} cursor={'pointer'}>
                Sterge oferta
              </MCParagraph>
            )}
          </div>
        </MCRow>
      </MCCard>
    </div>
  );
}
