import classNames from 'classnames';
import * as React from 'react';
import styles from './MCTitle.module.css';

export interface MCTitleProps {
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  centerOnMobile?: boolean;
}

export function MCTitle({
  onClick,
  children,
  centerOnMobile = false,
}: MCTitleProps) {
  return (
    <div
      onClick={onClick}
      className={classNames({ [styles.centerOnMobile]: centerOnMobile })}
    >
      <span className={styles.title}>{children}</span>
    </div>
  );
}
