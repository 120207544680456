import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../types/redux';
// @ts-ignore
import {
  getDriverAction,
  updateDriverAction,
} from '../../../redux/actions/driverAction';
import { useHistory } from 'react-router-dom';
import { InputField } from '../../../_shared/components/InputField/InputField';
import './OnboardingDriver.style.css';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { getDriver } from '../../../api/drivers';
import verifiedIcon from '../../../assets/onboarding/verified.svg';
import { OnboardingStage } from '../../../types/api';
import { MCParagraph, MCSpacing } from '../../../_shared/components';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';

interface Props {
  stage: number;
}

export const OnboardingVeriff = ({ stage }: Props) => {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [lastName, setLastName] = useState<string>(
    driver?.driver?.lastName ?? ''
  );
  const [firstName, setFirstName] = useState<string>(
    driver?.driver?.firstName ?? ''
  );

  const getDriverRecursivePolling = useCallback(async () => {
    const driver = await getDriver();
    // @ts-ignore
    if (driver?.onboarding.stage === OnboardingStage.VerifyDocuments) {
      setTimeout(() => {
        getDriverRecursivePolling();
      }, 5000);
    } else {
      dispatch(getDriverAction());
    }
  }, [dispatch]);

  const { trackEvent } = useDriverAnalytics();

  return (
    <div className="onboarding-veriff-container">
      {stage === OnboardingStage.DriverDetails ? (
        <>
          <div className="onboarding-veriff-input-container">
            <InputField
              label={'Nume'}
              type={'Surname'}
              placeholder={'Nume'}
              value={lastName}
              isValid={lastName.length === 0 || lastName.length > 2}
              errorText={'Numele trebuie sa contina peste 2 caractere'}
              isBold={true}
              onChange={(value) => {
                trackEvent('Onboarding_LastNameInput_Type', {
                  charCount: value.length,
                });
                setLastName(value);
              }}
            />
          </div>
          <MCSpacing />

          <div className="onboarding-veriff-input-container">
            <InputField
              label={'Prenume'}
              type={'Name'}
              placeholder={'Prenume'}
              value={firstName}
              isValid={firstName.length === 0 || firstName.length > 2}
              errorText={'Prenumele trebuie sa contina peste 2 caractere'}
              isBold={true}
              onChange={(value) => {
                trackEvent('Onboarding_FirstNameInput_Type', {
                  charCount: value.length,
                });
                setFirstName(value);
              }}
            />
          </div>
          <MCSpacing />
          <ActionButton
            label="Confirm"
            onClick={() => {
              trackEvent('Onboarding_VeriffConfirm_Click');
              dispatch(
                updateDriverAction({
                  ...driver.driver,
                  firstName,
                  lastName,
                  onboarding: {
                    ...driver.driver?.onboarding,
                    stage: OnboardingStage.DriverVerified,
                    isVerified: true,
                    verificationStatus: 'approved',
                  },
                })
              );
              console.log('sent');
            }}
          />
        </>
      ) : (
        <>
          <MCParagraph fontSize={20} textAlign={'center'}>
            Felicitari, esti eligibil pentru inchirieri!
          </MCParagraph>
          <MCSpacing />
          <img src={verifiedIcon} alt="verified" />
          <div style={{ height: 16 }} />
          <ActionButton
            label="Catre Catalog"
            onClick={() => {
              trackEvent('Onboarding_DoneToCatalog_Click');
              history.push('/catalog');
            }}
          />
        </>
      )}
      <MCSpacing />
      <MCSpacing />
      <MCSpacing />
    </div>
  );
};
