import './Legal.style.css';

export const PrivacyPolicy = () => {
  return (
    <div className="legal-container">
      <div className="legal-container-width">
        <p className="legal-container-text header">
          Politica de Cookies si Confidentialitate
        </p>

        <p className="legal-container-text">
          www.Masinilacheie.ro (“Websiteul”) foloseste fisiere de tip cookie
          pentru a personaliza si imbunatati experienta ta de navigare, pentru a
          se integra cu retele de socializare si a afisa reclame relevante
          intereselor tale. Te informam ca ne-am actualizat politicile de
          confidentialitate, pentru a implementa cele mai recente modificari
          propuse si impuse de Regulamentul (UE) 2016/679 privind protectia
          persoanelor fizice în ceea ce priveste prelucrarea datelor cu caracter
          personal. Protectia datelor tale este importanta pentru noi si iti
          oferim posibilitatea de a personaliza modulele cookie pe care le
          doresti din pagina de setari. De asemenea, te invitam sa aloci timpul
          necesar pentru a citi si intelege continutul Politicii de Cookie si a
          politicii de confidentialitate
        </p>

        <p className="legal-container-text sub-header">Politica de Cookies</p>
        <p className="legal-container-text bold">Drepturile utilizatorilor</p>
        <p className="legal-container-text">
          Websiteul, la fel ca și majoritatea website-urilor, folosește cookies.
          Cookies sunt date într-un standard industrial pe care o pagină web le
          transferă pe computerul dumneavoastră, temporar, pentru a permite
          adaptarea conținutului la utilizator. În acest scop, cookies
          colectează informații cu privire la comportamentul dumneavoastră
          online. Cookies sunt stocate pentru o perioadă determinată.
        </p>
        <p className="legal-container-text">
          Cele mai multe cookie-uri pot fi incluse intr-una dintre categoriile
          de mai jos:
        </p>

        <p className="legal-container-text bold">1. Strict Necesare</p>
        <p className="legal-container-text">
          Aceste cookie-uri sunt esentiale in furnizarea serviciilor solicitate
          de catre utilizatori. Fara ele, Websiteul nu poate functiona in
          conditii optime ori livra serviciile furnizate la intreaga sa
          capacitate calitativa. Cookies necesare stocate de Website sunt
          plasate de catre domeniul masinilacheie.ro, in scopul îmbunătățirii
          performantelor, avand o perioada de viata de 30 de zile.
        </p>

        <p className="legal-container-text bold">2. Performanta</p>
        <p className="legal-container-text">
          Aceste cookie-uri ne permit să oferim o experiență de înaltă calitate
          utilizatorilor noștri, precum și să măsuram audiența Websiteului.
          Informațiile colectate de aceste cookie-uri nu identifică
          utilizatorii. Acestea sunt concepute pentru a ajuta la îmbunătățirea
          funcționării Websiteului. Aceste cookie-uri colectează informații
          despre modul în care cititorii utilizează un site web, cum ar fi
          paginile cele mai populare, ce metodă de conectare între pagini este
          cea mai eficientă și dacă utilizatorii întâmpină mesaje de eroare de
          pe paginile web. Acest Website utilizează Google Analytics, un program
          de analiză a traficului de internet furnizat de Google, Inc.
          (“Google”). Google Analytics utilizează cookie-uri pentru a ajuta
          Websiteul să analizeze modul în care utilizatorii utilizează
          Websiteul. Informațiile generate de cookie despre utilizare a
          Websiteului (inclusiv adresa IP) vor fi transmise și stocate de Google
          pe serverele din Irlanda.
        </p>
        <p className="legal-container-text">
          Puteți refuza utilizarea cookie-urilor prin selectarea setărilor
          corespunzătoare din browserul dumneavoastra. Cu toate acestea,
          rețineți că, dacă faceți acest lucru, este posibil să nu puteți
          utiliza întreaga funcționalitate a Websiteului. Google va utiliza
          aceste informații în scopul evaluării utilizării de către
          dumneavoastra a Websiteului al compilării rapoartelor privind
          activitatea Websiteului și în furnizarea altor servicii legate de
          activitatea Websiteului. Google poate, de asemenea, să transmită
          aceste informații unor terțe părți, în cazul în care acest lucru este
          cerut prin lege sau în cazul în care aceste terțe părți sunt
          împuterniciți care prelucrează informațiile în numele Google. Google
          nu va asocia adresa dumneavoastra IP cu alte date deținute de Google.
        </p>

        <p className="legal-container-text bold">
          3.Cookie-urile de direcționare și de publicitate
        </p>
        <p className="legal-container-text">
          Aceste cookie-uri colectează informații despre obiceiurile tale de
          navigare pentru a face publicitatea relevantă pentru dumneavoastra.
          Ele sunt, de asemenea, folosite pentru a limita numărul de vizualizări
          ale unei reclame, precum și pentru a ajuta la măsurarea eficacității
          campaniilor publicitare.
        </p>
      </div>
    </div>
  );
};
