import { FC, useRef } from 'react';
import style from './TimelineIncome.module.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { MCSpacing } from '../../../../_shared/components';
//import { InputSelect } from '../../../../_shared/components/InputSelect/InputSelect';
import { ReportTime } from '../../../pages/Reports';

type Props = {
  reportTime: ReportTime[];
  isLoading: boolean;
  daysData: Array<number>;
};

export const TimelineIncome: FC<Props> = ({
  reportTime,
  daysData,
  isLoading,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);

  const options: Highcharts.Options = {
    title: {
      text: undefined, // disable title
    },
    chart: {
      type: 'area',
      style: {
        fontFamily: 'Roboto',
        color: '#280659',
        borderRadius: 50,
      },
      backgroundColor: '#FFFFFF',
      animation: true,
      reflow: true,
      height: '259px',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: undefined,
      },
      type: 'datetime',
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%e.%m', this.value as number);
        },
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: function () {
          return this.value + 'Ron';
        },
      },
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: '#FFFFFF',
      borderRadius: 10,
      shadow: {
        color: '#000000',
        offsetX: 0,
        offsetY: 3,
        opacity: 0.05,
        width: 5,
      },
      formatter: function () {
        return (
          moment(new Date(this.x ? this.x : '')).format('DD.MM.YYYY') +
          '<br/>' +
          this.y +
          ' Ron'
        );
      },
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      {
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#533A8B'],
            [1, '#FFFFFF'],
          ],
        },
        type: 'area',
        data: daysData,
        pointStart: Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ),
        pointInterval: 24 * 3600 * 1000, // per day
      },
    ],
  };

  return (
    <>
      <div className={style.selectContainer}>
        <span className={style.labelInputSelect}>Venituri pe zi</span>
        <div className="ml-3"></div>
      </div>
      <MCSpacing />
      <div className={style.chartContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          responsive={true}
        />
      </div>
    </>
  );
};
