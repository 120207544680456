import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  useAppCarReservationsQuery,
  useAppDisabledIntervals,
  useAppOverlappingReservationError,
  useAppTimeForm,
} from '../../../hooks';
import { Car, ReservationType } from '../../../types/api';
import { ReduxState } from '../../../types/redux';
import { BEGIN_DATE, END_DATE } from '../../../utils/constants';
import { MCSpacing, MCTwoPaneLayout } from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { InputField } from '../../../_shared/components/InputField/InputField';
import {
  InputSelect,
  InputSelectProps,
} from '../../../_shared/components/InputSelect/InputSelect';
import { MCDateForm } from '../../../_shared/components/MCDateForm';
import {
  useAppCreateUnavailabilityMutation,
  useLeftNavigatorOwnerProps,
  useOwnerAnalytics,
} from '../../hooks';
import { useAppCarsQuery } from '../../hooks/queries/useAppCarsQuery';
import styles from './Unavailability.module.css';

const RESERVATION_TYPE_OPTIONS: InputSelectProps<ReservationType>['options'] = [
  {
    name: 'Închiriere în regim propriu',
    value: ReservationType.Owner,
  },
  {
    name: 'Service',
    value: ReservationType.Service,
  },
  {
    name: 'Personal',
    value: ReservationType.Personal,
  },
];

export const Unavailability = () => {
  const props = useLeftNavigatorOwnerProps();
  const ownerId = useSelector(
    (x: ReduxState) => x.userReducer.driver?._id || ''
  );

  const { data: cars, isLoading } = useAppCarsQuery();
  const [selectedCarId, setSelectedCarId] = useState('');
  const [reservationType, setReservationType] = useState(ReservationType.Owner);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [locationDetails, setLocationDetails] = useState('');

  const toOption = useCallback((car: Car) => {
    return {
      name: `${car?.make} ${car?.model} ${car?.year} - ${car.registrationNumber}`,
      value: car._id || '',
    };
  }, []);

  useEffect(() => {
    if (!isLoading && cars?.[0]?._id) {
      setSelectedCarId(cars[0]._id);
    }
  }, [cars, isLoading, toOption]);

  const carSelectOptions = useMemo(() => {
    return (cars || []).map(toOption);
  }, [cars, toOption]);

  const {
    finalInterval,
    interval,
    clearInterval,
    setDateEnd,
    setDateBegin,
    setMinutesBegin,
    setMinutesEnd,
  } = useAppTimeForm({});

  useEffect(() => {
    clearInterval();
  }, [selectedCarId, clearInterval]);

  const { data: reservations } = useAppCarReservationsQuery({
    carId: selectedCarId,
    beginDate: BEGIN_DATE,
    endDate: END_DATE,
  });

  const { trackEvent } = useOwnerAnalytics();

  const timeRestrictions = useAppDisabledIntervals(reservations, interval);

  const { mutateAsync: createUnavailability } =
    useAppCreateUnavailabilityMutation();

  const confirmReservationError = useAppOverlappingReservationError(
    finalInterval,
    reservations || []
  );

  return (
    <MCTwoPaneLayout title="Setări indisponibilitate" {...props}>
      {!!selectedCarId && (
        <>
          <MCSpacing />
          <MCSpacing />
          <div className={styles.centeredHalfWidth}>
            <InputSelect
              options={carSelectOptions}
              value={selectedCarId}
              onValueChange={(carId) => {
                const car = cars?.find((x) => x._id === carId);
                trackEvent('Unavailability_Car_Selected', {
                  carId: car?._id || '',
                  carMake: car?.make || '',
                  carModel: car?.model || '',
                });
                setSelectedCarId(carId);
              }}
              label="Masina"
              bold
            />
          </div>
          <MCSpacing />
          <MCSpacing />
          <MCDateForm
            interval={interval}
            onDateBeginChanged={(date) => {
              trackEvent('Unavailability_StartingDate_Selected', {
                date: moment(date).format('DD/MM/YYYY'),
              });
              setDateBegin(date);
            }}
            onEndDateChanged={(date) => {
              trackEvent('Unavailability_EndingDate_Selected', {
                date: moment(date).format('DD/MM/YYYY'),
              });
              setDateEnd(date);
            }}
            onBeginMinutesChanged={(mins) => {
              trackEvent('Unavailability_StartingHour_Selected', {
                hour: moment().startOf('day').add(mins).format('HH:mm'),
              });
              setMinutesBegin(mins);
            }}
            onEndMinutesChanged={(mins) => {
              trackEvent('Unavailability_EndingHour_Selected', {
                hour: moment().startOf('day').add(mins).format('HH:mm'),
              });
              setMinutesEnd(mins);
            }}
            onClear={() => {
              trackEvent('Unavailability_CancelDates_Selected');
              clearInterval();
            }}
            {...timeRestrictions}
          />
          <MCSpacing />
          <MCSpacing />
          <div className={styles.centeredHalfWidth}>
            <InputSelect
              options={RESERVATION_TYPE_OPTIONS}
              value={reservationType}
              onValueChange={(type) => {
                trackEvent('Unavailability_Reason_Selected', {
                  selected: type,
                });
                setReservationType(type);
              }}
              label="Motivul"
              bold
            />
          </div>
          {reservationType === ReservationType.Owner && (
            <>
              <MCSpacing />
              <MCSpacing />
              <div className={styles.centeredHalfWidth}>
                <InputField
                  label={'Nume (opțional)'}
                  value={name}
                  placeholder={'Nume'}
                  onChange={(name) => {
                    trackEvent('Tooltip_NameInput_Type', {
                      charCount: name.length,
                    });
                    setName(name);
                  }}
                />
              </div>
              <MCSpacing />
              <MCSpacing />
              <div className={styles.centeredHalfWidth}>
                <InputField
                  label={'Telefon (opțional)'}
                  value={phoneNumber}
                  placeholder={'Număr de telefon'}
                  onChange={(phoneNumber) => {
                    trackEvent('Unavailability_PhoneInput_Type', {
                      charCount: name.length,
                    });
                    setPhoneNumber(phoneNumber);
                  }}
                  type="tel"
                />
              </div>
              <MCSpacing />
              <MCSpacing />
              <div className={styles.centeredHalfWidth}>
                <InputField
                  label={'Detalii Locație (opțional)'}
                  value={locationDetails}
                  placeholder={'Locație'}
                  onChange={(locationDetails) => {
                    trackEvent('Unavailability_LocationInput_Type', {
                      charCount: locationDetails.length,
                    });
                    setLocationDetails(locationDetails);
                  }}
                />
              </div>
            </>
          )}
          <MCSpacing />
          <MCSpacing />
          <div className={styles.center}>
            <ActionButton
              label="Setează indisponibilitate"
              disabled={!(finalInterval.dateBegin && finalInterval.dateEnd)}
              onClick={async () => {
                try {
                  trackEvent('Unavailability_Submit_Click');

                  if (!finalInterval.dateBegin || !finalInterval.dateEnd) {
                    toast.error('Intervalul de timp nu este specificat');
                    return;
                  }

                  if (confirmReservationError) {
                    toast.error(confirmReservationError);
                    return;
                  }

                  const dateBegin = moment(finalInterval.dateBegin).unix();
                  const dateEnd = moment(finalInterval.dateEnd).unix();

                  const reservationDetails = {
                    car: selectedCarId,
                    reservationType,
                    name,
                    phoneNumber,
                    locationDetails,
                    owner: ownerId,
                    dateBegin,
                    dateEnd,
                  };

                  await createUnavailability(reservationDetails);

                  toast('Indisponibilitate setată cu success');

                  setName('');
                  setPhoneNumber('');
                  setLocationDetails('');
                  clearInterval();
                } catch (error) {
                  toast.error('A apărut o eroare');
                }
              }}
            />
          </div>
        </>
      )}
    </MCTwoPaneLayout>
  );
};
