import moment from 'moment';
import { useMemo } from 'react';
import { Reservation } from '../../types/api';

export function useAppDisabledIntervals(
  reservationsWithoutMoment: Array<Reservation> = [],
  interval: { dateBegin?: Date; dateEnd?: Date }
) {
  const reservations = useMemo(() => {
    return reservationsWithoutMoment
      .filter((x) => x.dateBegin && x.dateEnd)
      .map((x) => ({
        ...x,
        bufferDateBegin: moment.unix(x.bufferDateBegin),
        bufferDateEnd: moment.unix(x.bufferDateEnd),
      }));
  }, [reservationsWithoutMoment]);

  const disabledBeginIntervals = useMemo(() => {
    return reservations?.map(({ bufferDateBegin, bufferDateEnd }) => ({
      begin: bufferDateBegin.clone().startOf('day').toDate(),
      end: bufferDateEnd.clone().endOf('day').subtract(1, 'day').toDate(),
    }));
  }, [reservations]);

  const disabledEndIntervals = useMemo(() => {
    return reservations?.map(({ bufferDateBegin, bufferDateEnd }) => ({
      begin: bufferDateBegin.clone().startOf('day').add(1, 'day').toDate(),
      end: bufferDateEnd.clone().endOf('day').toDate(),
    }));
  }, [reservations]);

  const minMinutesBegin = useMemo(() => {
    if (!interval.dateBegin) {
      return;
    }

    const found = reservations?.find((x) =>
      x.bufferDateEnd.isSame(interval.dateBegin, 'day')
    );
    if (!found) {
      return;
    }

    const { bufferDateEnd } = found;
    return bufferDateEnd.diff(bufferDateEnd.clone().startOf('day'), 'minutes');
  }, [interval.dateBegin, reservations]);

  const maxMinutesEnd = useMemo(() => {
    if (!interval.dateEnd) {
      return;
    }

    const found = reservations?.find((x) =>
      x.bufferDateBegin.isSame(interval.dateEnd, 'day')
    );
    if (!found) {
      return;
    }

    const { bufferDateBegin } = found;
    return bufferDateBegin.diff(
      bufferDateBegin.clone().startOf('day'),
      'minutes'
    );
  }, [interval.dateEnd, reservations]);

  return {
    disabledBeginIntervals,
    disabledEndIntervals,
    minMinutesBegin,
    maxMinutesEnd,
  };
}
