import { ReceptionDayData } from '../../../types/receptionDeliveries';
import styles from './ReceptionDay.module.css';

interface Props {
  dayData: ReceptionDayData;
}

export const ReceptionsDay = ({ dayData }: Props) => {
  return (
    <div className={styles.mainContainer}>
      <div
        className={
          dayData.isSelected ? styles.badgeSelected : styles.badgeNormal
        }
      />
      <div>
        <p className={styles.dayOfTheWeek}>{dayData.dayOfTheWeek}</p>
        <p className={styles.date}>{dayData.date}</p>
      </div>
    </div>
  );
};
