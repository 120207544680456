import {
  MCCheckbox,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import styles from './CheckoutCompanyInvoice.module.css';

interface CheckoutInvoiceProps {
  isCompanyInvoiceChecked: boolean;
  companyName: string;
  companyCUI: string;
  toggleIsCompanyInvoiceChecked: () => void;
  setCompanyName: (v: string) => void;
  setCompanyCUI: (v: string) => void;
  isValidCompanyName: boolean;
  isValidCompanyCUI: boolean;
}

export function CheckoutInvoice({
  isCompanyInvoiceChecked,
  companyName,
  companyCUI,
  toggleIsCompanyInvoiceChecked,
  setCompanyName,
  setCompanyCUI,
  isValidCompanyName,
  isValidCompanyCUI,
}: CheckoutInvoiceProps) {
  const { trackEvent } = useDriverAnalytics();

  return (
    <div className={styles.container}>
      <MCCheckbox
        value={isCompanyInvoiceChecked}
        onChange={toggleIsCompanyInvoiceChecked}
      >
        <MCParagraph fontSize={18}>
          Doresc factura pe persoana juridica
        </MCParagraph>
      </MCCheckbox>
      <MCSpacing />
      {isCompanyInvoiceChecked && (
        <>
          <InputField
            isBold
            labelFontSize={16}
            value={companyName}
            isValid={isValidCompanyName}
            onChange={(name) => {
              trackEvent('Checkout_CompanyName_Input', {
                charCount: name.length,
              });
              setCompanyName(name);
            }}
            label="Nume"
            placeholder="Nume"
          />
          <MCSpacing />
          <InputField
            isBold
            labelFontSize={16}
            value={companyCUI}
            isValid={isValidCompanyCUI}
            onChange={(cui) => {
              trackEvent('Checkout_CompanyCUI_Input', {
                charCount: cui.length,
              });
              setCompanyCUI(cui);
            }}
            label="CUI"
            placeholder="CUI"
          />
          <MCSpacing />
        </>
      )}
    </div>
  );
}
