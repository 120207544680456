// import * as React from 'react';
import { Car } from '../../types/api';
import { useAppCarReservationsQuery } from '../../hooks';
import { useCarAvailability } from './useCarAvailability';
import moment from 'moment';

export function useCarAvailabilityHourIntervals({
  car,
  interval,
}: {
  car: Car;
  interval: { dateBegin?: Date; dateEnd?: Date };
}) {
  const { data: beginDayReservations, isLoading: loadingBegin } =
    useAppCarReservationsQuery(
      {
        carId: car._id,
        beginDate: moment(interval.dateBegin).startOf('day').toDate(),
        endDate: moment(interval.dateBegin).endOf('day').toDate(),
      },
      { enabled: !!interval.dateBegin && car.type === 'car' }
    );
  const { data: endDayReservations, isLoading: loadingEnd } =
    useAppCarReservationsQuery(
      {
        carId: car._id,
        beginDate: moment(interval.dateEnd).startOf('day').toDate(),
        endDate: moment(interval.dateEnd).endOf('day').toDate(),
      },
      { enabled: !!interval.dateEnd && car.type === 'car' }
    );

  const beginDayReservation = beginDayReservations?.[0];
  const endDayReservation = endDayReservations?.[0];

  return useCarAvailability(
    interval.dateBegin && interval.dateEnd && !loadingBegin && !loadingEnd
      ? {
          teamSettings: car.teamSettings,
          beginDayReservation: beginDayReservation,
          endDayReservation: endDayReservation,
          beginReservationDate: interval.dateBegin,
          endReservationDate: interval.dateEnd,
        }
      : undefined
  );
}
