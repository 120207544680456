import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import {
  svgCarType,
  svgLocation,
  svgPhone,
  pngUser,
  pngDeliveryArrow,
  pngReceptionArrow,
  svgWrench,
} from '../../../assets';
import {
  useAppCarReservationsQuery,
  useAppDisabledIntervals,
  useAppTimeForm,
} from '../../../hooks';
import { useAppComputeOffer } from '../../../hooks/util/useAppComputeOffer';
import { Car, Reservation, ReservationType } from '../../../types/api';
import {
  BACKGROUND_COLOR,
  BEGIN_DATE,
  END_DATE,
} from '../../../utils/constants';
import {
  LinkText,
  MCCell,
  MCDateForm,
  MCListItem,
  MCModal,
  MCParagraph,
  MCRow,
  MCSpacing,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { MCSvgImage } from '../../../_shared/components/MCSvgImage';
import { formatDateUnix } from '../../../_shared/util';
import { useOwnerAnalytics, useReservationDetails } from '../../hooks';
import { useAppDeleteReservationMutation } from '../../hooks/mutations/useAppDeleteReservationMutation';
import { useAppEditUnavailabilityMutation } from '../../hooks/mutations/useAppEditReservationMutation';
import { CarSelect } from '../CarSelect/CarSelect';
import styles from './ReservationTooltip.module.css';

const TITLE: Record<ReservationType, string> = {
  [ReservationType.Driver]: 'Detalii inchiriere',
  [ReservationType.Owner]: 'Detalii inchiriere',
  [ReservationType.Service]: 'Detalii service',
  [ReservationType.Personal]: 'Detalii eveniment personal',
};

export interface ReservationTooltipProps {
  reservation?: Reservation;
  marginTop: number;
  onClearSelection: () => void;
}

export function ReservationTooltip({
  reservation,
  onClearSelection,
  marginTop,
}: ReservationTooltipProps) {
  const [isEdit, setIsEdit] = useState(false);

  const doClearSelection = useCallback(() => {
    setIsEdit(false);
    onClearSelection();
  }, [onClearSelection]);

  return (
    <>
      {reservation && !isEdit && (
        <ReservationTooltipImpl
          onClearSelection={doClearSelection}
          reservation={reservation}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          marginTop={marginTop}
        />
      )}
      {reservation && isEdit && (
        <ReservationTooltipImpl
          onClearSelection={doClearSelection}
          reservation={reservation}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          marginTop={marginTop}
        />
      )}
    </>
  );
}

export interface ReservationTooltipImplProps {
  reservation: Reservation;
  marginTop: number;
  onClearSelection: () => void;
  isEdit: boolean;
  setIsEdit: (v: boolean) => void;
}

function ReservationTooltipImpl({
  onClearSelection,
  reservation,
  marginTop,
  isEdit,
  setIsEdit,
}: ReservationTooltipImplProps) {
  const [disableClickOutside, setDisableClickOutside] = useState(false);
  const { trackEvent } = useOwnerAnalytics();

  const { mutateAsync: updateReservationAsync } =
    useAppEditUnavailabilityMutation();

  const {
    clearInterval,
    interval,
    finalInterval,
    setDateEnd,
    setDateBegin,
    setMinutesBegin,
    setMinutesEnd,
  } = useAppTimeForm({ from: reservation.dateBegin, to: reservation.dateEnd });

  const data = useReservationDetails(
    reservation,
    finalInterval.dateBegin
      ? moment(finalInterval.dateBegin).unix()
      : undefined,
    finalInterval.dateEnd ? moment(finalInterval.dateEnd).unix() : undefined
  );

  const computedOffer = useAppComputeOffer({
    car: data.car,
    guaranteeType: 'integral',
    dateBegin: finalInterval.dateBegin
      ? finalInterval.dateBegin.getTime() / 1000
      : 0,
    dateEnd: finalInterval.dateEnd ? finalInterval.dateEnd.getTime() / 1000 : 0,
  });

  const [guard, setGuard] = useState(true);
  useEffect(() => {
    if (guard) {
      return;
    }
    data.setRentalPrice(computedOffer.basePrice / 100);
    setGuard(true);
  }, [guard, computedOffer, data]);

  const { data: reservations } = useAppCarReservationsQuery({
    carId: data.car._id,
    beginDate: BEGIN_DATE,
    endDate: END_DATE,
    excludeId: reservation._id,
  });
  const timeRestrictions = useAppDisabledIntervals(reservations, interval);

  const { mutateAsync: deleteReservation } = useAppDeleteReservationMutation();

  const visible = !!reservation;

  const isDriver = reservation?.reservationType === ReservationType.Driver;
  const isOwner = reservation?.reservationType === ReservationType.Owner;
  const isDriverOrOwner = isDriver || isOwner;

  const onDelete = useCallback(() => {
    if (!reservation) {
      return;
    }

    setDisableClickOutside(true);
    confirmAlert({
      message: 'Esti sigur ca vrei sa stergi acest eveniment ?',
      buttons: [
        {
          label: 'Da',
          onClick: async () => {
            setDisableClickOutside(false);
            onClearSelection();
            await deleteReservation(reservation);
          },
        },
        {
          label: 'Nu',
          onClick: () => {
            setDisableClickOutside(false);
          },
        },
      ],
    });
  }, [deleteReservation, onClearSelection, reservation]);

  return (
    <>
      {data && visible && (
        <MCModal
          visible={visible}
          onClose={() => {
            trackEvent('Tooltip_Close_Click');
            onClearSelection();
          }}
          disableClickOutside={disableClickOutside}
        >
          <div
            className={styles.container}
            style={{
              backgroundColor:
                BACKGROUND_COLOR[
                  reservation.reservationType || ReservationType.Driver
                ],
              marginTop: Math.max(marginTop, 188),
            }}
          >
            <MCParagraph bold>
              {TITLE[reservation.reservationType || ReservationType.Driver]}
            </MCParagraph>
            <MCSpacing />

            {reservation.reservationType === ReservationType.Service && (
              <>
                <MCListItem marginBottom={0} image={svgWrench} imageSize={13}>
                  <MCParagraph>Service</MCParagraph>
                </MCListItem>
                <MCSpacing />
              </>
            )}
            {reservation.reservationType === ReservationType.Personal && (
              <>
                <MCListItem marginBottom={0} image={pngUser} imageSize={13}>
                  <MCParagraph>Motiv personal</MCParagraph>
                </MCListItem>
                <MCSpacing />
              </>
            )}
            {isDriverOrOwner && (
              <>
                {isEdit ? (
                  <InputField
                    icon={pngUser}
                    value={data.name}
                    onChange={(name) => {
                      trackEvent('Tooltip_NameInput_Type', {
                        charCount: name.length,
                      });
                      data.setName(name);
                    }}
                    inline
                  />
                ) : (
                  <MCListItem marginBottom={0} image={pngUser} imageSize={13}>
                    <MCParagraph>{data.name}</MCParagraph>
                  </MCListItem>
                )}
                <MCSpacing />
              </>
            )}
            {isDriverOrOwner && (
              <>
                {isEdit ? (
                  <InputField
                    icon={svgPhone}
                    value={data.phoneNumber}
                    onChange={(phoneNumber) => {
                      trackEvent('Tooltip_PhoneInput_Type', {
                        charCount: phoneNumber.length,
                      });
                      data.setPhoneNumber(phoneNumber);
                    }}
                    inline
                  />
                ) : (
                  <MCListItem marginBottom={0} image={svgPhone} imageSize={13}>
                    <MCParagraph>{data.phoneNumber} </MCParagraph>
                  </MCListItem>
                )}
                <MCSpacing />
              </>
            )}

            {isEdit ? (
              <CarSelect
                selectedCar={data.car as never as Car}
                onChangeCar={(car: Car) => {
                  trackEvent('Tooltip_Car_Selected', {
                    carId: car._id,
                    carMake: car.make,
                    carModel: car.model,
                  });
                  clearInterval();
                  data.setCar(car);
                }}
              />
            ) : (
              <MCListItem marginBottom={0} image={svgCarType} imageSize={13}>
                <MCParagraph>
                  {`${reservation.car?.make} ${reservation.car?.model} ${reservation.car?.engine.transmission} ${reservation.car?.year} - ${reservation.car?.registrationNumber}`}{' '}
                </MCParagraph>
              </MCListItem>
            )}
            <MCSpacing />
            {isDriverOrOwner && (
              <>
                {isEdit ? (
                  <InputField
                    icon={svgLocation}
                    value={data.location}
                    onChange={data.setLocation}
                    inline
                  />
                ) : (
                  <MCListItem
                    marginBottom={0}
                    image={svgLocation}
                    imageSize={13}
                  >
                    <MCParagraph>{data.location} </MCParagraph>
                  </MCListItem>
                )}
                <MCSpacing />
              </>
            )}
            {!isEdit && (
              <>
                <MCRow>
                  <MCCell>
                    <MCParagraph bold>ID: </MCParagraph>
                  </MCCell>
                  <MCCell>
                    <MCParagraph>{reservation.reservationId}</MCParagraph>
                  </MCCell>
                </MCRow>
              </>
            )}
            {!isEdit && (
              <>
                <MCRow>
                  <MCCell>
                    <MCParagraph bold>Predare</MCParagraph>
                  </MCCell>
                  <MCCell>
                    <MCSvgImage size={14} image={pngDeliveryArrow} />
                    <MCParagraph>{`${formatDateUnix(
                      reservation.dateBegin
                    )}`}</MCParagraph>
                  </MCCell>
                </MCRow>
                <MCRow>
                  <MCCell>
                    <MCParagraph bold>Primire</MCParagraph>
                  </MCCell>
                  <MCCell>
                    <MCSvgImage size={14} image={pngReceptionArrow} />
                    <MCParagraph>{`${formatDateUnix(
                      reservation.dateEnd
                    )}`}</MCParagraph>
                  </MCCell>
                </MCRow>
              </>
            )}

            {isEdit && (
              <MCDateForm
                compact
                interval={interval}
                onDateBeginChanged={(date) => {
                  trackEvent('Tooltip_StartingDate_Selected', {
                    date: moment(date).format('DD/MM/YYYY'),
                  });
                  setGuard(false);
                  setDateBegin(date);
                }}
                onEndDateChanged={(date) => {
                  trackEvent('Tooltip_EndingDate_Selected', {
                    date: moment(date).format('DD/MM/YYYY'),
                  });
                  setGuard(false);
                  setDateEnd(date);
                }}
                onBeginMinutesChanged={(mins) => {
                  trackEvent('Tooltip_StartingHour_Selected', {
                    hour: moment().startOf('day').add(mins).format('HH:mm'),
                  });
                  setGuard(false);
                  setMinutesBegin(mins);
                }}
                onEndMinutesChanged={(mins) => {
                  trackEvent('Tooltip_EndingHour_Selected', {
                    hour: moment().startOf('day').add(mins).format('HH:mm'),
                  });
                  setGuard(false);
                  setMinutesEnd(mins);
                }}
                {...timeRestrictions}
              />
            )}

            {isDriverOrOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Depozit</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit ? (
                    <InputField
                      value={`${data.deposit}`}
                      type="number"
                      onChange={(value: string) => {
                        trackEvent('Tooltip_Deposit_Type', {
                          amount: Number(value),
                        });
                        return data.setDeposit(Number(value));
                      }}
                    />
                  ) : (
                    <MCParagraph>{data.deposit}</MCParagraph>
                  )}
                  <MCSpacing vertical={false} />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriverOrOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Pret / zi</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit ? (
                    <InputField
                      value={`${data.pricePerDay}`}
                      type="number"
                      onChange={(value: string) => {
                        trackEvent('Tooltip_PricePerDay_Type', {
                          amount: Number(value),
                        });
                        return data.setPricePerDay(Number(value));
                      }}
                    />
                  ) : (
                    <MCParagraph>
                      {reservation.reservationType === ReservationType.Driver
                        ? (reservation.pricing?.pricePerDay || 0) / 100
                        : data.pricePerDay}
                    </MCParagraph>
                  )}
                  <MCSpacing vertical={false} />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriverOrOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Numar zile</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit && <MCSpacing vertical={false} />}
                  <MCParagraph>{data.numberOfDays}</MCParagraph>
                  <MCSpacing
                    full={isEdit}
                    vertical={!isEdit ? false : undefined}
                  />
                  <MCParagraph> Zile</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriverOrOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Pret de baza</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit ? (
                    <InputField
                      value={`${data.price}`}
                      type="number"
                      onChange={(value: string) => {
                        trackEvent('Tooltip_TotalPrice_Type', {
                          amount: Number(value),
                        });
                        return data.setRentalPrice(Number(value));
                      }}
                    />
                  ) : (
                    <MCParagraph>
                      {reservation.reservationType === ReservationType.Driver
                        ? (reservation.pricing?.basePrice || 0) / 100
                        : data.rentalPrice}
                    </MCParagraph>
                  )}
                  <MCSpacing vertical={false} />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriverOrOwner &&
              !isEdit &&
              data.reservation.taxes &&
              data.reservation.taxes.length > 0 &&
              data.reservation.taxes.map((t) => {
                return (
                  <MCRow key={t.name}>
                    <MCCell>
                      <MCParagraph bold>{t.name}</MCParagraph>
                    </MCCell>
                    <MCCell>
                      <MCParagraph>
                        {t.name === 'Taxa extra 100 km' &&
                        data.reservation.extraOptions?.extraHundredKm
                          ? (data.reservation.extraOptions.extraHundredKm *
                              t.value) /
                            100
                          : t.value / 100}
                      </MCParagraph>
                      <MCSpacing vertical={false} />
                      <MCParagraph>Ron</MCParagraph>
                    </MCCell>
                  </MCRow>
                );
              })}
            {isDriverOrOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Total optiuni</MCParagraph>
                </MCCell>
                <MCCell>
                  <MCParagraph>
                    {(reservation.pricing?.optionsPrice || 0) / 100}
                  </MCParagraph>
                  <MCSpacing vertical={false} />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriver && !isEdit && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Pret total</MCParagraph>
                </MCCell>
                <MCCell>
                  <MCParagraph>
                    {(reservation.pricing.totalPrice || 0) / 100}
                  </MCParagraph>
                  <MCSpacing vertical={false} />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Pret total</MCParagraph>
                </MCCell>
                <MCCell>
                  {
                    (console.log({
                      pricing: reservation.pricing,
                      price: data.price,
                    }),
                    (<></>))
                  }
                  <MCParagraph>
                    {(data.price || 0) +
                      reservation.pricing.taxesPrice / 100 +
                      reservation.pricing.optionsPrice / 100}
                  </MCParagraph>
                  <MCSpacing vertical={false} />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriver && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Avans</MCParagraph>
                </MCCell>
                <MCCell>
                  <MCParagraph>
                    {(reservation.pricing?.customerPaidAmount || 0) / 100}
                  </MCParagraph>
                  <MCSpacing vertical={false} />
                  <MCParagraph> Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Avans</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit ? (
                    <InputField
                      value={`${data.advance}`}
                      type="number"
                      onChange={(value: string) => {
                        trackEvent('Tooltip_Advance_Type', {
                          amount: Number(value),
                        });
                        return data.setAdvance(Number(value));
                      }}
                    />
                  ) : (
                    <MCParagraph>{data.advance}</MCParagraph>
                  )}
                  <MCSpacing vertical={false} />
                  <MCParagraph> Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriver && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Rest de plata</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit && <MCSpacing vertical={false} />}
                  <MCParagraph>
                    {(reservation.pricing?.customerLeftToPayAmount || 0) / 100}
                  </MCParagraph>
                  <MCSpacing
                    full={isEdit}
                    vertical={!isEdit ? false : undefined}
                  />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriver && !!reservation.pricing.discount && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>
                    Discount (oferit de Masini la Cheie)
                  </MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit && <MCSpacing vertical={false} />}
                  <MCParagraph>
                    {reservation.pricing.discount / 100}
                  </MCParagraph>
                  <MCSpacing
                    full={isEdit}
                    vertical={!isEdit ? false : undefined}
                  />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isOwner && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Rest de plata</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit && <MCSpacing vertical={false} />}
                  <MCParagraph>{data.leftToPay}</MCParagraph>
                  <MCSpacing
                    full={isEdit}
                    vertical={!isEdit ? false : undefined}
                  />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriver && (
              <MCRow>
                <MCCell>
                  <MCParagraph bold>Suma de facturat</MCParagraph>
                </MCCell>
                <MCCell>
                  {isEdit && <MCSpacing vertical={false} />}
                  <MCParagraph>
                    {(
                      (reservation.pricing.supplierInvoiceValue || 0) / 100
                    ).toFixed(2)}
                  </MCParagraph>
                  <MCSpacing
                    full={isEdit}
                    vertical={!isEdit ? false : undefined}
                  />
                  <MCParagraph>Ron</MCParagraph>
                </MCCell>
              </MCRow>
            )}
            {isDriverOrOwner && !isEdit && (
              <MCRow alignItems="flex-start">
                <MCCell>
                  <MCParagraph bold>Taxe</MCParagraph>
                </MCCell>
                <MCCell>
                  <MCParagraph>
                    <>
                      {data.reservation.taxes &&
                        (data.reservation.taxes || [])
                          .map((t) => `${t.name.replace('Taxa ', '')}`)
                          .join(', ')}
                    </>
                  </MCParagraph>
                  <MCSpacing vertical={false} />
                </MCCell>
              </MCRow>
            )}
            {isDriverOrOwner && !isEdit && (
              <MCRow alignItems="flex-start">
                <MCCell>
                  <MCParagraph bold>Optiuni</MCParagraph>
                </MCCell>
                <MCCell>
                  <MCParagraph>
                    <>
                      {data.reservation.options &&
                        (data.reservation.options || [])
                          .map(
                            (t) =>
                              `${t.name.replace('Taxa ', '')}${
                                t.quantity > 1 ? ` x ${t.quantity}` : ''
                              }`
                          )
                          .join(', ')}
                    </>
                  </MCParagraph>
                  <MCSpacing vertical={false} />
                </MCCell>
              </MCRow>
            )}
            <MCSpacing />
            {reservation.reservationType !== ReservationType.Driver && (
              <>
                {!isEdit && (
                  <div className={styles.edit}>
                    <ActionButton
                      label="Editeaza"
                      onClick={() => {
                        trackEvent('Tooltip_Edit_Click');
                        setIsEdit(true);
                      }}
                    />
                  </div>
                )}
                {isEdit && (
                  <div className={styles.edit}>
                    <ActionButton
                      label="Salveaza"
                      onClick={async () => {
                        try {
                          trackEvent('Tooltip_Save_Click');
                          if (
                            !finalInterval.dateBegin ||
                            !finalInterval.dateEnd
                          ) {
                            return;
                          }

                          const pricingData =
                            reservation.reservationType ===
                            ReservationType.Owner
                              ? {
                                  pricing: {
                                    ...reservation.pricing,
                                    basePrice: data.price * 100,
                                    totalPrice:
                                      data.price * 100 +
                                      reservation.pricing.taxesPrice +
                                      reservation.pricing.optionsPrice,
                                    pricePerDay: data.pricePerDay * 100,
                                    customerPrice:
                                      data.price * 100 +
                                      reservation.pricing.taxesPrice +
                                      reservation.pricing.optionsPrice,
                                    customerPaidAmount: data.advance * 100,
                                    customerLeftToPayAmount:
                                      data.leftToPay * 100,
                                    marketplaceRevenue: 0,
                                    supplierRevenue: 0,
                                    supplierInvoiceValue: 0,
                                  },
                                }
                              : {};

                          await updateReservationAsync({
                            _id: reservation._id,
                            car: data.car._id,
                            dateBegin: moment(finalInterval.dateBegin).unix(),
                            dateEnd: moment(finalInterval.dateEnd).unix(),
                            deposit: data.deposit * 100,
                            paymentMethod: data.paymentMethod,
                            locationDetails: data.location,
                            name: data.name,
                            phoneNumber: data.phoneNumber,
                            ...pricingData,
                          });
                          onClearSelection();
                        } catch (error) {
                          toast.error('A aparut o eroare');
                        }
                      }}
                    />
                  </div>
                )}
                <MCSpacing />
                <LinkText
                  center
                  onClick={() => {
                    if (isEdit) {
                      trackEvent('Tooltip_CancelEdit_Click');
                      setIsEdit(false);
                      return;
                    }
                    trackEvent('Tooltip_Delete_Click');

                    onDelete();
                  }}
                >
                  {isEdit ? 'Anuleaza' : 'Sterge'}
                </LinkText>
              </>
            )}
          </div>
        </MCModal>
      )}
    </>
  );
}
