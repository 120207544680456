import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import './SupportPhone.style.css';

export const SupportPhone = () => {
  const { trackEvent } = useDriverAnalytics();

  return (
    <div className="support-phone-fixed-bottom-container">
      <div className="support-phone-fixed-bottom-center">
        <a
          className="support-phone-label"
          href="tel:+40723473933"
          onClick={() => {
            trackEvent('Global_Phone_Click');
          }}
        >
          Informatii: +40 723 473 933
        </a>
      </div>
    </div>
  );
};
