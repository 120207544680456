import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCars } from '../../../api/cars';
import { Layout } from '../../components/Layout';
import { Car } from '../../../types/api';
import { CAR_DEACTIVATED, CAR_PENDING } from '../../../utils/constants';

export const CarPage = () => {
  const { id } = useParams<{ id: string }>();

  const [isEditing, setIsEditing] = useState(id === 'new');
  const [carData, setCarData] = useState<Car | undefined>(undefined);

  useEffect(() => {
    const getAsyncCar = async () => {
      const car = (await getCars({ _id: id }))[0];
      setCarData(car);
    };

    if (id !== 'new') {
      getAsyncCar();
    } else {
      if (isEditing === false) {
        setIsEditing(true);
      }
    }
  }, [id, isEditing]);

  return (
    <Layout>
      {isEditing ? (
        <CarEdit />
      ) : (
        <CarView carData={carData!} setIsEditing={setIsEditing} />
      )}
    </Layout>
  );
};

interface carProps {
  carData: Car;
  setIsEditing: any;
}
const CarView = ({ carData, setIsEditing }: carProps) => {
  const styleRow = getCarStatusStyle(carData?.status);
  return (
    <div className={`card shadow ${styleRow}`} style={{ borderRadius: 8 }}>
      <div className="card-body">
        <p>Owner: {carData?.owner}</p>
        <p>Make: {carData?.make}</p>
        <p>Model: {carData?.model}</p>
        <p>Year: {carData?.year}</p>
        <p>Transmission: {carData?.engine.transmission}</p>
        <p>Power: {carData?.engine.power}</p>
        <p>Displacement: {carData?.engine.displacement}</p>
        <p>Location: {carData?.location[0].location.name}</p>
        <p>Status: {carData?.status}</p>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

const CarEdit = () => {
  return <div className="card shadow" style={{ borderRadius: 8 }}></div>;
};

export const getCarStatusStyle = (status?: string): string => {
  let rowType = 'list-group-item-success';
  if (status === CAR_PENDING) {
    rowType = 'list-group-item-warning';
  }
  if (status === CAR_DEACTIVATED) {
    rowType = 'list-group-item-danger';
  }
  return rowType;
};
