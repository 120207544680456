import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from '../../../_shared/components/Layout';
import { ReduxState } from '../../../types/redux';
import { LoadingOutlined } from '@ant-design/icons';
import { postAccountStatus } from '../../../api/sellers';
import { toast } from 'react-toastify';

export const StripeCallback = () => {
  const auth = useSelector((state: ReduxState) => state.userReducer);

  useEffect(() => {
    doAccountUpdate();
  }, [auth]);

  const doAccountUpdate = async () => {
    const res = await postAccountStatus();
    if (res?.updated?.stripeSeller) {
      toast.success('Succesfully linked account to Stripe');
    } else {
      toast.error('Try again to link account to Stripe');
    }

    setTimeout(() => {
      window.location.href = '/profile';
    }, 2000);
  };

  return (
    <Layout>
      <div className="d-flex justify-content-center p-5">
        <LoadingOutlined className="h1 p-5 text-danger" />
      </div>
    </Layout>
  );
};
