import moment from 'moment';
import { useState } from 'react';
import { useAppTimeForm } from '../../../hooks';
import { CarEvent } from '../../../types/api';
import {
  MCDateForm,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import styles from './ModalCarEvent.module.css';

interface props {
  carEvent: CarEvent;
  onSave: (e: CarEvent) => void;
  onDelete: (e: CarEvent) => void;
}

const minimumDate = new Date('January 1, 2020 00:00:00');
const maximumDate = new Date('January 1, 2030 00:00:00');

export const ModalCarEvent = ({ carEvent, onSave, onDelete }: props) => {
  const { interval, setDateEnd, setDateBegin, setMinutesBegin, setMinutesEnd } =
    useAppTimeForm({ from: carEvent?.dateStart, to: carEvent?.dateEnd });

  const [cost, setCost] = useState(carEvent?.cost ? carEvent?.cost / 100 : 0);
  const [issuer, setIssuer] = useState(carEvent?.issuer);
  const [notificationDays, setNotificationDays] = useState('14');

  return (
    <div className={styles.modalContainer}>
      <MCParagraph fontSize={24} bold>
        {carEvent?.type}
      </MCParagraph>
      <MCSpacing />
      <MCSpacing />

      <MCParagraph fontSize={18}>Valabilitate:</MCParagraph>
      <MCSpacing />
      <MCDateForm
        compact
        interval={interval}
        minDate={minimumDate}
        maxDate={maximumDate}
        onDateBeginChanged={(date) => {
          setDateBegin(date);
        }}
        onEndDateChanged={(date) => {
          setDateEnd(date);
        }}
        onBeginMinutesChanged={(mins) => {
          setMinutesBegin(mins);
        }}
        onEndMinutesChanged={(mins) => {
          setMinutesEnd(mins);
        }}
        disableHourPicker
      />
      <MCSpacing />
      <MCSpacing />

      <InputField
        label="Cost asigurare / operatiune"
        value={`${cost ?? 0}`}
        onChange={(e) => setCost(parseInt(e))}
      />
      <MCSpacing />
      <MCSpacing />
      <InputField
        label="Asigurator / Furnizor"
        value={issuer}
        onChange={(e) => setIssuer(e)}
      />
      <MCSpacing />
      <MCSpacing />
      <InputSelect
        label="Notificare "
        value={notificationDays}
        options={[
          { value: '7', name: 'Cu 7 zile inainte de expirare' },
          { value: '14', name: 'Cu 14 zile inainte de expirare' },
          { value: '30', name: 'Cu 30 zile inainte de expirare' },
        ]}
        onValueChange={(e) => {
          setNotificationDays(e);
        }}
      />

      <MCSpacing />
      <MCSpacing />

      <div style={{ alignSelf: 'center' }}>
        <ActionButton
          disabled={
            interval.dateBegin === undefined || interval.dateEnd === undefined
          }
          onClick={() => {
            onSave({
              ...carEvent,
              cost: cost * 100,
              issuer,
              dateStart: interval.dateBegin
                ? Math.floor(interval?.dateBegin?.getTime() / 1000)
                : undefined,
              dateEnd: interval.dateEnd
                ? Math.floor(interval?.dateEnd?.getTime() / 1000)
                : undefined,
              dateNotif: interval.dateEnd
                ? moment
                    .unix(Math.floor(interval?.dateEnd?.getTime() / 1000))
                    .subtract(notificationDays, 'days')
                    .unix()
                : undefined,
              daysTotal:
                interval.dateBegin && interval.dateEnd
                  ? moment(interval.dateEnd).diff(
                      moment(interval.dateBegin),
                      'days'
                    )
                  : 0,
            });
          }}
          label={'Salveaza'}
        />
      </div>
      <MCSpacing />

      {carEvent._id && (
        <div style={{ alignSelf: 'center' }}>
          <MCParagraph
            color="red"
            bold
            cursor="pointer"
            onClick={() => {
              onDelete(carEvent);
            }}
          >
            Sterge
          </MCParagraph>
        </div>
      )}
    </div>
  );
};
