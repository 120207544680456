import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import dropDownArrow from '../../../assets/drop-down-arrow.svg';
import { useOnClickOutside } from '../../../utils/useOnClickOutside';
import './CustomHourPicker.style.css';

function generateIntervals() {
  const halfHourMinutes = 30;
  const minutesInADay = halfHourMinutes * 2 * 24;

  const options: Array<{ label: string; minutes: number }> = [];

  for (let minutes = 0; minutes < minutesInADay; minutes += halfHourMinutes) {
    const label = moment()
      .startOf('day')
      .add(minutes, 'minutes')
      .format('HH:mm');
    options.push({ label, minutes });
  }

  return options;
}

const MAX_MINUTES = 23 * 60 + 30;
const HOUR_OPTIONS = generateIntervals();

interface Props {
  onMinutesChange: (time: number) => void;
  minutes: number;
  minMinutes?: number;
  maxMinutes?: number;
  showHourLabel?: boolean;
}

export const CustomHourPicker = ({
  onMinutesChange,
  minutes,
  minMinutes = 0,
  maxMinutes = MAX_MINUTES,
  showHourLabel = true,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedMinutes = useMemo(() => {
    return Math.floor(minutes / 30) * 30;
  }, [minutes]);

  useEffect(() => {
    if (selectedMinutes < minMinutes) {
      onMinutesChange(minMinutes);
    } else if (selectedMinutes > maxMinutes) {
      onMinutesChange(maxMinutes);
    }
  }, [minMinutes, maxMinutes, selectedMinutes, onMinutesChange]);

  const selectedHour = useMemo(() => {
    return (
      HOUR_OPTIONS.find(({ minutes }) => minutes === selectedMinutes) ||
      HOUR_OPTIONS[0]
    );
  }, [selectedMinutes]);

  const displayedOptions = useMemo(
    () =>
      HOUR_OPTIONS.filter(
        (x) => minMinutes <= x.minutes && x.minutes <= maxMinutes
      ),
    [maxMinutes, minMinutes]
  );

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsModalOpen(false));

  const renderHours = () => {
    return displayedOptions.map((option, index) => {
      return (
        <p
          key={index}
          className="input-hour-time-label-modal"
          /*
          // @ts-ignore */
          onClick={() => {
            onMinutesChange(option.minutes);
            setIsModalOpen(false);
          }}
        >
          {option.label}
        </p>
      );
    });
  };

  return (
    <div
      className="input-hour-container"
      onClick={() => {
        if (isModalOpen === false) {
          setIsModalOpen(true);
        }
      }}
    >
      {showHourLabel && <p className="input-hour-label">Ora</p>}
      <p className="input-hour-time-label">{selectedHour.label}</p>
      <img src={dropDownArrow} alt="dropdown" />
      {isModalOpen && (
        // @ts-ignore
        <div ref={ref} className="input-hour-absolute-container">
          <div className="input-hour-modal-container">{renderHours()}</div>
        </div>
      )}
    </div>
  );
};
