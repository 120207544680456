import { StatusColorType } from '../../../utils/constants';
import styles from './MCStatusDot.module.css';

export interface MCStatusDotProps {
  statusColor?: StatusColorType;
  marginLeft?: number;
  marginRight?: number;
}

export function MCStatusDot({
  statusColor = StatusColorType.STATUS_COLOR_GREEN,
  marginLeft,
  marginRight,
}: MCStatusDotProps) {
  return (
    <div
      className={styles.dot}
      style={{ backgroundColor: statusColor, marginLeft, marginRight }}
    />
  );
}
