import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getLocations, updateLocation } from '../../../api/locations';
import { Location } from '../../../types/api';
import InputField from '../../../_shared/components/Input/InputField';
import { Layout } from '../../../_shared/components/Layout';

export const LocationPage = () => {
  // @ts-ignore
  const { id } = useParams();

  const [isEditing, setIsEditing] = useState(id === 'new');
  const [locationData, setLocationData] = useState<Location | undefined>(
    undefined
  );

  useEffect(() => {
    const getAsyncLocation = async () => {
      const location = (await getLocations({ _id: id }))[0];
      setLocationData(location);
    };

    if (id !== 'new') {
      getAsyncLocation();
    } else {
      if (isEditing === false) {
        setIsEditing(true);
      }
    }
  }, [isEditing, id]);

  return locationData ? (
    <Layout>
      {isEditing ? (
        <LocationEdit
          locationData={locationData!}
          setIsEditing={setIsEditing}
        />
      ) : (
        <LocationView
          locationData={locationData!}
          setIsEditing={setIsEditing}
        />
      )}
    </Layout>
  ) : (
    <></>
  );
};

const LocationView = ({ locationData, setIsEditing }: any) => {
  return (
    <div className="card shadow" style={{ borderRadius: 8 }}>
      <div className="card-body">
        <p>Location: {locationData?.name}</p>
        <p>Latitude: {locationData?.lat}</p>
        <p>Longitude: {locationData?.long}</p>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

const LocationEdit = ({ locationData, setIsEditing }: any) => {
  const history = useHistory();

  const [locationName, setLocationName] = useState(locationData?.name ?? '');
  const [lat, setLat] = useState(locationData?.lat ?? '');
  const [long, setLong] = useState(locationData?.long ?? '');

  const onSave = async () => {
    try {
      if (locationData !== undefined) {
        await updateLocation(locationData._id, {
          ...locationData,
          name: locationName,
          lat,
          long,
        });
      } else {
        // await createLocation({ name: locationName, lat, long });
        history.push(`/locations`);
      }
      setIsEditing(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onCancel = () => {
    if (locationData === undefined) {
      history.push('/locations');
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div className="card shadow" style={{ borderRadius: 8 }}>
      <div className="card-body">
        <InputField
          label={'Name:'}
          placeholder={locationName}
          onChange={setLocationName}
        />
        <InputField label={'Latitude:'} placeholder={lat} onChange={setLat} />
        <InputField
          label={'Longitude:'}
          placeholder={long}
          onChange={setLong}
        />
        <button className="btn btn-outline-success" onClick={onSave}>
          Save
        </button>
        <button
          className="btn btn-outline-danger"
          onClick={onCancel}
          style={{ marginLeft: 10 }}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};
