import { useCallback } from 'react';
import { makeVar, useReactiveVar } from 'react-reactive-var';

type Currency = 'RON' | 'EUR';

const CURRENCY_LOCALSTORAGE_KEY = 'CURRENCY_LOCALSTORAGE_KEY';

export const currencyVar = makeVar<Currency>(
  (localStorage.getItem(CURRENCY_LOCALSTORAGE_KEY) as Currency | null) === 'RON'
    ? 'RON'
    : 'EUR'
);

export const useCurrencyFormatter = () => {
  const currency = useReactiveVar(currencyVar);
  const formatCurrency = useCallback(
    (value: number, minFractionDigits: number = 0) => {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minFractionDigits,
      }).format(value / (currency === 'EUR' ? 500 : 100));
    },
    [currency]
  );

  return {
    currency,
    setCurrency: useCallback((currency: Currency) => {
      currencyVar(currency);
      localStorage.setItem(CURRENCY_LOCALSTORAGE_KEY, currency);
    }, []),
    formatCurrency,
  };
};
