import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  loginDriverAction,
  registerDriverAction,
  resetPasswordAction,
} from '../../redux/actions/driverAction';
import { ReduxState } from '../../types/redux';
import { PlatformType, PLATFORM_TYPE } from '../../utils/constants';
import { emailIsValid } from '../../utils/validation';
import { useDriverAnalytics } from '../../_driver/hooks/useDriverAnalytics';
import { MCCheckboxAcceptTermsAndConds } from '../components';
import { ActionButton } from '../components/ActionButton/ActionButton';
import { InputField } from '../components/InputField/InputField';
import { Separator } from '../components/Separator';
import './AuthForm.style.css';

interface Props {
  isLogin: boolean;
  redirectRouteIfAuthenticated?: string;
}

export const AuthForm = ({ isLogin, redirectRouteIfAuthenticated }: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const { trackEvent: trackEventDriver } = useDriverAnalytics();
  const { trackEvent: trackEventOwner } = useDriverAnalytics();

  const trackEvent =
    PLATFORM_TYPE === PlatformType.Driver ? trackEventDriver : trackEventOwner;

  const driverModel = useSelector((state: ReduxState) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (email.length) {
      setIsValidEmail(emailIsValid(email));
    }
  }, [email]);

  useEffect(() => {
    if (password.length > 8) {
      setIsValidPassword(true);
    } else {
      if (password.length) {
        setIsValidPassword(false);
      }
    }
  }, [password]);

  const { isLoading, mutate: doAuthAction } = useMutation(
    async ({
      isLogin,
      email,
      password,
    }: {
      isLogin: boolean;
      email: string;
      password: string;
    }) => {
      await dispatch(
        isLogin
          ? loginDriverAction(email, password)
          : registerDriverAction(email, password),
      );
    },
  );

  const onSubmit = async () => {
    trackEvent(isLogin ? 'AuthForm_Login_Click' : 'AuthForm_Register_Click');

    let error = '';
    if (!isLogin && !isAcceptedTerms) {
      error = 'Nu ai acceptat termenii si conditiile';
    }

    if (!isLogin && !isValidPassword) {
      error = 'Parola nu este valida';
    }

    if (!isLogin && !isValidEmail) {
      error = 'Emailul nu este valid';
    }

    if (error.length === 0) {
      doAuthAction({
        isLogin,
        email,
        password,
      });
    } else {
      toast.error(error);
    }
  };

  if (driverModel.isAuthenticated) {
    return <Redirect to={redirectRouteIfAuthenticated} />;
  }

  return (
    <div className="login-form-container">
      <p className="login-form-headline">
        {isLogin ? 'Autentificare' : 'Înregistrare'}
      </p>
      <div className="login-form-separator">
        <Separator />
      </div>
      <div className="login-form-input-container">
        <InputField
          label={isLogin ? 'Email' : '*Email'}
          placeholder={'Email'}
          type={'email'}
          isValid={isValidEmail}
          onChange={(value) => {
            setEmail(value);
            trackEvent('AuthForm_EmailInput_Type', { email: value });
          }}
        />
      </div>
      <div className="login-form-input-container">
        <InputField
          label={isLogin ? 'Parola' : '*Creeaza parola - minim 8 caractere'}
          placeholder={'Parola'}
          type={'password'}
          isValid={isValidPassword}
          onChange={(value) => {
            setPassword(value);
            trackEvent('AuthForm_PasswordInput_Type', { chars: value.length });
          }}
        />
      </div>

      {isLogin ? (
        <p
          className="login-form-forget-password"
          onClick={() => {
            trackEvent('AuthForm_ForgotPass_Click');

            if (isLogin && !isValidEmail) {
              toast.error('Emailul nu este valid');
              return;
            }

            try {
              dispatch(resetPasswordAction(email));
            } catch (error) {
              toast.error('A aparut o eroare');
            }
          }}
        >
          am uitat parola
        </p>
      ) : (
        <div className="login-form-accept-terms-container">
          <MCCheckboxAcceptTermsAndConds
            value={isAcceptedTerms}
            onChange={(checked) => {
              setIsAcceptedTerms(checked);
              trackEvent('AuthForm_AcceptTermsAndConditions_Click', {
                checked,
              });
            }}
          />
        </div>
      )}

      <div className="login-form-action-button-container ">
        <ActionButton
          isLoading={isLoading}
          disabled={isLoading}
          label={isLogin ? 'Autentifică-te' : 'Creeaza cont'}
          onClick={onSubmit}
        />
      </div>

      {isLogin ? (
        <p
          className="login-form-no-account-label"
          onClick={() => {
            history.push('/register');
            trackEvent('AuthForm_GotoRegister_Click');
          }}
        >
          Nu ai cont?{' '}
          <span className="login-form-no-account-label red">
            Inregistreaza-te
          </span>
        </p>
      ) : (
        <p
          className="login-form-no-account-label"
          onClick={() => {
            history.push('/login');
            trackEvent('AuthForm_GotoLogin_Click');
          }}
        >
          Ai deja cont?{' '}
          <span className="login-form-no-account-label red">
            Autentifica-te
          </span>
        </p>
      )}
    </div>
  );
};
