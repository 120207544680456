import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import Footer from '../_shared/components/Footer/Footer';
import { StripeSuccess } from './components/Stripe/StripeSuccess';
import { StripeCancel } from './components/Stripe/StripeCancel';
import { Onboarding } from '../_driver/pages/Onboarding/Onboarding';
import { Profile } from './pages/Profile/Profile';
import { StripeCallback } from './components/Stripe/StripeCallback';
import { LoginPage } from '../_shared/Auth/Auth';
import { PrivateRoute } from '../routing/PrivateRoute';
import { DRIVER, OWNER } from '../utils/constants';
import { NotFoundPage } from '../_shared/pages/NotFound';
import { NavbarDriver } from './components/NavbarDriver/NavbarDriver';
import { SupportPhone } from './components/SupportPhone/SupportPhone';
import { TermsAndConditions } from '../_shared/pages/Legal/TermsAndConditions';
import { PrivacyPolicy } from '../_shared/pages/Legal/PrivacyPolicy';
import { Gdpr } from '../_shared/pages/Legal/Gdpr';
import { ScrollToTop } from '../_shared/components/ScrollToTop';
import { TripsPage } from './pages/TripsPage';
import { Checkout } from './pages/Checkout';
import { useDriverAnalytics } from './hooks/useDriverAnalytics';
import { useEffect } from 'react';

function RouteListener() {
  const { trackEvent } = useDriverAnalytics();
  const location = useLocation();

  const url = location.pathname + location.search;

  useEffect(() => {
    trackEvent('Global_RouteSwitch', { url });
  }, [trackEvent, url]);

  return null;
}

function HrefRedirect({ to }: { to: string }) {
  window.location.href = to;
  return null;
}

function PathRedirect({
  linkBuilder,
}: {
  linkBuilder: (param: string) => string;
}) {
  //@ts-ignore
  const { path } = useParams();
  if (path) {
    window.location.href = linkBuilder(path);
  }
  return null;
}

export const DriverRouting = () => {
  return (
    <BrowserRouter>
      <div style={{ zIndex: 1000000 }}>
        <ScrollToTop />
        <NavbarDriver />
        <RouteListener />
        <Switch>
          <Route
            exact={true}
            path="/"
            component={() => <HrefRedirect to="https://masinilacheie.ro/" />}
          />
          <Route exact={true} path="/login">
            <LoginPage isLogin={true} />
          </Route>
          <Route exact={true} path="/register">
            <LoginPage isLogin={false} />
          </Route>
          <Route
            exact={true}
            path="/catalog"
            component={() => (
              <HrefRedirect to="https://masinilacheie.ro/catalog" />
            )}
          />
          <Route
            exact={true}
            path={'/catalog/inchirieri-auto-:path'}
            component={() => (
              <PathRedirect
                linkBuilder={(path) =>
                  `https://masinilacheie.ro/inchirieri-auto-${path}`
                }
              />
            )}
          />
          <Route
            exact={true}
            path={'/inchirieri-auto-:path'}
            component={() => (
              <PathRedirect
                linkBuilder={(path) =>
                  `https://masinilacheie.ro/inchirieri-auto-${path}`
                }
              />
            )}
          />
          <Route
            exact={true}
            path={'/car/:path'}
            component={() => (
              <PathRedirect
                linkBuilder={(path) =>
                  `https://masinilacheie.ro/car/${path}`
                }
              />
            )}
          />
          <Route path="/checkout" component={Checkout} />
          <PrivateRoute
            exact={true}
            path="/profile"
            allowed={[DRIVER]}
            component={Profile}
          />
          <PrivateRoute
            exact={true}
            path="/onboarding"
            allowed={[DRIVER]}
            component={Onboarding}
          />
          <PrivateRoute
            exact={true}
            path="/stripe/callback"
            allowed={[OWNER, DRIVER]}
            component={StripeCallback}
          />
          <PrivateRoute
            exact={true}
            path="/reservations"
            allowed={[OWNER, DRIVER]}
            component={TripsPage}
          />
          <Route exact={true} path="/success" component={StripeSuccess} />
          <Route
            exact={true}
            path="/stripe/success/:id"
            component={StripeSuccess}
          />
          <Route
            exact={true}
            path="/stripe/cancel/:id"
            component={StripeCancel}
          />
          <Route
            exact={true}
            path="/terms_and_conditions"
            component={TermsAndConditions}
          />
          <Route
            exact={true}
            path="/privacy_policy"
            component={PrivacyPolicy}
          />
          <Route exact={true} path="/gdpr" component={Gdpr} />
          <Route component={NotFoundPage} />
        </Switch>
        <SupportPhone />
        <Footer />
      </div>
    </BrowserRouter>
  );
};
