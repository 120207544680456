import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Car, CarSettings } from '../../../types/api';
import {
  MCSpacing,
  MCTabGroup2,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { useAppUpdateCarSettingsMutation } from '../../hooks/mutations/useAppUpdateCatSettingsMutation';
import { useAppCarsQuery } from '../../hooks/queries';
import { useOwnerQuery } from '../../hooks/queries/useOwnerQuery';
import { PriceGuarantees } from './PriceGuarantees';
import styles from './Prices.module.css';
import { PriceSettings } from './PriceSettings';

export function Prices() {
  const props = useLeftNavigatorOwnerProps();

  const { trackEvent } = useOwnerAnalytics();

  const tabs = useMemo(
    () => [
      { name: 'Setari de preturi', value: 0 },
      { name: 'Garantii (Setari avansate)', value: 1 },
    ],
    []
  );
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const { mutate: updateSettings, isLoading: isSaving } =
    useAppUpdateCarSettingsMutation();

  const { data: cars, isLoading: isCarLoading } = useAppCarsQuery({
    types: ['car', 'category'],
  });
  const [selectedCarId, setSelectedCarId] = useState('');

  const { data: owner, isLoading: isOwnerLoading } = useOwnerQuery();

  const isLoading = isCarLoading || isOwnerLoading;

  const toOption = useCallback((car: Car) => {
    return {
      name:
        car.type === 'category'
          ? car.categoryDetails.name
          : `${car?.make} ${car?.model} ${car?.year} - ${car.registrationNumber}`,
      value: car._id || '',
    };
  }, []);

  const [extraScheduleTax, setExtraScheduleTax] = useState(0);
  const hasData = useRef(false);
  useEffect(() => {
    if (hasData.current) {
      return;
    }

    if (!isLoading) {
      hasData.current = true;

      if (cars?.[0]?._id) {
        setSelectedCarId(cars[0]._id);
      }

      if (owner?.teamSettings.schedule.isExtraScheduleTax) {
        setExtraScheduleTax(0);
      }
    }
  }, [
    cars,
    isLoading,
    owner?.teamSettings.schedule.isExtraScheduleTax,
    toOption,
  ]);

  const carSelectOptions = useMemo(() => {
    return (cars || []).map(toOption);
  }, [cars, toOption]);

  const [basePrices, setBasePrices] = useState<CarSettings>();
  const [initialPrices, setInitialPrices] = useState<CarSettings>();

  useEffect(() => {
    if (selectedCarId !== '' && cars?.length) {
      const found = cars.find((x) => x._id === selectedCarId);
      if (found) {
        setBasePrices(JSON.parse(JSON.stringify(found.settings)));
        setInitialPrices(JSON.parse(JSON.stringify(found.settings)));
      }
    }
  }, [selectedCarId, cars]);

  const isModified = useMemo(() => {
    return JSON.stringify(initialPrices) !== JSON.stringify(basePrices);
  }, [basePrices, initialPrices]);

  if (!basePrices) {
    return null;
  }

  return (
    <MCTwoPaneLayout title="Preturi" {...props}>
      <MCSpacing />
      <MCSpacing />

      <div className={styles.halfWidth}>
        <InputSelect
          options={carSelectOptions}
          value={selectedCarId}
          onValueChange={(carId) => {
            trackEvent('Prices_Car_Selected', { carId });
            setSelectedCarId(carId);
          }}
          label="Masina"
          bold
        />
      </div>
      <MCSpacing />
      <MCSpacing />

      <MCTabGroup2<number>
        tabs={tabs}
        activeTab={activeTab}
        onActiveTabChanged={(tab) => {
          trackEvent('Prices_Tab_Click', { tabName: tab.name });
          setActiveTab(tab);
        }}
        capitalize={false}
      />

      <MCSpacing />

      {activeTab.value === 0 && (
        <PriceSettings
          carId={selectedCarId}
          basePrices={basePrices}
          setBasePrices={setBasePrices}
          extraScheduleFee={
            owner?.teamSettings.schedule.isExtraScheduleTax
              ? extraScheduleTax
              : 0
          }
          onExtraScheduleFeeChange={
            owner?.teamSettings.schedule.isExtraScheduleTax
              ? setExtraScheduleTax
              : () => {}
          }
        />
      )}
      {activeTab.value === 1 && (
        <PriceGuarantees
          basePrices={basePrices}
          setBasePrices={setBasePrices}
        />
      )}
      <div className={styles.saveButtonContainer}>
        <ActionButton
          disabled={!isModified || isSaving}
          isLoading={isSaving}
          onClick={() => {
            trackEvent('Prices_Save_Click', { carId: selectedCarId });

            const carSettings = {
              ...basePrices,
              rentalOffers: [...basePrices.rentalOffers],
              minDaysRental: basePrices.rentalOffers[0].days,
            };

            updateSettings({ carId: selectedCarId, carSettings });
          }}
          label="Salveaza"
        />
      </div>
    </MCTwoPaneLayout>
  );
}
