import { useQuery } from 'react-query';
import { getReservations } from '../../api/reservations';

export function useAppReservationsQuery(
  filter: any,
  { size, page }: { size?: number; page?: number } = {}
) {
  return useQuery(['owner-reservations', { filter, size, page }], async () => {
    return (await getReservations(filter, size, page)).reservations;
  });
}
