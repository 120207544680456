import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import tripsImg from '../../../assets/trips.svg';
import profileImage from '../../../assets/profile/default-profile-image.svg';
import { logout } from '../../../redux/actions/driverAction';
import { LIST_CAR_LINK } from '../../../utils/constants';
import {
  MCGenericLeftNavigator,
  MCGenericLeftNavigatorProps,
} from '../../../_shared/components';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import { useHistory } from 'react-router-dom';

export const LeftNavigator = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useDriverAnalytics();

  const options = useMemo((): MCGenericLeftNavigatorProps['options'] => {
    return [
      {
        text: 'Profilul meu',
        route: '/profile',
        image: profileImage,
        onEvent: () => trackEvent('LeftMenu_Profile_Click'),
      },
      {
        text: 'Inchirieri',
        route: '/reservations',
        image: tripsImg,
        onEvent: () => trackEvent('LeftMenu_Rentals_Click'),
      },
      {
        text: 'Listeaza-ti masina',
        onClick: () => window.open(LIST_CAR_LINK, '_blank'),
        isRed: true,
        onEvent: () => trackEvent('LeftMenu_ListCar_Click'),
      },
    ];
  }, [trackEvent]);

  const history = useHistory();

  return (
    <MCGenericLeftNavigator
      options={options}
      onLogout={async () => {
        trackEvent('LeftMenu_Logout_Click');
        await dispatch(logout());
        history.push('/login');
      }}
    />
  );
};
