import { ReceptionEvent } from '../../../types/receptionDeliveries';
import { EventCard } from './EventCard';
import styles from './EventsContainer.module.css';
import { ReceptionEventType } from './ReceptionEventType';

interface Props {
  events: Array<ReceptionEvent>;
  onEventPressed: (event: ReceptionEvent) => void;
}

export const EventsContainer = ({ events, onEventPressed }: Props) => {
  return (
    <div className={styles.mainContainer}>
      {events.map((event) => {
        return (
          <div className={styles.eventContainer} key={event.comparator}>
            <ReceptionEventType
              key={event.dateEnd}
              type={event.type}
              date={event.type === 'DELIVERY' ? event.dateBegin : event.dateEnd}
            />
            <EventCard
              eventData={event}
              key={event.dateBegin}
              onClick={() => onEventPressed(event)}
            />
          </div>
        );
      })}
    </div>
  );
};
