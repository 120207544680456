import { useCallback, useEffect, useState } from 'react';
import {
  Reservation,
  ReservationType,
  Location,
  PaymentMethod,
} from '../../../types/api';
import { computeNumberOfDaysForRental } from '../../../utils/mathUtil';

export function useReservationDetails(
  reservation: Reservation,
  dateBegin?: number,
  dateEnd?: number
) {
  const [car, setCar] = useState(reservation.car);

  const [phoneNumber, setPhoneNumber] = useState(reservation.phoneNumber || '');

  const [name, setName] = useState(reservation.name || '');

  const [location, setLocation] = useState(
    (reservation.reservationType === ReservationType.Driver
      ? (reservation?.location as Location)?.name
      : reservation.locationDetails) || ''
  );

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    reservation.paymentMethod || PaymentMethod.Card
  );

  const [deposit, setDeposit] = useState(reservation?.pricing?.deposit || 0);

  const numberOfDays =
    dateBegin && dateEnd ? computeNumberOfDaysForRental(dateBegin, dateEnd) : 1;

  const [price, setPrice] = useState(reservation.pricing.basePrice || 0);

  const taxesPrice = reservation.pricing.taxesPrice;
  const optionsPrice = reservation.pricing.optionsPrice;

  const [rentalPrice, setRentalPrice] = useState(
    price > 0 ? price - taxesPrice - optionsPrice : 0
  );

  const [pricePerDay, setPricePerDay] = useState(rentalPrice / numberOfDays);

  const [advance, setAdvance] = useState(
    reservation.pricing.customerPaidAmount || 0
  );
  const leftToPay = Math.max(price + taxesPrice + optionsPrice - advance, 0);

  useEffect(() => {
    setPricePerDay(rentalPrice / numberOfDays);
  }, [numberOfDays, rentalPrice]);

  const onDepositChange = (value: number) => {
    setDeposit(100 * value);
  };

  const onRentalPriceChange = (value: number) => {
    setPrice(100 * value + taxesPrice + optionsPrice);
    setRentalPrice(100 * value);
    setPricePerDay((100 * value) / numberOfDays);
  };

  const onPricePerDayChange = (value: number) => {
    setPrice(100 * value * numberOfDays + taxesPrice + optionsPrice);
    setRentalPrice(100 * value * numberOfDays);
    setPricePerDay(100 * value);
  };

  const onAdvanceChange = (value: number) => {
    setAdvance(100 * value);
  };

  const formatPrice = useCallback(
    (x: number) => Number((x / 100).toFixed(2)),
    []
  );

  return {
    car,
    phoneNumber,
    name,
    location,
    paymentMethod,
    deposit: formatPrice(deposit),
    taxes: formatPrice(taxesPrice),
    rentalPrice: formatPrice(rentalPrice),
    price: formatPrice(price),
    numberOfDays,
    pricePerDay: formatPrice(pricePerDay),
    advance: formatPrice(advance),
    leftToPay: formatPrice(leftToPay),
    reservation,
    setCar,
    setName,
    setPhoneNumber,
    setLocation,
    setPaymentMethod,
    setDeposit: onDepositChange,
    setRentalPrice: onRentalPriceChange,
    setPricePerDay: onPricePerDayChange,
    setAdvance: onAdvanceChange,
  };
}
