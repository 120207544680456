import {
  ReceptionDayData,
  ReceptionEvent,
} from '../../../types/receptionDeliveries';
import { EventsContainer } from './EventsContainer';
import { ReceptionsDay } from './ReceptionDay';
import styles from './ReceptionDayContainer.module.css';

interface Props {
  dayEvents: {
    dayData: ReceptionDayData;
    events: Array<ReceptionEvent>;
  };
  onEventPressed: (event: ReceptionEvent) => void;
}

export const ReceptionsDayContainer = ({
  dayEvents,
  onEventPressed,
}: Props) => {
  return (
    <div className={styles.mainContainer}>
      <ReceptionsDay dayData={dayEvents.dayData} />
      <EventsContainer
        events={dayEvents.events}
        onEventPressed={onEventPressed}
      />
    </div>
  );
};
