import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getDrivers, updateDriver } from '../../../api/drivers';
import { Layout } from '../../../_shared/components/Layout';
import { Driver } from '../../../types/api';
import InputField from '../../../_shared/components/Input/InputField';

export const UserPage = () => {
  // @ts-ignore
  const { id } = useParams();

  const [isEditing, setIsEditing] = useState(id === 'new');
  const [userData, setUserData] = useState<Driver | undefined>(undefined);

  useEffect(() => {
    const getAsyncUser = async () => {
      const user = (await getDrivers({ _id: id }))[0];
      setUserData(user);
    };

    if (id !== 'new') {
      getAsyncUser();
    } else {
      if (isEditing === false) {
        setIsEditing(true);
      }
    }
  }, [isEditing, id]);

  return (
    <Layout>
      {isEditing ? (
        <UserEdit userData={userData!} setIsEditing={setIsEditing} />
      ) : (
        <UserView userData={userData!} setIsEditing={setIsEditing} />
      )}
    </Layout>
  );
};

const UserView = ({ userData, setIsEditing }: any) => {
  return (
    <div className="card shadow" style={{ borderRadius: 8 }}>
      <div className="card-body">
        <p>auth email: {userData?.auth?.email}</p>
        <p>email: {userData?.email}</p>
        <p>phone: {userData?.phone}</p>
        <p>first name: {userData?.firstName}</p>
        <p>last name: {userData?.lastName}</p>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

const UserEdit = ({ userData, setIsEditing }: any) => {
  const history = useHistory();

  const [auth, setAuth] = useState(userData?.auth?._id ?? '');
  const [email, setEmail] = useState(userData?.email ?? '');
  const [phone, setPhone] = useState(userData?.phone ?? '');
  const [firstName, setFirstName] = useState(userData?.firstName ?? '');
  const [lastName, setLastName] = useState(userData?.lastName ?? '');

  const onSave = async () => {
    try {
      if (userData !== undefined) {
        await updateDriver(userData._id, {
          ...userData,
          auth,
          email,
          phone,
          firstName,
          lastName,
        });
      } else {
        history.push(`/users`);
      }
      setIsEditing(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onCancel = () => {
    if (userData === undefined) {
      history.push('/users');
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div className="card shadow" style={{ borderRadius: 8 }}>
      <div className="card-body">
        <InputField
          label={'auth:'}
          placeholder={'auth._id'}
          onChange={setAuth}
        />
        <InputField label={'email:'} placeholder={email} onChange={setEmail} />
        <InputField label={'phone:'} placeholder={phone} onChange={setPhone} />
        <InputField
          label={'first name:'}
          placeholder={firstName}
          onChange={setFirstName}
        />
        <InputField
          label={'last name:'}
          placeholder={lastName}
          onChange={setLastName}
        />

        <button className="btn btn-outline-success" onClick={onSave}>
          Save
        </button>
        <button
          className="btn btn-outline-danger"
          onClick={onCancel}
          style={{ marginLeft: 10 }}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};
