import { Car } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/constants';

export const getCars = async (filter: any): Promise<Array<Car>> => {
  try {
    return (
      await axios.get(`${BASE_URL}/car`, {
        params: {
          filter,
        },
      })
    ).data.found.cars as Car[];
  } catch (err) {
    console.error('Failed to get cars');
    console.error(err);
    throw err;
  }
};

export const createCar = async (carData: Car): Promise<Car> => {
  try {
    return (await axios.post(`${BASE_URL}/car`, carData)).data.car;
  } catch (err) {
    console.error('Failed to post car');
    console.error(err);
    throw err;
  }
};

export const updateCarSettings = async (
  carId: string,
  carData: Car['settings']
) => {
  try {
    return (await axios.put(`${BASE_URL}/car/${carId}/settings`, carData)).data;
  } catch (err) {
    console.error('Failed to put car');
    console.error(err);
    throw err;
  }
};

export const updateCar = async (carId: string, carData: Car) => {
  try {
    return (await axios.put(`${BASE_URL}/car/${carId}`, carData)).data;
  } catch (err) {
    console.error('Failed to put car');
    console.error(err);
    throw err;
  }
};

export const updateCarStatus = async (carId: string, carStatus: string) => {
  try {
    return (await axios.put(`${BASE_URL}/car/${carId}`, { status: carStatus }))
      .data;
  } catch (err) {
    console.error('Failed to put car');
    console.error(err);
    throw err;
  }
};

export const deleteCar = async (carId: string) => {
  try {
    return await axios.delete(`${BASE_URL}/car/${carId}`);
  } catch (err) {
    console.error('Failed to delete car');
    console.error(err);
    throw err;
  }
};
