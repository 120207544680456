import moment from 'moment';
import 'moment/locale/ro';
import { MonthDay } from './computeMonthDays';
import './CustomDatePickerCalendar.style.css';

const weekDays = moment.weekdaysShort(true);

interface Props {
  onDateClick: any;
  monthDays?: Array<Array<MonthDay>>;
}

export const CustomDatePickerCalendar = ({ onDateClick, monthDays }: Props) => {
  return (
    <>
      <div className="date-picker-modal-week-container">
        {weekDays.map((day, index) => {
          return (
            <p className="date-picker-modal-week-cell" key={index}>
              {day}
            </p>
          );
        })}
      </div>
      <div className="date-picker-modal-calendar">
        {monthDays?.map((week, index) => {
          return (
            <div key={index} className="date-picker-modal-week-container">
              {week.map((day) => {
                return (
                  <p
                    key={day.date.getTime()}
                    className={
                      day.dateLabel
                        ? day.isSelectable
                          ? 'date-picker-modal-week-cell date'
                          : 'date-picker-modal-week-cell disabled'
                        : 'date-picker-modal-week-cell'
                    }
                    onClick={(e) => {
                      if (day.dateLabel && day.isSelectable) {
                        onDateClick(day.date);
                      }
                    }}
                  >
                    {day.dateLabel}
                  </p>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
