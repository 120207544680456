import { FC } from 'react';
import { useAppWindowSizeInfo } from '../../../../hooks';
import { SelectedCarsInfoReport } from '../../../../types/api';
import { MCSpacing } from '../../../../_shared/components';
import { ReportTime } from '../../../pages/Reports/Reports';
import { InfoPerCarBody } from '../InfoPerCarBody/InfoPerCarBody';
import { InfoPerCarBodyMobile } from '../InfoPerCarBodyMobile/InfoPerCarBodyMobile';
import { InfoPerCarHead } from '../InfoPerCarHead/InfoPerCarHead';
import { SelectGroup } from '../SelectGroup/SelectGroup';
import style from './CarsData.module.css';

interface CarsDataProps {
  reportTime: ReportTime[];
  selectedCars: SelectedCarsInfoReport[];
  isLoading: boolean;
}

export const CarsData: FC<CarsDataProps> = ({
  reportTime,
  selectedCars,
  isLoading,
}) => {
  const { isMobile } = useAppWindowSizeInfo();

  return (
    <>
      <SelectGroup>
        <div className={style.selectContainer}>
          <span className={style.labelInputSelect}>{'Raportare unitara'}</span>
          <div className="ml-3"></div>
        </div>
      </SelectGroup>
      <MCSpacing />
      <div className={style.container}>
        {!isMobile && <InfoPerCarHead />}
        {isMobile ? (
          <InfoPerCarBodyMobile cars={selectedCars} />
        ) : (
          <InfoPerCarBody cars={selectedCars} />
        )}
      </div>
    </>
  );
};
