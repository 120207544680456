import moment from 'moment';
import {
  CreateUnavailabilityDto,
  EditUnavailabilityDto,
  Guarantees,
  Reservation,
  TripIntervalType,
} from '../types/api';
import axios from '../utils/axios';
import { BASE_URL, CANCELED, PENDING_PAYMENT } from '../utils/constants';

export const getReservations = async (filter: any, size = 50, page = 0) => {
  try {
    return (
      await axios.get(`${BASE_URL}/reservation`, {
        params: {
          filter,
          size,
          page,
        },
      })
    ).data.found as { count: number; reservations: Reservation[] };
  } catch (err) {
    console.error('Failed to get reservations');
    console.error(err);
    throw err;
  }
};

export const getReceptionsDeliveries = async () => {
  try {
    return (await axios.get(`${BASE_URL}/reservation/receptions-deliveries`))
      .data.found as {
      count: number;
      reservations: Reservation[];
    };
  } catch (err) {
    console.error('Failed to get receptions deliveries');
    console.error(err);
    throw err;
  }
};

export const searchReservations = async (
  search: string,
  size = 50,
  page = 0
) => {
  try {
    return (
      await axios.get(`${BASE_URL}/reservation/search`, {
        params: {
          size,
          page,
          search,
        },
      })
    ).data.found as { count: number; reservations: Reservation[] };
  } catch (err) {
    console.error('Failed to get reservations');
    console.error(err);
    throw err;
  }
};

export const createReservation = async (
  reservationData: Pick<
    Reservation,
    | 'user'
    | 'phoneNumber'
    | 'name'
    | 'email'
    | 'dateBegin'
    | 'dateEnd'
    | 'car'
    | 'location'
    | 'checkoutPaymentMethod'
    | 'locationDetails'
    | 'companyCUI'
    | 'companyName'
  >,
  guaranteeType: keyof Guarantees,
  voucher?: string
): Promise<Reservation> => {
  try {
    return (
      await axios.post(`${BASE_URL}/reservation`, {
        ...reservationData,
        guaranteeType,
        voucher,
      })
    ).data.reservation;
  } catch (err) {
    console.error('Failed to create reservation');
    console.error(err);
    throw err;
  }
};

export const createAnonymousReservation = async (
  reservationData: Pick<
    Reservation,
    | 'dateBegin'
    | 'dateEnd'
    | 'car'
    | 'location'
    | 'checkoutPaymentMethod'
    | 'locationDetails'
    | 'phoneNumber'
    | 'name'
    | 'email'
    | 'companyCUI'
    | 'companyName'
  >,
  guaranteeType: keyof Guarantees,
  voucher?: string
): Promise<Reservation> => {
  try {
    return (
      await axios.post(`${BASE_URL}/reservation/anonymous`, {
        ...reservationData,
        guaranteeType,
        voucher,
      })
    ).data.reservation;
  } catch (err) {
    console.error('Failed to create reservation');
    console.error(err);
    throw err;
  }
};

export const createUnavailability = async (
  reservationData: CreateUnavailabilityDto
): Promise<Reservation> => {
  try {
    return (
      await axios.post(
        `${BASE_URL}/reservation/unavailability`,
        reservationData
      )
    ).data.reservation;
  } catch (err) {
    console.error('Failed to create reservation');
    console.error(err);
    throw err;
  }
};

export const updateStatusReservation = async (
  reservationId: string,
  isAccepted: boolean
) => {
  try {
    return (
      await axios.put(`${BASE_URL}/reservation/${reservationId}`, {
        status: isAccepted ? PENDING_PAYMENT : CANCELED,
      })
    ).data;
  } catch (err) {
    console.error('Failed to update reservation');
    console.error(err);
    throw err;
  }
};

export const updateReservationStripeSuccess = async (
  reservationId: string,
  confirmationCode: string
) => {
  try {
    return (
      await axios.put(
        `${BASE_URL}/reservation/${reservationId}/payment-approved`,
        {},
        { params: { confirmationCode } }
      )
    ).data.updated as Reservation;
  } catch (err) {
    console.error('Failed to update reservation');
    console.error(err);
    throw err;
  }
};

export const updateReservation = async (reservation: EditUnavailabilityDto) => {
  try {
    return (
      await axios.put(`${BASE_URL}/reservation/${reservation._id}`, reservation)
    ).data;
  } catch (err) {
    console.error('Failed to update reservation');
    console.error(err);
    throw err;
  }
};

export const deleteReservation = async (reservationId: string) => {
  try {
    return await axios.delete(`${BASE_URL}/reservation/${reservationId}`);
  } catch (err) {
    console.error('Failed to delete reservation');
    console.error(err);
    throw err;
  }
};

export const getReservationsForCar = async ({
  carId,
  beginDate,
  endDate,
  isOwner = false,
}: {
  carId: string | Array<string>;
  beginDate: number;
  endDate: number;
  isOwner?: boolean;
}) => {
  const response = await axios.get(
    `${BASE_URL}/reservation/car${isOwner ? '-owner' : ''}`,
    {
      params: {
        carId,
        beginDate,
        endDate,
      },
    }
  );

  return response.data.found as Reservation[];
};

export const getMyReservations = async ({
  type,
}: {
  type: TripIntervalType;
}): Promise<Array<Reservation>> => {
  const response = await axios.get(`${BASE_URL}/reservation/mine`, {
    params: {
      type,
      time: moment().unix(),
    },
  });

  return response.data.found.reservations as Reservation[];
};
