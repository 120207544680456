import moment from 'moment';
import { useMemo } from 'react';
import { Car, Guarantees, VoucherDiscountType } from '../../types/api';
import { computeNumberOfDaysForRental } from '../../utils/mathUtil';
export interface Offer {
  taxes: Array<{
    name: string;
    value: number;
    quantity: number;
    totalValue: number;
  }>;
  options: Array<{
    name: string;
    value: number;
    quantity: number;
    totalValue: number;
  }>;
  days: number;
  perDayKm: number;
  totalKm: number;
  deposit: number;
  pricePerDay: number;
  basePrice: number;
  taxesPrice: number;
  optionsPrice: number;
  totalPrice: number;
  discount: number;
  customerPrice: number;

  default?: boolean;
}

const defaultOffer: Offer = {
  taxes: [],
  options: [],
  days: 0,
  perDayKm: 0,
  totalKm: 0,
  deposit: 0,
  pricePerDay: 0,
  basePrice: 0,
  taxesPrice: 0,
  optionsPrice: 0,
  totalPrice: 0,
  discount: 0,
  customerPrice: 0,

  default: true,
};

export const doComputeOffer = (params: {
  car: Car;
  dateBegin?: number;
  dateEnd?: number;
  guaranteeType: keyof Guarantees;
  euExit?: boolean;
  extraHundredKm?: number;
  extraScheduleTax?: number;
  extraDeliveryFee?: number;
  voucher?: {
    value: number;
    type: VoucherDiscountType;
  };
}): Offer => {
  const {
    car,
    dateBegin = 0,
    dateEnd = 0,
    guaranteeType,
    euExit = false,
    extraHundredKm = 0,
    extraScheduleTax = 0,
    extraDeliveryFee = 0,
    voucher,
  } = params;

  if (!dateBegin || !dateEnd) {
    return defaultOffer;
  }

  let days = computeNumberOfDaysForRental(dateBegin, dateEnd);
  if (days < car.settings.rentalOffers[0].days) {
    days = car.settings.rentalOffers[0].days;
  }
  const euExitTax =
    euExit && car.settings.euExitActive ? car.settings.euExitFee : 0;
  const euExitGuarantee =
    euExit && car.settings.euExitActive ? car.settings.euExitGuarantee : 0;
  const extraHundredKmTax =
    extraHundredKm > 0 && !!car.settings.extraHundredKmFee
      ? car.settings.extraHundredKmFee * extraHundredKm
      : 0;

  // Taxes do have qantity
  const taxes: Offer['taxes'] = [];
  if (extraScheduleTax) {
    taxes.push({
      name: 'Taxa extra program',
      value: extraScheduleTax,
      quantity: 1,
      totalValue: extraScheduleTax,
    });
  }
  if (extraDeliveryFee) {
    taxes.push({
      name: 'Taxa livrare masina',
      value: extraDeliveryFee,
      quantity: 1,
      totalValue: extraDeliveryFee,
    });
  }

  const options: Offer['options'] = [];
  if (euExitTax) {
    options.push({
      name: 'Taxa iesire in UE',
      value: euExitTax,
      quantity: 1,
      totalValue: euExitTax,
    });
  }
  if (extraHundredKmTax) {
    options.push({
      name: 'Taxa extra 100 km',
      value: car.settings.extraHundredKmFee,
      quantity: extraHundredKm,
      totalValue: car.settings.extraHundredKmFee * extraHundredKm,
    });
  }

  const currentMonthIdx = moment.unix(dateBegin).month();

  let offer = defaultOffer;
  for (let i = car.settings.rentalOffers.length - 1; i >= 0; i--) {
    const ratio =
      car.settings.rentalOffers[i].priceIntegral /
      car.settings.rentalOffers[0].priceIntegral;

    const originalOffer = car.settings.rentalOffers[i];

    const basePrice =
      originalOffer.priceIntegral *
      ((100 + car.settings.monthIncrease[currentMonthIdx]) / 100);

    const rawPerDayPrice = {
      integral:
        basePrice +
        Math.round(
          ratio * car.settings.guarantees.integral.assurancePricePerDay
        ),
      reduced:
        basePrice +
        Math.round(
          ratio * car.settings.guarantees.reduced.assurancePricePerDay
        ),
      minimal:
        basePrice +
        Math.round(
          ratio * car.settings.guarantees.minimal.assurancePricePerDay
        ),
    }[guaranteeType];
    const perDayPrice = Math.round(rawPerDayPrice);

    if (car.settings.rentalOffers[i].days <= days) {
      const optionsPrice = options.reduce(
        (acc, tax) => acc + tax.value * tax.quantity,
        0
      );
      const taxesPrice = taxes.reduce(
        (acc, tax) => acc + tax.value * tax.quantity,
        0
      );

      const pricePerDay = perDayPrice;
      const basePrice = days * perDayPrice;

      const totalPrice = basePrice + taxesPrice + optionsPrice;

      let discount = voucher
        ? voucher.type === VoucherDiscountType.Percentage
          ? Math.floor(basePrice * voucher.value)
          : voucher.value
        : 0;
      if (discount > basePrice) {
        discount = basePrice;
      }

      const customerPrice = totalPrice - discount;

      offer = {
        taxes,
        options,
        days,
        perDayKm: car.settings.rentalOffers[i].distance + extraHundredKm * 100,
        totalKm:
          car.settings.rentalOffers[i].distance * days + extraHundredKm * 100,
        deposit:
          car.settings.guarantees[guaranteeType].guarantee + euExitGuarantee,
        pricePerDay,
        basePrice,
        taxesPrice,
        totalPrice,
        optionsPrice,
        discount,
        customerPrice,
      };
      break;
    }
  }

  return offer;
};

export const useAppComputeOffer = ({
  car,
  dateBegin = 0,
  dateEnd = 0,
  guaranteeType,
  euExit = false,
  extraHundredKm = 0,
  extraScheduleTax = 0,
  extraDeliveryFee = 0,
  voucher,
}: {
  car: Car;
  dateBegin?: number;
  dateEnd?: number;
  guaranteeType: keyof Guarantees;
  euExit?: boolean;
  extraHundredKm?: number;
  extraScheduleTax?: number;
  extraDeliveryFee?: number;
  voucher?: {
    value: number;
    type: VoucherDiscountType;
  };
}) => {
  return useMemo((): Offer => {
    return doComputeOffer({
      car,
      dateBegin,
      dateEnd,
      guaranteeType,
      euExit,
      extraHundredKm,
      extraScheduleTax,
      extraDeliveryFee,
      voucher,
    });
  }, [
    car,
    dateBegin,
    dateEnd,
    guaranteeType,
    euExit,
    extraHundredKm,
    extraScheduleTax,
    extraDeliveryFee,
    voucher,
  ]);
};
