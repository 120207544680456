import moment from 'moment';
import { useMemo } from 'react';
import { svgBackButton } from '../../../assets';
import { MCHeadline } from '../../../_shared/components';
import styles from './DateNavigator.module.css';

interface DateNavigatorProps {
  firstDay: Date;
  lastDay: Date;
  onPrev: () => void;
  onNext: () => void;
}

export function DateNavigator({
  firstDay,
  lastDay,
  onNext,
  onPrev,
}: DateNavigatorProps) {
  const title = useMemo(() => {
    return `${moment(firstDay).format('DD/MM')} - ${moment(lastDay).format(
      'DD/MM'
    )}`;
  }, [firstDay, lastDay]);

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={onPrev}>
        <img src={svgBackButton}></img> Inainte
      </div>
      <div className={styles.dates}>
        <MCHeadline>{title}</MCHeadline>
      </div>
      <div className={styles.button} onClick={onNext}>
        Urmator <img src={svgBackButton} className={styles.rotate}></img>
      </div>
    </div>
  );
}
