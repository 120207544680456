import { FC } from 'react';
import style from './SelectGroup.module.css';

export interface SelectGroupProps {
  children: React.ReactNode | React.ReactNode[];
}

export const SelectGroup: FC<SelectGroupProps> = ({ children }) => {
  return <div className={style.selectGroup}>{children}</div>;
};
