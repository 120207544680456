import { useQuery } from 'react-query';
import { getInvoices } from '../../api/invoices';

export function useAppInvoicesQuery(
  filter: any,
  { size, page }: { size?: number; page?: number } = {}
) {
  return useQuery(['owner-invoices', { filter, size, page }], async () => {
    return (await getInvoices(filter, size, page)).invoices;
  });
}
