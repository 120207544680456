import { FC } from 'react';
import { SummaryData } from '../../../../types/api';
import { MCSpacing } from '../../../../_shared/components';
import { InputSelect } from '../../../../_shared/components/InputSelect/InputSelect';
import { ReportTime } from '../../../pages/Reports';
import { SelectGroup } from '../SelectGroup/SelectGroup';
import { SummaryInfoCellBody } from '../SummaryInfoCellBody/SummaryInfoCellBody';
import { SummaryInfoCellHeader } from '../SummaryInfoCellHeader/SummaryInfoCellHeader';
import style from './SummaryData.module.css';

interface Props {
  reportTime: ReportTime[];
  isLoading: boolean;
  summaryData: SummaryData[];
  daysFilter: string;
  setDaysFilter: (e: string) => void;
}

export const SummaryDataFC: FC<Props> = ({
  reportTime,
  isLoading,
  summaryData,
  daysFilter,
  setDaysFilter,
}) => {
  return (
    <>
      <SelectGroup>
        <div className={style.selectContainer}>
          <span className={style.labelInputSelect}>{'Sumar'}</span>
          <div className="ml-3">
            <InputSelect
              options={reportTime}
              value={daysFilter}
              onValueChange={setDaysFilter}
            />
          </div>
        </div>
      </SelectGroup>
      <MCSpacing />
      <div className={style.container}>
        {summaryData.map((data) => (
          <div className={style.infoCell} key={data.title}>
            <SummaryInfoCellHeader title={data.title} tooltip={data.tooltip} />
            <SummaryInfoCellBody
              value={data.value}
              isIncrease={data.isIncrease}
              increaseValue={data.increaseValue}
              suffix={data.suffix}
            />
          </div>
        ))}
      </div>
    </>
  );
};
