import { DAY_S, MINUTE_S } from './constants';

export function computeNumberOfDaysForRental(
  unixBegin: number,
  unixEnd: number
) {
  const durationInSec = unixEnd - unixBegin;
  if (durationInSec <= 0) {
    return 0;
  }

  let days = Math.floor(durationInSec / DAY_S);
  const minutes = Math.floor(durationInSec - days * DAY_S) / MINUTE_S;
  if (days === 0 || minutes >= 90) {
    days += 1;
  }

  return days;
}
