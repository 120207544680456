interface props {
  keys: Array<string>;
  data: Array<any>;
  onEdit: (id: string) => void;
  onRemove?: (id: string) => void;
}

export const Table = ({ keys, data, onEdit, onRemove }: props) => {
  return (
    <div style={{ marginTop: 10 }}>
      {renderRows(keys, data, onEdit, onRemove)}
    </div>
  );
};

const renderRows = (
  keys: Array<string>,
  data: Array<any>,
  onEdit: (id: string) => void,
  onRemove?: (id: string) => void
) => {
  const tableContent = data.map((row: any) => {
    const type = row.rowType;
    return (
      <li
        className={`list-group-item shadow ${type}`}
        style={{ marginTop: 20, borderRadius: 8 }}
      >
        {renderRow(keys, row, onEdit, onRemove)}
      </li>
    );
  });

  return (
    <ul className="list-group" style={{ marginTop: 10 }}>
      {tableContent}
    </ul>
  );
};

const renderRow = (
  keys: Array<string>,
  rowData: any,
  onEdit: (id: string) => void,
  onRemove?: (id: string) => void
) => {
  const row = keys.map((key) => {
    return (
      <div>
        <p>
          {key}: {rowData[key]}
        </p>
      </div>
    );
  });

  row.push(
    <div className="d-flex justify-content-between row-hl">
      <button
        className="btn btn-primary"
        onClick={async () => {
          await onEdit(rowData._id);
        }}
      >
        Edit
      </button>
      {onRemove && (
        <button
          className="btn btn-outline-danger"
          onClick={async () => {
            await onRemove(rowData._id);
          }}
        >
          Remove
        </button>
      )}
    </div>
  );

  return row;
};
