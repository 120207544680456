import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: '1',
    Corsa: 750,
    C220: 2700,
    total: 3450,
  },
  {
    name: '2',
    Corsa: 500,
    C220: 3500,
    total: 4000,
  },
  {
    name: '3',
    Corsa: 1250,
    C220: 4200,
    total: 5450,
  },
  {
    name: '4',
    Corsa: 1150,
    C220: 3700,
    total: 4850,
  },
];

export const DashboardPage = () => {
  return (
    <div
      className="d-flex justify-content-center row-hl"
      style={{ marginTop: 100, marginBottom: 10 }}
    >
      <h2>Admin Dashboard</h2>
      <div style={{ marginTop: 50 }}>
        <ResponsiveContainer width={500} height={300}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Corsa" stroke="#8884d8" />
            <Line type="monotone" dataKey="C220" stroke="#82ca9d" />
            <Line type="monotone" dataKey="total" stroke="#880808" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
