import axios from '../utils/axios';
import { Voucher } from '../types/api';
import { BASE_URL } from '../utils/constants';

export const getVoucherByCode = async ({ code }: { code: string }) => {
  try {
    return (
      await axios.get(`${BASE_URL}/vouchers`, {
        params: {
          code,
        },
      })
    ).data.found as Voucher;
  } catch (err) {
    console.error('Failed to get voucher with code ' + code);
    console.error(err);
    throw err;
  }
};
