import { useState } from 'react';
import { DefaultLayout } from 'src/_shared/components/DefaultLayout';
import { Mail, Phone } from 'lucide-react';
import { Checkbox } from 'src/components/ui/checkbox';
import { Button } from 'src/components/ui/button';

export function NewSettings() {
  const [emailNotifications, setEmailNotifications] = useState<boolean>(true);
  const [smsNotifications, setSmsNotifications] = useState<boolean>(true);

  return (
    <DefaultLayout>
      <h1 className="text-xl mb-6">Setări</h1>

      <div className="w-1/2 pr-10">
        <h2 className="text-lg mb-4">Opțiuni de notificare</h2>

        <div className="flex justify-between">
          {/* TODO: replace with user's email */}
          <div className="flex items-center gap-2">
            <Mail className="h-5 w-5" />
            {/* remove all STYLING of <p> after cleaning project */}
            <p className="text-base mb-0">demo@demo.com</p>
          </div>
          <Checkbox
            checked={emailNotifications}
            onCheckedChange={() => setEmailNotifications(!emailNotifications)}
            className="border-black"
          />
        </div>

        <div className="flex justify-between">
          {/* TODO: replace with user's phone number */}
          <div className="flex items-center gap-2">
            <Phone className="h-5 w-5" />
            {/* remove all STYLING of <p> after cleaning project */}
            <p className="text-base mb-0">+40773739172</p>
          </div>
          <Checkbox
            checked={smsNotifications}
            onCheckedChange={() => setSmsNotifications(!smsNotifications)}
          />
        </div>
      </div>

      <Button variantSize="sm">Hello</Button>
    </DefaultLayout>
  );
}
