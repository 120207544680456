import moment from 'moment';
import {
  MAX_DISCOUNT,
  MIN_DISCOUNT,
  RECOMMENDED_DISCOUNTS,
} from '../utils/constants';

export function formatDateUnix(dateUnix: number) {
  return `${moment.unix(dateUnix).format('DD/MM/YYYY')} Ora ${moment
    .unix(dateUnix)
    .format('HH:mm')}`;
}

export function createSetHandlerFactory<T>(current: T, setter: (t: T) => void) {
  return (key: keyof T) => {
    return (value: T[typeof key]) => setter({ ...current, [key]: value });
  };
}

export function computeDiscount(basePrice: number, discountPrice: number) {
  const difference = basePrice - discountPrice;
  const percentage = Math.round((difference / basePrice) * 100);

  return Math.max(MIN_DISCOUNT, Math.min(percentage, MAX_DISCOUNT));
}

export function getRecommendedDiscount(rentalDays: number) {
  for (const { days, discount } of RECOMMENDED_DISCOUNTS) {
    if (rentalDays >= days) {
      return discount;
    }
  }

  return 0;
}
