import { MCSpacing, MCTwoPaneLayout } from '../../../_shared/components';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
//import { useAppReportQuery } from '../../hooks/queries';
import { CarsData } from '../../components/Reports/CarsData/CarsData';
import { SummaryDataFC } from '../../components/Reports/SummaryData/SummaryData';
import { TimelineIncome } from '../../components/Reports/TimelineIncome/TimelineIncome';
import { useCallback, useEffect, useState } from 'react';
import { getOwnerReport } from '../../../api/sellers';
import { OwnerReport } from '../../../types/api';

export enum ReportTimeType {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

export interface ReportsFilter {
  summaryTime: number;
  timelineTime: number;
  selectedCarsTime: number;
}

export interface ReportTime {
  name: string;
  value: string;
}

export interface CarDataProps {
  title: string;
  registrationNumber: string;
  value: number;
  isIncrease: boolean;
  increaseValue: number;
}

export const Reports = () => {
  const props = useLeftNavigatorOwnerProps();

  const [daysFilter, setDaysFilter] = useState<string>('30');
  const [report, setReport] = useState<OwnerReport | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const { trackEvent } = useOwnerAnalytics();

  const getReportsAsync = useCallback(async () => {
    setIsLoading(true);
    const res = await getOwnerReport({
      summaryTime: parseInt(daysFilter),
      timelineTime: parseInt(daysFilter),
      selectedCarsTime: parseInt(daysFilter),
    });

    // @ts-ignore
    setReport(res);
    setIsLoading(false);
  }, [daysFilter]);

  useEffect(() => {
    getReportsAsync();
  }, [daysFilter, getReportsAsync]);

  return (
    <MCTwoPaneLayout title="Rapoarte" {...props}>
      <MCSpacing />
      <MCSpacing />
      <SummaryDataFC
        reportTime={reportTimeDummy}
        isLoading={isLoading}
        summaryData={report?.summary || []}
        daysFilter={daysFilter}
        setDaysFilter={(days) => {
          trackEvent('Reports_DaysDropdown_Selected', { days });
          setDaysFilter(days);
        }}
      />
      <MCSpacing />
      <MCSpacing />
      <TimelineIncome
        reportTime={reportTimeDummy}
        isLoading={isLoading}
        daysData={report?.valueDates || []}
      />
      <MCSpacing />
      <MCSpacing />
      <CarsData
        reportTime={reportTimeDummy}
        isLoading={isLoading}
        selectedCars={report?.selectedCars || []}
      />
    </MCTwoPaneLayout>
  );
};

const reportTimeDummy: ReportTime[] = [
  {
    name: 'ultimele 14 zile',
    value: '14',
  },
  {
    name: 'ultimele 30 zile',
    value: '30',
  },
  {
    name: 'ultimele 3 luni',
    value: '90',
  },
  {
    name: 'ultimele 6 luni',
    value: '180',
  },
  {
    name: 'ultimul an',
    value: '365',
  },
];
