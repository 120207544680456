import { Offer } from '../../../hooks/util/useAppComputeOffer';
import { useCurrencyFormatter } from '../../../hooks/util/useCurrencyFormatter';
import { Separator } from '../../../_shared/components/Separator';
import './RentOfferCard.style.css';

export interface RentOfferPricesProps {
  offer: Offer;
}

export function RentOfferPrices({ offer }: RentOfferPricesProps) {
  const { formatCurrency } = useCurrencyFormatter();

  console.log({ offer: offer.options });

  return (
    <div className="car-page-details-inner-container bottom">
      <div className="car-page-details-text-container">
        <p className="car-page-details-card-text normal">
          {`${formatCurrency(offer?.pricePerDay, 2)} x ${offer?.days} zile`}
        </p>
        <p className="car-page-details-card-text normal">
          {`${formatCurrency(offer.basePrice, 2)}`}
        </p>
      </div>
      <div className="car-page-details-text-container">
        <p className="car-page-details-card-text normal">Distanta Maxima</p>
        <p className="car-page-details-card-text normal">
          {`${offer.totalKm} Km`}
        </p>
      </div>
      {offer.taxes.map(({ name, quantity, value }) => {
        return (
          <div className="car-page-details-text-container">
            <p className="car-page-details-card-text normal">{name}</p>
            <p className="car-page-details-card-text normal">{`${
              quantity
                ? formatCurrency(quantity * value, 2)
                : formatCurrency(value, 2)
            }`}</p>
          </div>
        );
      })}
      {offer.options.map(({ name, quantity, value }) => {
        return (
          <div className="car-page-details-text-container">
            <p className="car-page-details-card-text normal">
              {name}
              {quantity && quantity > 1 ? ` x ${quantity}` : ''}
            </p>
            <p className="car-page-details-card-text normal">{`${
              quantity
                ? formatCurrency(quantity * value, 2)
                : formatCurrency(value, 2)
            }`}</p>
          </div>
        );
      })}
      {offer.deposit && (
        <div className="car-page-details-text-container">
          <p className="car-page-details-card-text normal">Depozit</p>
          <p className="car-page-details-card-text normal">{`${formatCurrency(
            offer.deposit
          )}`}</p>
        </div>
      )}
      <div className="car-page-details-separator">
        <Separator />
      </div>
      {!!offer.discount && (
        <>
          <div className="car-page-details-text-container">
            <p
              className="car-page-details-card-text normal"
              style={{ color: '#58A138' }}
            >
              Discount
            </p>
            <p className="car-page-details-card-text normal">{`- ${formatCurrency(
              offer.discount,
              2
            )}`}</p>
          </div>
          <div className="car-page-details-separator">
            <Separator />
          </div>
        </>
      )}

      <div className="car-page-details-text-container">
        <p className="car-page-details-card-text total">Total</p>
        <p className="car-page-details-card-text total">
          {`${formatCurrency(offer.customerPrice, 2)}`}
        </p>
      </div>
    </div>
  );
}
