import classNames from 'classnames';
import styles from './MCSvgImage.module.css';

export interface MCSvgImageProps {
  image: any;
  addLeftMargin?: boolean;
  size?: number;
}

export function MCSvgImage({
  image,
  addLeftMargin = false,
  size,
}: MCSvgImageProps) {
  return (
    <img
      src={image}
      style={size ? { height: size, width: size } : undefined}
      className={classNames(styles.image, {
        [styles.imageLeftMargin]: addLeftMargin,
      })}
      alt="List item"
    />
  );
}
