import { useQuery } from 'react-query';
import { getMyReservations } from '../../api/reservations';
import { TripIntervalType } from '../../types/api';

export function useAppTripsQuery({
  type = TripIntervalType.Present,
}: {
  type: TripIntervalType;
}) {
  return useQuery(
    ['driver-trips', { type }],
    async () => {
      return getMyReservations({ type });
    },
    {
      staleTime: 60000,
    }
  );
}
