import moment from 'moment';
import { useQuery } from 'react-query';
import { getReceptionsDeliveries } from '../../api/reservations';
import {
  ReceptionDayEvents,
  ReceptionEvent,
} from '../../types/receptionDeliveries';

export function useOwnerReservationEventsQuery() {
  return useQuery(
    ['owner-reservations-events'],
    async () => {
      const reservations = (await getReceptionsDeliveries()).reservations;

      let events: Array<ReceptionEvent> = [];
      const notFilteredEvents: Array<ReceptionEvent> = [];
      const days: Array<ReceptionDayEvents> = [];

      reservations.forEach((reservation: any) => {
        const delivery = {
          type: 'DELIVERY',
          dateBegin: reservation.dateBegin,
          dateEnd: reservation.dateEnd,
          car: reservation.car,
          user: reservation.user,
          name: reservation.name,
          phoneNumber: reservation.phoneNumber,
          locationDetails: reservation.locationDetails,
          comparator: reservation.dateBegin,
          reservation,
        };

        const reception = {
          type: 'RECEPTION',
          dateBegin: reservation.dateBegin,
          dateEnd: reservation.dateEnd,
          car: reservation.car,
          user: reservation.user,
          name: reservation.name,
          phoneNumber: reservation.phoneNumber,
          locationDetails: reservation.locationDetails,
          comparator: reservation.dateEnd,
          reservation,
        };

        // @ts-ignore
        notFilteredEvents.push(delivery);
        // @ts-ignore
        notFilteredEvents.push(reception);
      });

      const nowUnix = moment().unix();
      events = notFilteredEvents.filter((event) => {
        return event.comparator! > nowUnix;
      });

      events.sort((a: any, b: any) => {
        return a.comparator - b.comparator;
      });

      events.forEach((event, i) => {
        const dayFormatted = moment
          .unix(event.comparator!)
          .format('DD/MM/YYYY');

        if (!days.length) {
          const dayOfTheWeek =
            moment.unix(nowUnix).format('DD/MM/YYYY') === dayFormatted
              ? 'Astăzi'
              : moment.unix(event.comparator!).format('dddd');
          days.push({
            dayData: {
              dayOfTheWeek:
                dayOfTheWeek[0].toUpperCase() + dayOfTheWeek.substring(1),
              date: moment.unix(event.comparator!).format('D MMM'),
              isSelected:
                moment.unix(nowUnix).format('DD/MM/YYYY') === dayFormatted,
            },
            events: [event],
          });
          return;
        } else {
          const lastDayFormatted = moment
            .unix(days[days.length - 1].events[0].comparator!)
            .format('DD/MM/YYYY');

          if (dayFormatted === lastDayFormatted) {
            days[days.length - 1].events.push(event);
          } else {
            const dayOfTheWeek = moment.unix(event.comparator!).format('dddd');
            days.push({
              dayData: {
                dayOfTheWeek:
                  dayOfTheWeek[0].toUpperCase() + dayOfTheWeek.substring(1),
                date: moment.unix(event.comparator!).format('D MMM'),
                isSelected: false,
              },
              events: [event],
            });
          }
        }
      });

      return days;
    },
    {
      staleTime: 60000,
    }
  );
}
