import { Sidebar } from './Sidebar';

export function DefaultLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Sidebar />
      <main className="ml-16 md:ml-20 xl:ml-72">
        <div className="mx-auto min-h-screen max-w-7xl p-4">{children}</div>
      </main>
    </>
  );
}
