import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  svgCalendarCancel,
  svgCalendarCancelBold,
  svgCalendarSearch,
  svgCalendarSearchBold,
  svgCarBoldIcon,
  svgCarIcon,
  svgCategoryBoldIcon,
  svgCategoryIcon,
  svgCog,
  svgInsuranceBoldIcon,
  svgInsuranceIcon,
  svgInvoice,
  svgInvoiceBold,
  svgLocation,
  svgLocationBoldIcon,
  svgOverview,
  svgPriceTag,
  svgPriceTagBold,
  svgReceptionsDeliveries,
  svgReceptionsDeliveriesBold,
  svgRentalHistory,
  svgRentalHistoryBold,
  svgReport,
  svgReportBold,
} from '../../assets';
import { logout } from '../../redux/actions/driverAction';
import { MCGenericLeftNavigatorProps } from '../../_shared/components';
import { useAppCarsQuery } from './queries';
import { useOwnerAnalytics } from './useOwnerAnalytics';

export function useLeftNavigatorOwnerProps() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { trackEvent } = useOwnerAnalytics();
  const onLogout = useCallback(async () => {
    trackEvent('LeftMenu_Logout_Click');
    await dispatch(logout());
    history.push('/login');
  }, [dispatch, history, trackEvent]);

  const { data: cars } = useAppCarsQuery({
    types: ['category'],
  });

  const options = useMemo((): MCGenericLeftNavigatorProps['options'] => {
    const options: MCGenericLeftNavigatorProps['options'] = [
      {
        text: 'Prezentare generală',
        route: '/overview',
        icon: {
          regular: svgOverview,
          selected: svgOverview,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Overview_Click'),
      },
      {
        text: 'Istoric inchirieri',
        route: '/rental-history',
        icon: {
          regular: svgRentalHistory,
          selected: svgRentalHistoryBold,
        },
        onEvent: () => trackEvent('LeftMenu_RentalHistory_Click'),
      },
      {
        text: 'Primiri - Predări',
        route: '/receptions-deliveries',
        icon: {
          regular: svgReceptionsDeliveries,
          selected: svgReceptionsDeliveriesBold,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_ReceptionsDeliveries_Click'),
      },
      {
        text: 'Preturi',
        route: '/prices',
        icon: {
          regular: svgPriceTag,
          selected: svgPriceTagBold,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Prices_Click'),
      },
      {
        text: 'Masini',
        route: '/cars',
        icon: {
          regular: svgCarIcon,
          selected: svgCarBoldIcon,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Cars_Click'),
      },
      {
        text: 'Categorii',
        route: '/categories',
        icon: {
          regular: svgCategoryIcon,
          selected: svgCategoryBoldIcon,
        },
        disabled: false,
        hidden: !cars?.length,
        onEvent: () => trackEvent('LeftMenu_Categories_Click'),
      },
      {
        text: 'Disponibilitate mașini',
        route: '/availability',
        icon: {
          regular: svgCalendarSearch,
          selected: svgCalendarSearchBold,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Availability_Click'),
      },
      {
        text: 'Setări indisponibilitate',
        route: '/unavailability',
        icon: {
          regular: svgCalendarCancel,
          selected: svgCalendarCancelBold,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Unavailability_Click'),
      },
      {
        text: 'Facturi',
        route: '/invoices',
        icon: {
          regular: svgInvoice,
          selected: svgInvoiceBold,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Invoices_Click'),
      },
      {
        text: 'Locatii',
        route: '/locations',
        icon: {
          regular: svgLocation,
          selected: svgLocationBoldIcon,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Locations_Click'),
      },
      {
        text: 'Asigurari',
        route: '/insurance',
        icon: {
          regular: svgInsuranceIcon,
          selected: svgInsuranceBoldIcon,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Assurance_Click'),
      },
      {
        text: 'Rapoarte',
        route: '/reports',
        icon: {
          regular: svgReport,
          selected: svgReportBold,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Reports_Click'),
      },
      {
        text: 'Setări',
        route: '/settings',
        icon: {
          regular: svgCog,
          selected: svgCog,
        },
        disabled: false,
        onEvent: () => trackEvent('LeftMenu_Settings_Click'),
      },
    ].filter((x) => !x.hidden);

    return options;
  }, [cars?.length, trackEvent]);

  return { options, onLogout };
}
