import { pngEmptyTrips, svgArrow } from '../../../assets';
import { MCCenter, MCHeadline, MCSpacing } from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import styles from './TripsEmpty.module.css';

export function TripsEmpty({ type }: { type: string }) {
  const { trackEvent } = useDriverAnalytics();

  return (
    <MCCenter>
      <MCHeadline>Nu ai rezervari {type}.</MCHeadline>
      <MCSpacing />
      <img src={pngEmptyTrips} alt="empty" />
      <MCHeadline>Poti face o rezervare in doar cateva minute</MCHeadline>
      <MCSpacing />
      <MCSpacing />
      <img src={svgArrow} className={styles.arrow} alt="down-arrow" />
      <MCSpacing />
      <div className={styles.buttonContainer}>
        <ActionButton
          label="Vreau asistență"
          onClick={() => {
            trackEvent('Rentals_NeedAssistance_Click');
          }}
        />
      </div>
    </MCCenter>
  );
}
