import moment from 'moment';
import 'moment/locale/ro';
import { forwardRef, useEffect, useState } from 'react';
import arrow from '../../../assets/drop-down-arrow.svg';
import { computeMonthDays, MonthDay } from './computeMonthDays';
import { CustomDatePickerCalendar } from './CustomDatePickerCalendar';
import './CustomDatePickerModal.style.css';

const monthNames = moment.months();

const firstDayMonth = new Date();
firstDayMonth.setDate(1);

interface Props {
  minimumDate?: Date;
  maximumDate?: Date;
  onDateClick?: any;
  disabledIntervals?: Array<{ begin: Date; end: Date }>;
  isDisabledDate?: (date: moment.Moment) => boolean;
}

export const CustomDatePickerModal = forwardRef(
  (
    {
      onDateClick,
      minimumDate,
      maximumDate,
      disabledIntervals,
      isDisabledDate,
    }: Props,
    ref
  ) => {
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(2022);
    const [monthDays, setMonthDays] = useState<Array<Array<MonthDay>>>();

    useEffect(() => {
      setMonth(monthNames[firstDayMonth.getMonth()]);
      setYear(firstDayMonth.getFullYear());

      setMonthDays(
        computeMonthDays(
          firstDayMonth,
          minimumDate,
          maximumDate,
          disabledIntervals
        )
      );
    }, [disabledIntervals, maximumDate, minimumDate]);

    const onNextMonth = () => {
      firstDayMonth.setMonth(firstDayMonth.getMonth() + 1);
      setMonth(monthNames[firstDayMonth.getMonth()]);
      setYear(firstDayMonth.getFullYear());
      setMonthDays(
        computeMonthDays(
          firstDayMonth,
          minimumDate,
          maximumDate,
          disabledIntervals
        )
      );
    };

    const onPrevMonth = () => {
      firstDayMonth.setMonth(firstDayMonth.getMonth() - 1);
      setMonth(monthNames[firstDayMonth.getMonth()]);
      setYear(firstDayMonth.getFullYear());
      setMonthDays(
        computeMonthDays(
          firstDayMonth,
          minimumDate,
          maximumDate,
          disabledIntervals
        )
      );
    };

    const onConfirm = () => {};

    const onDismiss = () => {};

    return (
      // @ts-ignore
      <div ref={ref} className="date-picker-modal-absolute-container">
        <div className="date-picker-modal-container">
          <div className="date-picker-modal-inner-container">
            <div className="date-picker-modal-month-container">
              <img
                className="date-picker-modal-month-button left"
                onClick={onPrevMonth}
                src={arrow}
                alt="arrow"
              />
              <p className="date-picker-modal-month-label">{`${month} ${year}`}</p>
              <img
                className="date-picker-modal-month-button right"
                onClick={onNextMonth}
                src={arrow}
                alt="arrow"
              />
            </div>

            <CustomDatePickerCalendar
              onDateClick={onDateClick}
              monthDays={monthDays}
            />

            {false && (
              <div className="date-picker-modal-buttons-container">
                <div
                  className="date-picker-modal-bubble-button confirm"
                  onClick={onConfirm}
                >
                  Confirma
                </div>
                <div
                  className="date-picker-modal-bubble-button"
                  onClick={onDismiss}
                >
                  Renunta
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
