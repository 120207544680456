import classNames from 'classnames';
import * as React from 'react';
import styles from './MCCenter.module.css';

export interface MCCenterProps {
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
}

export function MCCenter({ children, className }: MCCenterProps) {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
}
