import { Invoice } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/constants';

export const getInvoices = async (filter: any, size = 50, page = 0) => {
  try {
    return (
      await axios.get(`${BASE_URL}/invoices`, {
        params: {
          filter,
          size,
          page,
        },
      })
    ).data.data as { count: number; invoices: Invoice[] };
  } catch (err) {
    console.error('Failed to get invoices');
    console.error(err);
    throw err;
  }
};
