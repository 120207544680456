import moment from 'moment';
import { useMemo } from 'react';
import { Car, Reservation } from '../../../types/api';
import styles from './OwnerCarCard.module.css';

interface Props {
  carDetails: Car;
  noDaysForRental: number;
  beginDayReservation?: Reservation;
  endDayReservation?: Reservation;
}

export const OwnerCarCard = ({
  carDetails,
  noDaysForRental,
  beginDayReservation,
  endDayReservation,
}: Props) => {
  const selectedOffer = useMemo(() => {
    const offers = [...carDetails.settings.rentalOffers];
    const dayCount = noDaysForRental || 7;
    const offer =
      offers.reverse().find((offer) => offer.days <= dayCount) || offers[0];

    return {
      perDayPrice: offer.priceIntegral,
      perDayKm: offer.distance,
      deposit: carDetails.settings.guarantees.integral.guarantee,
    };
  }, [
    carDetails.settings.guarantees.integral.guarantee,
    carDetails.settings.rentalOffers,
    noDaysForRental,
  ]);

  const navigateToCar = () => {
    window.open(`https://app.masinilacheie.ro/car/${carDetails.baseUrl}`);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.textContainer}>
          <p className={styles.title} onClick={navigateToCar}>
            {carDetails.type === 'car'
              ? `${carDetails.make} ${carDetails.model} ${carDetails.year}`
              : carDetails.categoryDetails.name}
          </p>
          {carDetails.type === 'car' && (
            <p
              className={styles.registerPlate}
            >{`${carDetails.registrationNumber}`}</p>
          )}
          <p className={styles.label}>{`${noDaysForRental} zile X ${
            selectedOffer.perDayPrice / 100
          } Ron / Zi`}</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <p className={styles.label}>distanta maxima</p>
            <p className={styles.label}>
              {`${
                noDaysForRental ? selectedOffer.perDayKm * noDaysForRental : '-'
              } Km`}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <p className={styles.label}>depozit garant</p>
            <p className={styles.label}>{`${
              noDaysForRental ? selectedOffer.deposit / 100 : '-'
            } Ron`}</p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <p className={styles.total}>Total</p>
            <p className={styles.total}>{`${
              noDaysForRental
                ? (selectedOffer.perDayPrice * noDaysForRental) / 100
                : '-'
            } Ron`}</p>
          </div>
        </div>
        <img
          className={styles.imgContainer}
          src={carDetails.images[0]}
          onClick={navigateToCar}
          alt="car"
        />
      </div>
      {beginDayReservation && (
        <p className={styles.redLabel}>{`Disponibila pe ${moment
          .unix(beginDayReservation.bufferDateEnd)
          .format('DD.MM.YYYY')} incepand cu ora ${moment
          .unix(beginDayReservation.bufferDateEnd)
          .format('HH:mm')}`}</p>
      )}

      {endDayReservation && (
        <p className={styles.redLabel}>{`Disponibila pana pe ${moment
          .unix(endDayReservation.bufferDateBegin)
          .format('DD.MM.YYYY')} pana la ora ${moment
          .unix(endDayReservation.bufferDateBegin)
          .format('HH:mm')}`}</p>
      )}
    </div>
  );
};
