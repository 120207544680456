import React, { useCallback } from 'react';
import styles from './InputSelect.module.css';
import dropDownArrow from '../../../assets/drop-down-arrow.svg';
import classNames from 'classnames';

export interface InputSelectProps<T> {
  value: T;
  options: Array<{ value: T; name: string }>;
  onValueChange: (value: T) => void;
  label?: string;
  image?: any;
  bold?: boolean;
  marginLeft?: number;
  paddingLeft?: number;
  innerPaddingLeft?: number;
}

export function InputSelect<T extends string>({
  value,
  onValueChange,
  options,
  label,
  image,
  bold = false,
  marginLeft,
  paddingLeft,
  innerPaddingLeft,
}: InputSelectProps<T>) {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onValueChange(event.target.value as unknown as T);
    },
    [onValueChange]
  );

  return (
    <div className={styles.inputFieldContainer} style={{ paddingLeft }}>
      {!!image && image}
      {!!label && <div className={styles.selectLabel}>{label}</div>}
      <div
        className={styles.innerContainer}
        style={{
          marginLeft: marginLeft
            ? marginLeft
            : !!image || !!label
            ? undefined
            : 0,
          marginRight: !!image || !!label ? undefined : 8,
        }}
      >
        <select
          className={classNames(styles.selectContainer, {
            [styles.boldSelectedFont]: bold,
          })}
          style={{ paddingLeft: innerPaddingLeft }}
          value={value}
          onChange={onChange}
        >
          {options.map(({ name, value }, index) => (
            <option value={value} key={`${value}-${index}`}>
              {name}
            </option>
          ))}
        </select>
        <img
          src={dropDownArrow}
          alt="dropdown"
          className={styles.dropdownIcon}
        />
      </div>
    </div>
  );
}
