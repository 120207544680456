import { useDispatch, useSelector } from 'react-redux';
import verifiedIcon from '../../../assets/onboarding/verified.svg';
import {
  isEmailVerified,
  resendEmailVerification,
} from '../../../redux/actions/driverAction';
import { ReduxState } from '../../../types/redux';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { updateDriverAction } from '../../../redux/actions/driverAction';
import './OnboardingEmailStage.style.css';
import { useEffect } from 'react';
import { OnboardingStage } from '../../../types/api';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';

interface Props {
  stage: number;
}
export const OnboardingEmailStage = ({ stage }: Props) => {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  const dispatch = useDispatch();

  const { trackEvent } = useDriverAnalytics();

  useEffect(() => {
    if (
      driver?.driver?.onboarding?.stage &&
      driver?.driver?.onboarding?.stage < 2
    ) {
      setTimeout(() => {
        const isVerified = isEmailVerified();
        if (isVerified) {
          dispatch(
            updateDriverAction({
              ...driver.driver,
              // @ts-ignore
              onboarding: {
                ...driver?.driver?.onboarding,
                stage: OnboardingStage.EmailVerified,
                isEmailVerified: true,
              },
            })
          );
        }
      }, 1500);
    }
  }, [dispatch, driver.driver]);

  return (
    <div className="onboarding-email-container">
      <p className="onboarding-email-header">
        {stage === OnboardingStage.VerifyEmail
          ? 'Deja am trimis un link de validare pe adresa:'
          : 'Felicitari! Adresa ta de email a fost validata'}
      </p>
      {stage === OnboardingStage.VerifyEmail ? (
        <>
          <p className="onboarding-email-email">{driver.driver?.email}</p>
          <p className="onboarding-email-email">
            Verifica emailul. Noi te asteptam aici.
          </p>
          <div className="onboarding-email-loading" />
          <p className="onboarding-email-email">
            Ceva nu a decurs bine?{' '}
            <span
              className="onboarding-email-email underline-clickable"
              onClick={() => {
                trackEvent('Onboarding_ResendEmail_Click');
                resendEmailVerification();
              }}
            >
              Retrimite link
            </span>
          </p>
        </>
      ) : (
        <>
          <img src={verifiedIcon} alt="Verificare" />
          <div style={{ height: 16 }} />
          <ActionButton
            label="Pasul urmator"
            onClick={() => {
              trackEvent('Onboarding_NextStep_Click', { stepNumber: 1 });
              dispatch(
                updateDriverAction({
                  ...driver.driver,
                  // @ts-ignore
                  onboarding: {
                    ...driver?.driver?.onboarding,
                    stage: OnboardingStage.VerifyPhone,
                  },
                })
              );
            }}
          />
        </>
      )}
    </div>
  );
};
