import styles from './ReceptionEventType.module.css';
import receptionArrow from '../../../assets/owner/receptionDeliveries/receptionArrow.png';
import deliveryArrow from '../../../assets/owner/receptionDeliveries/deliveryArrow.png';
import moment from 'moment';

interface Props {
  type: 'DELIVERY' | 'RECEPTION';
  date: number;
}

export const ReceptionEventType = ({ type, date }: Props) => {
  const label = type === 'DELIVERY' ? 'Predare' : 'Primire';
  const hour = moment.unix(date).local().format('HH:mm');

  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer}>
        <p className={styles.label}>
          <strong>{hour}</strong>
        </p>
        <p className={styles.label}>{label}</p>
      </div>
      <img src={type === 'DELIVERY' ? deliveryArrow : receptionArrow} />
    </div>
  );
};
