import moment from 'moment';
import { ReceptionEvent } from '../../../types/receptionDeliveries';
import calendar from '../../../assets/owner/receptionDeliveries/calendar.png';
import dateArrow from '../../../assets/owner/receptionDeliveries/dateArrow.png';
import phoneIcon from '../../../assets/owner/receptionDeliveries/phoneIcon.png';
import userIcon from '../../../assets/owner/receptionDeliveries/userIcon.png';
import locationIcon from '../../../assets/owner/receptionDeliveries/location.png';
import styles from './EventCard.module.css';
import classNames from 'classnames';

interface Props {
  eventData: ReceptionEvent;
  onClick?: () => void;
}

export const EventCard = ({ eventData, onClick }: Props) => {
  const navigateToCar = () => {
    window.open(`https://app.masinilacheie.ro/car/${eventData.car.baseUrl}`);
  };

  const dateBeginFormatted = `${moment
    .unix(eventData.dateBegin)
    .format('DD/MM/YYYY')} Ora ${moment
    .unix(eventData.dateBegin)
    .format('HH:mm')}`;

  const dateEndFormatted = `${moment
    .unix(eventData.dateEnd)
    .format('DD/MM/YYYY')} Ora ${moment
    .unix(eventData.dateEnd)
    .format('HH:mm')}`;

  let userName = eventData.user
    ? eventData.user.firstName + ' ' + eventData.user.lastName
    : eventData.name;
  if (userName === 'undefined undefined') {
    userName = '';
  }

  let phone = eventData.user
    ? eventData.user.onboarding?.phone
    : eventData.phoneNumber;
  if (phone === 'undefined') {
    phone = '';
  }

  return (
    <div
      className={classNames(styles.mainContainer, {
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <div>
        <div className={styles.textContainer}>
          <div className={styles.detailsContainer}>
            <p className={styles.titleLabel}>Detalii inchiriere</p>
            <div className={styles.horizontalContainer}>
              <div className={styles.verticalContainer}>
                <div className={styles.horizontalContainer}>
                  <img className={styles.smallImage} src={calendar} />
                  <p
                    className={
                      eventData.type === 'DELIVERY'
                        ? styles.labelBold
                        : styles.label
                    }
                  >
                    {dateBeginFormatted}
                  </p>
                </div>

                <div className={styles.horizontalContainer}>
                  <img className={styles.smallImage} src={phoneIcon} />
                  <p className={styles.label}>{phone}</p>
                </div>
              </div>

              <div
                className={styles.verticalContainerName}
                style={{ marginLeft: 8 }}
              >
                <div className={styles.horizontalContainer}>
                  <img className={styles.smallImage} src={dateArrow} />
                  <p
                    className={
                      eventData.type === 'RECEPTION'
                        ? styles.labelBold
                        : styles.label
                    }
                  >
                    {dateEndFormatted}
                  </p>
                </div>

                <div className={styles.horizontalContainer}>
                  <img className={styles.smallImage} src={userIcon} />
                  <p className={styles.label}>{userName}</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.carContainer}>
            <p className={styles.titleLabel}>Masina</p>
            {eventData.car.type === 'car' && (
              <>
                <p
                  className={styles.carLabel}
                  onClick={navigateToCar}
                >{`${eventData.car.make} ${eventData.car.model} ${eventData.car.year}`}</p>
                <p className={styles.labelBold}>
                  {eventData.car.registrationNumber}
                </p>
              </>
            )}
            {eventData.car.type === 'category' && (
              <>
                <p
                  className={styles.carLabel}
                  onClick={navigateToCar}
                >{`${eventData.car.categoryDetails.name}`}</p>
              </>
            )}
          </div>
        </div>
        <div className={styles.horizontalContainer} style={{ marginTop: 12 }}>
          <img className={styles.smallImage} src={locationIcon} />
          <p className={styles.label}>{eventData.locationDetails}</p>
        </div>
      </div>
      <img
        className={styles.imageContainer}
        src={eventData.car.images[0]}
        style={{ cursor: 'pointer' }}
        onClick={navigateToCar}
      />
    </div>
  );
};
