export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const DRIVER_UPDATE = 'DRIVER_UPDATE';
export const DRIVER_LOADED = 'DRIVER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGOUT = 'LOGOUT';
