import { useEffect, useMemo, useState } from 'react';
import { useAppTripsQuery, useAppWindowSizeInfo } from '../../../hooks';
import { Location, TripIntervalType } from '../../../types/api';
import {
  MCDivider,
  MCSpacing,
  MCTabGroup,
  MCTitle,
} from '../../../_shared/components';
import { MCTripCard } from '../../../_shared/components/MCTripCard';
import { LeftNavigator } from '../../components/LeftNavigator';
import { TripsEmpty } from '../../components/TripsEmpty/TripsEmpty';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import styles from './TripsPage.module.css';

export const TripsPage = () => {
  const { isMobile } = useAppWindowSizeInfo();

  const tabs = useMemo(() => {
    return [
      { name: 'in desfasurare', value: TripIntervalType.Present },
      { name: 'viitoare', value: TripIntervalType.Future },
      { name: 'finalizate', value: TripIntervalType.Past },
    ];
  }, []);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const { data: reservationsPresent, isLoading: isLoadingPresent } =
    useAppTripsQuery({
      type: TripIntervalType.Present,
    });
  const { data: reservationsFuture, isLoading: isLoadingFuture } =
    useAppTripsQuery({
      type: TripIntervalType.Future,
    });
  const { data: reservationsPast, isLoading: isLoadingPast } = useAppTripsQuery(
    {
      type: TripIntervalType.Past,
    }
  );

  const isLoading = isLoadingFuture || isLoadingPast || isLoadingPresent;
  const reservations =
    activeTab.value === TripIntervalType.Present
      ? reservationsPresent
      : activeTab.value === TripIntervalType.Past
      ? reservationsPast
      : reservationsFuture;

  const { trackEvent } = useDriverAnalytics();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!!reservationsPresent?.length) {
      return;
    }

    if (!!reservationsFuture?.length) {
      return setActiveTab(tabs[1]);
    }

    if (!!reservationsPast?.length) {
      return setActiveTab(tabs[2]);
    }
  }, [
    isLoading,
    reservationsFuture?.length,
    reservationsPast?.length,
    reservationsPresent?.length,
    tabs,
  ]);

  const isEmpty = !isLoading && reservations?.length === 0;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <LeftNavigator />
        <div className={styles.mainContainer}>
          <div className={styles.titleContainer}>
            <MCTitle>Inchirieri</MCTitle>
            <MCDivider fullWidth />
          </div>
          {!isLoading && (
            <>
              <MCTabGroup
                tabs={tabs}
                activeTab={activeTab}
                onActiveTabChanged={(tab) => {
                  trackEvent(
                    tab.value === TripIntervalType.Future
                      ? 'Rentals_Future_Click'
                      : tab.value === TripIntervalType.Past
                      ? 'Rentals_Finalized_Click'
                      : 'Rentals_Current_Click'
                  );

                  setActiveTab(tab);
                }}
              />

              <MCSpacing />
              <MCSpacing />
              {!isMobile && <MCSpacing />}
              {!isEmpty &&
                reservations?.map((reservation) => {
                  const { car, location, dateBegin, dateEnd } = reservation;
                  return (
                    <MCTripCard
                      key={reservation._id}
                      imageSource={reservation.car.images[0]}
                      title={
                        car?.type === 'car'
                          ? `${car?.make} ${car?.model} ${car?.engine.transmission}`
                          : car?.categoryDetails.name
                      }
                      deposit={(reservation?.pricing?.deposit || 0) / 100}
                      address={(location as Location)?.name || ''}
                      fromDate={new Date((dateBegin || 0) * 1000)}
                      toDate={new Date((dateEnd || 0) * 1000)}
                      phone={reservation?.owner?.phoneNumber || ''}
                    />
                  );
                })}
              {isEmpty && <TripsEmpty type={activeTab.name} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
