import { useQuery } from 'react-query';
import { getSeller } from '../../../api/sellers';

export function useOwnerQuery() {
  return useQuery(
    'owner-data',
    async () => {
      return getSeller();
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}
