import classNames from 'classnames';
import { FC } from 'react';
import { svgDecreaseArrow, svgIncreaseArrow } from '../../../../assets';
import { SelectedCarsInfoReport } from '../../../../types/api';
import { MCSvgImage } from '../../../../_shared/components/MCSvgImage';
import style from './InfoPerCarBodyMobile.module.css';

type Props = {
  cars: SelectedCarsInfoReport[];
};

export const InfoPerCarBodyMobile: FC<Props> = ({ cars }) => {
  return (
    <div className={style.carContainer}>
      {cars.map((car) => (
        <div key={car.registrationNumber}>
          <div className={style.modelAndRegistration}>
            <span>{car.model}</span>
            <span className="font-weight-bold">{car.registrationNumber}</span>
          </div>

          <div className={style.dataContainer}>
            <div className={classNames(style.dataCellTitles, 'col p-0')}>
              <span className={style.title}>Venit total</span>
              <span className={style.title}>Venit / zi inchiriere</span>
              <span className={style.title}>Clienti</span>
              <span className={style.title}>Zile de inchiriere</span>
              <span className={style.title}>Nr. de zile / inchiriere</span>
            </div>
            <div className="col">
              {car.data.map((data) => (
                <div className={style.dataCell} key={data.totalIncome}>
                  <span className={style.totalIncome}>{data.totalIncome}</span>
                  {data.incomeSymbol && (
                    <span className={classNames(style.totalIncome, 'ml-1')}>
                      {data.incomeSymbol}
                    </span>
                  )}
                  <div
                    className={classNames(
                      style.statisticsText,
                      data.isIncomeIncrease ? style.greenColor : style.redColor
                    )}
                  >
                    <span>{data.incomeIncreaseValue}</span>
                    {data.incomeSymbol && (
                      <span className="ml-1">{data.incomeSymbol}</span>
                    )}

                    {data.isIncomeIncrease
                      ? svgIncreaseArrow && (
                          <MCSvgImage image={svgIncreaseArrow} size={14} />
                        )
                      : svgDecreaseArrow && (
                          <MCSvgImage image={svgDecreaseArrow} size={14} />
                        )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
