import { MCParagraph, MCSpacing } from '../../../_shared/components';
import { InputField } from '../../../_shared/components/InputField/InputField';
import PhoneInput from 'react-phone-number-input';
import classNames from 'classnames';
import styles from './CheckoutDriverDetails.module.css';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';

interface CheckoutDriverDetailsProps {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  setLastName: (x: string) => void;
  setFirstName: (x: string) => void;
  setEmail: (x: string) => void;
  setPhone: (x: string) => void;
  isLastNameValid: boolean;
  isFirstNameValid: boolean;
  isEmailValid: boolean;
  isPhoneValid: boolean;
}

export function CheckoutDriverDetails({
  lastName,
  firstName,
  email,
  phone,
  setLastName,
  setFirstName,
  setEmail,
  setPhone,
  isLastNameValid,
  isFirstNameValid,
  isEmailValid,
  isPhoneValid,
}: CheckoutDriverDetailsProps) {
  const { trackEvent } = useDriverAnalytics();
  return (
    <div>
      <InputField
        isBold
        labelFontSize={16}
        value={lastName}
        isValid={isLastNameValid}
        onChange={(x) => {
          trackEvent('Checkout_LastNameInput_Type', {
            charCount: x.length,
          });
          setLastName(x);
        }}
        label="Nume"
        placeholder="Nume"
      />
      <MCSpacing />
      <InputField
        isBold
        labelFontSize={16}
        value={firstName}
        isValid={isFirstNameValid}
        onChange={(x) => {
          trackEvent('Checkout_FirstNameInput_Type', {
            charCount: x.length,
          });
          setFirstName(x);
        }}
        label="Prenume"
        placeholder="Prenume"
      />
      <MCSpacing />
      <InputField
        isBold
        labelFontSize={16}
        value={email}
        isValid={isEmailValid}
        type={'email'}
        onChange={(x) => {
          trackEvent('Checkout_EmailInput_Type', {
            charCount: x.length,
          });
          setEmail(x);
        }}
        label="Email"
        placeholder="Email"
      />
      <MCSpacing />
      <div style={{ marginBottom: 8 }}>
        <MCParagraph bold fontSize={16}>
          Telefon
        </MCParagraph>
      </div>

      <PhoneInput
        initialValueFormat="national"
        placeholder="0770 777 777"
        defaultCountry="RO"
        value={phone}
        onChange={(x) => {
          if (x) {
            trackEvent('Checkout_PhoneInput_Type', {
              charCount: x.length,
            });
            setPhone(x);
          }
        }}
        className={classNames({ [styles.error]: !isPhoneValid })}
      />
    </div>
  );
}
