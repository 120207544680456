import moment from 'moment';
import { MCListItem } from '../MCListItem';
import { MCSpacing } from '../MCSpacing';
import { Separator } from '../Separator';
import { MCParagraph, MCTitle } from '../Typography';
import styles from './MCTripCard.module.css';
import { svgArrow, svgCalendar, svgPhone, svgLocation } from '../../../assets';
import { useAppWindowSizeInfo } from '../../../hooks';
import { MCSvgImage } from '../MCSvgImage';

export interface MCTripCardProps {
  imageSource: any;
  title: string;
  phone: string;
  address: string;
  fromDate: Date;
  toDate: Date;
  deposit: number;
}

export function MCTripCard({
  imageSource,
  title,
  phone,
  address,
  fromDate,
  toDate,
  deposit,
}: MCTripCardProps) {
  const { isMobile } = useAppWindowSizeInfo();

  return (
    <div className={styles.container}>
      {isMobile && (
        <>
          <div className={styles.alignFlexEnd}>
            <div>
              <MCSpacing />
              <MCParagraph bold>Depozit: </MCParagraph>
              <MCParagraph>RON {deposit}</MCParagraph>
            </div>
            <MCSpacing />
            <MCParagraph bold underline>
              Vezi factura
            </MCParagraph>
          </div>
          <Separator />
          <MCSpacing />
        </>
      )}
      <img className={styles.thumbnail} src={imageSource} alt="thumbnail" />
      <div className={styles.rightContainer}>
        <MCTitle centerOnMobile>{title}</MCTitle>
        <MCSpacing />
        <div className={styles.detailsContainer}>
          <div className={styles.contactDetailsContainer}>
            <MCListItem image={svgPhone} centerTextOnMobile>
              <MCParagraph>{phone}</MCParagraph>
            </MCListItem>
            <MCListItem image={svgLocation} centerTextOnMobile>
              <MCParagraph>{address}</MCParagraph>
            </MCListItem>
            <MCListItem image={svgCalendar} centerTextOnMobile>
              <MCParagraph>
                {moment(fromDate).format('DD/MM/YYYY Or\\a HH:mm')}
              </MCParagraph>
              <MCSvgImage image={svgArrow} addLeftMargin />
              <MCParagraph>
                {moment(toDate).format('DD/MM/YYYY Or\\a HH:mm')}
              </MCParagraph>
            </MCListItem>
          </div>
          {!isMobile && (
            <>
              <Separator vertical />
              <div>
                <div>
                  <MCParagraph bold>Depozit: </MCParagraph>
                  <MCParagraph>RON {deposit}</MCParagraph>
                </div>
                <MCSpacing />
                <MCParagraph bold underline>
                  Vezi factura
                </MCParagraph>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
