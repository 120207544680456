import axios from 'axios';
import store from '../redux/store';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const token = store.getState()?.userReducer?.token;

  if (!token) {
    return config;
  }

  if (!config.headers) {
    config.headers = {};
  }

  if (!config.headers['authorization']) {
    config.headers['authorization'] = `bearer ${token}`;
  }

  return config;
});

export default instance;
