import { HTMLInputTypeAttribute } from 'react';
import { MCSvgImage } from '../MCSvgImage';
import './InputField.style.css';

interface Props {
  inline?: boolean;
  label?: string;
  icon?: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string;
  isBold?: boolean;
  isValid?: boolean;
  errorText?: string;
  isCenter?: boolean;
  onChange: (value: string) => void;
  labelFontSize?: number;
}

export const InputField = ({
  inline,
  icon,
  label,
  type,
  placeholder,
  value,
  isBold = false,
  isValid = true,
  errorText = '',
  isCenter = false,
  onChange,
  labelFontSize = undefined,
}: Props) => {
  return (
    <div
      className="input-field-container"
      style={{ flexDirection: inline ? 'row' : 'column' }}
    >
      {label && (
        <p
          style={{ fontSize: labelFontSize }}
          className={`input-field-label ${isBold ? 'bold' : ''}`}
        >
          {label}
        </p>
      )}
      <div
        className={`input-field-input ${isValid ? '' : 'invalid'} ${
          isCenter ? 'center' : ''
        }`}
      >
        {icon && <MCSvgImage image={icon} size={13} />}
        <input
          style={{ border: 'none', width: '100%' }}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>

      {!!errorText && (
        <p
          className={
            isValid
              ? 'input-field-label transparent'
              : 'input-field-label error'
          }
        >
          {errorText}
        </p>
      )}
    </div>
  );
};

export const InputFieldPhone = ({
  onChange,
  isCenter,
  isValid,
  value,
  labelFontSize = undefined,
  fullWidth,
}: Props & { fullWidth?: boolean }) => {
  return (
    <div
      style={{ width: fullWidth ? '100%' : undefined }}
      className="input-field-container phone"
    >
      <p
        style={{ fontSize: labelFontSize }}
        className={`input-field-label ${isCenter ? 'center' : ''} bold `}
      >
        Numar de telefon
      </p>
      <input
        className={`input-field-input ${isValid ? '' : 'invalid'} ${
          isCenter ? 'center' : ''
        }`}
        type={'tel'}
        value={value}
        placeholder={'07xx xxx xxx'}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

interface InputNumericFieldProps
  extends Pick<Props, Exclude<keyof Props, 'onChange' | 'value'>> {
  value: number;
  onChange: (value: number) => void;
}

export const InputNumericField = ({
  value,
  onChange,
  ...props
}: InputNumericFieldProps) => {
  return (
    <InputField
      value={`${value}`}
      onChange={(x) => onChange(Number(x))}
      {...props}
    />
  );
};

interface InputPriceFieldProps extends InputNumericFieldProps {}

export const InputPriceField = ({
  value,
  onChange,
  ...props
}: InputPriceFieldProps) => {
  return (
    <InputNumericField
      value={value / 100}
      onChange={(x) => onChange(x * 100)}
      {...props}
    />
  );
};
