import { MCParagraph } from '../Typography';
import styles from './MCTabGroup2.module.css';

export interface MCTabItem2<T> {
  name: string;
  value: T;
}

export interface MCTabGroup2Props<T> {
  activeTab: MCTabItem2<T>;
  tabs: Array<MCTabItem2<T>>;
  onActiveTabChanged: (tab: MCTabItem2<T>) => void;
  capitalize?: boolean;
}

export function MCTabGroup2<T extends string | number>({
  tabs,
  activeTab,
  onActiveTabChanged,
  capitalize = true,
}: MCTabGroup2Props<T>) {
  return (
    <div className={styles.container}>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`${styles.tabItem} ${
            tab.value === activeTab.value ? styles.activeTabItem : ''
          }`}
          style={{ textTransform: capitalize ? 'capitalize' : undefined }}
          onClick={() => onActiveTabChanged(tab)}
        >
          <MCParagraph bold={tab.value === activeTab.value}>
            {tab.name}
          </MCParagraph>
        </div>
      ))}
    </div>
  );
}
