import { useMutation, useQueryClient } from 'react-query';
import { updateCarEvent } from '../../../api/carEvents';
import { CarEvent } from '../../../types/api';

export function useAppUpdateCarEventMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      carEventId,
      carEventData,
    }: {
      carEventId: string;
      carEventData: CarEvent;
    }) => {
      return updateCarEvent(carEventId, carEventData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('owner-carEvents');
      },
    }
  );
}
