import { useMemo } from 'react';
import styles from './AgendaGridlines.module.css';

export function AgendaGridlines() {
  const lineCount = useMemo(() => new Array(15).fill(0), []);

  return (
    <div className={styles.container}>
      {lineCount.map((_, idx) => (
        <div key={idx} className={styles.gridline}></div>
      ))}
    </div>
  );
}
