interface Props {
  vertical?: boolean;
}

export const Separator = ({ vertical }: Props) => {
  // @ts-ignore
  return (
    <div
      style={
        vertical
          ? {
              height: '100%',
              width: 1,
              borderRadius: 10,
              backgroundColor: 'rgba(245, 73, 82, 0.3)',
              marginInline: 32,
            }
          : {
              width: '100%',
              height: 1,
              borderRadius: 10,
              backgroundColor: 'rgba(245, 73, 82, 0.3)',
            }
      }
    />
  );
};
