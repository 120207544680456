import { BASE_URL } from '../utils/constants';
import { Location } from '../types/api';
import axios from '../utils/axios';

export const getLocations = async (filter: any) => {
  try {
    return (
      await axios.get(`${BASE_URL}/location`, {
        params: {
          filter,
        },
      })
    ).data.data;
  } catch (err) {
    console.error('Failed to get locations');
    console.error(err);
    throw err;
  }
};

export const createLocation = async (
  locationData: Location
): Promise<Location> => {
  try {
    return (await axios.post(`${BASE_URL}/location`, locationData)).data.data;
  } catch (err) {
    console.error('Failed to create location');
    console.error(err);
    throw err;
  }
};

export const updateLocation = async (
  locationId: string,
  locationData: Location
) => {
  try {
    return (await axios.put(`${BASE_URL}/location/${locationId}`, locationData))
      .data.data;
  } catch (err) {
    console.error('Failed to put location');
    console.error(err);
    throw err;
  }
};

export const deleteLocation = async (locationId: string) => {
  try {
    return await axios.delete(`${BASE_URL}/location/${locationId}`);
  } catch (err) {
    console.error('Failed to delete location');
    console.error(err);
    throw err;
  }
};
