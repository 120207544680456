import { useQuery } from 'react-query';
import { getCarEvents } from '../../../api/carEvents';

export function useAppCarEventsQuery(
  filter: any,
  { size, page }: { size?: number; page?: number } = {}
) {
  return useQuery(['owner-carEvents', { filter, size, page }], async () => {
    return (await getCarEvents(filter, size, page)).carEvents;
  });
}
