import React from 'react';
import { svgAddIcon } from '../../../assets';
import { MCParagraph } from '../Typography';
import styles from './MCButton.module.css';

interface Props {
  label: string;
  isLoading?: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export function MCButton({ label, isLoading, onClick, disabled }: Props) {
  return (
    <div
      className={styles.smallButton}
      onClick={disabled ? undefined : onClick}
    >
      <img src={svgAddIcon} />
      <div style={{ width: 8 }} />
      <MCParagraph cursor="pointer" bold>
        {label}
      </MCParagraph>
    </div>
  );
}
