import { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppCarByFilterQuery } from '../../../hooks/queries/useAppCarByFilterQuery';
import { MCSpacing } from '../../../_shared/components';
import { CheckoutHeader } from '../../components/Checkout';
import { CheckoutBody } from '../../components/Checkout/CheckoutBody';
import styles from './Checkout.module.css';
import { GuaranteeType } from '../../../types/api';
import { EXTRA_KM } from '../../../utils/constants';

interface CheckoutProps {}

export function Checkout({}: CheckoutProps) {
  const { search } = useLocation();

  const { data: car } = useAppCarByFilterQuery({
    _id: new URLSearchParams(search).get('carId') || '',
  });

  const rawParams = useMemo(() => {
    const params = new URLSearchParams(search);

    const guarantees: Array<GuaranteeType | null> = [
      'integral',
      'reduced',
      'minimal',
    ];

    const guaranteeType = (
      guarantees.includes(params.get('assurance') as any)
        ? params.get('assurance') || 'integral'
        : 'integral'
    ) as GuaranteeType;

    const extraKm = params.get('extraKm');

    return {
      carId: params.get('carId') || '',
      locationId: params.get('locationId') || '',
      dateBegin: Number(params.get('dateBegin') || '0'),
      dateEnd: Number(params.get('dateEnd') || '0'),
      guaranteeType: car?.settings?.guarantees?.[guaranteeType].active
        ? guaranteeType
        : 'integral',
      euExit: !!car?.settings.euExitActive && params.get('euexit') === 'true',
      extraKm: EXTRA_KM.find(x => x.value === extraKm)?.value || '0',
    };
  }, [search, car]);

  if (
    !rawParams.carId ||
    !rawParams.dateBegin ||
    !rawParams.dateEnd ||
    !rawParams.locationId
  ) {
    return <Redirect to={'/catalog'} />;
  }

  return (
    <div className={styles.container}>
      <CheckoutHeader />
      <MCSpacing />
      {car && (
        <CheckoutBody
          car={car}
          dateBegin={rawParams.dateBegin}
          dateEnd={rawParams.dateEnd}
          locationId={rawParams.locationId}
          guaranteeType={rawParams.guaranteeType}
          euExit={rawParams.euExit}
          extraKm={rawParams.extraKm}
        />
      )}
    </div>
  );
}
