import GoogleMapReact from 'google-map-react';
import { useCallback, useMemo } from 'react';
import { svgLocationBoldIcon } from '../../../assets';
import { Location } from '../../../types/api';
import { MCParagraph, MCSpacing } from '../../../_shared/components';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { MCTooltip } from '../../../_shared/components/MCTooltip';
import { useOwnerAnalytics } from '../../hooks';
import styles from './FixLocation.module.css';

interface props {
  location: Location;
  allLocations: Array<Location>;
  setLocation: (loc: Location) => void;
  onDelete: () => void;
}

const AnyReactComponent = (props: any) => (
  <div className={styles.mapPinContainer}>
    <img src={svgLocationBoldIcon} className={styles.mapPin} />
  </div>
);

export const FixLocation = ({
  location,
  allLocations,
  setLocation,
  onDelete,
}: props) => {
  const { trackEvent } = useOwnerAnalytics();
  const toOption = useCallback((location: Location) => {
    return {
      name: location.name,
      value: location._id!,
    };
  }, []);

  const locationSelectOptions = useMemo(() => {
    return [...(allLocations || []).map(toOption)];
  }, [allLocations, toOption]);

  return (
    <div className={styles.fixedLocation}>
      <div className={styles.fixedLocationContainer}>
        <div className={styles.nameContainer}>
          <div className={styles.inputField}>
            <div style={{ width: 396 }}>
              <InputField
                label="Nume cartier / sector"
                placeholder={'Ex: Cartierul Primaverii'}
                value={location.name}
                type={'email'}
                onChange={(e) => {
                  trackEvent('Locations_NeighbourhoodInput_Type', { name: e });
                  setLocation({
                    ...location,
                    name: e,
                  });
                }}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <MCTooltip
                children={
                  <div className={styles.modalContainer}>
                    <h5 className={styles.headerText}>Nume Punct Fix</h5>
                    <p className={styles.tooltipText}>
                      Acesta este numele locatiei vazut de catre consumatori in
                      pagina masinii. Acest nume are rolul de a informa clientul
                      in ce CARTIER sau in ce SECTOR se afla masina.
                    </p>
                    <p className={styles.tooltipText}>
                      Acest nume nu trebuie sa descrie locatia exacta a masinii.
                      Pentru asta avem campul "Adresa completa".
                    </p>
                  </div>
                }
              />
            </div>
          </div>

          <MCSpacing />
          <div style={{ width: 396 }}>
            <InputSelect
              label="Locatie"
              value={
                location?.parent?.length
                  ? location.parent[0]
                  : allLocations[0]._id!
              }
              options={locationSelectOptions}
              onValueChange={(e) => {
                const newLocation = allLocations.find((loc) => loc._id === e);
                trackEvent('Locations_LocationCombo_Select', {
                  name: newLocation?.name || '',
                });

                setLocation({
                  ...location,
                  parent: [e],
                  lat: newLocation?.lat!,
                  lng: newLocation?.lng!,
                });
              }}
            />
          </div>
          <MCSpacing />

          <div className={styles.inputField}>
            <div style={{ width: 396 }}>
              <InputField
                label="Adresa completa"
                placeholder={
                  'Ex: Bucuresti, Sector 6, Strada Primaverii, nr. 103'
                }
                value={location.fullAdress}
                onChange={(e) => {
                  trackEvent('Locations_FullAddressInput_Type', {
                    name: e,
                  });
                  setLocation({ ...location, fullAdress: e });
                }}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <MCTooltip
                children={
                  <div className={styles.modalContainer}>
                    <h5 className={styles.headerText}>Adresa Exacta</h5>
                    <p className={styles.tooltipText}>
                      Aceasta este adresa exact a punctului de primire/predare a
                      masinii. Aceasta adresa va fi transmisa catre chirias dupa
                      ce acesta confirma inchirierea, in emailul ce contine
                      toate detaliile inchirierii.
                    </p>
                    <p className={styles.tooltipText}>
                      Adresa trebuie sa contina (daca este cazul) Oras, Sector,
                      Strada, Numar strada. Optional se poate specifica si un
                      punct de reper.
                    </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.mapContainer}>
          <MCParagraph textAlign="center">Pune pin-ul pe harta</MCParagraph>
          <MCSpacing amount={8} />
          <div className={styles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCJ3GaqSd71LeVzSsm2v9254ZC16s9qaeo',
              }}
              center={location}
              defaultZoom={11}
              options={{
                maxZoom: 17,
                minZoom: 5,
                fullscreenControl: false,
                zoomControl: true,
                draggable: true,
                keyboardShortcuts: false,
              }}
              onClick={({ lat, lng }) => {
                trackEvent('Locations_MapPin_Pick', {
                  lat,
                  lng,
                });
                setLocation({ ...location, lat, lng });
              }}
            >
              <AnyReactComponent
                key={'asf'}
                lat={location.lat}
                lng={location.lng}
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
      {/* <div className={styles.alignRight}>
        <MCSpacing />
        <MCParagraph
          fontSize={16}
          underline
          cursor="pointer"
          color="red"
          onClick={() => {
            trackEvent('Locations_DeleteLocation_Click');
            onDelete();
          }}
        >
          Sterge locatie
        </MCParagraph>
      </div> */}
    </div>
  );
};
