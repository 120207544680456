import { useState, useLayoutEffect } from 'react';

interface SizeInfo {
  width: number | undefined;
  height: number | undefined;
  isMobile: boolean;
}

export function useAppWindowSizeInfo() {
  const [windowSizeInfo, setWindowSizeInfo] = useState<SizeInfo>({
    width: undefined,
    height: undefined,
    isMobile: true,
  });
  useLayoutEffect(() => {
    function handleResize() {
      setWindowSizeInfo({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < 470,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSizeInfo;
}
