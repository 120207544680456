import classNames from 'classnames';
import * as React from 'react';
import styles from './LinkText.module.css';

export interface LinkTextProps {
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  destructive?: boolean;
  center?: boolean;
}

export function LinkText({
  onClick,
  children,
  destructive = true,
  center = false,
}: LinkTextProps) {
  return (
    <div
      onClick={onClick}
      className={classNames({
        [styles.center]: center,
      })}
    >
      <span
        className={classNames(styles.linkText, {
          [styles.destructive]: destructive,
        })}
      >
        {children}
      </span>
    </div>
  );
}
