import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteDriver, getDrivers } from '../../../api/drivers';
import { Table } from '../../../_shared/components/Table';

export const UsersPage = () => {
  const [users, setUsers] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const asyncGetUsers = async () => {
      const users = await getDrivers({});
      setUsers(users);
    };

    asyncGetUsers();
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const onRemove = async (id: string) => {
    setTimeout(() => {
      refreshPage();
    }, 300);
    await deleteDriver(id);
  };

  return (
    <div
      className="d-flex justify-content-center row-hl col-sm-6 col-md-8"
      style={{ marginTop: 60, marginBottom: 10 }}
    >
      <div className="col-6">
        <div>
          <h2>Users</h2>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push(`/user/new`);
            }}
          >
            Add User
          </button>
          <Table
            keys={['email', 'phone', 'firstName', 'lastName']}
            data={users}
            onEdit={(id: string) => {
              history.push(`/user/${id}`);
            }}
            onRemove={onRemove}
          />
        </div>
      </div>
    </div>
  );
};
