import classNames from 'classnames';
import { FC } from 'react';
import { svgDecreaseArrow, svgIncreaseArrow } from '../../../../assets';
import { SelectedCarsInfoReport } from '../../../../types/api';
import { MCSvgImage } from '../../../../_shared/components/MCSvgImage';
import style from './InfoPerCarBody.module.css';

type Props = {
  cars: SelectedCarsInfoReport[];
};

export const InfoPerCarBody: FC<Props> = ({ cars }) => {
  return (
    <>
      {cars.map((car) => (
        <div className={style.row} key={car.registrationNumber}>
          <div className={classNames(style.modelAndRegistration, 'col-2 p-0')}>
            <span>{car.model}</span>
            <span className="font-weight-bold">{car.registrationNumber}</span>
          </div>
          {car.data.map((data) => (
            <div
              className={classNames(style.dataCell, 'col-2 p-0')}
              key={data.totalIncome}
            >
              <span className={style.totalIncome}>{data.totalIncome}</span>
              {data.incomeSymbol && (
                <span className={classNames(style.totalIncome, 'ml-1')}>
                  {data.incomeSymbol}
                </span>
              )}
              <div
                className={classNames(
                  style.statisticsText,
                  data.isIncomeIncrease ? style.greenColor : style.redColor
                )}
              >
                <span>{data.incomeIncreaseValue}</span>
                {data.incomeSymbol && (
                  <span className="ml-1">{data.incomeSymbol}</span>
                )}

                {data.isIncomeIncrease
                  ? svgIncreaseArrow && (
                      <MCSvgImage image={svgIncreaseArrow} size={14} />
                    )
                  : svgDecreaseArrow && (
                      <MCSvgImage image={svgDecreaseArrow} size={14} />
                    )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
