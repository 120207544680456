import moment from 'moment';
import { useQuery } from 'react-query';
import { getReservationsForCar } from '../../api/reservations';
import { PlatformType, PLATFORM_TYPE } from '../../utils/constants';

const IS_OWNER = PLATFORM_TYPE === PlatformType.Owner;

const minimumDate = moment().startOf('day').toDate();
const maximumDate = new Date();
maximumDate.setMonth(maximumDate.getMonth() + 4);

export function useAppCarReservationsQuery(
  {
    beginDate = minimumDate,
    endDate = maximumDate,
    carId,
    excludeId,
  }: {
    carId: string | string[];
    beginDate?: Date;
    endDate?: Date;
    excludeId?: string;
  },
  options: { enabled?: boolean } = { enabled: true }
) {
  return useQuery(
    ['car-reservations', { beginDate, endDate, carId, excludeId }],
    async () => {
      if (carId instanceof String && carId.trim() === '') {
        return [];
      }

      const reservations = await getReservationsForCar({
        carId,
        beginDate: moment(beginDate).unix(),
        endDate: moment(endDate).unix(),
        isOwner: IS_OWNER,
      });

      return reservations.filter(
        (reservation) => reservation._id !== excludeId
      );
    },
    options
  );
}
