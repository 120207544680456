import { useHistory } from 'react-router-dom';
import { useProfileCompletionLevel } from '../../../hooks';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import './ProfileCompletion.style.css';

export const ProfileCompletion = () => {
  const { completionLevel, completed } = useProfileCompletionLevel();

  const history = useHistory();
  const { trackEvent } = useDriverAnalytics();

  return (
    <div className="profile-data-container-right">
      <p className="profile-data-right-header">Nivel completare profil</p>
      <div className={`profile-data-complation progress${completionLevel}`}>
        <p className="profile-data-right-progress">{completionLevel}%</p>
      </div>

      {completed ? (
        <>
          <ActionButton
            label={'Deschide catalog'}
            onClick={() => {
              trackEvent('Profile_OpenCatalog_Click');
              history.push('/catalog');
            }}
          />
        </>
      ) : (
        <>
          <ActionButton
            label={'Finalizeaza profil'}
            onClick={() => {
              trackEvent('Profile_ContinueOnboarding_Click');
              history.push('/onboarding');
            }}
          />
        </>
      )}
    </div>
  );
};
