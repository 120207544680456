import { UserReduxModel, Action } from '../../types/redux';
import { TOKEN } from '../../utils/constants';
import {
  REGISTER_FAIL,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  DRIVER_LOADED,
  REGISTER_SUCCESS,
  DRIVER_UPDATE,
} from '../types';

const initialState: UserReduxModel = {
  token: localStorage.getItem(TOKEN),
  isAuthenticated: false,
  isLoading: true,
  driver: undefined,
  owner: undefined,
};

export const userReducer = (
  state: UserReduxModel = initialState,
  action: Action
): UserReduxModel => {
  const { type, payload } = action;

  switch (type) {
    case DRIVER_UPDATE:
    case DRIVER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        driver: payload,
        owner: payload,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isLoading: true,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT: {
      localStorage.removeItem(TOKEN);
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        driver: undefined,
        owner: undefined,
      };
    }
    default:
      return state;
  }
};
