// @ts-ignore
import moment from 'moment-timezone';

import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useSelector } from 'react-redux';
import store from './redux/store';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Setup } from './_shared/components/Setup';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import 'react-image-lightbox/style.css';
import './styles.css'
import { useEffect } from 'react';
import Modal from 'react-modal';
import {
  IS_DEV_ENV,
  MIXPANEL_TOKEN,
  MONTHS_SHORT,
  STRIPE_PUB_KEY,
} from './utils/constants';
import mixpanel from 'mixpanel-browser';
import { ReduxState } from './types/redux';
import { HelmetProvider } from 'react-helmet-async';

mixpanel.init(MIXPANEL_TOKEN, { debug: IS_DEV_ENV, ignore_dnt: true });

const queryClient = new QueryClient();

const stripePromise = loadStripe(STRIPE_PUB_KEY);

function MixpanelIdentifier() {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  useEffect(() => {
    if (driver.driver?.email || driver.owner?.email) {
      mixpanel.identify(driver.driver?.email || driver.owner?.email);
    }
  }, [driver.driver?.email, driver.owner?.email]);

  return null;
}

function App() {
  useEffect(() => {
    Modal.setAppElement('body');
    const locale = moment.locale();
    if (locale === 'ro') {
      moment.updateLocale(locale, {
        monthsShort: MONTHS_SHORT,
      });
    }
  }, []);

  return (
    <div className="webpage">
      <HelmetProvider>
        <Elements stripe={stripePromise}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <MixpanelIdentifier />
              <Setup />
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Elements>
      </HelmetProvider>
    </div>
  );
}

export default App;
