import React from 'react';
import { Reservation } from '../../../types/api';
import { HistoryRentalCard } from '../../../_driver/components/HistoryRentalCard';
import { MCParagraph, MCSpacing } from '../../../_shared/components';
import styles from './RentalHistoryTable.module.css';

interface RentalHistoryTableProps {
  reservations: Reservation[];
  onClickReservation: (reservation: Reservation) => void;
}

export function RentalHistoryTable({
  reservations,
  onClickReservation,
}: RentalHistoryTableProps) {
  return (
    <div>
      <div className={styles.tableHeader}>
        <div style={{ width: '25%' }}>
          <MCParagraph bold>Interval</MCParagraph>
        </div>
        <div style={{ width: '20%' }}>
          <MCParagraph bold>Masina</MCParagraph>
        </div>
        <div style={{ width: '20%' }}>
          <MCParagraph bold>Costul</MCParagraph>
        </div>
        <div style={{ width: '20%' }}>
          <MCParagraph bold>Client</MCParagraph>
        </div>
        <div style={{ width: '15%' }}>
          <MCParagraph bold>ID inchiriere</MCParagraph>
        </div>
      </div>
      <MCSpacing />
      {reservations.map((reservation) => (
        <HistoryRentalCard
          key={reservation._id}
          reservation={reservation}
          onClickReservation={onClickReservation}
        />
      ))}
    </div>
  );
}
