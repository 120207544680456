import * as React from 'react';
import styles from './MCCheckbox.module.css';

export interface MCCheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
}

export function MCCheckbox({ value, onChange, children }: MCCheckboxProps) {
  return (
    <div className={styles.container}>
      <input
        className={styles.checkbox}
        type="checkbox"
        checked={value}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      {children}
    </div>
  );
}

export function MCCheckboxAcceptTermsAndConds({
  value,
  onChange,
}: Pick<MCCheckboxProps, 'onChange' | 'value'>) {
  return (
    <MCCheckbox value={value} onChange={onChange}>
      <p className="login-form-accept-terms-label ">
        Accept{' '}
        <a
          href="https://masinilacheie.ro/partner_terms_and_conditions/"
          target="_blank"
        >
          <span className="login-form-accept-terms-label red">
            Condițiile de utilizare
          </span>
        </a>
      </p>
    </MCCheckbox>
  );
}
