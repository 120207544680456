import moment from 'moment';
import { useMemo } from 'react';
import { svgArrow, svgLocation } from '../../../assets';
import { Car, CarLocation } from '../../../types/api';
import { MCParagraph, MCSpacing } from '../../../_shared/components';
import { InputField } from '../../../_shared/components/InputField/InputField';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import styles from './CheckoutRentalDetails.module.css';

interface CheckoutRentalDetailsProps {
  from?: Date;
  to?: Date;
  car: Car;
  locationId: string;
  locationHint: string;
  setLocationHint: (x: string) => void;
  onEdit: () => void;
}

export function CheckoutRentalDetails({
  from = new Date(),
  to = new Date(),
  car,
  locationId,
  locationHint,
  setLocationHint,
  onEdit,
}: CheckoutRentalDetailsProps) {
  const location = useMemo(() => {
    return (car.location as CarLocation[]).find(
      (x) => x.location._id === locationId
    );
  }, [car.location, locationId]);

  const { withEvent } = useDriverAnalytics();

  const begin = moment
    .utc()
    .set('year', from.getFullYear())
    .set('month', from.getMonth())
    .set('date', from.getDate())
    .set('hours', from.getHours())
    .set('minutes', from.getMinutes());

  const end = moment
    .utc()
    .set('year', to.getFullYear())
    .set('month', to.getMonth())
    .set('date', to.getDate())
    .set('hours', to.getHours())
    .set('minutes', to.getMinutes());

  return (
    <div>
      <MCSpacing />
      <div className={styles.row}>
        <div>
          <div>
            <MCParagraph bold>Interval: </MCParagraph>
          </div>
          <div>
            <MCParagraph>{begin.format('DD/MM/YYYY Or\\a HH:mm')}</MCParagraph>
            <img src={svgArrow} className={styles.arrow} alt="arrow" />
            <MCParagraph>{end.format('DD/MM/YYYY Or\\a HH:mm')}</MCParagraph>
          </div>
        </div>
        <MCParagraph
          underline
          onClick={withEvent('Checkout_EditInterval_Click')(onEdit)}
        >
          Editeaza
        </MCParagraph>
      </div>
      <MCSpacing />
      <div className={styles.row}>
        <div>
          <MCParagraph bold>Locatie: </MCParagraph>
          <MCParagraph>{location?.location.name}</MCParagraph>
        </div>
        <MCParagraph
          underline
          onClick={withEvent('Checkout_EditLocation_Click')(onEdit)}
        >
          Editeaza
        </MCParagraph>
      </div>
      {false && location?.location.isAreaLocation && (
        <>
          <MCSpacing />
          <InputField
            icon={svgLocation}
            placeholder="Reper"
            value={locationHint}
            onChange={setLocationHint}
          />
        </>
      )}
    </div>
  );
}
