import moment from 'moment';
import { svgRightArrowIcon } from '../../../assets';
import { CarEvent } from '../../../types/api';
import { MCCard, MCParagraph } from '../../../_shared/components';
import { MCButton } from '../../../_shared/components/MCButton';

interface props {
  carEvent: any;
  onEdit: (event: CarEvent) => void;
}

export const HistoryCarEvent = ({ carEvent, onEdit }: props) => {
  return (
    <MCCard style={{ width: '100%', padding: 32 }} hover>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '12%' }}>
          <MCParagraph fontSize={18} bold>
            {carEvent.type}
          </MCParagraph>
        </div>
        <div
          style={{
            width: '13%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <MCParagraph>
            {moment.unix(carEvent.dateStart).format('DD/MM/YYYY')}
          </MCParagraph>
        </div>
        <img src={svgRightArrowIcon}></img>
        <div
          style={{
            width: '13%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <MCParagraph>
            {moment.unix(carEvent.dateEnd).format('DD/MM/YYYY')}
          </MCParagraph>
        </div>
        <div
          style={{
            width: '20%',
          }}
        >
          <MCParagraph>{carEvent.issuer}</MCParagraph>
        </div>

        <div
          style={{
            width: '20%',
          }}
        >
          {carEvent.cost && (
            <MCParagraph bold>{carEvent.cost / 100} Ron</MCParagraph>
          )}
        </div>
        <div
          style={{
            width: '22%',
          }}
        >
          <MCButton
            label={`Editeaza ${carEvent.type}`}
            onClick={() => {
              onEdit(carEvent);
            }}
          />
        </div>
      </div>
    </MCCard>
  );
};
