import contactIcon from '../../../assets/profile/contact-icon.svg';
import contractIcon from '../../../assets/profile/contract-icon.svg';
import dataSafetyIcon from '../../../assets/profile/data-safety-icon.svg';
import './ProfileWhyCollect.style.css';

export const ProfileWhyCollect = () => {
  return (
    <div className="profile-de-ce-container">
      <p className="profile-de-ce-header">De ce colectam aceste date?</p>
      <div className="profile-de-ce-h-container">
        <div className="profile-de-ce-inner-container">
          <img src={contactIcon} alt="contact" />
          <p className="profile-de-ce-label">Contact</p>
          <p className="profile-de-ce-label sublabel">
            Dorim sa te tinem la curent cu orice modificare ce poate aparea
            legat de rezervarea ta.
          </p>
        </div>
        <div className="profile-de-ce-inner-container">
          <img src={contractIcon} alt="contract" />
          <p className="profile-de-ce-label">Realizarea contractului</p>
          <p className="profile-de-ce-label sublabel">
            Aceste date ajuta partenerul de la care ai inchiriat masina sa
            completeze contractul.
          </p>
        </div>
        <div className="profile-de-ce-inner-container">
          <img src={dataSafetyIcon} alt="data-ssafety" />
          <p className="profile-de-ce-label">Siguranta datelor tale</p>
          <p className="profile-de-ce-label sublabel">
            Datele tale sunt in siguranta cu noi fiind protejate in baza de date
            MongoDB Atlas.
          </p>
        </div>
      </div>
    </div>
  );
};
