import moment from 'moment';
import { useCallback, useMemo, useReducer } from 'react';

const minimumDate = new Date();
minimumDate.setHours(0, 0, 0, 0);

const dateBegin = new Date(minimumDate.getTime());
dateBegin.setDate(minimumDate.getDate());
const dateEnd = new Date(minimumDate.getTime());
dateEnd.setDate(minimumDate.getDate() + 5);

export const START_HOURS = 9;
const START_MINUTES = 60 * START_HOURS;

function getInitialState({ from, to }: { from?: number; to?: number }): {
  dateBegin?: Date;
  dateEnd?: Date;
} {
  if (from && to) {
    return {
      dateBegin: moment(from).toDate(),
      dateEnd: moment(to).toDate(),
    };
  }

  if (from) {
    return {
      dateBegin: moment(from)
        .startOf('day')
        .add(START_MINUTES, 'minutes')
        .toDate(),
      dateEnd: moment(from)
        .startOf('day')
        .add(5, 'days')
        .add(START_MINUTES, 'minutes')
        .toDate(),
    };
  }

  return {};
}

export interface AppTimeFormReducerState {
  dateBegin?: Date;
  dateEnd?: Date;
  minutesBegin: number;
  minMinutesBegin: number;
  minutesEnd: number;
}

function getInitialReducerState(params: { from?: number; to?: number }) {
  const initialState = getInitialState(params);
  let minMinutes = 0;
  const today = new Date();
  if (
    initialState &&
    initialState.dateBegin &&
    today.getDate() === initialState.dateBegin.getDate() &&
    today.getMonth() === initialState.dateBegin.getMonth()
  ) {
    minMinutes =
      Math.floor((today.getHours() * 60 + today.getMinutes() + 150) / 30) * 30;
  }

  const begin = new Date(
    moment(initialState.dateBegin).toDate().setHours(0, 0, 0, 0)
  );

  const end = new Date(
    moment(initialState.dateEnd).toDate().setHours(0, 0, 0, 0)
  );

  const result: AppTimeFormReducerState = {
    dateBegin: initialState.dateBegin ? begin : undefined,
    dateEnd: initialState.dateEnd ? end : undefined,
    minutesBegin: initialState.dateBegin
      ? moment(initialState.dateBegin).diff(begin, 'minutes')
      : START_MINUTES, // 09:00 AM
    minMinutesBegin: minMinutes,
    minutesEnd: initialState.dateEnd
      ? moment(initialState.dateEnd).diff(end, 'minutes')
      : START_MINUTES, // 09:00 AM
  };

  return result;
}

enum ActionType {
  SetDateBegin = 'SetDateBegin',
  SetDateEnd = 'SetDateEnd',
  SetMinutesBegin = 'SetMinutesBegin',
  SetMinMinutesBegin = 'SetMinMinutesBegin',
  SetMinutesEnd = 'SetMinutesEnd',
  Clear = 'Clear',
}

type Action =
  | {
      type: typeof ActionType.SetDateBegin;
      dateBegin: Date;
      minDaysBetween: number;
    }
  | { type: typeof ActionType.SetDateEnd; dateEnd: Date }
  | { type: typeof ActionType.SetMinutesBegin; minutesBegin: number }
  | { type: typeof ActionType.SetMinMinutesBegin; minMinutesBegin: number }
  | { type: typeof ActionType.SetMinutesEnd; minutesEnd: number }
  | { type: typeof ActionType.Clear };

function AppTimeFormReducer(
  state: AppTimeFormReducerState,
  action: Action
): AppTimeFormReducerState {
  switch (action.type) {
    case ActionType.Clear:
      return getInitialReducerState({});

    case ActionType.SetDateBegin: {
      const dateBeginMoment = moment(action.dateBegin).startOf('day');
      const newDateBegin = new Date(action.dateBegin.setHours(0, 0, 0, 0));

      let newDateEnd = state.dateEnd;
      if (newDateEnd && moment(newDateEnd).diff(dateBeginMoment, 'days') <= 0) {
        newDateEnd = new Date(newDateBegin);
        newDateEnd.setTime(
          newDateBegin.getTime() + action.minDaysBetween * 24 * 60 * 60 * 1000 // transform a day in milliseconds
        );
      }

      return {
        ...state,
        dateBegin: newDateBegin,
        dateEnd: newDateEnd,
      };
    }

    case ActionType.SetDateEnd: {
      const newDateEnd = new Date(action.dateEnd.setHours(0, 0, 0, 0));
      return {
        ...state,
        dateEnd: newDateEnd,
      };
    }

    case ActionType.SetMinutesBegin: {
      return {
        ...state,
        minutesBegin: Math.floor(action.minutesBegin / 30) * 30,
      };
    }

    case ActionType.SetMinMinutesBegin: {
      return {
        ...state,
        minMinutesBegin: Math.floor(action.minMinutesBegin / 30) * 30,
      };
    }

    case ActionType.SetMinutesEnd: {
      return {
        ...state,
        minutesEnd: Math.floor(action.minutesEnd / 30) * 30,
      };
    }
  }

  return state;
}

export function useAppTimeForm({
  from,
  to,
  minDaysBetween = 1,
}: {
  from?: number;
  to?: number;
  minDaysBetween?: number;
}) {
  const [reducerState, dispatch] = useReducer(
    AppTimeFormReducer,
    getInitialReducerState({
      from: from ? from * 1000 : undefined,
      to: to ? to * 1000 : undefined,
    })
  );

  const setDateBegin = useCallback(
    (dateBegin: Date) => {
      const today = new Date();
      dispatch({ type: ActionType.SetDateBegin, dateBegin, minDaysBetween });
      if (
        today.getDate() === dateBegin.getDate() &&
        today.getMonth() === dateBegin.getMonth()
      ) {
        // Add 2.5h if the rental starts today
        // Sync begin minutes and end minutes
        const minMinutes = today.getHours() * 60 + today.getMinutes() + 150;
        const showMinutes = Math.max(minMinutes, 9 * 60);

        dispatch({
          type: ActionType.SetMinutesBegin,
          minutesBegin: showMinutes,
        });
        dispatch({ type: ActionType.SetMinutesEnd, minutesEnd: showMinutes });
        dispatch({
          type: ActionType.SetMinMinutesBegin,
          minMinutesBegin: Math.min(minMinutes, 23 * 60 + 30),
        });
      } else {
        dispatch({
          type: ActionType.SetMinMinutesBegin,
          minMinutesBegin: 0,
        });
      }
    },
    [dispatch, minDaysBetween]
  );

  const setDateEnd = useCallback(
    (dateEnd: Date) => {
      dispatch({ type: ActionType.SetDateEnd, dateEnd });
    },
    [dispatch]
  );

  const setMinutesBegin = useCallback(
    (minutesBegin: number) => {
      dispatch({ type: ActionType.SetMinutesBegin, minutesBegin });
    },
    [dispatch]
  );

  const setMinMinutesBegin = useCallback(
    (minMinutesBegin: number) => {
      dispatch({ type: ActionType.SetMinMinutesBegin, minMinutesBegin });
    },
    [dispatch]
  );

  const setMinutesEnd = useCallback(
    (minutesEnd: number) => {
      dispatch({ type: ActionType.SetMinutesEnd, minutesEnd });
    },
    [dispatch]
  );

  const clearInterval = useCallback(() => {
    dispatch({ type: ActionType.Clear });
  }, [dispatch]);

  const finalInterval = useMemo(() => {
    return {
      dateBegin:
        reducerState.dateBegin &&
        moment(reducerState.dateBegin)
          .add(reducerState.minutesBegin, 'minutes')
          .toDate(),

      dateEnd:
        reducerState.dateEnd &&
        moment(reducerState.dateEnd)
          .add(reducerState.minutesEnd, 'minutes')
          .toDate(),
    };
  }, [reducerState]);

  return {
    interval: reducerState,
    finalInterval,
    setDateBegin,
    setDateEnd,
    setMinutesBegin,
    setMinMinutesBegin,
    setMinutesEnd,
    clearInterval,
  };
}
