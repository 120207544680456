import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from '../../../_shared/components/Layout';
import { ReduxState } from '../../../types/redux';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

export const StripeCancel = () => {
  const auth = useSelector((state: ReduxState) => state.userReducer);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      toast.error('Payment failed');
      history.push('/reservations');
    }, 1000);
  }, [auth, history]);

  return (
    <Layout>
      <div className="d-flex justify-content-center p-5">
        <LoadingOutlined className="h1 p-5 text-danger" />
      </div>
    </Layout>
  );
};
