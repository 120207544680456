import { LoadingOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Car } from '../../../types/api';
import {
  CAR_ACTIVE,
  CAR_AUTOMATIC,
  CAR_COMING_SOON,
  CAR_DEACTIVATED,
  CAR_FUEL_DIESEL,
  CAR_FUEL_ELECTRIC,
  CAR_FUEL_GAS,
  CAR_FUEL_HYBRID_GAS,
  CAR_FUEL_HYBRID_PLUG,
  CAR_FULE_GAS_GPL,
  CAR_MANUAL,
  CAR_TRACTION_ALL,
  CAR_TRACTION_FRONT,
  CAR_TRACTION_REAR,
  CAR_TYPE_BREAK,
  CAR_TYPE_CONVERTIBLE,
  CAR_TYPE_COUPE,
  CAR_TYPE_CROSS,
  CAR_TYPE_HATCHBACK,
  CAR_TYPE_MONO,
  CAR_TYPE_PICKUP,
  CAR_TYPE_SEDAN,
  CAR_TYPE_SUV,
} from '../../../utils/constants';
import {
  MCParagraph,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import {
  InputField,
  InputNumericField,
} from '../../../_shared/components/InputField/InputField';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { useAppUpdateCarMutation } from '../../hooks/mutations/useAppUpdateCarMutation';
import { useAppCarsQuery } from '../../hooks/queries';
import styles from './Cars.module.css';

export const Cars = () => {
  const props = useLeftNavigatorOwnerProps();

  const { data: cars, isLoading } = useAppCarsQuery({
    types: ['car'],
  });
  const [selectedCarId, setSelectedCarId] = useState('');
  const [selectedCar, setSelectedCar] = useState<Car>();
  const [isModified, setIsModified] = useState(false);
  const { trackEvent } = useOwnerAnalytics();

  const { mutateAsync: updateCar } = useAppUpdateCarMutation();

  const toOption = useCallback((car: Car) => {
    return {
      name: `${car?.make} ${car?.model} ${car?.year} - ${car.registrationNumber}`,
      value: car._id || '',
    };
  }, []);

  const hasData = useRef(false);
  useEffect(() => {
    if (hasData.current) {
      return;
    }

    if (!isLoading && cars?.[0]?._id) {
      hasData.current = true;
      setSelectedCarId(cars[0]._id);
    }
  }, [cars, isLoading, toOption]);

  useEffect(() => {
    if (selectedCarId !== '' && cars?.length) {
      const found = cars.find((x) => x._id === selectedCarId);
      if (found) {
        setSelectedCar(found);
      }
    }
  }, [selectedCarId, cars]);

  const carSelectOptions = useMemo(() => {
    return (cars || []).map(toOption);
  }, [cars, toOption]);

  const withUnknownOption = useCallback(
    (options: Array<{ value: string; name: string }>) => {
      return options;
    },
    []
  );

  return (
    <MCTwoPaneLayout title={'Masini'} {...props}>
      <MCSpacing />
      <MCSpacing />
      <MCSpacing />
      <div className={styles.contentCenter}>
        <div className={styles.halfWidth}>
          <InputSelect
            options={carSelectOptions}
            value={selectedCarId}
            onValueChange={(id) => {
              const found = carSelectOptions.find((x) => x.value === id);
              trackEvent('Cars_CarsCombo_Select', {
                carId: selectedCarId,
                name: found?.name || '',
              });
              setSelectedCarId(id);
            }}
            label={'Masina'}
            bold
          />
        </div>

        <div className={styles.halfWidth}>
          <MCSpacing />
          <MCSpacing />
          <MCSpacing />

          <>
            <MCParagraph fontSize={20} bold>
              Marca si Model
            </MCParagraph>
            <MCSpacing />
            {isLoading && <LoadingOutlined className="h1 p-5" />}
            {selectedCar && (
              <>
                <InputField
                  label={'Marca'}
                  value={selectedCar?.make}
                  placeholder={''}
                  onChange={(make) => {
                    trackEvent('Cars_CarMakeInput_Type', {
                      make,
                      carId: selectedCarId,
                    });
                    setSelectedCar({ ...selectedCar, make: make });
                    setIsModified(true);
                  }}
                />
                <MCSpacing />
                <InputField
                  label={'Model'}
                  value={selectedCar?.model}
                  placeholder={''}
                  onChange={(mod) => {
                    trackEvent('Cars_CarModelInput_Type', {
                      model: mod,
                      carId: selectedCarId,
                    });
                    setSelectedCar({ ...selectedCar, model: mod });
                    setIsModified(true);
                  }}
                />
                <MCSpacing />
                <MCSpacing />
                <MCSpacing />
              </>
            )}
          </>

          <>
            <MCParagraph fontSize={20} bold>
              Status Masina
            </MCParagraph>
            <MCSpacing />
            {isLoading && <LoadingOutlined className="h1 p-5" />}
            {selectedCar && (
              <>
                <InputSelect
                  options={[
                    { name: 'Activa', value: CAR_ACTIVE },
                    { name: 'In curand', value: CAR_COMING_SOON },
                    { name: 'Dezactivata', value: CAR_DEACTIVATED },
                  ]}
                  value={selectedCar?.status}
                  onValueChange={(status) => {
                    trackEvent('Cars_StatusCombo_Select', {
                      status,
                      carId: selectedCarId,
                    });
                    setSelectedCar({ ...selectedCar, status: status });
                    setIsModified(true);
                  }}
                  label="Status"
                  bold
                />

                <MCSpacing />

                <>
                  <InputField
                    label={'Numar inmatriculare'}
                    value={selectedCar?.registrationNumber}
                    placeholder={''}
                    onChange={(reg) => {
                      trackEvent('Cars_RegistrationNumber_Select', {
                        number: reg,
                        carId: selectedCarId,
                      });
                      setSelectedCar({
                        ...selectedCar,
                        registrationNumber: reg,
                      });
                      setIsModified(true);
                    }}
                  />
                  <MCSpacing />
                </>
                <MCSpacing />
                <MCSpacing />
              </>
            )}
          </>

          <MCParagraph fontSize={20} bold>
            Motor si date tehnice
          </MCParagraph>
          <MCSpacing />
          {isLoading && <LoadingOutlined className="h1 p-5" />}
          {selectedCar && (
            <>
              <InputField
                label={'An de fabricatie'}
                value={`${selectedCar?.year}`}
                placeholder={''}
                onChange={(year) => {
                  trackEvent('Cars_YearInput_Type', {
                    year,
                    carId: selectedCarId,
                  });
                  setSelectedCar({
                    ...selectedCar,
                    year: parseInt(year),
                  });
                  setIsModified(true);
                }}
              />
              <MCSpacing />

              <>
                <InputField
                  label={'Putere (Cai Putere)'}
                  value={`${selectedCar?.engine.power}`}
                  placeholder={''}
                  onChange={(power) => {
                    trackEvent('Cars_PowerInput_Type', {
                      carId: selectedCarId,
                      power,
                    });
                    setSelectedCar({
                      ...selectedCar,
                      engine: {
                        ...selectedCar?.engine,
                        power: parseInt(power),
                      },
                    });
                    setIsModified(true);
                  }}
                />
                <MCSpacing />
                <InputField
                  label={'Cuplu (Newton Metru)'}
                  value={`${selectedCar?.engine.torque}`}
                  placeholder={''}
                  onChange={(torque) => {
                    trackEvent('Cars_TorqueInput_Type', {
                      carId: selectedCarId,
                      torque,
                    });
                    setSelectedCar({
                      ...selectedCar,
                      engine: {
                        ...selectedCar?.engine,
                        torque: parseInt(torque),
                      },
                    });
                    setIsModified(true);
                  }}
                />
                <MCSpacing />
                <InputField
                  label={` ${
                    selectedCar.fuel === CAR_FUEL_ELECTRIC
                      ? 'Capacitate baterie (Kwh)'
                      : 'Capacitate cilindrica (Cm3)'
                  }`}
                  value={`${selectedCar?.engine.displacement}`}
                  placeholder={''}
                  onChange={(displacement) => {
                    trackEvent('Cars_DisplacementInput_Type', {
                      carId: selectedCarId,
                      displacement,
                    });
                    setSelectedCar({
                      ...selectedCar,
                      engine: {
                        ...selectedCar?.engine,
                        displacement: parseInt(displacement),
                      },
                    });
                    setIsModified(true);
                  }}
                />
              </>
              <MCSpacing />
              {selectedCar.fuel === CAR_FUEL_ELECTRIC && (
                <>
                  <InputNumericField
                    label={'Autonomie (Km)'}
                    value={selectedCar?.engine.range || 0}
                    placeholder={''}
                    onChange={(range) => {
                      trackEvent('Cars_AutonomyInput_Type', {
                        carId: selectedCarId,
                        range: '' + range,
                      });
                      setSelectedCar({
                        ...selectedCar,
                        engine: {
                          ...selectedCar?.engine,
                          range: range,
                        },
                      });
                      setIsModified(true);
                    }}
                  />
                  <MCSpacing />
                </>
              )}
              <InputSelect
                marginLeft={30}
                options={[
                  { name: 'Manuala', value: CAR_MANUAL },
                  { name: 'Automata', value: CAR_AUTOMATIC },
                ]}
                value={selectedCar.engine.transmission}
                onValueChange={(trans) => {
                  trackEvent('Cars_TransmissionSelect_Selected', {
                    carId: selectedCarId,
                    selected: trans,
                  });
                  setSelectedCar({
                    ...selectedCar,
                    engine: {
                      ...selectedCar?.engine,
                      transmission: trans,
                    },
                  });
                  setIsModified(true);
                }}
                label="Transmisie"
                bold
              />
              <MCSpacing />

              <>
                <InputSelect
                  marginLeft={38}
                  options={withUnknownOption([
                    { name: 'Sedan', value: CAR_TYPE_SEDAN },
                    { name: 'Hatchback', value: CAR_TYPE_HATCHBACK },
                    { name: 'Break', value: CAR_TYPE_BREAK },
                    { name: 'Coupe', value: CAR_TYPE_COUPE },
                    { name: 'Crossover', value: CAR_TYPE_CROSS },
                    { name: 'SUV', value: CAR_TYPE_SUV },
                    { name: 'Monovolum', value: CAR_TYPE_MONO },
                    { name: 'Pickup', value: CAR_TYPE_PICKUP },
                    { name: 'Decapotabila', value: CAR_TYPE_CONVERTIBLE },
                  ])}
                  value={selectedCar.carType}
                  onValueChange={(carType) => {
                    trackEvent('Cars_CarTypeSelect_Selected', {
                      carId: selectedCarId,
                      selected: carType,
                    });
                    setSelectedCar({ ...selectedCar, carType: carType });
                    setIsModified(true);
                  }}
                  label="Caroserie"
                  bold
                />
                <MCSpacing />
                <InputSelect
                  marginLeft={39}
                  options={withUnknownOption([
                    { name: 'Fata', value: CAR_TRACTION_FRONT },
                    { name: 'Spate', value: CAR_TRACTION_REAR },
                    { name: '4 X 4', value: CAR_TRACTION_ALL },
                  ])}
                  value={selectedCar.engine.tractionType}
                  onValueChange={(traction) => {
                    trackEvent('Cars_TractionSelect_Selected', {
                      carId: selectedCarId,
                      selected: traction,
                    });
                    setSelectedCar({
                      ...selectedCar,
                      engine: {
                        ...selectedCar.engine,
                        tractionType: traction,
                      },
                    });
                    setIsModified(true);
                  }}
                  label="Tractiune"
                  bold
                />
                <MCSpacing />
              </>
              <InputSelect
                marginLeft={40}
                options={[
                  { name: '2 locuri', value: '2' },
                  { name: '4 locuri', value: '4' },
                  { name: '5 locuri', value: '5' },
                  { name: '6 locuri', value: '6' },
                  { name: '6 + 1 locuri', value: '7' },
                  { name: '7 + 1 locuri', value: '8' },
                  { name: '8 + 1 locuri', value: '9' },
                ]}
                value={`${selectedCar.seats}`}
                onValueChange={(seats) => {
                  trackEvent('Cars_SeatsSelect_Selected', {
                    carId: selectedCarId,
                    selected: seats,
                  });
                  setSelectedCar({
                    ...selectedCar,
                    seats: parseInt(seats),
                  });
                  setIsModified(true);
                }}
                label="Locuri"
                bold
              />
              <MCSpacing />
              <>
                <InputSelect
                  marginLeft={26}
                  options={withUnknownOption([
                    { name: 'Benzina', value: CAR_FUEL_GAS },
                    { name: 'Diesel', value: CAR_FUEL_DIESEL },
                    { name: 'Benzina/GPL', value: CAR_FULE_GAS_GPL },
                    { name: 'Hybrid/Benzina', value: CAR_FUEL_HYBRID_GAS },
                    { name: 'Plug-in Hybrid', value: CAR_FUEL_HYBRID_PLUG },
                    { name: 'Electric', value: CAR_FUEL_ELECTRIC },
                  ])}
                  value={selectedCar.fuel}
                  onValueChange={(fuel) => {
                    trackEvent('Cars_FuelSelect_Selected', {
                      carId: selectedCarId,
                      selected: fuel,
                    });
                    setSelectedCar({
                      ...selectedCar,
                      fuel: fuel,
                    });
                    setIsModified(true);
                  }}
                  label="Combustibil"
                  bold
                />
                <MCSpacing />
              </>
            </>
          )}
          <MCSpacing />
          <MCSpacing />
          <div className={styles.saveBtn}>
            <ActionButton
              label={'Salveaza'}
              disabled={!isModified}
              onClick={() => {
                trackEvent('Cars_Save_Click', {
                  carId: selectedCarId,
                });
                if (selectedCar) {
                  updateCar({ carId: selectedCarId, carData: selectedCar });
                  setIsModified(false);
                }
              }}
            />
          </div>
          <MCSpacing />
        </div>
      </div>
    </MCTwoPaneLayout>
  );
};
