import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from '../../../_shared/components/Layout';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { updateReservationStripeSuccess } from '../../../api/reservations';
import {
  CONFIRMED,
  GTAG_EVENT_CONVERSION_ID,
  IS_PROD_ENV,
} from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types/redux';

export const StripeSuccess = () => {
  const { search, pathname } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const confirmationCode = params.get('confirmationCode');
  const success = pathname === '/success';
  console.log({ pathname });

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccesfull, setIsSuccesfull] = useState(success);
  const driverModel = useSelector((state: ReduxState) => state.userReducer);

  const history = useHistory();

  // @ts-ignore
  const { id } = useParams();

  const updateReservation = useCallback(
    async (id: string) => {
      const handleGtag = () => {
        if (IS_PROD_ENV) {
          (window as any).gtag('event', 'conversion', {
            send_to: GTAG_EVENT_CONVERSION_ID,
            value: res.pricing.customerPrice / 100,
            currency: 'RON',
            transaction_id: res.reservationId,
          });
        }
      };

      if (success) {
        handleGtag();
        return;
      }
      if (!confirmationCode) {
        return;
      }
      const res = await updateReservationStripeSuccess(id, confirmationCode);
      setIsLoading(false);

      const isConfirmed = res.status === CONFIRMED;

      if (isConfirmed) {
        setIsSuccesfull(true);
      }
      toast.success('Payment succesfull');

      if (isConfirmed) {
        handleGtag();
      }
    },
    [confirmationCode, success]
  );

  useEffect(() => {
    setTimeout(() => {
      if (driverModel.isAuthenticated) {
        history.push('/reservations');
      }
    }, 2500);
  }, [driverModel.isAuthenticated, history]);

  useEffect(() => {
    updateReservation(id);
  }, [id, updateReservation]);

  return (
    <Layout>
      <div className="d-flex justify-content-center p-5">
        {isSuccesfull ? (
          <h2 style={{ textAlign: 'center' }}>
            Rezervare confirmata cu succes!
          </h2>
        ) : isLoading ? (
          <LoadingOutlined className="h1 p-5 text-danger" />
        ) : (
          <h2>A aparut o problema.</h2>
        )}
      </div>
    </Layout>
  );
};
