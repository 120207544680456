import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OnboardingStage } from '../../types/api';
import { ReduxState } from '../../types/redux';

const STAGE_ORDER: OnboardingStage[] = [
  OnboardingStage.None,
  OnboardingStage.VerifyEmail,
  OnboardingStage.EmailVerified,
  OnboardingStage.VerifyPhone,
  OnboardingStage.PhoneVerified,
  OnboardingStage.DriverDetails,
  OnboardingStage.DriverVerified,
];

enum CompletionLevel {
  C0 = '0',
  C25 = '25',
  C50 = '50',
  C75 = '75',
  C100 = '100',
}

export function useProfileCompletionLevel() {
  const driver = useSelector((state: ReduxState) => state.userReducer);

  const progress =
    driver?.driver?.onboarding?.stage || OnboardingStage.VerifyEmail;
  const isCompleted = useCallback(
    (stage: OnboardingStage) => {
      const stageIdx = STAGE_ORDER.indexOf(stage);
      return (
        stageIdx !== -1 &&
        STAGE_ORDER.indexOf(stage) <= STAGE_ORDER.indexOf(progress)
      );
    },
    [progress]
  );

  const completionLevel = useMemo(() => {
    if (driver?.driver?.onboarding?.isVerified) return CompletionLevel.C100;

    if (isCompleted(OnboardingStage.PhoneVerified)) {
      return CompletionLevel.C75;
    }

    if (isCompleted(OnboardingStage.EmailVerified)) {
      return CompletionLevel.C50;
    }

    return CompletionLevel.C25;
  }, [driver?.driver?.onboarding?.isVerified, isCompleted]);

  return { completionLevel, isCompleted, completed: completionLevel === '100' };
}
