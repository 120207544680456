import { useMemo } from 'react';
import {
  svgCarType,
  svgDisplacementIcon,
  svgFuelType,
  svgGearboxType,
  svgPowerIcon,
  svgSeatsNumber,
  svgTorqueIcon,
  svgTractionType,
} from '../../../assets';
import { Car } from '../../../types/api';
import styles from './CheckoutCarDetails.module.css';

interface CheckoutCarDetailsProps {
  car: Car;
}

export function CheckoutCarDetails({ car }: CheckoutCarDetailsProps) {
  let torque = `${car.engine.torque} nm`;
  let displacement =
    car.fuel === 'Electric'
      ? `${car.engine.range} km`
      : `${car.engine.displacement} cm³`;
  let tractionType = `${car.engine.tractionType}`;
  let power = `${car.engine.power} cp`;

  if (car.type === 'category' && car.categoryDetails.cars.length > 1) {
    torque = displacement = tractionType = power = '-';
  }

  const details = useMemo(() => {
    return [
      {
        left: {
          image: svgCarType,
          text: `${car.carType}`,
        },
        right: {
          image: svgSeatsNumber,
          text: `${car.seats} locuri`,
        },
      },
      {
        left: {
          image: svgFuelType,
          text: `${car.fuel}`,
        },
        right: {
          image: svgGearboxType,
          text: `${
            car.engine.transmission === 'Automatic'
              ? 'Cutie automată'
              : 'Cutie manuală'
          }`,
        },
      },
      {
        left: {
          image: svgPowerIcon,
          text: `Putere: ${power}`,
        },
        right: {
          image: svgTractionType,
          text: `Tracțiune ${tractionType}`,
        },
      },
      {
        left: {
          image: svgTorqueIcon,
          text: `Cuplu: ${torque}`,
        },
        right: {
          image: svgDisplacementIcon,
          text: `${
            car.fuel === 'Electric' ? 'Autonomie' : 'Cilindre'
          }: ${displacement}`,
        },
      },
    ];
  }, [car]);

  return (
    <div className={styles.container}>
      <div className={styles.col}>
        {details.map((x, index) => (
          <div key={index} className={styles.item}>
            <img className={styles.image} src={x.left.image} alt="row-icon" />
            <span className={styles.description}>{x.left.text}</span>
          </div>
        ))}
      </div>
      <div className={styles.col}>
        {details.map((x, index) => (
          <div key={index} className={styles.item}>
            <img className={styles.image} src={x.right.image} alt="row-icon" />
            <span className={styles.description}>{x.right.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
