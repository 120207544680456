import './ActionButton.styles.css';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  label: string;
  isLoading?: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const ActionButton = ({
  label,
  onClick,
  isLoading = false,
  disabled = false,
}: Props) => {
  return (
    <div
      className={`button  ${disabled ? 'action-button-disabled' : ''}`}
      onClick={!disabled ? onClick : undefined}
    >
      {isLoading && (
        <LoadingOutlined
          className={'button-invisible-spinner'}
          marginWidth={10}
        />
      )}
      {label}
      {isLoading && <LoadingOutlined marginWidth={10} />}
    </div>
  );
};
