import { MCHeader } from '../../../_shared/components';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import styles from './CheckoutHeader.module.css';

interface CheckoutHeaderProps {}

export function CheckoutHeader({}: CheckoutHeaderProps) {
  const { trackEvent } = useDriverAnalytics();

  return (
    <div className={styles.container}>
      <MCHeader
        title="Confirmă și plătește"
        onBack={() => trackEvent('Checkout_BackArrow_Click')}
      />
    </div>
  );
}
