import { useCallback, useState } from 'react';
import { useOwnerReservationEventsQuery } from '../../../hooks/queries/useOwnerReservationsEventsQuery';
import { Reservation } from '../../../types/api';
import { MCTwoPaneLayout } from '../../../_shared/components';
import { ReservationTooltip } from '../../components/ReservationTooltip';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { ReceptionsDayContainer } from './ReceptionDayContainer';

export const ReceptionsDeliveries = () => {
  const props = useLeftNavigatorOwnerProps();

  const { data: days } = useOwnerReservationEventsQuery();
  const { trackEvent } = useOwnerAnalytics();

  const [clickedReservation, setClickedReservation] = useState<Reservation>();
  const [marginTop, setMarginTop] = useState(0);

  const renderDays = useCallback(() => {
    if (days?.length) {
      return days.map((day, idx) => {
        // @ts-ignore
        return (
          <ReceptionsDayContainer
            dayEvents={day}
            key={day.dayData.date}
            onEventPressed={(e) => {
              setClickedReservation(e.reservation);
              trackEvent('ReceptionDeliveries_CardClick_Click', {
                carId: e.reservation.car._id,
                index: idx,
                reservationId: e.reservation.reservationId,
                reservationMongoId: e.reservation._id,
              });

              let eventCount = 0;
              for (let i = 0; i < idx; i++) {
                eventCount += days[i].events.length;
              }

              let clickedEventIdx = day.events.indexOf(e);
              clickedEventIdx = clickedEventIdx < 0 ? 0 : clickedEventIdx;

              setMarginTop((clickedEventIdx + eventCount) * 140 + 32 * idx);
            }}
          />
        );
      });
    }
  }, [days, setClickedReservation]);

  const onClearSelection = useCallback(
    () => setClickedReservation(undefined),
    [setClickedReservation]
  );

  return (
    <MCTwoPaneLayout title="Primiri - Predări" {...props}>
      <ReservationTooltip
        reservation={clickedReservation}
        onClearSelection={onClearSelection}
        marginTop={marginTop}
      />
      {renderDays()}
    </MCTwoPaneLayout>
  );
};
