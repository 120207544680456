import moment from 'moment';
import { useState } from 'react';
import Modal from 'react-modal';
import { useAppCarReservationsQuery, useAppTimeForm } from '../../../hooks';
import { Car } from '../../../types/api';
import { MCSpacing } from '../../../_shared/components';
import { ActionButton } from '../../../_shared/components/ActionButton/ActionButton';
import { SearchFormCarPage } from '../SearchForm/SearchFormCarPage';
import styles from './CheckoutDatesModal.module.css';

interface CheckoutDatesModalProps {
  dateBegin: Date;
  dateEnd: Date;
  initialLocationId: string;
  minDaysRental: number;
  car: Car;
  onSubmit: (data: {
    dateBegin: Date;
    dateEnd: Date;
    minutesBegin: number;
    minutesEnd: number;
    locationId: string;
  }) => void;
  onClose: () => void;
}

export function CheckoutDatesModal({
  dateBegin,
  dateEnd,
  initialLocationId,
  minDaysRental,
  car,
  onSubmit,
  onClose,
}: CheckoutDatesModalProps) {
  const { setDateBegin, setDateEnd, setMinutesBegin, setMinutesEnd, interval } =
    useAppTimeForm({
      from: moment(dateBegin).unix(),
      to: moment(dateEnd).unix(),
    });
  const [locationId, setLocationId] = useState(initialLocationId);

  const { data: reservations } = useAppCarReservationsQuery(
    {
      carId: car?._id || '',
    },
    { enabled: car.type === 'car' }
  );

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
    >
      <SearchFormCarPage
        initialLocationId={locationId}
        onLocationChange={(x) => setLocationId(x._id || '')}
        interval={interval}
        onDateBeginChanged={(date) => {
          const newDateBegin = moment.utc(moment(date).format('YYYY-MM-DD'));
          setDateBegin(newDateBegin.toDate());
        }}
        onDateEndChanged={(date) => {
          const newEndDate = moment.utc(moment(date).format('YYYY-MM-DD'));
          setDateEnd(newEndDate.toDate());
        }}
        onMinutesBeginChanged={setMinutesBegin}
        onMinutesEndChanged={setMinutesEnd}
        minDaysRental={minDaysRental}
        carDetails={car}
        reservations={reservations || []}
      />
      <MCSpacing />
      <MCSpacing />
      <MCSpacing />
      <div className={styles.buttonContainer}>
        <ActionButton
          label="Schimba datele"
          onClick={() => {
            if (interval.dateBegin && interval.dateEnd) {
              onSubmit({
                ...interval,
                dateBegin: interval.dateBegin,
                dateEnd: interval.dateEnd,
                locationId,
              });
            }
          }}
        />
      </div>
    </Modal>
  );
}
