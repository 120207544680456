import { OwnerReport, Seller } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/constants';

export const getSeller = async (): Promise<Seller> => {
  try {
    return (await axios.get(`${BASE_URL}/owner/me`)).data;
  } catch (err) {
    console.error('Failed to login user');
    console.error(err);
    throw err;
  }
};

export const getSellers = async (filter: any): Promise<Array<Seller>> => {
  try {
    return (
      await axios.get(`${BASE_URL}/owner`, {
        params: {
          filter,
        },
      })
    ).data.found.sellers;
  } catch (err) {
    console.error('Failed to get sellers');
    console.error(err);
    throw err;
  }
};

export const updateSeller = async (sellerId: string, sellerData: Seller) => {
  try {
    return await axios.put(`${BASE_URL}/owner/${sellerId}`, sellerData);
  } catch (err) {
    console.error('Failed to put sellers');
    console.error(err);
    throw err;
  }
};

export const createConnectAccount = async () => {
  try {
    return (await axios.post(`${BASE_URL}/owner/connect`, {})).data;
  } catch (err) {
    console.error('Failed to connect to Stripe');
    console.error(err);
    throw err;
  }
};

export const postAccountStatus = async () => {
  try {
    return (await axios.post(`${BASE_URL}/owner/status`, {})).data;
  } catch (err) {
    console.error('Failed to get status');
    console.error(err);
    throw err;
  }
};

export const getAccountBalance = async (stripeId: string) => {
  try {
    return (await axios.get(`${BASE_URL}/owner/stripe/${stripeId}/balance`))
      .data;
  } catch (err) {
    console.error('Failed to get balance');
    console.error(err);
    throw err;
  }
};

export const getAccountSettings = async (stripeId: string) => {
  try {
    return (await axios.get(`${BASE_URL}/owner/stripe/${stripeId}/settings`))
      .data;
  } catch (err) {
    console.error('Failed to get settings');
    console.error(err);
    throw err;
  }
};

export const getOwnerReport = async (filter: any) => {
  try {
    return (
      await axios.get(`${BASE_URL}/owner/report`, {
        params: {
          filter,
        },
      })
    ).data as OwnerReport;
  } catch (err) {
    console.error('Failed to get reports');
    console.error(err);
    throw err;
  }
};

export const getOwnerEmails = async ({ token }: { token: string }) => {
  try {
    return (
      await axios.get(`${BASE_URL}/owner/list-owners`, {
        headers: {
          authorization: token,
        },
      })
    ).data as {
      count: number;
      sellers: Array<{ _id: string; email: string; name: string }>;
    };
  } catch (err) {
    console.error('Failed to get reports');
    console.error(err);
    throw err;
  }
};

export const switchOwner = async ({
  token,
  email,
}: {
  token: string;
  email: string;
}) => {
  try {
    return (
      await axios.post(
        `${BASE_URL}/owner/create-owner-token`,
        {
          email,
        },
        {
          headers: {
            authorization: token,
          },
        }
      )
    ).data as { token: string };
  } catch (err) {
    console.error('Failed to get reports');
    console.error(err);
    throw err;
  }
};
