import moment from 'moment';
import { useMemo } from 'react';
import { Reservation } from '../../types/api';

export function useAppOverlappingReservationError(
  interval: {
    dateBegin?: Date;
    dateEnd?: Date;
  },
  reservations: Array<Reservation>
) {
  return useMemo(() => {
    const { dateBegin: startDate, dateEnd: endDate } = interval;
    if (!startDate || !endDate) {
      return null;
    }

    const start = moment(startDate);
    const end = moment(endDate);
    if (!start.isBefore(end, 'days')) {
      return 'Data de început a rezervării este înaintea datei de sfârșit';
    }

    const foundOverlappingReservation = reservations?.find((reservation) => {
      const dateBegin = moment.unix(reservation.bufferDateBegin);
      const dateEnd = moment.unix(reservation.bufferDateEnd);

      // selected dates inside a reservation
      if (
        start.isBetween(dateBegin, dateEnd, 'minutes', '[]') &&
        end.isBetween(dateBegin, dateEnd, 'minutes', '[]')
      ) {
        return true;
      }

      // one date of reservation overlaps with the selected interval
      return (
        dateBegin.isBetween(start, end, 'minutes', '()') ||
        dateEnd.isBetween(start, end, 'minutes', '()')
      );
    });
    if (foundOverlappingReservation) {
      return 'Există o rezervare care se suprapune cu datele alese';
    }

    return null;
  }, [interval, reservations]);
}
