import { useRef, useState } from 'react';
import dropDownArrow from '../../../assets/drop-down-arrow.svg';
import { useOnClickOutside } from '../../../utils/useOnClickOutside';
import './CustomDatePicker.style.css';
import { CustomDatePickerModal } from './CustomDatePickerModal';

interface Props {
  minimumDate?: Date;
  maximumDate?: Date;
  onDateSelected?: any;
  selectedDateLabel: string;
  disabledIntervals?: Array<{ begin: Date; end: Date }>;
  isDisabled?: (date: moment.Moment) => boolean;
}

export const CustomDatePicker = ({
  onDateSelected,
  minimumDate,
  maximumDate,
  selectedDateLabel,
  disabledIntervals,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsModalOpen(false));

  return (
    <div className="custom-date-picker-container">
      <p
        onClick={() => setIsModalOpen(!isModalOpen)}
        className="custom-date-picker-label"
      >
        Data
      </p>
      <p
        onClick={() => setIsModalOpen(!isModalOpen)}
        className="custom-date-picker-date-label"
      >
        {selectedDateLabel}
      </p>
      <img
        onClick={() => setIsModalOpen(!isModalOpen)}
        src={dropDownArrow}
        alt="dropdown"
      />
      {isModalOpen && (
        <CustomDatePickerModal
          ref={ref}
          disabledIntervals={disabledIntervals}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          onDateClick={(date: Date) => {
            setIsModalOpen(false);
            onDateSelected(date);
          }}
        />
      )}
    </div>
  );
};
