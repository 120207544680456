import { useMutation, useQueryClient } from 'react-query';
import { updateTeamSettings } from '../../../api/teamSettings';
import { TeamSettings } from '../../../types/api';

export function useAppUpdateTeamSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (teamSettings: TeamSettings) => {
      return updateTeamSettings(teamSettings);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('owner-data');
      },
    }
  );
}
