import styles from './MCDivider.module.css';

export interface MCDividerProps {
  fullWidth?: boolean;
  color?: string;
}

export function MCDivider({ fullWidth, color }: MCDividerProps) {
  return (
    <div
      className={`${styles.container} ${fullWidth ? styles.fullWidth : ''}`}
      style={{ backgroundColor: color }}
    />
  );
}
