import { Link } from 'react-router-dom';
import './Legal.style.css';

export const TermsAndConditions = () => {
  return (
    <div className="legal-container">
      <div className="legal-container-width">
        <p className="legal-container-text header">Termeni și Condiții</p>

        <p className="legal-container-text sub-header">Introducere</p>

        <p className="legal-container-text">
          Vă rugăm să citiți acest document cu atenție. Acest document (denumit
          în continuare „Prezentul document”, „Contractul” sau „Termenii și
          Condițiile”) reprezintă condițiile utilizării site-ului
          www.masinilacheie.ro (denumit în continuare „Site-ul”) și condițiile
          plasării de comenzi prin intermediul site-ului. Prin navigarea pe
          site-ul nostru sau prin plasarea unei comenzi sunteți de acord cu
          Termenii și Condițiile descrise mai jos. Acest document reprezintă o
          convenție legală - un contract între dumneavoastră și noi. Vă rugăm să
          citiți și{' '}
          <Link to="/privacy_policy">
            Politica noastră de confidențialitate
          </Link>{' '}
          și{' '}
          <Link to="/cookies">
            Politica privind utilizarea modulelor cookie
          </Link>
          , înainte de a naviga pe site sau a plasa o comandă. Dacă nu sunteți
          de acord cu acești Termeni sau cu Politicile indicate mai sus, vă
          rugăm să nu utilizați site-ul.
        </p>
        <p className="legal-container-text sub-header">Cine suntem?</p>
        <p className="legal-container-text">
          Site-ul www.masinilacheie.ro aparține si este intretinut de societatea
          MasiniLaCheie SRL, societate de naționalitate română cu sediul social
          în București, sectorul 6, Str. Moise Constantin 5D, înmatriculată în
          Registrul Comerțului București sub nr. J40/19653/2021, având codul
          fiscal 45205227, e-mail{' '}
          <a href="mailto:contact@masinilacheie.ro">contact@masinilacheie.ro</a>
          .
        </p>

        <p className="legal-container-text sub-header">Definiții</p>

        <p className="legal-container-text">
          <span className="legal-container-text bold">„Oferta terților”</span>{' '}
          desemnează oferta prezentată pe site-ul www.masinilacheie.ro,
          transmisă de terti, persoane juridice, care intentionează să
          închirieze autoturismele către Dvs. prin intermediul Site-ului.
          Aceștia sunt terți independenți de societatea Mașini-La-Cheie SRL. și
          includ, dar fără a se limita la, furnizorii independenți de servicii
          de închirieri auto.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            „Serviciile de închirieri auto”
          </span>{' '}
          includ, dar fără a se limita la, servicii de închirieri de autoturisme
          și autovehicule rutiere, furnizate de către terții care au prezentat
          oferta pe site-ul www.masinilacheie.ro, cum ar fi, dar fără a se
          limita la, închirierea de autoturisme fără șofer. Acestă ofertă vă
          este prezentată prin societatea Masini-La-Cheie SRL, în calitatea sa
          de intermediară.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            „Oferta Mașinilacheie”
          </span>{' '}
          desemnează serviciul care vă este furnizat direct de către
          Mașini-La-Cheie SRL și care presupune accesul și utilizarea site-ului
          de internet www.masinilacheie.ro, care vă permite să solicitați și să
          beneficiați de oferta terților. De asemenea, Mașini-La-Cheie SRL oferă
          servicii de colectare și de prelucrare a plăților, care ne permit să
          încasăm tarife de la dvs. și să plătim tarife în contul bancar al
          terților care prestează către Dvs. servicii de închirieri auto, cu
          reținerea comisionului de către societatea Masini-La-Cheie SRL., ce
          este individualizat în oferta terților.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">“Utilizatorul”</span> este
          orice persoana fizica care are varsta peste 18 ani sau persoana
          juridica inregistrata pe Site, care, prin crearea contului, si-a dat
          acordul cu privire la clauzele specifice site-ului din sectiunea
          Termeni si Conditii.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">“Clientul”</span> este
          Utilizatorul care a solicitat servicii de închirieri auto în nume
          propriu din oferta prezentată de terți și care a efectuat plata.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">
            “Contractul de intermediere”
          </span>{' '}
          reprezinta contractul încheiat între societatea Masini-La-Cheie SRL si
          terțul care beneficiază de serviciului de intermediere, în temeiul
          căruia acesta din urmă s-a obligat să furnizeze servicii de înhirieri
          auto, în limita ofertei, către Client.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">“Comanda”</span>{' '}
          desemneaza solicitarea Dvs. prin intermediul site-ului
          www.masinilacheie.ro prin care cereti societății Masini-La-Cheie SRL
          să intermedieze închirierea autovehicului disponibil in Oferta
          Masinilacheie.
        </p>
        <p className="legal-container-text">
          <span className="legal-container-text bold">“Garanția”</span>{' '}
          desemnează suma de bani ce poate fi solicitată în cadrul anumitor
          Oferte, ce are ca scop garantarea seriozitatea clientului și este
          afisata pe Site în momentul în care Oferta este prezentată. În
          privința acestei sume, societatea Masini-La-Cheie SRL nu procesează
          plata, ci, acest demers este dreptul exclusiv al terțului care
          prezintă oferta pe Site.
        </p>

        <p className="legal-container-text sub-header">
          Prezentul contract. Obligativitate. Contact.
        </p>

        <p className="legal-container-text bold">Caracter obligatoriu.</p>
        <p className="legal-container-text">
          Înțelegeți că atât prezentul contract, cât și toate politicile la care
          acesta face referire (e.g., Politică de confidențialitate, Politică
          privind utilizarea modulelor cookie) au caracter obligatoriu față de
          dvs. Prin navigarea pe site-ul nostru sau prin plasarea unei comenzi,
          vă luați angajamentul de a respecta prezentul contract și politicile
          indicate.
        </p>

        <p className="legal-container-text bold">Modificări și actualizări.</p>
        <p className="legal-container-text">
          Ne rezervăm dreptul de a modifica oricând și fără notificare
          prealabilă acești Termeni. Solicitării dumneavoastră i se vor aplica
          termenii care sunt în vigoare la momentul plasării comenzii. Accesând
          site-ul nostru veți găsi cea mai recentă versiune a Termenilor.
        </p>
        <p className="legal-container-text">
          Nu putem garanta faptul că orice oferte care au fost incluse la un
          moment dat pe site vor fi disponibile în orice moment. Ne rezervăm
          dreptul de a înceta în orice moment intermedierea unei relații cu un
          terț.
        </p>

        <p className="legal-container-text bold">Contact.</p>
        <p className="legal-container-text">
          Dacă aveți întrebări sau neclarități, ne puteți contacta cu încredere
          la{' '}
          <a href="mailto:contact@masinilacheie.ro">contact@masinilacheie.ro</a>{' '}
          sau prin completarea formularului de contact și o să vă răspundem în
          cel mai scurt timp.
        </p>

        <p className="legal-container-text sub-header">
          Date cu caracter personal
        </p>
        <p className="legal-container-text">
          Site-ul prelucrează date cu caracter personal furnizate de dvs. sau
          colectate din alte surse astfel cum am descris pe larg în{' '}
          <Link to="/privacy_policy">Politica de confidențialitate</Link>. Ne-am
          luat angajamentul să respectăm legislația națională și europeană
          privind protecția datelor cu caracter personal și libera circulație a
          acestor date.
        </p>
        <p className="legal-container-text">
          Prin vizitarea și/sau utilizarea site-ului, plasarea de comenzi sau
          prin interacțiunea cu noi prin orice metodă și/sau orice mijloc de
          comunicare la distanță, sunteți de acord cu prelucrarea datelor dvs.
          cu caracter personal astfel cum am descris în{' '}
          <Link to="/privacy_policy">Politica de confidențialitate</Link>.
        </p>

        <p className="legal-container-text sub-header">Eligibilitate</p>
        <p className="legal-container-text">
          Pentru a putea plasa în mod legal o comandă pe site-ul nostru trebuie:
        </p>
        <p className="legal-container-text">
          - să aveți peste 18 ani și/sau capacitate deplină de exercițiu;
        </p>
        <p className="legal-container-text">
          - să fiți de acord cu prezentul Contract;
        </p>
        <p className="legal-container-text">
          - să ne furnizați informații de identitate și de contact reale,
          complete și actualizate.
        </p>

        <p className="legal-container-text sub-header">
          Reguli privind utilizarea site-ului
        </p>
        <p className="legal-container-text bold">Reguli.</p>
        <p className="legal-container-text">
          Prin accesarea, vizitarea, solicitarea unei comenzi sau desfășurarea
          oricărei alte activități pe site-ul nostru, promiteți să respectați
          următoarele reguli:
        </p>
        <p className="legal-container-text">
          1. Veți folosi acest site exclusiv pentru efectuarea comenzilor
          legitime sau pentru informare;
        </p>
        <p className="legal-container-text">
          2. Nu veți efectua nicio comandă falsă sau frauduloasă, în caz contrar
          ne rezervăm dreptul de a anula comanda și informa autoritățile
          competente sau a ne adresa justiției pentru recuperarea oricăror
          prejudicii cauzate;
        </p>
        <p className="legal-container-text">
          3. Veți furniza informații reale, exacte, complete și actualizate;
        </p>
        <p className="legal-container-text">
          4. Veți respecta drepturile de proprietate intelectuală cu privire la
          orice element regăsit pe acest site.
        </p>
        <p className="legal-container-text">
          5. Nu veți desfășura niciun fel de acțiune care ar putea aduce orice
          fel de prejudiciu site-ului nostru, în caz contrar ne rezervăm dreptul
          de a ne adresa justiției pentru recuperarea oricăror prejudicii
          cauzate.
        </p>
        <p className="legal-container-text bold">Consecințe.</p>
        <p className="legal-container-text">
          Ne rezervăm dreptul de a bloca accesul oricărui utilizator care
          încalcă regulile de mai sus, de a anula comenzile, de a sesiza
          autoritățile competente pentru tragerea la răspundere
          administrativă/penală a oricăror fapte antisociale și de a ne adresa
          justiției pentru recuperarea în integralitate a oricăror prejudiciilor
          cauzate, prezente sau viitoare, inclusiv beneficiile nerealizate și
          cheltuielile de judecată (inclusiv onorariile avocaților).
        </p>

        <p className="legal-container-text sub-header">
          Încheierea contractului
        </p>
        <p className="legal-container-text bold">Protecție.</p>
        <p className="legal-container-text">
          În măsura în care nu vom accepta comanda, dar dvs. v-au fost retrase
          sume de bani, vom proceda la rambursarea acestor sume în cel mai scurt
          timp.
        </p>
        <p className="legal-container-text bold">Decizia ne aparține.</p>
        <p className="legal-container-text">
          Ne rezervăm dreptul de a decide, în mod unilateral și fără a preciza
          motivul, încheierea sau nu a unui contract. Nu vom avea nicio
          răspundere față de dvs. în situația în care refuzăm să dăm curs unei
          comenzi.
        </p>
        <p className="legal-container-text">
          În situația în care plasați o comandă, puteți anula această acțiune
          fără niciun motiv, cu cel mult 7 zile înainte de începerea perioadei
          de închiriere, suma de bani va fi returnată în integralitate, cu
          excepția situației în care anularea este realizată cu mai puțin de 72
          de ore, caz în care suma de bani ce reprezintă contravaloarea
          serviciilor de închiriere nu va fi returnată. De asemenea, dacă
          anularea comenzii este realizată cu mai mult de 72 de ore, dar mai
          puțin de 7 zile, ne obligăm să returnăm 50% din suma de bani ce a fost
          achitată de către Dvs.
        </p>
        <p className="legal-container-text bold">Oferta terților.</p>
        <p className="legal-container-text">
          Nu putem garanta că autoturismele care s-au găsit la un moment dat pe
          site vor fi disponibile în orice moment pentru prestarea de către
          beneficiarul contractului de intermediere a serviciului de închiriere.
          Nu vom avea nicio răspundere față dvs. în situația în care un
          autoturism cuprins în ofertă nu se mai regăsește pe site. Deși vom
          încerca să avem în timp real pe site actualizare informațiile privind
          disponibilitatea autotuturismelor, nu putem garanta faptul că aceste
          informații vor fi actualizate, în orice moment. Toate comenzile vor
          depinde de existența autoturismelor în ofertă.
        </p>
        <p className="legal-container-text bold">Garanții. </p>
        <p className="legal-container-text">
          În măsura în care autoturismele sunt indisponibile, dar dvs. ați
          plasat deja comanda, vă vom informa cu privire la indisponibilitatea
          acestor autoturisme și vă vom recomanda autoturisme similare, la
          valoare egală sau apropiată care pot fi comandate. În măsura în care
          nu doriți să comandați închirierea de autoturisme similare recomandate
          de noi, vom anula comanda, iar în măsura în care ați plătit deja
          prețul închirierii, vom proceda la returnarea sumelor de bani în cel
          mai scurt timp.
        </p>

        <p className="legal-container-text sub-header">Prețul închirierii</p>
        <p className="legal-container-text">
          Prețul închirierii autoturismelor este afișat în lei pe site și
          include taxa pe valoare adăugată. În măsura în care există o eroare cu
          privire la prețul afișat pe site, vă vom aduce la cunoștință în cel
          mai scurt timp, urmând să vă returnăm suma de bani achitată
          suplimentar sau să vă solicităm o sumă de bani suplimentară. În măsura
          în care noul preț nu este mulțumitor pentru Dvs., nu răspundeți la
          solicitarea noastră sau nu vă putem contacta, vă vom anula comanda și
          vă vom rambursa cât mai curând posibil suma de bani achitată.
        </p>
        <p className="legal-container-text">
          Pe langa pretul achitat conform prezentului contract, pentru unele
          dintre Oferte poate fi perceputa o suma cu titlul de Garanție, ce este
          afisata pe Site în momentul în care Oferta este prezentată. În
          privința acestei sume, societatea Masini-La-Cheie SRL nu procesează
          plata, ci, acest demers este dreptul exclusiv al terțului care
          prezintă oferta pe Site.
        </p>
        <p className="legal-container-text">
          Prețurile serviciilor de închiriere vor putea fi
          actualizate/modificare în orice moment, iar această
          actualizare/modificare va înlocui orice preț anterior. Comenzii dvs. i
          se vor aplica prețurile de la momentul plasării efective a comenzii.
        </p>
        <p className="legal-container-text">
          Plata serviciilor prestate de către Masini-La-Cheie SRL. precum și a
          serviciilor de închiriere comandate si prestate de terții care
          prezintă oferta pe site-ul www.masinilacheie.ro , se realizează prin
          plata cu cardul online.
        </p>
        <p className="legal-container-text">
          Dacă optați pentru plata online cu cardul bancar, procesarea datelor
          de pe cardul dumneavoastră se face prin intermediul{' '}
          <a href="https://stripe.com/en-gb-ro">Stripe Inc.</a>, iar noi nu vom
          stoca niciun fel de date referitoare la cardul dumneavoastră.
        </p>
        <p className="legal-container-text">
          Instituția emitentă va proceda la validarea și autorizarea plății. În
          situația în care, tranzacția nu se procesează din motive independente
          nouă (e.g., fonduri inexistente pe card, card expirat), contractul
          dintre noi nu se încheie și nu vom avea nicio obligație să notificam
          tertul, însă dvs. puteți încerca din nou procesarea plății cu un card
          valid.
        </p>

        <p className="legal-container-text sub-header">
          Forța majoră și cazul fortuit
        </p>
        <p className="legal-container-text">
          Nu vom fi trași la răspundere pentru niciun fel de întârzieri sau
          nereușite în îndeplinirea serviciilor noastre, dacă intervine un caz
          de forță majoră sau un caz fortuit. Forța majoră include, dar nu se
          limitează la schimbări în legi sau reglementări, embargouri, războaie,
          acte teroriste, revolte, incendii, cutremure, accidente nucleare,
          inundații, greve, epidemii, pandemii, condiții meteorologice și acte
          ale hackerilor sau furnizorilor de servicii de internet.
        </p>

        <p className="legal-container-text sub-header">
          Proprietatea intelectuală
        </p>
        <p className="legal-container-text">
          Întregul conținut al site-ului este proprietatea intelectuală a
          societății Masini-La-Cheie SRL. Site-ul poate folosit de către terți
          doar pentru informare și/sau plasare de comenzi. Utilizatorii
          site-ului nu au dreptul de a descărca, modifica parțial sau integral
          site-ul, reproduce parțial și/sau integral site-ul, copia, distribui,
          vinde sau exploata site-ul în orice altă manieră contrară intereselor
          Societății Masini-La-Cheie SRL., indiferent dacă există sau nu un scop
          comercial.
        </p>
        <p className="legal-container-text">
          Orice conținut (incluzând, dar fără a se limita la baze de date,
          elemente de grafică, mărci, conținut juridic) sunt proprietatea
          intelectuală a Masini-La-Cheie SRL. Întregul site este protejat de
          Legea nr. 8/1996 privind drepturile de autor și drepturile conexe, iar
          pentru orice încălcare a proprietății intelectuale, ne rezervăm
          dreptul de a sesiza instanțele de judecată competente pentru
          recuperarea integrală a prejudiciului, precum și de a depune o
          plângere penală la organele judiciare pentru tragerea la răspundere
          penală a făptuitorului.
        </p>

        <p className="legal-container-text sub-header">Clauze Finale</p>
        <p className="legal-container-text">
          Acești Termeni constituie acordul integral dintre dumneavoastră şi noi
          în ceea ce privește obiectul oricărui Contract și înlocuiește orice
          alt acord, orice altă înțelegere anterioară verbală sau scrisă dintre
          dumneavoastră și noi.
        </p>
        <p className="legal-container-text">
          Acest Contract are caracter obligatoriu. Nu puteți transfera, cesiona,
          greva sau înstrăina în niciun alt mod acest Contract sau oricare
          dintre drepturile sau obligațiile dumneavoastră care decurg din
          acesta, fără acordul nostru prealabil și scris. Noi putem transfera,
          cesiona, greva, subcontracta sau înstrăina în orice alt mod un
          Contract sau oricare dintre drepturile sau obligațiile noastre care
          decurg din Contract. Prezentului Contract, precum și oricărei
          utilizări a site-ului li se vor aplica legea română. Orice litigiu
          între noi va fi supus spre soluționare instanțelor române.
        </p>
      </div>
    </div>
  );
};
