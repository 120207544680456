import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createAnonymousReservation,
  createReservation,
} from '../../../api/reservations';
import {
  useAppCarReservationsQuery,
  useAppOverlappingReservationError,
  useAppTimeForm,
  useAppWindowSizeInfo,
} from '../../../hooks';
import { useAppComputeOffer } from '../../../hooks/util/useAppComputeOffer';
import {
  Car,
  CarLocation,
  CheckoutPaymentMethod,
  GuaranteeType,
  Guarantees,
  VoucherDiscountType,
} from '../../../types/api';
import { ReduxState } from '../../../types/redux';
import { emailIsValid } from '../../../utils/validation';
import {
  MCCell,
  MCCheckbox,
  MCDivider,
  MCParagraph,
  MCRow,
  MCSpacing,
} from '../../../_shared/components';
import { RentOfferPrices } from '../../pages/Car/RentOfferPrices';
import styles from './CheckoutBody.module.css';
import { CheckoutCarDetails } from './CheckoutCarDetails';
import { CheckoutDatesModal } from './CheckoutDatesModal';
import { CheckoutDriverDetails } from './CheckoutDriverDetails';
import { CheckoutInvoice } from './CheckoutCompanyInvoice';
import { CheckoutPayment } from './CheckoutPayment';
import { CheckoutRentalDetails } from './CheckoutRentalDetails';
import { useMutation, useQuery } from 'react-query';
import { CheckoutGuaranteeOptions } from './CheckoutGuaranteeOptions';
import { useStripe } from '@stripe/react-stripe-js';
import { useCarAvailabilityHourIntervals } from '../../hooks';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { useCurrencyFormatter } from '../../../hooks/util/useCurrencyFormatter';
import { useDriverAnalytics } from '../../hooks/useDriverAnalytics';
import categoryBannerImg from '../../../assets/checkout/car-category-banner-img.png';
import { CAR_ACTIVE, EXTRA_KM } from '../../../utils/constants';
import { getVoucherByCode } from '../../../api/vouchers';
import { InputField } from '../../../_shared/components/InputField/InputField';
import moment from 'moment-timezone';
// import { InputField } from '../../../_shared/components/InputField/InputField';

interface CheckoutBodyProps {
  car: Car;
  dateBegin: number;
  dateEnd: number;
  locationId: string;
  guaranteeType: GuaranteeType;
  euExit: boolean;
  extraKm: string;
}

function useDriverDetailsForm() {
  const driver = useSelector((state: ReduxState) => state.userReducer);

  const [lastName, setLastName] = useState(driver.driver?.lastName || '');
  const [firstName, setFirstName] = useState(driver.driver?.firstName || '');
  const [email, setEmail] = useState(driver.driver?.email || '');
  const [phone, setPhone] = useState(driver.driver?.onboarding?.phone || '');

  useEffect(() => {
    if (driver.isAuthenticated) {
      setLastName(driver.driver?.lastName || '');
      setFirstName(driver.driver?.firstName || '');
      setEmail(driver.driver?.email || '');
      setPhone(driver.driver?.onboarding?.phone || '');
    }
  }, [
    driver.driver?.email,
    driver.driver?.firstName,
    driver.driver?.lastName,
    driver.driver?.onboarding?.phone,
    driver.isAuthenticated,
  ]);

  const isLastNameValid = !!lastName;
  const isFirstNameValid = !!firstName;
  const isEmailValid = !!email && emailIsValid(email);
  const isPhoneValid = !!phone;

  const presentError = useCallback(() => {
    const error = (() => {
      if (!isLastNameValid) {
        return 'Nume necompletat';
      }

      if (!isFirstNameValid) {
        return 'Prenume necompletat';
      }

      if (!isEmailValid) {
        return 'Email nevalid';
      }

      if (!isPhoneValid) {
        return 'Telefon necompletat';
      }
    })();

    if (error) {
      toast.error(error);
    }
  }, [isEmailValid, isFirstNameValid, isLastNameValid, isPhoneValid]);

  return {
    lastName,
    firstName,
    email,
    phone,
    setLastName,
    setFirstName,
    setEmail,
    setPhone,
    isLastNameValid: !!lastName,
    isFirstNameValid: !!firstName,
    isEmailValid: !!email && emailIsValid(email),
    isPhoneValid: !!phone,
    isEverythingValid:
      isLastNameValid && isFirstNameValid && isEmailValid && isPhoneValid,
    presentError,
  };
}

function useCompanyInvoiceDetailsForm() {
  const [isCompanyInvoiceChecked, setIsCompanyInvoiceChecked] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyCUI, setCompanyCUI] = useState('');
  const { trackEvent } = useDriverAnalytics();

  const toggleIsCompanyInvoiceChecked = useCallback(() => {
    trackEvent('Checkout_CompanyInvoice_Checkbox', {
      checked: !isCompanyInvoiceChecked,
    });
    setIsCompanyInvoiceChecked((c) => !c);
  }, [isCompanyInvoiceChecked, trackEvent]);

  const isValidCompanyName = !!companyName;
  const isValidCompanyCUI = !!companyCUI;

  const isValid =
    !isCompanyInvoiceChecked || (isValidCompanyName && isValidCompanyCUI);

  useEffect(() => {
    if (!isCompanyInvoiceChecked) {
      setCompanyName('');
      setCompanyCUI('');
    }
  }, [isCompanyInvoiceChecked]);

  return {
    isCompanyInvoiceChecked,
    isValid,
    isValidCompanyName,
    isValidCompanyCUI,
    toggleIsCompanyInvoiceChecked,
    companyName,
    setCompanyName,
    companyCUI,
    setCompanyCUI,
  };
}

export function CheckoutBody({
  car,
  dateBegin,
  dateEnd,
  locationId: initialLocationId,
  guaranteeType: initialGuaranteeType,
  euExit: initialEuExit,
  extraKm: initialExtraKm
}: CheckoutBodyProps) {
  const driver = useSelector((state: ReduxState) => state.userReducer);
  const history = useHistory();
  const stripe = useStripe();

  const { currency, formatCurrency } = useCurrencyFormatter();

  const { finalInterval, ...timeForm } = useAppTimeForm({
    from: dateBegin,
    to: dateEnd,
  });
  const [locationId, setLocationId] = useState(initialLocationId);
  const [locationHint, setLocationHint] = useState('');

  const [guaranteeType, setGuaranteeType] =
    useState<keyof Guarantees>(initialGuaranteeType);

  const extraDeliveryFee =
    car.location.find((loc: CarLocation) => {
      return loc.location._id === locationId;
    })?.deliveryExtraFee || 0;

  const availability = useCarAvailabilityHourIntervals({
    car,
    interval: finalInterval,
  });

  const [extraHundredKm, setExtraHundredKm] = useState<string>(initialExtraKm);
  const [isExitEu, setIsExitEu] = useState(initialEuExit);

  const [voucherCode, setVoucherCode] = useState('');
  const [committedVoucherCode, setCommittedVoucherCode] = useState('');
  const { data: voucher } = useQuery(
    ['voucher-check', committedVoucherCode],
    async () => {
      if (!committedVoucherCode) {
        return null;
      }

      try {
        const voucher = await getVoucherByCode({ code: committedVoucherCode });
        return voucher;
      } catch (error) {
        toast.error('Cod incorect sau expirat');
      }
    },
    { retry: 1, retryDelay: 0 }
  );

  const offer = useAppComputeOffer({
    car,
    dateBegin: (finalInterval.dateBegin?.getTime() || 0) / 1000,
    dateEnd: (finalInterval.dateEnd?.getTime() || 0) / 1000,
    guaranteeType,
    extraScheduleTax: availability?.extraTax,
    euExit: isExitEu,
    extraHundredKm: parseInt(extraHundredKm),
    extraDeliveryFee,

    voucher: voucher
      ? {
          type: voucher.discountType as VoucherDiscountType,
          value: voucher.discountValue,
        }
      : undefined,
  });

  const minDaysBetween = car.settings.minDaysRental;

  const countryExitTax = car.settings.euExitFee || 0;
  const countryExitGuarantee = car.settings.euExitGuarantee || 0;

  const driverDetails = useDriverDetailsForm();
  const companyDetails = useCompanyInvoiceDetailsForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onOpenModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const { isMobile } = useAppWindowSizeInfo();

  const { data: reservations } = useAppCarReservationsQuery(
    {
      carId: car._id,
    },
    { enabled: car.type === 'car' }
  );
  const confirmReservationError = useAppOverlappingReservationError(
    finalInterval,
    reservations || []
  );

  const { identify, trackEvent } = useDriverAnalytics();

  function getUtcOffsetToBucharest() {
    const userTimeZoneOffset = moment().utcOffset();

    const bucharestUtcOffset = moment.tz('Europe/Bucharest').utcOffset();

    return (userTimeZoneOffset - bucharestUtcOffset) * 60;
  }

  const { isLoading: isButtonDisabled, mutateAsync: onSubmitRentalRequest } =
    useMutation(
      async ({ paymentMethod }: { paymentMethod: CheckoutPaymentMethod }) => {
        if (!companyDetails.isValid) {
          return;
        }

        if (confirmReservationError) {
          toast.error(confirmReservationError);
          return;
        }

        if (!driverDetails.isEverythingValid) {
          driverDetails.presentError();
          return;
        }

        identify({
          email: driverDetails.email.trim(),
          name:
            driverDetails.firstName.trim() +
            ' ' +
            driverDetails.lastName.trim(),
          phone: driverDetails.phone.trim(),
        });

        trackEvent('Checkout_Reserve_Click');

        let errorCode = 1;
        try {
          if (
            car?._id &&
            finalInterval.dateBegin &&
            finalInterval.dateEnd &&
            driverDetails.isEverythingValid
          ) {
            const commonOptions = {
              dateBegin:
                Math.floor(finalInterval.dateBegin.getTime() / 1000) +
                getUtcOffsetToBucharest(),
              dateEnd:
                Math.floor(finalInterval.dateEnd.getTime() / 1000) +
                getUtcOffsetToBucharest(),
              car: car._id,
              location: locationId,
              checkoutPaymentMethod: paymentMethod,
              companyName: !!companyDetails.companyName
                ? companyDetails.companyName
                : undefined,
              companyCUI: !!companyDetails.companyCUI
                ? companyDetails.companyCUI
                : undefined,
              extraOptions: {
                euExit: isExitEu,
                extraHundredKm: parseInt(extraHundredKm),
                extraDeliveryFee,
              },
            };

            errorCode = 2;

            const reservation = await (driver.isAuthenticated
              ? createReservation(
                  {
                    ...commonOptions,
                    user: driver?.driver?._id,
                    name: `${driverDetails.lastName.trim()} ${driverDetails.firstName.trim()}`,
                    phoneNumber: driverDetails.phone.trim(),
                    email: driverDetails.email.trim(),
                  },
                  guaranteeType,
                  voucherCode
                )
              : createAnonymousReservation(
                  {
                    ...commonOptions,
                    name: `${driverDetails.lastName.trim()} ${driverDetails.firstName.trim()}`,
                    phoneNumber: driverDetails.phone.trim(),
                    email: driverDetails.email.trim(),
                  },
                  guaranteeType,
                  voucherCode
                ));

            errorCode = 3;

            if (paymentMethod !== CheckoutPaymentMethod.Owner) {
              errorCode = 4;
              stripe?.redirectToCheckout({
                sessionId: reservation.stripeSessionId!,
              });
            } else {
              errorCode = 5;
              history.replace('/success');
            }
          }
        } catch (error) {
          toast('A aparut o eroare. Cod eroare: ' + errorCode);
        }
      }
    );

  const carsCategory = car.categoryDetails.cars.slice(0, 3);
  let splitterCount =
    carsCategory?.length >= 3 ? 2 : carsCategory?.length === 2 ? 1 : 0;

  return (
    <div className={styles.container}>
      {isModalVisible && finalInterval.dateBegin && finalInterval.dateEnd && (
        <CheckoutDatesModal
          initialLocationId={locationId}
          car={car}
          dateBegin={finalInterval.dateBegin}
          dateEnd={finalInterval.dateEnd}
          minDaysRental={minDaysBetween}
          onSubmit={(data) => {
            timeForm.setDateBegin(data.dateBegin);
            timeForm.setDateEnd(data.dateEnd);
            timeForm.setMinutesBegin(data.minutesBegin);
            timeForm.setMinutesEnd(data.minutesEnd);
            setLocationId(data.locationId);

            setIsModalVisible(false);
          }}
          onClose={() => setIsModalVisible(false)}
        />
      )}
      <div className={styles.checkoutLeft}>
        <MCParagraph fontSize={18} bold>
          Detalii inchiriere
        </MCParagraph>
        <MCSpacing />
        <CheckoutRentalDetails
          car={car}
          from={finalInterval.dateBegin}
          to={finalInterval.dateEnd}
          locationId={locationId}
          locationHint={locationHint}
          setLocationHint={setLocationHint}
          onEdit={onOpenModal}
        />

        {(car.settings.euExitActive || !!car.settings.extraHundredKmFee) && (
          <>
            <MCSpacing />
            <MCDivider fullWidth />

            <MCParagraph fontSize={18} bold>
              Extra optiuni
            </MCParagraph>

            {car.settings.euExitActive && (
              <MCRow>
                <MCCell>
                  <MCCheckbox value={isExitEu} onChange={setIsExitEu}>
                    <MCParagraph>{`Iesire in UE ( ${
                      countryExitTax
                        ? formatCurrency(countryExitTax) + ' Cost Extra'
                        : ''
                    } ${
                      countryExitGuarantee
                        ? 'si ' +
                          formatCurrency(countryExitGuarantee) +
                          ' Garantie Extra'
                        : ''
                    } )`}</MCParagraph>
                  </MCCheckbox>
                </MCCell>
              </MCRow>
            )}

            {!!car.settings.extraHundredKmFee && (
              <MCRow>
                <MCCell>
                  <div>
                    {/* 
                  // @ts-ignore */}
                    <InputSelect
                      value={extraHundredKm}
                      options={EXTRA_KM}
                      onValueChange={(e) => {
                        setExtraHundredKm(e);
                      }}
                    >
                      <MCParagraph>Extra 100 km</MCParagraph>
                    </InputSelect>
                  </div>
                </MCCell>
              </MCRow>
            )}
          </>
        )}

        <MCSpacing />
        <MCDivider fullWidth />

        <MCParagraph fontSize={18} bold>
          Optiuni de garantie
        </MCParagraph>
        <MCSpacing />
        {!!finalInterval.dateBegin && !!finalInterval.dateEnd && (
          <CheckoutGuaranteeOptions
            dateBegin={finalInterval.dateBegin}
            dateEnd={finalInterval.dateEnd}
            car={car}
            selected={guaranteeType}
            onSelectedChange={(type) => {
              trackEvent(
                type === 'integral'
                  ? 'Checkout_GuaranteeOption1Integral_Click'
                  : type === 'reduced'
                  ? 'Checkout_GuaranteeOption2Reduced_Click'
                  : 'Checkout_GuaranteeOption3Minimal_Click'
              );
              setGuaranteeType(type);
            }}
          />
        )}
        <MCSpacing />

        <MCDivider fullWidth />
        <MCParagraph fontSize={18} bold>
          Detalii sofer
        </MCParagraph>
        <MCSpacing />
        <MCSpacing />
        <CheckoutDriverDetails {...driverDetails} />

        <MCSpacing />
        <MCDivider fullWidth />
        <MCParagraph fontSize={18} bold>
          Cod de reducere
        </MCParagraph>
        <MCSpacing />

        {!voucher && (
          <>
            <MCParagraph>Aplica un cod de reducere</MCParagraph>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1 }}>
                <InputField
                  isBold
                  value={voucherCode}
                  onChange={(voucherCode) => {
                    trackEvent('Checkout_VoucherCode_Input', {
                      voucherCode,
                    });
                    setVoucherCode(voucherCode);
                  }}
                  placeholder="Voucher"
                />
              </div>
              <div
                style={{
                  background:
                    'linear-gradient(274.07deg, #F54952 1.2%, #422680 98.79%)',
                  marginLeft: 10,
                  display: 'inline-block',
                  color: '#fff',
                  fontWeight: '700',
                  cursor: 'pointer',
                  borderRadius: 10,
                  wordWrap: 'normal',
                  padding: '10px 16px',
                  fontFamily: 'Roboto',
                }}
                onClick={() => setCommittedVoucherCode(voucherCode.trim())}
              >
                Aplica acum
              </div>
            </div>
          </>
        )}
        {!!voucher && (
          <>
            <MCParagraph bold color="#58A138">
              Cod reducere {voucher.code} aplicat
            </MCParagraph>
            <MCSpacing amount={2} />
            <MCParagraph>
              reducere de{' '}
              {voucher.discountType === VoucherDiscountType.Percentage
                ? `${voucher.discountValue * 100}%`
                : formatCurrency(voucher.discountValue)}{' '}
              pentru costul total al inchirierii
            </MCParagraph>
          </>
        )}

        <MCSpacing />
        <MCDivider fullWidth />
        <MCParagraph fontSize={18} bold>
          Factura
        </MCParagraph>
        <MCSpacing />
        <CheckoutInvoice {...companyDetails} />
        <MCDivider fullWidth />
        <MCParagraph fontSize={18} bold>
          Modalitate de plata
        </MCParagraph>
        <MCSpacing />
        {currency === 'EUR' && (
          <MCParagraph>Suma finala de plata se va face in RON</MCParagraph>
        )}

        <MCSpacing />
        <MCSpacing />
        {finalInterval.dateBegin && finalInterval.dateEnd && (
          <CheckoutPayment
            beginsInExtraSchedule={availability?.beginsInExtraSchedule}
            dateBegin={finalInterval.dateBegin}
            dateEnd={finalInterval.dateEnd}
            total={offer.customerPrice}
            offer={offer}
            disableButton={
              isButtonDisabled ||
              car.status !== CAR_ACTIVE ||
              !driverDetails.isLastNameValid ||
              !driverDetails.isFirstNameValid ||
              !driverDetails.isEmailValid ||
              !driverDetails.isPhoneValid
            }
            onSubmit={(paymentMethod) =>
              onSubmitRentalRequest({ paymentMethod })
            }
            extraDeliveryFee={extraDeliveryFee}
            extraScheduleCost={availability?.extraTax}
            disabledCheckoutMethods={car.teamSettings.disabledCheckoutMethods}
          />
        )}
      </div>
      <div className={styles.checkoutRight}>
        {car.type === 'category' && carsCategory.length > 1 ? (
          <div className={styles.carColumn}>
            <div className={styles.carCategoryBanner}>
              {car.categoryDetails.name}
              <img
                className={styles.categoryBannerImage}
                src={categoryBannerImg}
                alt="Categorie masini de inchiriat"
              />
            </div>
            <MCSpacing />
            <MCParagraph fontSize={16} bold textAlign="center">
              *Veti primi oricare dintre aceste masini in functie de
              disponibilitatea flotei
            </MCParagraph>
            <MCSpacing />
            <div className={styles.carImagePreviewContainer}>
              {carsCategory.map((car) => (
                <>
                  <div className={styles.carPreview}>
                    <img
                      className={styles.carImage}
                      src={car.images[0]}
                      alt="Masini de inchiriat"
                    />
                    <div>{car.make}</div>
                    <div>{car.model}</div>
                  </div>
                  {splitterCount-- > 0 && (
                    <div className={styles.carPreviewSplitter} />
                  )}
                </>
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.carRow}>
            <img className={styles.carImg} src={car.images?.[0]} alt="car" />
            <div className={styles.carNameContainer}>
              <MCParagraph fontSize={18} bold>
                {`${car.make} ${car.model} ${car.year}`}
              </MCParagraph>
            </div>
          </div>
        )}
        <MCSpacing />
        <MCSpacing />
        <CheckoutCarDetails car={car} />
        {!isMobile && (
          <>
            <MCDivider fullWidth />
            <MCSpacing />
            <MCParagraph fontSize={18} bold>
              Detalii tarifare
            </MCParagraph>
            <MCSpacing />
            <RentOfferPrices offer={offer} />
          </>
        )}
      </div>
    </div>
  );
}
