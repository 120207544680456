import { IS_PROD_ENV } from '../../utils/constants';
import { DummyAnalytics } from './DummyAnalytics';
import { GenericAnalytics } from './GenericAnalytics';

const driverAnalytics = IS_PROD_ENV
  ? new GenericAnalytics<EventMap>()
  : new DummyAnalytics<EventMap>();

export function useDriverAnalytics() {
  return driverAnalytics;
}

type EventMap = {
  Global_RouteSwitch: {
    url: string;
  };
  Global_Phone_Click: undefined;
  Global_Intercom_Show: undefined;
  Global_Intercom_Hide: undefined;

  Navbar_Logo_Click: undefined;
  Navbar_Burger_Click: { open: boolean };
  Navbar_Catalog_Click: undefined;
  Navbar_Rentals_Click: undefined;
  Navbar_Profile_Click: undefined;
  Navbar_Locations_Hover: undefined;
  Navbar_Location_Click: { name: string };
  Navbar_Login_Click: undefined;
  Navbar_Register_Click: undefined;
  Navbar_ListCar_Click: undefined;

  Homepage_Location_Expand: undefined;
  Homepage_Location_Selected: { locationId: string; locationName: string };
  Homepage_StartingDate_Expand: undefined;
  Homepage_StartingDate_Selected: { date: string };
  Homepage_StartingHour_Expand: undefined;
  Homepage_StartingHour_Selected: { hour: string };
  Homepage_FinishingDate_Expand: undefined;
  Homepage_FinishingDate_Selected: { date: string };
  Homepage_FinishingHour_Expand: undefined;
  Homepage_FinishingHour_Selected: { hour: string };
  Homepage_FindCars_Click: undefined;
  Homepage_CarCard_Click: { price: number; carId: string };
  Homepage_CarouselLeft_Click: undefined;
  Homepage_CarouselRight_Click: undefined;
  Homepage_FullCatalog_Click: undefined;

  Footer_AboutUs_Click: undefined;
  Footer_HowItWorks_Click: undefined;
  Footer_FAQ_Click: undefined;
  Footer_Contact_Click: undefined;
  Footer_TermsAndConditions_Click: undefined;
  Footer_PrivacyPolicy_Click: undefined;
  Footer_GDPR_Click: undefined;
  Footer_Login_Click: undefined;
  Footer_Register_Click: undefined;
  Footer_Catalog_Click: undefined;
  Footer_ListCar_Click: undefined;
  Footer_FooterItem_Click: { name: string; url: string };
  Footer_SocialItem_Click: { name: string; url: string };

  Catalog_Location_Expand: undefined;
  Catalog_Location_Selected: { locationId: string; locationName: string };
  Catalog_StartingDate_Expand: undefined;
  Catalog_StartingDate_Selected: { date: string };
  Catalog_StartingHour_Expand: undefined;
  Catalog_StartingHour_Selected: { hour: string };
  Catalog_FinishingDate_Expand: undefined;
  Catalog_FinishingDate_Selected: { date: string };
  Catalog_FinishingHour_Expand: undefined;
  Catalog_FinishingHour_Selected: { hour: string };
  Catalog_FilterPrice_Expand: undefined;
  Catalog_FilterPrice_Selected: { selected: string };
  Catalog_Gearbox_Expand: undefined;
  Catalog_Gearbox_Selected: { selected: string };
  Catalog_Area_Expand: undefined;
  Catalog_Area_Selected: { selected: string };
  Catalog_Currency_Expand: undefined;
  Catalog_Currency_Selected: { selected: string };
  Catalog_CarCount_Display: {
    count: number;
    location: boolean;
    dateBegin: boolean;
    dateEnd: boolean;
  };
  Catalog_CarCard_Click: {
    price: number;
    carId: string;
    make: string;
    model: string;
    carClass: string;
    transmission: string;
    registrationNumber: string;
    otherOffers: boolean;
  };
  Catalog_Assistance_Click: undefined;
  Catalog_ListCar_Click: undefined;
  Catalog_FilterClear_Click: undefined;
  Catalog_FindCars_Click: undefined;

  CarPage_Photo_Click: { uri: string };
  CarPage_PhotoArrowLeft_Click: { uri: string };
  CarPage_PhotoArrowRight_Click: { uri: string };
  CarPage_Location_Expand: undefined;
  CarPage_Location_Selected: { locationId: string; locationName: string };
  CarPage_StartingDate_Expand: undefined;
  CarPage_StartingDate_Selected: { date: string };
  CarPage_StartingHour_Expand: undefined;
  CarPage_StartingHour_Selected: { hour: string };
  CarPage_FinishingDate_Expand: undefined;
  CarPage_FinishingDate_Selected: { date: string };
  CarPage_FinishingHour_Expand: undefined;
  CarPage_FinishingHour_Selected: { hour: string };
  CarPage_Confirm_Click: undefined;
  CarPage_Map_Click: undefined;

  AuthForm_EmailInput_Type: { email: string };
  AuthForm_PasswordInput_Type: { chars: number };
  AuthForm_ForgotPass_Click: undefined;
  AuthForm_AcceptTermsAndConditions_Click: { checked: boolean };
  AuthForm_Login_Click: undefined;
  AuthForm_Register_Click: undefined;
  AuthForm_GotoRegister_Click: undefined;
  AuthForm_GotoLogin_Click: undefined;

  LeftMenu_Profile_Click: undefined;
  LeftMenu_Rentals_Click: undefined;
  LeftMenu_Invoices_Click: undefined;
  LeftMenu_ListCar_Click: undefined;
  LeftMenu_Logout_Click: undefined;

  Profile_OpenCatalog_Click: undefined;
  Profile_ContinueOnboarding_Click: undefined;

  Rentals_Future_Click: undefined;
  Rentals_Finalized_Click: undefined;
  Rentals_Current_Click: undefined;
  Rentals_NeedAssistance_Click: undefined;

  Checkout_BackArrow_Click: undefined;
  Checkout_EditInterval_Click: undefined;
  Checkout_EditLocation_Click: undefined;
  Checkout_GuaranteeOption1Integral_Click: undefined;
  Checkout_GuaranteeOption2Reduced_Click: undefined;
  Checkout_GuaranteeOption3Minimal_Click: undefined;
  Checkout_LastNameInput_Type: { charCount: number };
  Checkout_FirstNameInput_Type: { charCount: number };
  Checkout_EmailInput_Type: { charCount: number };
  Checkout_PhoneInput_Type: { charCount: number };
  Checkout_CompanyInvoice_Checkbox: { checked: boolean };
  Checkout_CompanyName_Input: { charCount: number };
  Checkout_CompanyCUI_Input: { charCount: number };
  Checkout_VoucherCode_Input: { voucherCode: string };
  Checkout_Payment100_Click: undefined;
  Checkout_Payment20_Click: undefined;
  Checkout_PaymentOffice_Click: undefined;
  Checkout_AcceptTermsCheckbox_Click: undefined;
  Checkout_AcceptYearConditionsCheckbox_Click: undefined;
  Checkout_Reserve_Click: undefined;

  Onboarding_ResendEmail_Click: undefined; // ok
  Onboarding_SkipEmail_Click: undefined; // ok
  Onboarding_NextStep_Click: { stepNumber: number };
  Onboarding_PhoneNumberInput_Type: { charCount: number }; // ok
  Onboarding_SendCode_Click: undefined; // ok
  Onboarding_CodeInput_Type: { charCount: number }; // ok
  Onboarding_ValidateCode_Click: undefined; // ok
  Onboarding_ResendCode_Click: undefined; // ok
  Onboarding_LastNameInput_Type: { charCount: number };
  Onboarding_FirstNameInput_Type: { charCount: number };
  Onboarding_VeriffConfirm_Click: undefined;
  Onboarding_DoneToCatalog_Click: undefined;
};
