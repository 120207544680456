import completeIcon from '../../../assets/onboarding/step-completed.svg';
import { useProfileCompletionLevel } from '../../../hooks';
import { OnboardingStage } from '../../../types/api';
import './OnboardingProgress.style.css';

const OnboardingProgressBubble = ({
  completed,
  step,
  label,
}: {
  completed: boolean;
  step: number;
  label: string;
}) => {
  if (completed) {
    return (
      <div className="onboarding-progress-bubble-container">
        <img
          src={completeIcon}
          className="onboarding-progress-bubble-complete-icon"
          alt="progres-step"
        />
        <p className="onboarding-progress-bubble-label active">{label}</p>
      </div>
    );
  } else {
    return (
      <div className="onboarding-progress-bubble-container">
        <div className="onboarding-progress-bubble">
          <p className="onboarding-progress-label">{step}</p>
        </div>
        <p className="onboarding-progress-bubble-label">{label}</p>
      </div>
    );
  }
};

const OnboardingProgressLine = ({ completed }: { completed?: boolean }) => {
  return (
    <div
      className={`onboarding-progress-line ${completed ? 'complete' : ''}`}
    />
  );
};

export const OnboardingProgress = () => {
  const { isCompleted } = useProfileCompletionLevel();

  return (
    <div className="onboarding-progress-container">
      <OnboardingProgressBubble
        completed={isCompleted(OnboardingStage.EmailVerified)}
        step={1}
        label="Email"
      />
      <OnboardingProgressLine
        completed={isCompleted(OnboardingStage.EmailVerified)}
      />
      <OnboardingProgressLine
        completed={isCompleted(OnboardingStage.VerifyPhone)}
      />
      <OnboardingProgressBubble
        completed={isCompleted(OnboardingStage.PhoneVerified)}
        step={2}
        label="Telefon"
      />
      <OnboardingProgressLine
        completed={isCompleted(OnboardingStage.PhoneVerified)}
      />
      <OnboardingProgressLine
        completed={isCompleted(OnboardingStage.DriverDetails)}
      />
      <OnboardingProgressBubble
        completed={isCompleted(OnboardingStage.DriverVerified)}
        step={3}
        label="Sofer"
      />
    </div>
  );
};
