export const NotFoundPage = () => {
  return (
    <div
      className="d-flex justify-content-center row-hl"
      style={{ marginTop: 60, marginBottom: 10 }}
    >
      <h2>404 Not found</h2>
    </div>
  );
};
