import React, { useState } from 'react';
import styles from './MCTooltip.module.css';
import infoTooltipIcon from '../../../assets/owner/settings/infoTooltip.svg';
import { MCModal } from '../MCModal';

interface MCTooltipProps {
  children?: React.ReactNode | React.ReactNode[];
}

export function MCTooltip({ children }: MCTooltipProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className={styles.container}>
      <MCModal
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        children={children}
      />
      <img
        src={infoTooltipIcon}
        onClick={() => {
          setIsModalVisible(!isModalVisible);
        }}
        alt="tooltip"
      />
    </div>
  );
}
