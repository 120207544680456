import { InfoCircleFilled } from '@ant-design/icons';
import { FC } from 'react';
import style from './SummaryInfoCellHeader.module.css';

type Props = {
  title: string;
  tooltip: string;
};

export const SummaryInfoCellHeader: FC<Props> = ({ title, tooltip }) => {
  return (
    <div className={style.infoCellHeader}>
      <span className={style.title}>{title}</span>
      {false && (
        <InfoCircleFilled
          style={{
            height: 12,
            width: 12,
            color: '#C8C8C8',
          }}
        />
      )}
    </div>
  );
};
