import { TeamSettings } from '../types/api';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/constants';

export const initTeamSettings = async ({
  token,
}: {
  token: string;
}): Promise<void> => {
  try {
    await axios.post(
      `${BASE_URL}/team-settings`,
      {},
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.error('Failed to init settings');
    console.error(err);
    throw err;
  }
};

export const updateTeamSettings = async (teamSettings: TeamSettings) => {
  try {
    return await axios.put(`${BASE_URL}/team-settings`, teamSettings);
  } catch (err) {
    console.error('Failed to put teamSettings');
    console.error(err);
    throw err;
  }
};
