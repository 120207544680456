import classNames from 'classnames';
import { FC } from 'react';
import { svgDecreaseArrow, svgIncreaseArrow } from '../../../../assets';
import { MCSvgImage } from '../../../../_shared/components/MCSvgImage';
import style from './SummaryInfoCellBody.module.css';

type Props = {
  value: number;
  isIncrease: boolean;
  increaseValue: number;
  suffix: string;
};

export const SummaryInfoCellBody: FC<Props> = ({
  value,
  isIncrease,
  increaseValue,
  suffix,
}) => {
  return (
    <div className={style.infoCellBody}>
      <span className={style.bodyText}>
        {value} {suffix}
      </span>
      <span
        className={classNames(
          style.statisticsText,
          isIncrease ? style.greenColor : style.redColor
        )}
      >
        {increaseValue}
        <span className="ml-1">{<span>{suffix}</span>}</span>
        {isIncrease
          ? svgIncreaseArrow && (
              <MCSvgImage image={svgIncreaseArrow} size={14} />
            )
          : svgDecreaseArrow && (
              <MCSvgImage image={svgDecreaseArrow} size={14} />
            )}
      </span>
    </div>
  );
};
