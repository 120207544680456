import { useMutation, useQueryClient } from 'react-query';
import { createCarEvent } from '../../../api/carEvents';
import { CarEvent } from '../../../types/api';

export function useAppCreateCarEventMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (carEvent: CarEvent) => {
      return createCarEvent(carEvent);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('owner-carEvents');
      },
    }
  );
}
