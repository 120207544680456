import styles from './MCTabGroup.module.css';

export interface MCTabItem<T> {
  name: string;
  value: T;
}

export interface MCTabGroupProps<T> {
  activeTab: MCTabItem<T>;
  tabs: Array<MCTabItem<T>>;
  onActiveTabChanged: (tab: MCTabItem<T>) => void;
  capitalize?: boolean;
}

export function MCTabGroup<T extends string | number>({
  tabs,
  activeTab,
  onActiveTabChanged,
  capitalize = true,
}: MCTabGroupProps<T>) {
  return (
    <div className={styles.container}>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`${styles.tabItem} ${
            tab.value === activeTab.value ? styles.activeTabItem : ''
          }`}
          style={{ textTransform: capitalize ? 'capitalize' : undefined }}
          onClick={() => onActiveTabChanged(tab)}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
}
