import moment from 'moment';
import { useMemo } from 'react';
import { Reservation, TeamSettings } from '../../types/api';
import { MAX_MINUTES, MIN_MINUTES } from '../../utils/constants';

export enum CarUnavailabilityType {
  TooEarly = 'TooEarly',
  TooLate = 'TooLate',
  Unavailable = 'Unavailable',
}

function toRelativeDate(
  { min, max }: { min: number; max: number },
  date: Date
) {
  return {
    minDate: moment(date).startOf('day').add(min, 'minutes').toDate(),
    maxDate: moment(date).startOf('day').add(max, 'minutes').toDate(),
  };
}

export function useCarAvailability(options?: {
  teamSettings: TeamSettings;
  beginReservationDate: Date;
  endReservationDate: Date;
  beginDayReservation?: Reservation;
  endDayReservation?: Reservation;
}) {
  return useMemo(() => {
    if (!options) {
      return;
    }

    enum TimePoint {
      Start = 0,
      Reservation = 1,
      End = 2,
    }

    function getLimits(params: {
      beginSchedule: number;
      endSchedule: number;
      desiredTime: number;
      reserved?: number;
      isBeginDay: boolean;
      hasSchedule: boolean;
    }): { unavailability?: CarUnavailabilityType; min: number; max: number } {
      const result = {
        min: params.beginSchedule,
        max: params.endSchedule,
        unavailability: CarUnavailabilityType.Unavailable,
      };
      if (!params.hasSchedule) {
        return result;
      }

      const arr = [
        { time: params.beginSchedule, type: TimePoint.Start },
        { time: params.desiredTime, type: TimePoint.Reservation },
        { time: params.endSchedule, type: TimePoint.End },
      ];

      const { isBeginDay } = params;
      if (params.reserved !== undefined) {
        if (isBeginDay && params.endSchedule < params.reserved) {
          return result;
        } else if (!isBeginDay && params.beginSchedule > params.reserved) {
          return result;
        }

        arr.push({
          time: params.reserved,
          type: params.isBeginDay ? TimePoint.Start : TimePoint.End,
        });
      }

      arr.sort((a, b) => {
        const diff = a.time - b.time;
        return diff === 0 ? a.type - b.type : diff;
      });

      const idx = arr.findIndex((x) => x.type === TimePoint.Reservation);
      for (let i = arr.length - 1; i > idx; i--) {
        if (arr[i].type === TimePoint.Start) {
          return {
            unavailability: CarUnavailabilityType.TooEarly,
            min: arr[i].time,
            max: params.endSchedule,
          };
        }
      }

      for (let i = 0; i < idx; i++) {
        if (arr[i].type === TimePoint.End) {
          return {
            unavailability: CarUnavailabilityType.TooLate,
            min: arr[i].time,
            max: params.endSchedule,
          };
        }
      }

      return { min: params.beginSchedule, max: params.endSchedule };
    }

    const {
      teamSettings,
      beginReservationDate,
      endReservationDate,
      beginDayReservation,
      endDayReservation,
    } = options;

    const getMinutes = (unix: number) => {
      const m = moment.unix(unix);
      return m.diff(m.clone().startOf('day'), 'minutes');
    };

    const minutesBegin = moment(beginReservationDate).diff(
      moment(beginReservationDate).startOf('day'),
      'minutes'
    );

    const minutesEnd = moment(endReservationDate).diff(
      moment(endReservationDate).startOf('day'),
      'minutes'
    );

    const beginDayIdx = moment(beginReservationDate).isoWeekday() - 1;
    const endDayIdx = moment(endReservationDate).isoWeekday() - 1;

    const [beginScheduleStart, beginScheduleFinish] =
      teamSettings.schedule.schedule[beginDayIdx];

    const [endScheduleStart, endScheduleFinish] =
      teamSettings.schedule.schedule[endDayIdx];

    const isExtraScheduleEnabled = teamSettings.schedule.isExtraScheduleTax;

    const beginDayLimits = getLimits({
      beginSchedule: isExtraScheduleEnabled ? MIN_MINUTES : beginScheduleStart,
      endSchedule: isExtraScheduleEnabled ? MAX_MINUTES : beginScheduleFinish,
      desiredTime: minutesBegin,
      isBeginDay: true,
      reserved: beginDayReservation
        ? getMinutes(beginDayReservation.bufferDateEnd)
        : undefined,
      hasSchedule:
        isExtraScheduleEnabled ||
        teamSettings.schedule.scheduleDays[beginDayIdx],
    });

    const endDayLimits = getLimits({
      beginSchedule: isExtraScheduleEnabled ? MIN_MINUTES : endScheduleStart,
      endSchedule: isExtraScheduleEnabled ? MAX_MINUTES : endScheduleFinish,
      desiredTime: minutesEnd,
      isBeginDay: false,
      reserved: endDayReservation
        ? getMinutes(endDayReservation.bufferDateBegin)
        : undefined,
      hasSchedule:
        isExtraScheduleEnabled || teamSettings.schedule.scheduleDays[endDayIdx],
    });

    const isInsideSchedule =
      beginScheduleStart <= minutesBegin &&
      minutesBegin <= beginScheduleFinish &&
      endScheduleStart <= minutesEnd &&
      minutesEnd <= endScheduleFinish;

    const beginsInExtraSchedule =
      !teamSettings.schedule.scheduleDays[beginDayIdx] ||
      minutesBegin < beginScheduleStart ||
      minutesBegin > beginScheduleFinish;

    const endsInExtraSchedule =
      !teamSettings.schedule.scheduleDays[endDayIdx] ||
      minutesEnd < endScheduleStart ||
      minutesEnd > endScheduleFinish;

    let extraScheduleTaxQuantity = 0;
    if (isExtraScheduleEnabled) {
      extraScheduleTaxQuantity =
        beginsInExtraSchedule && endsInExtraSchedule ? 2 : 1;
    }

    const isUnavailable =
      beginDayLimits.unavailability === CarUnavailabilityType.Unavailable ||
      endDayLimits.unavailability === CarUnavailabilityType.Unavailable;

    const isDayExtrataxAvailableOnly = !(
      teamSettings.schedule.scheduleDays[beginDayIdx] &&
      teamSettings.schedule.scheduleDays[endDayIdx]
    );

    return {
      isUnavailable,
      pickup: {
        ...beginDayLimits,
        ...toRelativeDate(beginDayLimits, beginReservationDate),
      },
      retrieval: {
        ...endDayLimits,
        ...toRelativeDate(endDayLimits, endReservationDate),
      },
      isOutsideSchedule: !isInsideSchedule,
      beginsInExtraSchedule,
      endsInExtraSchedule,
      extraTax:
        isExtraScheduleEnabled &&
        (!isInsideSchedule || isDayExtrataxAvailableOnly)
          ? teamSettings.schedule.extraScheduleTax * extraScheduleTaxQuantity
          : 0,
    };
  }, [options]);
}
