import moment from 'moment';
import { CustomDatePicker } from './CustomDatePicker';
import { CustomHourPicker } from './CustomHourPicker';
import './InputFormDate.style.css';

interface Props {
  minimumDate?: Date;
  maximumDate?: Date;
  selectedDate?: Date;
  label: string;
  onDateSelected: any;
  minutes: number;
  minMinutes?: number;
  maxMinutes?: number;
  onMinutesChanged: (minutes: number) => void;
  disabledIntervals?: Array<{ begin: Date; end: Date }>;
  isDisabled?: (date: moment.Moment) => boolean;
  disableHourPicker?: boolean;
}

export const InputFormDate = ({
  minimumDate,
  maximumDate,
  label,
  selectedDate,
  onDateSelected,
  minutes,
  minMinutes,
  maxMinutes,
  onMinutesChanged,
  disabledIntervals = [],
  disableHourPicker = false,
}: Props) => {
  const dateLabel = selectedDate
    ? `${selectedDate.getDate()}/${
        selectedDate.getMonth() + 1
      }/${selectedDate.getFullYear()}`
    : `--/--/----`;

  return (
    <div className="input-date-container">
      <p className="input-date-text">{label}</p>
      <div className="input-date-inner-container">
        <CustomDatePicker
          disabledIntervals={disabledIntervals}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          onDateSelected={(date: Date) => {
            const dateWithMinutes = moment(date)
              .startOf('day')
              // .add(minutesState, 'minutes')
              .toDate();
            onDateSelected(dateWithMinutes);
          }}
          selectedDateLabel={dateLabel}
        />
        {!disableHourPicker && (
          <CustomHourPicker
            minutes={minutes}
            onMinutesChange={onMinutesChanged}
            minMinutes={minMinutes}
            maxMinutes={maxMinutes}
          />
        )}
      </div>
    </div>
  );
};
