import { useCallback, useMemo } from 'react';
import { svgCarType } from '../../../assets';
import { Car } from '../../../types/api';
import { InputSelect } from '../../../_shared/components/InputSelect/InputSelect';
import { MCSvgImage } from '../../../_shared/components/MCSvgImage';
import { useAppCarsQuery } from '../../hooks/queries';

interface CarSelectProps {
  selectedCar: Car;
  onChangeCar: (car: Car) => void;
}

export function CarSelect({ selectedCar, onChangeCar }: CarSelectProps) {
  const { data: cars } = useAppCarsQuery();
  const toOption = useCallback((car: Car) => {
    return {
      name: `${car?.make} ${car?.model} ${car?.year} - ${car.registrationNumber}`,
      value: car._id || '',
    };
  }, []);

  const carSelectOptions = useMemo(() => {
    return (cars || []).map(toOption);
  }, [cars, toOption]);

  return (
    <InputSelect
      options={carSelectOptions}
      value={selectedCar._id}
      onValueChange={(x) => {
        const found = cars?.find((car) => car._id === x);
        if (found) {
          onChangeCar(found);
        }
      }}
      image={<MCSvgImage image={svgCarType} size={13} />}
      bold
    />
  );
}
