import React, { useMemo } from 'react';
import {
  MCCell,
  MCCheckbox,
  MCHeadline,
  MCParagraph,
  MCSpacing,
} from '../../../_shared/components';
import { InputPriceField } from '../../../_shared/components/InputField/InputField';
import styles from './BasePrices.module.css';
import { CarSettings } from '../../../types/api';
import { createSetHandlerFactory } from '../../../_shared/util';
import { useOwnerAnalytics } from '../../hooks';

interface BasePricesProps {
  carId: string;
  basePrices: CarSettings;
  onBasePricesChange: (prices: CarSettings) => void;
  extraScheduleFee: number;
  onExtraScheduleFeeChange: (amount: number) => void;
}

export function BasePrices({
  carId,
  basePrices,
  onBasePricesChange,
  extraScheduleFee,
  onExtraScheduleFeeChange,
}: BasePricesProps) {
  const { trackEvent } = useOwnerAnalytics();
  const createSetter = useMemo(() => {
    return createSetHandlerFactory(basePrices, onBasePricesChange);
  }, [basePrices, onBasePricesChange]);
  return (
    <>
      <MCSpacing />
      <MCSpacing />

      <div>
        <MCHeadline>Detalii</MCHeadline>
      </div>
      <MCSpacing />

      <div className={styles.flexRow}>
        <MCCell>
          <div className={styles.detailsLabel}>
            <MCParagraph>Depozit garantie</MCParagraph>
          </div>
          <div className={styles.inputFieldWidth}>
            <InputPriceField
              value={basePrices.guarantees.integral.guarantee}
              type="number"
              onChange={(guarantee) => {
                trackEvent('Prices_Deposit_Type', { carId, amount: guarantee });
                onBasePricesChange({
                  ...basePrices,
                  guarantees: {
                    ...basePrices.guarantees,
                    integral: {
                      ...basePrices.guarantees.integral,
                      guarantee,
                    },
                  },
                });
              }}
            />
          </div>
          <MCParagraph>Ron</MCParagraph>
        </MCCell>
        <MCCell>
          <MCParagraph>Masina poate iesi din tara</MCParagraph>
          <MCSpacing vertical={false} />
          <MCCheckbox
            value={basePrices.euExitActive}
            onChange={(checked) => {
              trackEvent('Prices_ExitEuCheck_Click', { carId, checked });
              createSetter('euExitActive')(checked);
            }}
          />
        </MCCell>
      </div>
      <div className={styles.detailsAndExtraOptionsContainer}>
        <div className={styles.halfPane}>
          <div>
            <MCSpacing />
            <div className={styles.flexRow}>
              <div className={styles.detailsLabel}>
                <MCParagraph>100 km extra</MCParagraph>
              </div>
              <MCCell>
                <div className={styles.inputFieldWidth}>
                  <InputPriceField
                    value={basePrices.extraHundredKmFee}
                    type="number"
                    onChange={(amount) => {
                      trackEvent('Prices_Extra100km_Type', { carId, amount });
                      createSetter('extraHundredKmFee')(amount);
                    }}
                  />
                </div>
                <MCParagraph>Ron</MCParagraph>
              </MCCell>
            </div>
            <MCSpacing />
          </div>
        </div>
        <div className={styles.halfPane}>
          <div>
            {basePrices.euExitActive && (
              <>
                <MCSpacing />

                <div className={styles.flexRow}>
                  <MCCell>
                    <MCParagraph>Taxa iesire in EU</MCParagraph>
                  </MCCell>
                  <MCCell>
                    <div className={styles.inputFieldWidth}>
                      <InputPriceField
                        value={basePrices.euExitFee}
                        type="number"
                        onChange={(amount) => {
                          trackEvent('Prices_ExitEuFee_Type', {
                            carId,
                            amount,
                          });
                          createSetter('euExitFee')(amount);
                        }}
                      />
                    </div>
                    <MCParagraph>Ron</MCParagraph>
                  </MCCell>
                </div>

                <MCSpacing />

                <div className={styles.flexRow}>
                  <MCCell>
                    <MCParagraph>Garantie extra in EU</MCParagraph>
                  </MCCell>
                  <MCCell>
                    <div className={styles.inputFieldWidth}>
                      <InputPriceField
                        value={basePrices.euExitGuarantee}
                        type="number"
                        onChange={(amount) => {
                          trackEvent('Prices_ExitEuDeposit_Type', {
                            carId,
                            amount,
                          });
                          createSetter('euExitGuarantee')(amount);
                        }}
                      />
                    </div>
                    <MCParagraph>Ron</MCParagraph>
                  </MCCell>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
