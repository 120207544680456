import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { svgBackButton } from '../../../assets';
import { MCSpacing } from '../MCSpacing';
import { MCParagraph } from '../Typography';
import styles from './MCPagination.module.css';

interface MCPaginationProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  perPage: number;
  total: number;
}

export function MCPagination({
  currentPage,
  setCurrentPage,
  total,
  perPage,
}: MCPaginationProps) {
  const totalPages = Math.ceil(total / perPage);
  const lastPage = totalPages;

  const {
    showFirstElipsis,
    showLastElipsis,
    showFirstPage,
    showLastPage,
    centralPageNumbers,
  } = useMemo(() => {
    const centralPageNumbers = [];
    for (
      let i = Math.max(currentPage - 2, 1);
      i <= Math.min(lastPage, currentPage + 2);
      i++
    ) {
      centralPageNumbers.push(i);
    }

    const showFirstElipsis = centralPageNumbers[0] >= 3;
    const showLastElipsis =
      lastPage - centralPageNumbers[centralPageNumbers.length - 1] >= 2;
    const showFirstPage = centralPageNumbers[0] >= 2;
    const showLastPage = !centralPageNumbers.includes(lastPage);

    return {
      showFirstElipsis,
      showLastElipsis,
      showFirstPage,
      showLastPage,
      centralPageNumbers,
    };
  }, [currentPage, lastPage]);

  const onPressPage = useCallback(
    (page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  const firstIndex = (currentPage - 1) * perPage + 1;

  return (
    <>
      {totalPages > 1 && (
        <div className={styles.container}>
          <div
            className={classNames(styles.pageBlock, {
              [styles.arrowDisabled]: currentPage === 1,
            })}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            <img src={svgBackButton} className={styles.arrow} alt="arrow" />
          </div>
          {showFirstPage && (
            <div className={styles.pageBlock} onClick={() => onPressPage(1)}>
              <MCParagraph fontSize={18}>1</MCParagraph>
            </div>
          )}
          {showFirstElipsis && (
            <div className={styles.pageBlockUnclickable}>
              <MCParagraph fontSize={18}>...</MCParagraph>
            </div>
          )}
          {centralPageNumbers.map((x) => (
            <div
              key={x}
              className={styles.pageBlock}
              onClick={() => onPressPage(x)}
            >
              <MCParagraph fontSize={18} bold={x === currentPage}>
                {x}
              </MCParagraph>
            </div>
          ))}
          {showLastElipsis && (
            <div className={styles.pageBlockUnclickable}>
              <MCParagraph fontSize={18}>...</MCParagraph>
            </div>
          )}
          {showLastPage && (
            <div
              className={styles.pageBlock}
              onClick={() => onPressPage(lastPage)}
            >
              <MCParagraph fontSize={18}>{lastPage}</MCParagraph>
            </div>
          )}
          <div
            className={classNames(styles.pageBlock, {
              [styles.arrowDisabled]: currentPage === lastPage,
            })}
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            <img
              src={svgBackButton}
              className={styles.arrowReverse}
              alt="arrow"
            />
          </div>
        </div>
      )}
      <MCSpacing />
      {!!total && (
        <div className={styles.container}>
          <MCParagraph>
            Rezultate: {firstIndex} -{' '}
            {Math.min(firstIndex + perPage - 1, total)} din {total}
          </MCParagraph>
        </div>
      )}
    </>
  );
}
