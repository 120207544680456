import { FC } from 'react';
import style from './InfoPerCarHead.module.css';

export const InfoPerCarHead: FC = () => {
  return (
    <div className={style.titlesContainer}>
      <span className="col-2 p-0">Masini</span>
      <span className="col-2 p-0">Venit total</span>
      <span className="col-2 p-0">Venit / zi inchiriere</span>
      <span className="col-2 p-0">Clienti</span>
      <span className="col-2 p-0">Zile de inchiriere</span>
      <span className="col-2 p-0">Nr. de zile / inchiriere</span>
    </div>
  );
};
