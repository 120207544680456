import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteLocation, getLocations } from '../../../api/locations';
import { Table } from '../../../_shared/components/Table';

export const LocationsPage = () => {
  const [locations, setLocations] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const asyncGetLocations = async () => {
      const locations = await getLocations({});
      setLocations(locations);
    };

    asyncGetLocations();
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const onRemove = async (id: string) => {
    setTimeout(() => {
      refreshPage();
    }, 300);
    await deleteLocation(id);
  };

  return (
    <div
      className="d-flex justify-content-center row-hl col-sm-6 col-md-8"
      style={{ marginTop: 60, marginBottom: 10 }}
    >
      <div className="col-6">
        <div>
          <h2>Locations</h2>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push(`/location/new`);
            }}
          >
            Add Location
          </button>
          <Table
            keys={['name', 'lat', 'long']}
            data={locations}
            onEdit={(id: string) => {
              history.push(`/location/${id}`);
            }}
            onRemove={onRemove}
          />
        </div>
      </div>
    </div>
  );
};
