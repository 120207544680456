import { useMutation, useQueryClient } from 'react-query';
import { deleteReservation } from '../../../api/reservations';
import { Reservation } from '../../../types/api';

export function useAppDeleteReservationMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (reservation: Reservation) => {
      if (!reservation._id) {
        return;
      }
      return deleteReservation(reservation._id);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('car-reservations');
        queryClient.invalidateQueries('available-cars');
        queryClient.invalidateQueries('owner-reservations');
        queryClient.invalidateQueries('owner-reservations-events');
      },
    }
  );
}
