import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { getDriverAction } from '../redux/actions/driverAction';
import { DriverRouting } from '../_driver/DriverRouting';
import { AdminRouting } from '../_admin/AdminRouting';
import { OwnerRouting } from '../_owner/OwnerRouting';
import { PlatformType, PLATFORM_TYPE } from '../utils/constants';

export const Routing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.authToken) {
      dispatch(getDriverAction());
    }
  }, [dispatch]);

  // switch domains
  switch (PLATFORM_TYPE) {
    case PlatformType.Admin: {
      return <AdminRouting />;
    }
    case PlatformType.Owner: {
      return <OwnerRouting />;
    }
    default: {
      return <DriverRouting />;
    }
  }
};
