import React from 'react';
import styles from './MCProgress.module.css';

interface Props {
  percent: number;
}

export function MCProgress({ percent }: Props) {
  return (
    <div
      className={styles.largeProgress}
      style={{ backgroundColor: '#2806594d', borderRadius: 10 }}
    >
      <div
        className={styles.largeProgress}
        style={{
          width: `${percent}%`,
          backgroundColor: '#280659',
          background:
            'linear-gradient(to left, rgba(245,73,82,1),rgba(66,38,128,1))',
          borderRadius: 10,
        }}
      ></div>
    </div>
  );
}
