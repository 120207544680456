type BaseMap = Record<string, object | undefined>;

export class DummyAnalytics<EventMap extends BaseMap> {
  public trackEvent = <EventName extends keyof EventMap & string>(
    event: EventName,
    ...params: EventMap[EventName] extends undefined
      ? []
      : [EventMap[EventName]]
  ) => {};

  public withEvent = <EventName extends keyof EventMap & string>(
    event: EventName,
    ...params: EventMap[EventName] extends undefined
      ? []
      : [EventMap[EventName]]
  ) => {
    return <T extends (...args: any) => any>(func: T) => {
      return (...funcArgs: Parameters<T>) => {
        return func(...funcArgs);
      };
    };
  };

  public identify = ({ email, name }: { email: string; name: string }) => {};
}
