import React from 'react';
import {
  MCGenericLeftNavigator,
  MCGenericLeftNavigatorProps,
} from '../MCGenericLeftNavigator';
import { MCTitle } from '../Typography/MCTitle';
import styles from './MCTwoPaneLayout.module.css';

interface MCTwoPaneLayoutProps extends MCGenericLeftNavigatorProps {
  children?: React.ReactNode | React.ReactNodeArray;
  title: string;
}

export function MCTwoPaneLayout({
  children,
  title,
  ...leftMenuProps
}: MCTwoPaneLayoutProps) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <MCGenericLeftNavigator {...leftMenuProps} />
        <div className={styles.mainContainer}>
          <div className={styles.titleContainer}>
            <MCTitle>{title}</MCTitle>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
