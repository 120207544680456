import { useMutation, useQueryClient } from 'react-query';
import { updateCarSettings } from '../../../api/cars';
import { CarSettings } from '../../../types/api';

export function useAppUpdateCarSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      carId,
      carSettings,
    }: {
      carId: string;
      carSettings: CarSettings;
    }) => {
      return updateCarSettings(carId, carSettings);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('car-list');
      },
    }
  );
}
