import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types/redux';
import { Separator } from '../../../_shared/components/Separator';
import { ProfileCompletion } from './ProfileCompletion';
import './ProfileData.style.css';

const STATUS_VERIFY = 'se verifica';
const STATUS_VERIFIED = 'verificat';
const STATUS_UNVERIFIED = 'neverificat';

export const ProfileData = () => {
  const driver = useSelector((state: ReduxState) => state.userReducer);

  const renderProfileRow = (
    description: string,
    label?: string,
    status?: string
  ) => {
    return (
      <div className={`profile-data-row ${status ? 'center' : ''}`}>
        <p className="profile-data-row-label bold">{description}</p>
        <p className="profile-data-row-label">{label ?? 'necompletat'}</p>
        {status && (
          <div className="profile-data-row-status-container">
            <p className="profile-data-row-label auto">{status}</p>
            <div
              className={`profile-data-row-status-dot ${
                status === STATUS_VERIFY
                  ? 'orange'
                  : status === STATUS_UNVERIFIED
                  ? 'red'
                  : ''
              }`}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="profile-data-container">
      <p className="profile-data-header">Date cont</p>
      <Separator />
      <div className="profile-data-inner-container">
        <div className="profile-data-container-left">
          {renderProfileRow('Nume:', driver?.driver?.lastName)}
          {renderProfileRow('Prenume:', driver?.driver?.firstName)}
          {renderProfileRow(
            'Email:',
            driver?.driver?.email,
            driver?.driver?.onboarding?.isEmailVerified
              ? STATUS_VERIFIED
              : STATUS_UNVERIFIED
          )}
          {renderProfileRow(
            'Telefon:',
            driver?.driver?.onboarding?.phone ?? 'necompletat',
            driver?.driver?.onboarding?.isPhoneVerified
              ? STATUS_VERIFIED
              : STATUS_UNVERIFIED
          )}
        </div>
        <ProfileCompletion />
      </div>
    </div>
  );
};
