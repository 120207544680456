import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useAppCarReservationsQuery } from '../../../hooks';
import { Reservation } from '../../../types/api';
import {
  MCParagraph,
  MCSpacing,
  MCTwoPaneLayout,
} from '../../../_shared/components';
import { AgendaGridlines } from '../../components/AgendaGridlines';
import { AgendaHeader } from '../../components/AgendaHeader';
import { AgendaReservation } from '../../components/AgendaReservation';
import { DateNavigator } from '../../components/DateNavigator';
import { ReservationTooltip } from '../../components/ReservationTooltip';
import { useLeftNavigatorOwnerProps, useOwnerAnalytics } from '../../hooks';
import { useAppCarsQuery } from '../../hooks/queries';
import styles from './Overview.module.css';

const LEFT_WIDTH = 150;
const RIGHT_WIDTH = 980 - LEFT_WIDTH;
const ROW_HEIGHT = 150;

export function Overview() {
  const props = useLeftNavigatorOwnerProps();
  const { trackEvent } = useOwnerAnalytics();

  const [currentDate, setCurrentDate] = useState(new Date());
  const { firstDay, lastDay } = useMemo(() => {
    const firstDay = moment(currentDate).startOf('day').toDate();
    const lastDay = moment(currentDate)
      .startOf('day')
      .add(2, 'week')
      .subtract(1, 'day')
      .endOf('day')
      .toDate();

    const days: Array<Date> = [];
    for (let i = 0; i < 14; i++) {
      days.push(moment(currentDate).add(1, 'days').toDate());
    }

    return { firstDay, lastDay, days };
  }, [currentDate]);

  const onNext = useCallback(() => {
    trackEvent('Overview_Next_Click');
    setCurrentDate((d) => moment(d).add(2, 'weeks').toDate());
  }, [trackEvent]);

  const onPrev = useCallback(() => {
    trackEvent('Overview_Previous_Click');
    setCurrentDate((d) => moment(d).subtract(2, 'weeks').toDate());
  }, [trackEvent]);

  const { data: cars } = useAppCarsQuery();

  const { data: reservations } = useAppCarReservationsQuery({
    beginDate: firstDay,
    endDate: lastDay,
    carId: cars?.map((x) => x._id) || [],
  });

  const [clickedReservation, setClickedReservation] = useState<Reservation>();
  const onClearSelection = useCallback(
    () => setClickedReservation(undefined),
    [setClickedReservation]
  );

  const [marginTop, setMarginTop] = useState(0);

  return (
    <MCTwoPaneLayout title="Prezentare generală" {...props}>
      <MCSpacing />
      <MCSpacing />
      <DateNavigator
        firstDay={firstDay}
        lastDay={lastDay}
        onNext={onNext}
        onPrev={onPrev}
      />
      <MCSpacing />
      <MCSpacing />
      <AgendaHeader
        startDate={firstDay}
        leftWidth={LEFT_WIDTH}
        rightWidth={RIGHT_WIDTH}
      />
      <MCSpacing />
      <div className={styles.content}>
        <div style={{ width: LEFT_WIDTH }}>
          {cars?.map((car) => {
            return (
              <div
                key={car._id}
                style={{ height: ROW_HEIGHT }}
                className={styles.car}
              >
                <div>
                  <MCParagraph>{`${car.make} ${car.model}`}</MCParagraph>
                </div>
                <div>
                  <MCParagraph bold>{`${car.registrationNumber}`}</MCParagraph>
                </div>
                <a
                  href={`https://app.masinilacheie.ro/car/${car.baseUrl}`}
                  onClick={() =>
                    trackEvent('Overview_Car_Click', {
                      carId: car._id,
                      carMake: car.make,
                      carModel: car.model,
                    })
                  }
                >
                  <img
                    src={car.images[0]}
                    className={styles.carThumbnail}
                    alt="car-thumbnail"
                  />
                </a>
              </div>
            );
          })}
        </div>
        <div
          style={{
            width: RIGHT_WIDTH,
            height: (cars?.length || 0) * ROW_HEIGHT,
            position: 'relative',
          }}
        >
          <AgendaGridlines />
          {reservations?.map((reservation, idx) => {
            const carIdx = (cars || [])?.findIndex(
              (car) => car._id === reservation.car._id
            );

            if (carIdx === -1) {
              return null;
            }

            return (
              <AgendaReservation
                key={reservation._id}
                {...{
                  carIdx,
                  firstDay,
                  lastDay,
                  gridHeight: (cars?.length || 0) * ROW_HEIGHT,
                  gridWidth: RIGHT_WIDTH,
                  reservation: reservation,
                  rowHeight: ROW_HEIGHT,
                  onClick: () => {
                    trackEvent('Overview_Rental_Click', {
                      reservationMongoId: reservation._id,
                      reservationId: reservation.reservationId,
                    });
                    setClickedReservation(reservation);
                    setMarginTop(ROW_HEIGHT * carIdx);
                  },
                }}
              />
            );
          })}
        </div>
      </div>
      <ReservationTooltip
        reservation={clickedReservation}
        onClearSelection={onClearSelection}
        marginTop={marginTop}
      />
    </MCTwoPaneLayout>
  );
}
