import { Routing } from '../../routing/Routing';
import { ToastContainer } from 'react-toastify';
import { hotjar } from 'react-hotjar';

hotjar.initialize(2744123, 6);

export const Setup = () => {
  return (
    <div>
      <Routing />
      <ToastContainer position="top-center" autoClose={2000} />
    </div>
  );
};
